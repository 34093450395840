@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.swipeElement {
  gap: 0.6rem;
  flex-wrap: wrap;
  margin-bottom: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.elementSizeText {
  color: #a4a5a9;
  @include update_fonts($font_weight__400, $font_size__16);
}

.swipeColumn {
  padding: 0 1rem;
  height: 7.3125rem;
  border-radius: 0.625rem;
  border: 1px solid #d9d9d9;
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.swipeUp {
  margin-left: 3rem;
  margin-bottom: -0.813rem;
}

.swipeRight {
  margin-left: 3.53rem;
}

.swipeDown {
  margin-left: 3rem;
  margin-top: -0.875rem !important;
}

.swipeLength {
  width: 19.5625rem;
  padding: 1.5rem 0rem 1.5rem 1.9rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.swipeLengthText {
  padding: 0.44rem;
  background: $bgDarkGray;
  @include update_fonts($font_weight__500, $font_size__14);
}

.percentageInput {
  border-radius: 0.375rem !important;
  width: 6.25rem;
  height: 2.125rem;
  padding: 0.375rem 0.5rem 0.375rem 0.625rem;
  gap: 3.1875rem;
  border: 0.06rem solid $silver;
  display: $display__flex;
  align-items: $align_items__center;
}

.swipeLabel {
  color: $textGray;
  @include update_fonts($font_weight__600, $font_size__16);
}

.swipeDirectionText {
  color: $blue;
  @include update_fonts($font_weight__700, $font_size__16);
}

.swipeDirectionIcons {
  width: 2.44444rem;
  height: 2.44444rem;
  padding: 0.55556rem 0.2rem;
  flex-direction: column;
  gap: 0.55556rem;
  border-radius: 0.55556rem;
  border: 0.889px solid $Gray;
  background: $white;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.selectedSwipe {
  border: 0.06rem solid $blue;
}

.swipeLength {
  display: block;
}

.swipeLengthInputOrText {
  gap: 0.62rem;
  margin-top: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.swipeAction {
  margin-top: 0.6rem;
}
