$main_Header_BackgroundColor: linear-gradient(171deg, #050b20 0%, #394694 100%);

$font_family__Causten: "Causten";

// color
$white: #ffff;
$darkGray: #d9d9d9;
$transparent: transparent;
$Dark__Blue: #3772ff;
$lightGray: #585858;
$textGray: #4a4d53;
$Gray: #e6e6e6;
$lightGreen: #5dba7c;
$red: rgb(243, 55, 55);
$blue: #01a3e3;
$Brilliance: #fcfcfd;
$pink: #ff188f;
$lightBlue: #e4f3f9;
$textLightBlue: #01a3e3;
$darkBlue: #002858;
$bgColorLightBlue: #f2f4f7;
$disable: #d3d3d3;
$disableTextColor: #7f7f7f;
$blueTextColor: #444aea;
$textColor: #414356;
$darkGrayColor: #2c2c2c;
$borderColor: #dfdfdf;
$hoverBgColor: #d3f3ff;
$grayColor: #535456;
$bgGray: #e6e8ec;
$lightGrayTextColor: #9b9b9b;
$borderBlue: #d3f3ff;
$separator: #dbdbdb;
$disableText: #6e7282;
$grayBorder: #e9e9e9;
$bgDarkGray: #dcdcdc;
$lightPurple: #f6f9ff;
$purple: #d1effc;
$black: black;
$orange: #ff8a1e;
$lightPurpleBorder: #dde5f6;
$silver: #cdced0;
$gray: #505862;
$grayIconColor: #848484;
$textBlack: #000;
$navyBlue:#111548;
$light_green:#00c361;
$Dark_Black:#202020;
$dark_green:#00ab55;
$text_Red:#ee0b0b;

// height
$height__10: 10%;
$height__20: 20%;
$height__30: 30%;
$height__40: 40%;
$height__50: 50%;
$height__60: 60%;
$height__70: 70%;
$height__80: 80%;
$height__90: 90%;
$height__100: 100%;

// width
$width__10: 10%;
$width__20: 20%;
$width__30: 30%;
$width__40: 40%;
$width__50: 50%;
$width__60: 60%;
$width__70: 70%;
$width__80: 80%;
$width__90: 90%;
$width__100: 100%;

// display
$display__none: none;
$display__flex: flex;
$flex__wrap: wrap;
$display_inline__block: inline-block;
$justify_content__center: center;
$justify_content__end: flex-end;
$justify_content__start: start;
$justify_content__space_between: space-between;

// align items
$align_items__center: center;
$text_align__center: center;
$text_align__right: right;

// font-size
$font_size__10: 0.625rem;
$font_size__12: 0.75rem;
$font_size__13: 0.8125rem;
$font_size__14: 0.875rem;
$font_size__15: 0.9375rem;
$font_size__16: 1rem;
$font_size__17: 1.06rem;
$font_size__18: 1.125rem;
$font-size__19: 1.1875rem;
$font_size__20: 1.25rem;
$font_size__22: 1.375rem;
$font_size__24: 1.5rem;
$font_size__26: 1.63rem;
$font_size__28: 1.8rem;
$font_size__32: 2rem;
$font_size__34: 2.13rem;

// font-weight
$font_weight__400: 400;
$font_weight__500: 500;
$font_weight__600: 600;
$font_weight__700: 700;
$font_weight__800: 800;
$font_weight__900: 900;

// border
$border_radius__5: 0.3125rem;
$border__none: none;

// cursor
$cursor__not_allowed: not-allowed;
$cursor__pointer: pointer;

// media query
$max_width__300: 18.75rem;
$max_width__400: 25rem;
$max_width__600: 37.5rem;
$max_width__767: 47.9375rem;
$max_width__1199: 74.9375rem;
$max_width__1470: 91.875rem;
$max_width__1562: 97.625rem;
$max_width__1600: 100rem;
$max_width__1670: 104.38rem;


$min_width__300: 18.75rem;
$min_width__750: 46.875rem;
$min_width__768: 48rem;
$min_width__1200: 75rem;
$min_width__1470: 91.875rem;
$min_width__1562: 97.625rem;
