@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.test_Data_label {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.drawer_Title {
  color: #4a4d53;
  @include update_fonts($font_weight__700, $font_size__18);
}

.add_Test_Data_Btn {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__end;
}

.test_Data_Details {
  gap: 0.6rem;
  flex-direction: column;
  display: $display__flex;
}

.table_Details {
  margin-top: 22px;
}

//table details
.action-icon {
  display: $display__flex;
  justify-content: $justify_content__end;
}

.test--Data-Action__Buttons {
  margin-left: 0.6rem;
  cursor: $cursor__pointer;
}

.testDataName {
  max-width: 10.2rem;
  margin-bottom: 0 !important;
  color: #000000b8;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__600, $font_size__15);
}

.label {
  color: #6d6d6d;
  @include update_fonts($font-weight__400, $font_size__14);
}

.label-name {
  @include update_fonts($font-weight__400, $font_size__14);
}