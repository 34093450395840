@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.modalFooter {
  margin-right: 1.125rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.releaseDeviceBtn {
  width: 5.44rem;
  height: 2.25rem;
  padding: 0px 0.945rem;
  border-radius: 0.43rem;
  color: $white;
  background: rgb(255, 24, 143);
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.releaseDeviceBtn:hover {
  color: $white;
  background: rgb(255, 24, 143);
}

.releaseDeviceBtn[disabled],
.releaseDeviceBtn[disabled]:hover {
  background: #e6e6e6;
  color: $disableText;
}

.continueBtn {
  width: 5.44rem;
  height: 2.19rem;
  color: #7d7e82;
  border-radius: 0.43rem !important;
  border: 0.086rem solid #7d7e82 !important;
  background: $white;
  @include update_fonts($font_weight__500, $font_size__20);
}

.continueBtn:hover {
  border-radius: 0.43rem;
  color: #7d7e82;
  border: 0.086rem solid #7d7e82;
  background: $white;
}

.notes {
  margin-top: 0.9rem;
  @include update_fonts($font_weight__700, $font_size__18);
}

.counterTime {
  color: rgb(255, 24, 143);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.modalContent {
  @include update_fonts($font_weight__500, $font_size__16);
}

.lastInteractionLabel{
  @include update_fonts($font_weight__700, $font_size__16);
}