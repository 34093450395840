@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.selector {
  padding-right: 0.9rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.selectionText {
  color: $textGray;
  @include update_fonts($font_weight__500, $font_size__17);
}

.selectMode {
  border-radius: 0.3rem;
  border: 0.06rem solid #d6d6d6;
  background: $white;
  width: 100%;
  margin-top: 0.4rem;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
  }
}

.load-device-icon-and-text {
  padding: 0.3rem;
  gap: 0.44rem;
  border-radius: 0.44rem;
  margin-top: 0.56rem;
  background: rgba(214, 149, 23, 0.13);
  display: inline-flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.load-device-text {
  color: #d69517;
  @include update_fonts($font_weight__500, $font_size__16);
}

@media screen and (min-width: 86.5rem) and (max-width: 119.93rem), (min-width: 61.11rem) and (max-width: 75rem) {
  .selector {
    display: block;
  }
}
