@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.select--all-box {
  margin-left: 0.4rem;
  margin-bottom: 0.625rem;
  padding-top: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.invitation__Buttons {
  margin-left: 0.6rem;
  cursor: $cursor__pointer;
}

.spinner_Center {
  min-height: 70vh;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.email_Text {
  color: $navyBlue;
  @include update_fonts($font_weight__600, $font_size__16);
}

.team_Name {
  @include update_fonts($font_weight__600, $font_size__14);
}

.header {
  color: #8c8c8c;
  box-sizing: border-box;
  -webkit-transition: top 0.2s;
  transition: top 0.2s;
  font-size: 1.3rem !important;
  height: 3.5rem;
  background: linear-gradient(171deg, #050b20 0%, #394694 100%);
  border-bottom: 0.06rem solid #c3c5d2;
  width: 100%;
  position: fixed;
  z-index: 10;
  padding: 0 1.25rem;
  top: 0rem;
  left: 0rem;
  text-align: $text_align__center;
}

.header_Title {
  color: $white;
  @include update_fonts($font_weight__700, $font-size__24);
}

.back_Icon {
  position: relative;
  width: 3.44rem;
  right: -1.25rem;
  border-left: 0.06rem solid #ddd;
  height: 3.44rem;
  padding-top: 0.81rem;
  text-align: $text_align__center;
}

.border_Right {
  border-right: 0.06rem solid #c3c5d2;
  height: 2.375rem;
  margin: 0 1rem;
}
