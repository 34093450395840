@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.actionIcons {
  gap: 0.6rem;
  display: $display__flex;
}

.styledRow {
  margin: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__end;
  align-items: $align_items__center;
}

.pageScroll {
  height: calc(100vh - 8.625rem);
  overflow-y: scroll;
}

.stylesCol {
  display: $display__flex;
  justify-content: $justify_content__end;
}

.addIcon {
  margin-right: 0.6rem;
}

.table {
  width: 100%;
  margin-top: 0.6rem;
  padding: 0.6rem;
}

.pagination {
  float: right;
  margin-top: 0.6rem;
}
