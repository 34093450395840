@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.cookie_Consent {
  min-height: 1.63rem;
  color: #eee;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  backdrop-filter: blur(4px);
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
  background-color: rgba(55, 114, 255, 0.88);
  @include update_fonts($font-weight__400, $font_size__14);
}

.cookie_Consent button,
.cookie_Consent button:hover {
  height: 2.25rem;
  border-radius: 0.75rem;
  padding: 1.13rem;
  color: $Dark__Blue;
  background-color: $white;
  display: $display__flex;
  text-align: $text_align__center;
  align-items: $align_items__center;
  @include update_fonts($font_weight__500, $font_size__18);
}

.cookie_Text {
  color: $white;
  @include update_fonts($font_weight__500, $font_size__16);
}

.cookie_Text a {
  color: $textBlack !important;
  margin-left: 0.13rem;
  margin-right: 0.13rem;
}