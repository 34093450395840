@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.replaceElement {
  margin-top: 0.6rem;
  padding: 0.3rem;
  gap: 0.4375rem;
  border-radius: 0.375rem;
  float: left;
  background: $blue;
  color: $bgColorLightBlue;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__500, $font-size__14);
}

.disableButton {
  background-color: $Gray;
  color: $disableText;
  cursor: $cursor__not_allowed !important;
}

.selectImage {
  display: $display__flex;
}

.bounds {
  color: $textBlack;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__600, $font-size__16);
}

.stepImageOnHover {
  max-height: 31.25rem;
}

.draw_Selected_Area {
  padding: 2.6rem;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  flex-direction: column;
  display: $display__flex;
  align-items: $align_items__center;
  text-align: $text_align__center;
  justify-content: $justify_content__center;
}

.replace_Element_Btn {
  height: 3rem;
}

.selected_Image_Details {
  border-radius: 0.9rem;
  background: #f8faff;
  height: 100%;
  padding: 2.6rem;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  flex-direction: column;
  background-size: contain;
  border: 0.06rem solid $borderColor;
  display: $display__flex;
  align-items: $align_items__center;
  text-align: $text_align__center;
  justify-content: $justify_content__center;
}
.selected_Image_Container {
  max-height: 100%;
}
.selected_Image {
  display: contents;
  max-width: 100%;
  max-height: 100%;
}

.image {
  width: auto;
  max-height: 100%;
  max-width: 100%;
}

.bound_Details_And_Replace_Btn {
  flex-direction: column;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

@media (max-width: 86.5rem), (max-width: 61.11rem) {
  .image {
    max-height: 500px;
  }
}
@media (max-width: 74.94rem) {
  .image {
    max-height: 1005;
  }
}
