@import "../../../styles/variables.scss";

.emptyRecords {
  flex-direction: column;
  display: $display__flex;
  height: 100%;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.content {
  margin-top: 0.625rem;
}

.label {
  color: #2c2c2c;
  font-size: $font_size__18;
  font-weight: $font_weight__700;
}

.description {
  color: #585858;
  font-weight: 400;
  font-size: $font_size__14;
}

.margin {
  margin-top: 1.25rem;
}
