@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.save_Btn,
.save_Btn:hover {
  background-color: #20a160 !important;
  height: 2.5rem !important;
  border-radius: 0.6rem !important;
  padding:  0.25rem 0.81rem !important;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  span {
    color: $white !important;
    @include update_fonts($font-weight__600, $font_size__16);
  }
  &[disabled],
  &:disabled {
    background-color: #e8e8e8 !important;
    span {
      color: $disableText !important;
    }
  }
}

.reset_Btn,
.reset_Btn:hover {
  background-color: transparent !important;
  height: 2.44rem !important;
  border: 0.06rem solid #2855bf !important;
  border-radius: 0.6rem !important;
  padding: 0.25rem 0.56rem !important;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  span {
    color: #2855bf !important;
    @include update_fonts($font-weight__600, $font_size__16);
  }
  &[disabled],
  &:disabled {
    border: 0.06rem solid #9a9a9a !important;
    span {
      color: $disableText !important;
    }
  }
}
