@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.tapByIndex {
  width: 9.75rem;
  padding: 0.2rem 0.2rem 0.2rem 0.7rem;
  border-radius: 0.5rem;
  border: 0.06rem solid $silver;
  display: $display__flex;
  justify-content: $justify_content__space_between;
  align-items: $align_items__center;
}

.selectIndex {
  border-radius: 0.3rem;
  width: 6rem;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
}

.insertText {
  background: #dcdcdc;
  width: 4.938rem;
  padding: 0.4rem;
}
