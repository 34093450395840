@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.header_Options {
  max-width: 43.75rem;
  overflow: auto;
  padding: 0.625rem 0;
  gap: 0.625rem;
  margin-right: 0.625rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.disableButtonForAddStep {
  gap: 0.625rem;
  @include designer_header_button($disable);
}

.buttonForActionStep {
  @include designer_header_options_button($white, $textGray, $Gray);
}

.buttonForAIStepBlue {
  background-color: $Dark__Blue !important;
  @include designer_header_options_button($Dark__Blue, $textGray, $Gray);
}

.buttonForAIStepWhite {
  background-color: $white !important;
  @include designer_header_options_button($Dark__Blue, $textGray, $Gray);
}

.buttonForStopPlaying {
  gap: 0.625rem;
  @include designer_header_options_button($pink, $white, $Gray);
}

.buttonForActionStep,
.buttonForStopPlaying,
.disableButtonForAddStep > span {
  white-space: nowrap;
  font-size: $font_size__13;
}

.label {
  @include update_fonts($font_weight__500, $font_size__14);
}

.spinner {
  width: 1.87rem;
  height: 1.87rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.separatorName {
  margin-right: 4px;
  color: #3f5065;
  font-style: normal;
  line-height: normal;
  @include update_fonts($font_weight__600, $font_size__18);
}

.buttonActions {
  display: $display__flex;
  align-items: $align_items__center;
}

.border_Right {
  height: 2.375rem;
  border-right: 0.0625rem solid $separator;
  margin: 0 2px;
}

.actionsDropdown {
  overflow: hidden;
  border-radius: 1.375rem;
  box-shadow: 0rem 0.0625rem 0.375rem 0rem rgba(0, 0, 0, 0.3);
  background: $white;
}

.action-title {
  color: #6e7282;
  padding: 0.5rem 0.3rem;
  margin: 0 0.6rem;
  border-bottom: 0.06rem solid rgba(0, 0, 0, 0.06);
  @include update_fonts($font_weight__400, $font_size__13);
}

.more_Options_Icon {
  display: $display__none;
}

.show_Test_Data_Details {
  gap: 0.625rem;
  display: $display__flex;
}

.show_Results_Icon {
  gap: 0.625rem;
  display: $display__flex;
}

.show_Results_Icon ~ .more_Options_Icon {
  display: $display__flex;
}

@media (max-width: $max_width__1470) {
  .headerOptions {
    margin-right: 0.5rem;
    display: $display__flex;
    align-items: $align_items__center;
    justify-content: $justify_content__end;
  }
}

@media (max-width: $max_width__1470) and (min-width: $min_width__1200) {
  .more_Options_Icon {
    display: $display__flex;
  }
  .show_Test_Data_Details {
    display: $display__none;
  }
}

@media (max-width: $max_width__1470) {
  .buttonForActionStep > span {
    white-space: nowrap;
    display: $display__none;
  }
  .buttonForStopPlaying > span {
    white-space: nowrap;
    display: $display__none;
  }
  .disableButtonForAddStep > span {
    white-space: nowrap;
    display: $display__none;
  }
}
