@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.project_Details {
  margin-top: 1.25rem;
}

.main_Header {
  top: 3.38rem;
  padding: 0rem 1.3rem 0rem 1.3rem;
  height: 4.13rem;
  background-color: #f2f4f7;
  border-bottom: 0.06rem solid #ccc;
}

.main-Header-Text {
  color: #002858;
  @include update_fonts($font_weight__700, $font_size__20);
}

.header_Title {
  color: #202020;
  @include update_fonts($font_weight__600, $font_size__20);
}

.aws_Project_Details {
  display: $display__flex;
  align-items: $align_items__center;
}

.Search_And_validate_Btn,
.Search_And_validate_Btn:hover {
  background-color: #3772ff !important;
  height: 2.38rem !important;
  border-radius: 0.6rem !important;
  padding: 0.25rem 0.81rem !important;
  span {
    color: $white !important;
    @include update_fonts($font-weight__600, $font_size__16);
  }
  &[disabled],
  &:disabled {
    background-color: #e8e8e8 !important;
    span {
      color: $disableText !important;
    }
  }
}

.divider {
  background-color: #9a9a9a !important ;
}

.page_Scroll {
  height: calc(100vh - 138px);
  overflow-y: scroll;
}

.API_Key_And_Revoke_Button {
  margin: 0.6rem 0 0.9rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.generate_API_Key_Btn {
  margin: 0.6rem 0 0.9rem;
}

.generated_Key_Container {
  padding: 0.69rem 0.6rem 0.6rem 1.25rem;
  border-radius: 0.25rem;
  border: 0.06rem solid #d6d6d6;
  margin: 0.75rem 0;
}