@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.main_Header {
  top: 3.38rem;
  padding: 0rem 1.3rem 15px 1.3rem;
  background-color: #f2f4f7;
}

.main-Header-Text {
  color: #002858;
  @include update_fonts($font_weight__700, $font_size__20);
}

.key_Name_Add_Icon {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.delete_Icon {
  margin-bottom: 0.6rem;
}

.checkbox_And_Text {
  gap: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.import_XML_Files {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.import_Application_Strings {
  padding: 1.3rem;
}

.add_Application_String_Scroll {
  max-height: calc(100vh - 14.81rem);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
}

.action_Icon {
  margin-bottom: 8px;
  width: 40px;
  justify-content: normal;
  display: $display__flex;
}

.uploaded_Text {
  padding: 6px 10px;
  background: #d8e7dd;
  border-radius: 100px;
  font-size: 12px;
  color: #297042;
  margin: 2px;
  white-space: nowrap;
}

.uploaded_Badge {
  color: #297042;
  margin-right: 0.08rem;
  vertical-align: middle;
  white-space: nowrap;
}

.uploading_Text {
  padding: 6px 10px;
  background: #fff15c85;
  color: #000;
  border-radius: 100px;
  font-size: 12px;
  margin: 2px;
  white-space: nowrap;
}

.checkBox_In_Header {
  display: $display__flex;
  align-items: $align_items__center;
}

.header_Button_Group {
  margin: 0px 20px;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.select--all-box {
  margin-left: 0.4rem;
  margin-bottom: 0.625rem;
  padding-top: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.checkBox_And_Key {
  display: $display__flex;
  gap: 3px;
}

.sub_Header {
  margin: 0.6rem;
  margin-right: 1.3rem;
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__end;
}

.page_Scroll {
  height: calc(100vh - 8.625rem);
  overflow-y: scroll;
}

.clear_All_String {
  display: $display__flex;
  justify-content: $justify_content__end;
}

.action_Icons {
  gap: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.select_All_Box {
  margin-bottom: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.application_String_Key {
  width: 29.75rem;
}

.add_Application_String_Btn,
.add_Application_String_Btn:hover {
  @include common_button($dark_green, $white, 2.5rem);
}
