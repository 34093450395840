@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.elementTitle {
  flex: 1;
  display: $display__flex;
}

.elementActionButtonHide {
  width: 100%;
}

.elementTextInput {
  padding: 0.9375rem;
  width: 100%;
  height: 2.75rem;
  color: $lightGray !important;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
  @include update_fonts($font_weight__400);
}

.elementDeleteOrDisable {
  gap: 1.1875rem;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__600);
}

.inputStyle {
  width: 75%;
  border: 0rem solid $Gray !important;
  color: $darkGrayColor;
  background: transparent !important;
  font-size: $font_size__16 !important;
  @include update_fonts($font_weight__500);
}

.inputStyle .ant-input-disabled {
  background: transparent !important;
}

.recTestStepSelectorBorder {
  width: 50%;
  height:fit-content;
  border-top: 0.063rem solid #e8e8e8;
  border-right: 0.063rem solid $darkGray;
}

.recTestStepSelector {
  overflow: hidden;
  text-overflow: ellipsis !important;
  padding: 0.5rem 0rem 0.19rem 0.9rem;
  width: 100% !important;
}

.elementDivider {
  margin: 0.13rem 0rem !important;
  font-size: 0.625rem !important;
}

.verificationRules {
  border-top: 0.063rem solid #e8e8e8;
  padding: 0.5rem 1.25rem 2rem 1.25rem;
  width: 50%;
}

.addChildElement {
  float: right;
  margin-right: 20px;
}

.addChildElementText {
  color: $darkBlue;
  cursor: $cursor__pointer;
  text-align: $text_align__center;
  @include update_fonts($font_weight__600, $font_size__14);
}

.disableText {
  color: $disableText !important;
  cursor: $cursor__not_allowed !important;
}

.row {
  width: 100%;
  height: 27.94rem;
  overflow-y: auto;
  display: $display__flex;
}

.addChild {
  margin-top: 0.6rem;
  width: 100%;
}

.inputTitle {
  color: $grayColor;
  @include update_fonts($font_weight__600, $font_size__16);
}

.inputTitleOrInputBox {
  width: $width__100;
}

.border {
  height: 1.875rem;
  width: 0.0625rem;
  background: $lightPurpleBorder;
}

.switchText {
  color: $textGray;
  @include update_fonts($font_weight__500, $font_size__16);
}

.switch {
  gap: 0.75rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.addRules {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.recTestStepSelectionImage {
  width: 100% !important;
}

@media (max-width: 86.5rem),(max-width:61.11rem) {
  .row {
    display: block;
  }
  .verificationRules {
    width: 100%;
  }
  .recTestStepSelectorBorder {
    width: 100%;
  }
}
@media (max-width: 74.94rem) {
  .row {
    display: $display__flex;
  }
}