@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.textColorBlack {
  color: $black;
}

.content {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  line-height: 1;
  vertical-align: baseline;
  border-radius: 0.25rem;
  padding: 0.3rem;
  background-color: lightgray;
  color: $black;
  text-align: $text_align__center;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__500, $font_size__14);
}

.datIndex {
  margin-left: 0.3rem;
  margin-top: 0.3rem;
}
