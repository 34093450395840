.inspector-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.inspector-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-bottom: 12px;
  background: linear-gradient(180deg, #e2e2e2, #e0e0e0, 10%, #d6d6d6, 50%, #cacaca);
  border-top: 1px solid #f5f4f5;
  border-bottom: 1px solid #a5a4a5;
  -webkit-app-region: drag;
}

.inspector-toolbar button {
  border-color: #cacaca;
  -webkit-app-region: no-drag;
}

.inspector-main {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0em;
  width: 100%;
  height: 100%;
}

.inspector-main .screenshot-container {
  max-width: 500px;
  flex-grow: 1;
  flex-shrink: 2;
  flex-basis: 400px;
  height: 100%;
  width: 100%;
}
.inspector-main .screenshot-container .ant-spin-nested-loading,
.inspector-main .screenshot-container .ant-spin-container {
  height: 100%;
  width: 100%;
}

.inspector-main .screenshot-container img {
  max-width: 100%;
  max-height: 100%;
  border: 5px solid black;
  border-radius: 5px;
}

.inspector-main .screenshot-container .screenshotBox {
}

.inspector-main .interaction-tab-container {
  position: relative;
  flex-grow: 3;
  flex-basis: 550px;
  min-width: 380px;
  padding-bottom: 1em;
  padding-left: 1em;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.inspector-main :global(.ant-card-head) {
  max-height: 48px;
  padding-left: 10px;
}

.inspector-main :global(.ant-card-body) {
  height: calc(100% - 48px);
  padding: 5px;
}

.inspector-main .interaction-tab-container :global(.ant-tabs) {
  height: 100%;
}

.inspector-main .interaction-tab-container :global(.ant-tabs-content) {
  height: 100%;
}

.inspector-main .interaction-tab-container :global(.action-row) {
  display: flex;
  height: 100%;
}
.inspector-main .interaction-tab-container :global(.action-col) {
  min-width: 50%;
  /* max-width: 40%; */
}

.inspector-main .interaction-tab-container :global(.ant-card) {
  height: 100%;
}

.inspector-main .interaction-tab-container :global(.ant-tree) {
  overflow: auto;
}

.tree-container {
  height: 100%;
}

.tree-container :global(ul.ant-tree) {
  height: 100%;
  overflow: auto;
}

.inspector-main .interaction-tab-container .scroll-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 1em;
  padding-bottom: 1em;
}

.inspector-main .interaction-tab-container .scroll-buttons :global(button) {
  margin: 1em;
}

.inspector-main .interaction-tab-container .scroll-buttons .scroll-right {
  float: right;
}

.inspector-main .element-detail-container {
  flex-grow: 2;
  flex-basis: 360px;
  min-width: 300px;
}

.inspector-main .recorded-actions {
  margin-bottom: 12px;
  max-height: 40%;
  min-height: 30%;
  flex: 1;
}

.inspector-main .recorded-actions :global(.ant-btn-group) {
  margin-left: 10px;
}

.inspector-main .recorded-actions :global(.ant-card-body),
.inspector-main .interaction-tab-card :global(.ant-card-body) {
  overflow: scroll;
}

.inspector-main .interaction-tab-card {
  flex: 2;
}

.inspector-main :global(.ant-card-body::-webkit-scrollbar) {
  background: transparent;
  width: 14px;
}

.inspector-main .interaction-tab-container .interaction-tab-card {
  height: 100%;
  padding-bottom: 3em;
}

.highlighter-box {
  position: absolute;
  opacity: 0.5;
  cursor: pointer;
}

.advanceSelector-box {
  position: absolute;
  opacity: 0.1;
}

.advanceSelector-box.inspected-element-box {
  opacity: 10.5 !important;
  color: blue;
}

.highlighter-box.inspected-element-box div {
  visibility: hidden;
  border-radius: 9px;
  border: 3px solid #f00;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.28);
}

.highlighter-box.suggested-element-box div {
  background-color: lightpink;
  visibility: hidden;
}

.highlighter-box.hovered-element-box div {
  visibility: hidden;
  border-radius: 9px;
  border: 3px solid var(--Secondary, #01a3e3);
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.6);
}

.highlighter-box.inspected-element-box.hovered-element-box div {
  border: 4px solid blue;
  background-color: rgba(0, 128, 0, 0.507);
}

.highlighter-box.suggested-element-box.hovered-element-box div {
  background-color: green;
}

.highlighter-box.hovered-element-box div,
.highlighter-box.inspected-element-box div {
  position: relative;
  height: 100%;
  width: 100%;
  visibility: visible;
}
.highlighter-box.hovered-element-box div,
.highlighter-box.suggested-element-box div {
  position: relative;
  height: 100%;
  width: 100%;
  visibility: visible;
}

.elementActions {
  margin-bottom: 20px;
}

.selected-element-card {
  display: flex;
  flex-direction: column;
}

.selected-element-card :global(.ant-card-body) {
  flex: 1;
  overflow: auto;
}

.selected-action-lists-card {
  display: flex;
  flex-direction: column;
}

.selected-action-lists-card :global(.ant-card-body) {
  flex: 1;
  overflow: auto;
}

.sourceTag {
  color: #7d2020;
  font-weight: normal;
}

.sourceAttrName {
  color: #864da1;
  font-style: italic;
}

.no-recorded-actions {
  font-style: italic;
  margin-bottom: 20px;
}

.recorded-code {
  font-family: monospace;
  white-space: pre-wrap;
  word-break: break-all;
  margin-bottom: 20px;
}

.framework-dropdown {
  width: 150px;
}

.locator-test-interactions-container {
  float: right;
  margin-top: 0.5em;
}

.locator-test-interactions-container div {
  display: inline-block;
  width: 50%;
  padding: 4px;
}

.locator-test-interactions-container .send-keys-container {
  width: 100%;
}

.locator-test-interactions-container .send-keys-container input {
  width: 76%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.locator-test-interactions-container .send-keys-container button {
  position: absolute;
  right: 0;
  width: 25%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.locator-test-interactions-container div button,
.locator-test-interactions-container div input {
  width: 100%;
}

.back-link-container {
  margin-bottom: 0.5em;
}

.locator-search-results {
  width: 100%;
}

.locator-strategy-selector {
  width: 100%;
  margin-bottom: 1em;
}
.coordinatesContainer {
  position: absolute;
  background: rgba(255, 250, 205, 0.8);
  z-index: 10000000;
  padding: 2px;
}
.swipeInstructions {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100000000;
  background-color: white;
  padding: 4px;
  width: 10em;
}
.swipeSvg {
  position: absolute;
  z-index: 100000000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.swipeSvg line {
  stroke-width: 15;
  stroke: rgba(255, 153, 153, 0.8);
  stroke-linecap: round;
}

.swipeSvg circle {
  r: 10;
  fill: rgba(255, 153, 153, 0.8);
}
.swipeSvg rect {
  fill: blue;
  stroke: pink;
  stroke-width: 2;
  fill-opacity: 0.1;
  stroke-opacity: 0.9;
}

.innerScreenshotContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.innerScreenshotContainer .screenshotActionsPanel {
  margin-bottom: 2px;
  margin: 0 auto 2px auto;
}

.action-controls {
  margin-right: 20px;
}

.actions-container .btn-container {
  padding: 16px;
}

.actions-container .arg-row {
  margin-bottom: 8px;
}

.arg-container {
  padding: 4px 4px 0 0;
}

.actions-container :global(.ant-select) {
  width: 100%;
}

.actions-container :global(.ant-btn) {
  width: 100%;
}

.elementSection {
  background-color: transparent;
  margin-top: 5px;
  width: 100%;
}
.elementSectionCol {
  width: 100% !important;
}

.lst-selected-elements-cards .ant-btn {
  margin-left: 15px !important;
  border: none !important;
  font-size: 14px !important;
}

.actionCardList {
  width: 100%;
}

.ant-checkbox-wrapper {
  font-size: 14px;
}

.ant-card-head {
  padding: 5px !important;
}

.ant-card-extra {
  padding: 0px !important;
}

.lstSelectedElementsCards {
  margin: 0px 10px 10px 0px;
  width: 400px;
  float: left;
}

.cardHeaderIconContainer {
  bottom: 1px;
  position: relative;
}
.removeCard {
  margin-left: 5px;
  border: none;
  font-size: 14px;
  height: 0px;
}

.removeCard i {
  vertical-align: bottom;
  font-size: 16px;
}
.cardRow {
  padding: 2px;
  border-bottom: 1px solid #e8e8e8;
  min-width: 75px;
}

.cardLables {
  font-weight: 600;
}

.cardRow:last-child {
  border-bottom: none;
}

.inputMaxLoadTime {
  width: 60px;
}

.wordWrap {
  word-wrap: break-word;
}
.group-indication {
  border: 1px solid #1890ff61;
  border-radius: 6px;
}
.skip-Indication {
  border: 1px solid #1890ff33;
  border-radius: 6px;
}
.normal-indication {
  border: 1px solid #00000012;
  border-radius: 6px;
}
