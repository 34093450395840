:root {
  --app-font-color: rgba(0, 0, 0, 0.65);
  --app-sub-font-color: rgba(0, 0, 0, 0.45);
  --app-font-size: 14px;
}

html {
  --antd-wave-shadow-color: #65676b !important;
}

a {
  color: var(--app-sub-font-color);
}

::selection {
  background: #65676b !important;
}

.header-fixed .header-limiter {
  max-width: 1600px;
  text-align: center;
  margin: 0 auto;
}

.header-fixed .app-logo {
  height: 52px;
  width: auto;
  margin-top: -10px;
}

.cert-value-error input[type="text"]:focus,
.issue-url-error input[type="text"]:focus,
.sign-on-url-value-error input[type="text"]:focus,
.sign-on-logout-request-url-value-error input[type="text"]:focus,
.sign-on-logout-service-url-value-error input[type="text"]:focus {
  border-color: red !important;
}

.sso-configuration-input-field {
  width: 100%;
  border: 1px solid rgba(145, 145, 145, 0.65) !important;
  border-radius: 2px !important;
  height: 30px !important;
  background-color: rgb(249, 249, 249) !important;
}

.cert-value-error,
.issue-url-error,
.sign-on-url-value-error,
.sign-on-logout-request-url-value-error,
.sign-on-logout-service-url-value-error {
  border-color: red !important;
}

/* .ant-tabs-nav .ant-tabs-tab {
  margin: 0 8px 0 0 !important;
} */

.ant-tabs-nav .ant-tabs-tab-active {
  color: #444aea !important;
}

.ant-tabs-ink-bar {
  background-color: #444aea !important;
}

/* .ant-tabs-nav .ant-tabs-tab:hover {
  color: #444aea !important;
} */

.ant-tabs-bar {
  border-bottom: 1px solid #e8e8e85c !important;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  -webkit-box-shadow: 0 0 0 1px #65676b50 !important;
  box-shadow: 0 0 0 1px #65676b50 !important;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #65676b !important;
  color: white !important;
}

.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item-selected {
  border: none;
}

.ant-select-dropdown-menu-item-selected {
  font-weight: normal !important;
}

.ant-checkbox-checked::after {
  border: 1px solid #65676b;
}

.lineheight-3 {
  line-height: 3;
}

/*The header placeholder. It is displayed when the header is fixed to the top of thebrowser window, in order to prevent the content of the page from jumping up. */

.header-fixed-placeholder {
  height: 50px;
  display: none;
}

/* Logo */

.header-fixed .header-limiter h1 {
  float: left;
  font: normal 28px Cookie, Arial, Helvetica, sans-serif;
  line-height: 40px;
  margin: 0;
}

.header-fixed .header-limiter h1 span {
  color: #5383d3;
}

/* The navigation links */

.header-fixed .header-limiter a {
  color: #ffffff;
  text-decoration: none;
}

.header-fixed .header-limiter nav {
  font: 16px Arial, Helvetica, sans-serif;
  line-height: 40px;
  float: right;
}

.header-fixed .header-limiter nav a {
  display: inline-block;
  padding: 0 5px;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.9;
}

.header-fixed .header-limiter nav a:hover {
  opacity: 1;
}

.header-fixed .header-limiter nav a.selected {
  color: #608bd2;
  pointer-events: none;
  opacity: 1;
}

/* Fixed version of the header */

body.fixed .header-fixed {
  padding: 10px 40px;
  height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Goto Icon */

.goto-icon {
  font-size: 1.4em;
  float: left;
  position: relative;
  top: 0px;
  left: 18px;
}

/* Home Screen */

.server-list-container {
  margin: 10px 10px 10px 0;
}

.devices-list-container {
  margin-left: 4.16666667%;
  max-width: 1500px;
  margin-right: 4.16666667%;
}

.devices-list-container-top-border {
  box-shadow: 0 1px 1px 0 rgba(225, 225, 225, 0.65);
  height: 1px;
  color: #8c8c8c;
  box-sizing: border-box;
  -webkit-transition: top 0.2s;
  transition: top 0.2s;
  line-height: 1px;
}

.device-card {
  max-width: 250px;
  padding-top: 24px !important;
  width: auto;
  margin: 0 auto !important;
  width: 100%;
}

.devices-list-container .ant-card-cover svg {
  font-size: 84px !important;
  margin: 0 auto;
  margin-top: 8px;
  margin-bottom: 8px;
}

.devices-list-container .ant-card-actions svg {
  font-size: 18px !important;
}

.device-interection-view {
  height: inherit;
  min-width: 380px;
  border-right: 1px solid #c6c6c670;
  border-left: 1px solid #c6c6c670;
}

.device-properties-view {
  height: inherit;
}

.recording-device-recording-section {
  padding: 5px 0px 0px 10px;
}

/* Recording Button */

.recording-button-container {
  float: right;
  margin-right: 20px;
}

.recording-button {
  border: 0;
  border-radius: 3px;
  outline: none;
  padding: 6px 10px 5px 10px;
  color: white;
  background-color: #008ecc;
  cursor: pointer;
  width: 195px;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .recording-button {
    border: 0;
    border-radius: 3px;
    outline: none;
    padding: 6px 10px 5px 10px;
    color: white;
    background-color: #008ecc;
    cursor: pointer;
    width: 100px;
    text-align: center;
    font-size: 10px;
  }
}

/* Recording Status */

.device-view-screen-zindex {
  z-index: 100;
}

.recording-status {
  text-align: center;
  font-size: 13px;
  color: #65676b90;
  margin-bottom: 10px;
}

.recording-mode-status {
  color: #f04d5b;
}

.selected-device-name {
  font-weight: bold;
  font-size: 14px;
  padding-left: 15px !important;
  line-height: 2;
  padding-top: 0px;
  text-align: center;
}

.right-view {
  height: calc(100vh - 172px);
}

.step-view {
  height: 45%;
  border-bottom: "1px solid #c6c6c670";
  overflow: "auto";
}

.step-view-full {
  height: 100%;
  overflow: "auto";
}

/* Device View */

.device-view {
  height: calc(100vh - 200px);
}

.device-actions-view {
  height: 80px;
  padding: 0px 15px 0px 15px;
  margin: 0 auto;
}

.swipe-up {
  position: absolute;
  left: 48%;
  z-index: 10;
}

.swipe-down {
  position: absolute;
  bottom: -120px;
  left: 48%;
}

.swipe-right {
  position: absolute;
  top: 48%;
  right: -28px;
}

.swipe-left {
  position: absolute;
  top: 48%;
  left: -30px;
}

.device-margin-with-case .swipe-up {
  position: absolute;
  top: -70px;
  left: 48%;
}

.device-margin-with-case .swipe-down {
  position: absolute;
  left: 48%;
}

.device-margin-with-case .swipe-right {
  position: absolute;
  top: 48%;
}

.device-margin-with-case .swipe-left {
  position: absolute;
  top: 48%;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid black;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid black;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 10px solid black;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 10px solid black;
}

.swipe-up span {
  position: absolute;
  left: 57px;
  top: -3px;
  font-size: 11px;
}

.swipe-down span {
  position: absolute;
  left: 57px;
  top: -4px;
  font-size: 11px;
}

.swipe-right span {
  position: absolute;
  left: 17px;
  top: 14px;
  font-size: 11px;
}

.swipe-left span {
  position: absolute;
  left: 10px;
  top: 14px;
  font-size: 11px;
}

.swipe-up input[type="text"],
.swipe-down input[type="text"],
.swipe-left input[type="text"],
.swipe-right input[type="text"] {
  width: 25px;
  height: 14px;
  border: 1px solid #d9d9d9 !important;
  color: #8c8c8c;
  font-size: 11px;
  position: absolute;
  background: transparent;
  text-align: right;
  margin-left: 10px !important;
}

.swipe-up input[type="text"] {
  top: -2px;
  left: 15px;
}

.swipe-down input[type="text"] {
  top: -3px;
  left: 15px;
}

.swipe-left input[type="text"] {
  top: 15px;
  right: -1px;
  text-align: left;
}

.swipe-right input[type="text"] {
  top: 15px;
  left: -7px;
}

.in-center {
  max-width: 400px;
  margin: 0 auto;
}

.devices-actions {
  position: absolute;
  /* z-index: 9999; */
  box-shadow: 0 4px 4px 0 rgba(225, 225, 225, 0.65);
  top: 40 px !important;
}

.devices-actions .blank-actions-item {
  padding: 5px;
  text-align: center;
  border-right: none;
  border-bottom: none;
  font-weight: bold;
  background: rgb(206, 204, 204);
  margin: 0.5px;
  min-height: 15px;
  cursor: move;
  background: #f8f8ff;
  padding: 5px;
  margin: 0px;
  border-bottom: 1px solid black;
}

.devices-actions .platform-actions-item {
  padding: 5px;
  text-align: center;
  border-right: none;
  border-bottom: none;
  font-weight: bold;
  background: rgb(206, 204, 204);
  margin: 0.5px;
  min-height: 15px;
  cursor: move;
  background: #f8f8ff;
  padding: 5px;
  margin: 0px;
  border-top: 1px solid black;
}

.devices-actions .actions-item {
  padding: 3px;
  text-align: center;
  border-right: none;
  border-bottom: none;
  font-weight: bold;
  max-height: 33px;
  background: #f8f8ff;
  margin: 0px;
  min-height: 26px;
  padding: 7px;
}

.devices-actions .actions-item-top-border {
  text-align: center;
  border-top: 1px solid black;
  height: 28px;
  line-height: 2.1em;
}

.devices-actions .actions-item.last-item {
  width: calc(40% - 3px);
  max-width: calc(40% - 3px);
}

.devices-actions {
  cursor: pointer;
  max-width: 90%;
  z-index: 999;
  margin: 0 auto;
  background: #f8f8ff;
  border: 1px solid black;
  top: 80px !important;
}

/* Patch FontAwesome */

.svg-icon-inline--fa {
  height: unset !important;
}

.session-expired .svg-icon-inline--fa.fa-w-20 {
  width: 1.3em !important;
}

.step-action-type .svg-icon-inline--fa.fa-w-14 {
  width: 1.4em !important;
}

.step-action-type .fa-w-12 {
  width: 1.2em !important;
}

.step-action-type .fa-w-16,
.step-action-type .fa-w-18 {
  width: 1.4em !important;
}

.step-action-type .fa-w-18 {
  margin-bottom: 2px;
}

.actions-item .svg-icon-inline--fa {
  font-size: 18px;
}

/* Recording sub header */

.recording-sub-header {
  height: 25px;
  box-shadow: 0 1px 1px 0 rgba(225, 225, 225, 0.65);
  color: #8c8c8c;
  box-sizing: border-box;
  text-align: center;
  font-size: 1.1em !important;
}

/* Recording-Header */

.recording-header {
  height: 36px !important;
  border-bottom: 1px solid #c6c6c670;
}

.recording-name {
  padding-left: 8px;
  vertical-align: middle;
  line-height: 2;
  width: 100% !important;
  line-height: 1.1 !important;
}

.recording-name input {
  border-radius: unset;
  background: transparent;
  cursor: initial;
  color: #8c8c8c;
  width: 100%;
  border: unset !important;
  margin-top: -1px;
}

.recording-name-resize {
  padding: 4px 0 0 0;
}

.recording-save-button {
  margin-top: 4px;
}

@media only screen and (max-width: 1200px) {
  .recording-name-resize {
    widows: 80px !important;
    padding: 4px 0 0 0;
  }

  .recording-save-button {
    width: 100% !important;
    padding-top: 3px !important;
    padding: 3px 0 0 0;
  }

  .index-select-box {
    width: 100% !important;
  }
}

.recording-name div:nth-child(1),
.selected-step-name div:nth-child(1) {
  width: 20px;
  float: left;
  margin-top: 9px;
}

.selected-step-name div:nth-child(1) {
  padding-top: 5px;
}

.recording-name div:nth-child(2) {
  width: 115px;
  float: left;
  font-weight: bold;
  margin-top: 8px;
  word-wrap: none;
}

@media only screen and (max-width: 900px) {
  /* .recording-name div:nth-child(2){
		width: 70px;
		float: left;
		font-weight: bold;
		margin-top: 7px;
		font-size:10px;
	} */
  .save-button {
    cursor: pointer;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    font-size: 10px !important;
    width: fit-content;
    text-align: center;
    text-size-adjust: 100px;
  }

  .recording-name-resize {
    widows: 60px !important;
    padding: 4px 0 0 0;
  }
}

.selected-step-name div:nth-child(2) {
  width: 80px;
  float: left;
  padding-top: 13px;
  font-weight: bold;
}

.recording-name div:nth-child(3) {
  width: calc(100% - 30%);
  float: left;
  max-width: 500px;
  min-width: 200px;
}

.selected-step-name div:nth-child(3) {
  width: calc(100% - 216px);
  float: left;
  max-width: 400px;
  margin-left: 5px;
}

.selected-element-actions {
  padding: 0px 10px 0px 10px;
  padding-bottom: 25px;
}

.selected-element-actions input[type="text"] {
  border-radius: unset;
  background: transparent;
  cursor: initial;
  margin-top: 8px;
  font-weight: bold;
  width: 100%;
}

.selected-element-actions input[type="text"] {
  box-shadow: unset;
  padding-left: 0px;
  outline: none !important;
  border: none;
}

.recording-menu-items {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  font-size: 14px;
  height: 34px;
}

.recording-menu-items > * {
  margin: 0 33px;
  cursor: pointer;
}

.recording-menu-items .menu-item {
  display: inline-block;
}

.recording-menu-items .menu-item:hover {
  color: #008ecc;
}

.recording-menu-items .menu-item .separator {
  color: rgba(225, 225, 225, 0.65);
  transform: rotate(90deg);
}

.separator {
  background: rgba(225, 225, 225, 0.65);
  height: 1px;
  width: 100%;
  margin-left: -5px;
}

.recording-menu-items .menu-item .start-preview {
  cursor: pointer;
}

.recording-menu-items .menu-item.pause-icon {
  color: red;
}

.recording-menu-items .menu-item .save-button {
  cursor: pointer;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  padding-top: 3px !important;
}

.save-mobile-button {
  letter-spacing: 0.2px !important;
}

/*Step List*/

.steps-list-container {
  padding: 0px 0px 0px 5px !important;
  margin: 0 auto;
  float: left !important;
}

/*Add Step*/

.add-step-container {
  width: 25px;
  margin: 10px 8px;
  display: inline-block;
  top: 19px;
}

.add-step-inner-content {
  width: 25px;
  height: 35px;
  border-radius: 5px;
  background: transparent;
  color: #8c8c8c;
  border: 2px solid rgba(217, 225, 232, 0) !important;
  cursor: pointer;
  margin-top: 55px;
}

.add-step-inner-content svg,
.add-step-button-inside-card svg {
  font-size: 1.5em;
  color: #008ecc !important;
}

.step-add-icon-in-center {
  padding-top: 3% !important;
  text-align: center;
}

/*Step Cards*/

.step-card-expanded {
  width: 140px;
  margin: 10px 8px;
  display: flex;
  display: inline-block;
  border: 2px solid rgba(217, 225, 232, 0) !important;
}

.step-card-expanded .step-card-continue-after-fail .card-inner-content {
  background-color: rgba(216, 218, 229, 0.4);
}

.card-inner-content {
  width: 110px;
  height: 155px;
  border-radius: 5px;
  background: #ffffff;
  color: #8c8c8c;
  border: 1px solid rgba(130, 131, 134, 0.1) !important;
  cursor: pointer;
  padding: 1px;
}

.card-inner-content.active,
.card-inner-content.active .step-action-type {
  border: 2px solid #008ecc !important;
}

.card-inner-content.Passed {
  border: 1px solid #7cd175 !important;
  border-bottom: 5px solid #7cd175 !important;
}

.card-inner-content.Done {
  border: 1px solid #7cd175 !important;
  border-bottom: 5px solid #7cd175 !important;
}

.card-inner-content.Skipped {
  border: 1px solid #f7ba7b !important;
  border-bottom: 5px solid #f7ba7b !important;
}

.card-inner-content.Failed {
  border: 1px solid #ff4d4f !important;
  border-bottom: 5px solid #ff4d4f !important;
}

.card-inner-content.inprogress {
  border: 1px solid #a13597 !important;
  border-bottom: 5px solid #a13597 !important;
}

.card-inner-content.CONTINUE_AFTER_FAILED {
  border: 1px solid #a13597 !important;
  border-bottom: 5px solid #a13597 !important;
}

.card-inner-content.passed .step-action-type {
  border: 1px solid #7cd175 !important;
}

.card-inner-content.skipped .step-action-type {
  border: 1px solid #f7ba7b !important;
}

.card-inner-content.failed .step-action-type {
  border: 1px solid #ff4d4f !important;
}

.card-inner-content.inprogress .step-action-type {
  border: 1px solid #7cd175 !important;
}

.step-card-expanded .container {
  padding: 5px;
}

.step-card-expanded .screenshot {
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 120px;
  background: #f9f9fb;
  min-height: 120px;
  min-width: 100%;
}

.step-expanded-description {
  padding: 2px 16px;
}

.step-card-expanded .step-name-container {
  border-top: 1px solid rgba(130, 131, 134, 0.1) !important;
  position: relative;
  float: left;
  width: 100%;
}

.step-card-expanded .step-action-container {
  position: relative;
  float: right;
  width: auto;
}

.step-card-expanded .step-name {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  padding-right: 5px;
  padding-left: 5%;
  padding-top: 5px;
  text-overflow: ellipsis;
  float: left;
  position: absolute;
  width: 115px;
  font-weight: 400;
}

.step-card-expanded .step-actions {
  width: 100%;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #0000000f 100%);
  line-height: 2;
  margin-top: 0px;
  text-align: right;
  margin-right: 5px;
  display: none;
  cursor: pointer;
}

.add-step-button-inside-card {
  width: 30px;
  height: auto;
  float: right;
  right: -22px;
  position: absolute;
  top: 45%;
  display: none;
}

.step-card-expanded:hover .step-actions {
  display: table;
  padding-right: 5px;
}

.step-card-expanded:hover .add-step-button-inside-card {
  display: table;
}

.step-action-type {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f9f9fb;
  top: -10px;
  left: -10px;
  line-height: 2.7;
  margin: 0 auto;
  text-align: center;
  border-color: rgb(217, 225, 232);
  border-width: 1px;
  border-style: solid;
}

.step-action-type svg {
  padding: 3px 3px 3px 3px;
}

.tap-by-index-value {
  position: absolute;
  font-weight: 500;
  right: -5px;
  top: -2px;
  color: white;
  line-height: 1;
  border-radius: 2px;
  background-color: #338fcc;
  padding: 2px;
}

/* General */

/* Media Query */

@media only screen and (max-width: 762px) {
  .devices-actions {
    max-width: 90%;
  }
}

.radioStyle {
  display: "block";
  height: "45px";
  line-height: "45px";
}

/* selected-element-actions */

.selected-element-actions {
  margin: 0px 20px 0px 20px;
}

.selected-element-actions input[type="text"] {
  font-weight: normal;
}

.property-details {
  margin-left: 5px;
  margin-top: 5px;
  text-align: left;
}

.property-details button {
  float: left !important;
}

.property-details .svg-icon-inline--fa.fa-w-16 {
  width: 1.4em !important;
  margin-left: 8px;
  margin-top: 1px;
  color: var(--app-sub-font-color) !important;
}

.data-table-icon {
  display: inline;
  margin: 2px 0px 0px 10px;
  font-size: 1.17em;
  position: absolute;
  cursor: pointer;
}

.selected-element-action-container {
  /* height: 100%;
	width: 100%; */
}

.selected-element-properties-container {
  height: 100%;
  position: absolute !important;
  right: 0;
}

.step-alert-or-continue-on-failure {
  margin-top: 10px;
}

/* Element Properties headers */

.properties-modal {
  background: white;
  height: inherit;
  padding-left: 10px;
}

.properties-modal-content {
  height: calc(100% - 75px);
  width: 100%;
  background: white;
  position: absolute;
  right: 0;
  min-height: calc(100% - 302px);
  overflow: auto;
  padding-bottom: 15px;
}

.properties-modal-header {
  height: 40px;
  padding: 10px;
  border-bottom: 1px solid #65676b25;
}

.properties-modal .header-text {
  text-transform: uppercase;
  color: #65676b;
  font-weight: 600;
  font-size: 12px;
  float: left;
}

.properties-modal-header-close-icon {
  padding-top: 10px;
}

.properties-modal .close-icon {
  float: right;
  font-size: 1.2em;
}

.properties-modal .no-element-selected-text {
  color: #6c6c6c;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  margin-top: 60px;
}

.properties-modal .key,
.properties-modal .value {
  padding: 0px 0px 0px 10px;
}

.verfification {
  height: auto;
  border: 1px solid #65676b30;
  padding: 1%;
  overflow: auto;
  margin-top: 10px;
  max-height: 100px;
  min-height: 66px;
  padding-bottom: 10px;
}

.verfification .new-verification {
  float: left;
  border: 1px solid #65676b30;
  padding: 5px;
}

.submit-step,
.reset-step {
  float: left;
  width: 180px;
  margin-left: 2px;
}

.verfification .selected-item {
  float: left;
  border: 1px solid rgba(140, 140, 140, 0.55);
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  background: #65676b;
  color: white;
}

.verfification .control {
  margin-right: 10px;
  width: 230px !important;
}

.recording-header-message {
  margin: 0 auto;
  padding: 8px;
  background: rgba(0, 0, 0, 0.25) !important;
  text-align: center;
  color: #fafbfc !important;
  list-style: upper-alpha;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.session-expired {
  text-align: center;
  font-size: 12px;
  color: red;
  margin-bottom: 10px;
  position: absolute;
  top: 2px;
  background: #ff0000ba;
  width: 100%;
  color: white;
  cursor: pointer;
  z-index: 1;
}

.session-expired svg {
  color: white !important;
}

.inner {
  position: relative;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.content {
  height: calc(100vh - 200px);
  width: 90%;
  display: flex;
  top: 10px;
}

.rightBar {
  background-color: #ddd;
  margin-left: auto;
  width: auto;
}

.leftBar {
  background-color: #ddd;
  margin-left: auto;
  width: auto;
}

.inner .ant-spin.ant-spin-spinning {
  margin: 0 auto;
  top: 48%;
  position: absolute;
  left: 48%;
}

.block-screen-interaction,
.block-button-interaction,
.block-action-interation {
  pointer-events: none;
}

.block-action-interation {
  pointer-events: none;
  opacity: 0.4;
}

.device-block-screen-interaction-message {
  position: absolute;
  top: 45%;
  width: 100%;
  text-align: center;
  z-index: 103;
}

.device-block-screen-interaction-container .device-block-screen-interaction-message {
  position: relative;
  padding: 10px;
  width: auto;
  opacity: 1;
  z-index: 103;
}

.device-block-screen-interaction-container .device-block-screen-interaction-message div {
  border: 1px solid #c5c5c570;
}

.device-block-screen-interaction-container {
  /* position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	margin: 0;
	text-align: center;
	border-radius: 0;
	background: black;
	opacity: 0.3;
	z-index: 103; */
}

.device-block-screen-interaction-message div {
  background: black;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  border: 1px solid white;
  padding: 5px;
  border-radius: 5px;
  color: white;
  animation: blinking 1.5s infinite;
}

@keyframes blinking {
  0% {
    background: black;
    color: white;
  }

  47% {
    background: black;
    color: white;
  }

  62% {
    background: yellow;
    color: red;
  }

  97% {
    background: yellow;
    color: red;
  }

  100% {
    background: yellow;
    color: red;
  }
}

.blank-card {
  border: 1px solid rgb(51, 143, 204) !important;
}

.blank-card .step-action-type {
  border: 1px solid rgb(51, 143, 204) !important;
}

.blank-card .screenshot {
  opacity: 0.1;
}

.step-being-recording {
  background: black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  border: 1px solid white;
  padding: 5px 20px 5px 20px;
  border-radius: 5px;
  color: white;
  margin-top: 20%;
}

.disableControl {
  cursor: no-drop;
}

.opacity05 {
  opacity: 0.5;
}

.preview-action {
  float: right;
  margin-right: 5%;
}

.recording-on,
.recording-on:hover {
  background: red !important;
}

.app-list-container {
  overflow: auto;
}

.step-container-heigth {
  position: relative !important;
  height: calc(36vh - 0px) !important;
  overflow: auto;
}

.step-container-full {
  position: relative !important;
  height: calc(100vh - 200px) !important;
  overflow: auto;
}

.border-top {
  border-top: 1px solid #c6c6c670;
}

.preview-header {
  font-weight: bold;
  font-size: 14px;
  padding-left: 15px !important;
  line-height: 2;
  padding: 10px 0px 10px 0px;
}

.ant-tabs-bar {
  margin: 0 0 5px 0 !important;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f2f2f2;
}

.wrap-login100 {
  width: 390px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 30px 45px 45px 45px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

/*------------------------------------------------------------------[ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-title {
  display: block;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}

.login100-form-title i {
  font-size: 60px;
}

/*------------------------------------------------------------------[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  /* border-bottom: 2px solid #adadad; */
  margin-bottom: 15px;
}

.input100 {
  font-size: 14px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px;
  margin-top: 31px;
  border: 1px solid rgba(145, 145, 145, 0.65) !important;
  border-radius: 2px !important;
}

.input-text-box {
  font-size: 14px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 35px;
  background: transparent;
  padding: 0 5px;
  /* margin-top: 31px; */
  border: 1px solid rgba(145, 145, 145, 0.65) !important;
  border-radius: 2px !important;
}

/*---------------------------------------------*/

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  border: 1px solid rgba(145, 145, 145, 0.65) !important;
  border-radius: 2px !important;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  border: 1px solid rgba(145, 145, 145, 0.65) !important;
  border-radius: 2px !important;
}

.focus-input100::after {
  color: #999999;
  line-height: 1.2;
  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 16px;
  left: 0px;
  padding-left: 5px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  border: 1px solid rgba(145, 145, 145, 0.65) !important;
  border-radius: 2px !important;
}

/*------------------------------------------------------------------[ Button ]*/

.container-login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrap-login100-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  top: 0;
  left: -100%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn {
  font-size: 14px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px !important;
}

.wrap-login100-form-btn:hover .login100-form-bgbtn {
  left: 0;
}

/*------------------------------------------------------------------[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
  pointer-events: none;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  font-size: 16px;
  color: #c80000;
  display: block;
  position: absolute;
  background-color: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}
.margin-top-3 {
  margin-top: 3px;
}
.margin-right-2 {
  margin-right: 2px;
}
.min-w-150 {
  min-width: 150px;
}
.gap-y-10 {
  gap: 10px 0;
}
.p-b-16 {
  padding-bottom: 16px;
}

.p-b-26 {
  padding-bottom: 26px;
}

.p-b-48 {
  padding-bottom: 48px;
}

.p-t-115 {
  padding-top: 115px;
}

.login-product-name {
  font-size: 42px;
}

.center-device-mode {
  margin: 0 auto !important;
  max-width: 450px !important;
}

.radiostyle {
  display: "block";
  height: "30px";
  line-height: "30px";
}

div.clickEffect {
  position: "fixed";
  box-sizing: "border-box";
  border-style: "solid";
  border-color: red;
  border-radius: 1px;
  animation: clickEffect 30s ease-out;
  z-index: 99999;
}

@keyframes clickEffect {
  0% {
    opacity: 1;
    width: 0.5em;
    height: 0.5em;
    margin: -0.25em;
    border-width: 0.5em;
  }

  100% {
    opacity: 0.2;
    width: 1em;
    height: 1em;
    margin: -7.5em;
    border-width: 0.03em;
  }
}

.swipeSvg {
  position: absolute;
  z-index: 100000000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.swipeSvg line {
  stroke-width: 15;
  stroke: rgba(255, 153, 153, 0.8);
  stroke-linecap: round;
}

.swipeSvg circle {
  r: 10;
  fill: rgba(160, 156, 156, 0.849);
}

.already-on-xpress {
  padding: 12px;
  margin: 0 auto;
  text-align: center;
}

.already-on-xpress a {
  color: #1890ff !important;
}

.already-have-token-button {
  color: #1890ff !important;
  display: inline-block !important;
  border: none !important;
  box-shadow: none !important;
  padding: none !important;
  cursor: pointer;
  background: none;
}

.already-have-token {
  padding: 16px 0 0;
  margin: 10px auto 0;
  text-align: center;
  display: inline-block !important;
}

.already-have-token a {
  color: #1890ff !important;
}

.error {
  border: 1px solid red !important;
}

.text-align-center {
  text-align: center;
}

.success-email-sent-message {
  border: 1px solid green;
  background: #0080003d;
  color: green;
  padding: 10px;
}

.apple-device-brand-logo img {
  max-width: 15px;
  float: left;
  margin-right: 5px;
}

.android-device-brand-logo img {
  max-width: 15px;
  float: left;
  margin-right: 5px;
  padding-top: 3px;
}

.device-brand-name {
  float: left;
  margin-right: 5px;
  text-transform: uppercase;
  padding: 1.1px;
  color: var(--app-font-color) !important;
}

.device-os-ver {
  float: left;
  margin-right: 5px;
  text-transform: uppercase;
  padding: 1.1px;
  color: var(--app-font-color) !important;
}

/* Landscape and Potrait */

.landscape-center-device-mode {
  margin: 0 auto !important;
  max-width: 900px !important;
}

.device-view,
.device-actions-view {
  max-width: 786px;
  margin: 0 auto;
}

.landscape-device-interection-view {
  height: inherit;
  max-width: 848x;
}

.device-acquire-user-name {
  float: left;
}

*:focus {
  outline: unset;
}

.card {
  /* background-color: dodgerblue; */
  color: white;
  /* padding: 1rem; */
  height: auto;
}

.cards {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
}

.no-device-available {
  max-width: 1190px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  font-size: 14px;
  font-weight: 600;
  color: #ff00007d !important;
  margin-top: 25px;
  margin-bottom: 25px;
}

/* Screen larger than 600px? 2 column */

@media (min-width: 600px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Screen larger than 900px? 3 columns */

@media (min-width: 900px) {
  .cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

.ant-card-actions > li > span .allow-session {
  color: #5eba7d !important;
}

.ant-card-actions > li > span .block-session {
  color: #ff00007d !important;
}

.device-acquire-user-name {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.center-logo {
  width: 125px;
  height: 25px;
}

.logo-container {
  width: 100%;
  height: 50px;
  margin: 0 71px;
}

@media (max-width: 900px) {
  .header-fixed {
    font-size: 0.9em !important;
    width: 100%;
    position: fixed;
    z-index: 10;
  }

  .header-fixed .app-logo {
    margin-top: -17px;
  }
}

.device-screen-apple-logo img {
  padding-bottom: 4px;
  max-width: 15px;
  margin-right: 5px;
}

.device-screen-androidlogo img {
  padding-bottom: 3px;
  max-width: 15px;
  margin-right: 5px;
}

.device-screen-platform {
  padding-top: 3.9px;
}

.text-device-screen {
  text-align: center;
}

.portal-logo {
  max-height: 200px;
  width: 124px !important;
  height: 100px;
  text-align: center;
  margin: 0 auto;
}

.portal-logo-mini {
  max-height: 200px;
  width: 124px !important;
  height: 100px;
  text-align: center;
  margin: 0 auto;
}

.apple-device-logo {
  max-width: 200px;
  max-height: 200px;
  width: 106px !important;
  height: 119px;
  text-align: center;
  margin: 0 auto;
}

.android-device-logo {
  max-width: 200px;
  max-height: 200px;
  width: 60px !important;
  height: 120.2px;
  text-align: center;
  margin: 0 auto;
}

.android_logo {
  width: 40px !important;
  height: inherit !important;
  border: 1px solid #a3a3a3;
  border-radius: 5px;
}

.offline-device {
  outline: 4px solid #f5222d !important;
  outline-offset: -4.5px;
}

.online-device {
  border: 4px solid #e8e8e8 !important;
}

.offline-button {
  height: 23px !important;
  width: 200px !important;
  border: none !important;
  color: red !important;
  font-weight: 900 !important;
  cursor: no-drop !important;
}

.device-note {
  text-align: right;
}

.device-note-icon {
  margin-right: 6px !important;
}

.device-note-modal .ant-modal-body {
  padding-top: 0px;
}

.device-note-modal textarea {
  width: 100%;
  resize: none;
  height: 100px;
}

.device-note-edited-by {
  font-size: 11px;
  float: right;
  margin-top: 5px;
  color: gray;
}

.device-note-modal .ant-card-body {
  padding: 10px;
}

.device-note-history-container {
  max-height: 300px;
  overflow: auto;
}

.no-data {
  color: gray;
  text-align: center;
  padding: 20px;
}

.mobile-view-title-name {
  border-top: 5px solid #65676b30 !important;
  background: #65676b30;
  border-bottom: 5px solid #65676b30 !important;
  text-align: center;
  margin-bottom: 13px;
}

.header-title-name {
  border-top: 5px solid #65676b30 !important;
  background: #65676b30;
  border-bottom: 5px solid #65676b30 !important;
  text-align: center;
}

.padding-top {
  padding-top: 8px;
}

@media only screen and (max-width: 700px) {
  .input-text-modal {
    width: 70% !important;
  }
}

.lock-device-screen-recording {
}

.recording-screen-mobile-view {
  width: fit-content !important;
}

.device-note-popup {
  white-space: pre;
  max-height: 100px;
  overflow: auto;
}

.xpress-device-farm-logo {
  height: 56px;
  width: 110px;
  margin: 20px 81px;
}

::placeholder {
  opacity: 0.6;
}

.welcome-to-text {
  width: 161px;
  margin: 0px 57px 0;
  height: 28px;
}

.device-orientation-icon {
  width: 20px;
  -webkit-user-select: none;
  /* for safari & chrome browsers */
  -moz-user-select: none;
  /* for mozilla browsers */
  -khtml-user-select: none;
  /* for konqueror browsers */
  -ms-user-select: none;
  /* for IE10+ */
}

.msg-center {
  text-align: center;
  margin-top: 20%;
}

.no-selection {
  -webkit-user-select: none;
  /* for safari & chrome browsers */
  -moz-user-select: none;
  /* for mozilla browsers */
  -khtml-user-select: none;
  /* for konqueror browsers */
  -ms-user-select: none;
  /* for IE10+ */
}

.avatar-for-user {
  min-height: 29px;
  max-height: 67px;
  min-width: 38px;
  margin: -3px 0 0 -6px;
  position: absolute !important;
  left: 10px;
  top: 7px;
}

.logout-icon {
  margin-top: 10px !important;
}

.xpress-help {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 64px;
  height: 64px;
  z-index: 99;
}

.text-center {
  text-align: center;
}

.user-tag {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #338fcc;
  color: white;
  text-align: center;
  font-size: 20px;
  border: 0px;
  float: right;
}

.menu-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 20px;
  border: 0px;
  float: right;
}

.selected-element-content {
  height: inherit !important;
  max-height: calc(35vh - 0px);
  overflow: auto;
}

.selected-element-content.no-data {
  text-align: center;
  vertical-align: middle;
  line-height: calc(34vh - 20px);
  font-weight: bold;
}

.group-selected-element-content.no-data {
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}

.word-break-all {
  word-break: break-all;
}

.farm-name {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
}

.swipe-highlighter {
  fill: none;
  stroke: #ffaa80;
  stroke-width: 6;
}

.input-password-checkbox {
  margin-top: 10px !important;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

/* Splliter */

@media only screen and (min-width: 700px) {
  .splitter-layout {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .splitter-layout .layout-pane {
    position: relative;
    flex: 0 0 auto;
    overflow: auto;
  }

  .splitter-layout .layout-pane.layout-pane-primary {
    flex: 1 1 auto;
  }

  .splitter-layout > .layout-splitter {
    flex: 0 0 auto;
    width: 4px;
    height: 100%;
    cursor: col-resize;
    background-color: #ccc;
  }

  .splitter-layout .layout-splitter:hover {
    background-color: #bbb;
  }

  .splitter-layout.layout-changing {
    cursor: col-resize;
  }

  .splitter-layout.layout-changing > .layout-splitter {
    background-color: #aaa;
  }

  .splitter-layout.splitter-layout-vertical {
    flex-direction: column;
  }

  .splitter-layout.splitter-layout-vertical.layout-changing {
    cursor: row-resize;
  }

  .splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%;
    height: 4px;
    cursor: row-resize;
  }
}

.splitter-layout {
  height: calc(100% - 50px);
}

.recording-button .ant-spin-dot-item {
  background: white;
  width: 6px;
  height: 6px;
}

.recording-button .ant-spin-dot {
  font-size: 14px;
  margin-right: 3px;
}

/* Android Black Case */

.device {
  position: relative;
  top: calc(50% - 408px);
  height: fit-content;
  width: fit-content;
  padding: 50px 10px;
  border-radius: 20%/10%;
  background-color: #171717;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  border: 5px solid #2f2f2f;
}

.device:before,
.device:after {
  content: "";
  position: absolute;
  z-index: 2;
}

.device:before {
  width: 20%;
  height: 10px;
  top: 20px;
  left: 40%;
  border-radius: 10px;
  background-color: #2f2f2f;
}

.device:after {
  width: 65px;
  height: 12px;
  border-radius: 10%;
  border: solid 3px #2f2f2f;
  left: calc(50% - 30px);
  bottom: 15px;
}

/* Android Black Case Done */

/* IOS Black Case */

.device-ios {
  position: relative;
  top: calc(50% - 408px);
  height: fit-content;
  width: fit-content;
  padding: 50px 10px;
  border-radius: 20%/10%;
  background-color: #ffffff;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  border: 2px solid #f1ceb9;
}

.device-ios:before,
.device-ios:after {
  content: "";
  position: absolute;
  z-index: 2;
}

.device-ios:before {
  width: 20%;
  height: 10px;
  top: 20px;
  left: 40%;
  border-radius: 10px;
  background-color: #f1ceb9;
}

.device-ios:after {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 2px #f1ceb9;
  left: calc(50% - 20px);
  bottom: 4px;
}

/* Android Black Case Done */

/* IOS White Case */

.device-ios-gold-white {
  position: relative;
  top: calc(50% - 408px);
  height: fit-content;
  width: fit-content;
  padding: 50px 20px;
  border-radius: 12%/6%;
  background-color: #ffffff;
  /* box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1); */
  box-shadow: #f1ceb9 0 0 17px;
  margin: 0 auto;
  border: 2px solid #f1ceb9;
}

.device-ios-gold-white:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f1ceb9;
  border-radius: 10px;
  /* width: 20%;
	height: 10px;
	top: 20px;
	left: 40%;
	border-radius: 10px;
	background-color: #F1CEB9; */
}

/* .device-ios-gold-white .swipeSvg {
  padding: 50px 20px;
} */

/* Android Black Case Done */

/* IOS black XS */

.device-ios-black {
  background-color: #000;
  transition: all 0.1s linear, line-height 0s linear;
  background-position: 50% 100%, center;
  box-shadow: 0 0.5em 2em 0.2em rgba(0, 0, 0, 0.23), 0 0 0 0.5px #000 inset;
  border: solid #111;
  position: relative;
  height: fit-content;
  width: fit-content;
  border-radius: 20%/10%;
  margin: 0 auto;
  border-width: 2.207920792vmin;
  border-radius: 4.0550130098vmin;
}

.device-ios-black img {
  border-radius: 6% / 5%;
}

/* IOS black */

/* Android smart phone */

.smartphone {
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: auto;
  border: 6px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
  /* box-shadow: rgb(0 0 0) 0 0 11px; */
}

/* The horizontal line on the top of the device */

.smartphone:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The screen (or content) of the device */

.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
}

/* End */

/* Portal */

.portal {
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: auto;
  border: 16px black solid;
  border-top-width: 30px;
  border-bottom-width: 30px;
}

/* The horizontal line on the top of the device */

.portal:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
}

/* The screen (or content) of the device */

.portal .content {
  width: 360px;
  height: 640px;
  background: white;
}

/* Portal End */

/* Devicve Mouse Cursor */

.device-mouse-cursor {
  position: absolute;
  -webkit-animation: pulse 2s infinite;
  /* Chrome, Safari, Opera */
  animation: pulse 2s infinite;
  background: transparent;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  border: 1px solid #ffffff;
}

.menu-line {
  height: 2px;
  width: 20px;
  background: #4f4f4f;
  margin: 0 auto;
  margin-bottom: 2px;
  cursor: move;
}

.line-menu {
  width: 25px;
  height: 2px;
  background: #4f4f4f;
  margin: 0 auto;
  margin-bottom: 2px;
  text-align: center;
}

.hilight-action-item {
  -webkit-animation-name: color2;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0s;
}

@-webkit-keyframes color2 {
  5%,
  80% {
    color: #338fcc;
    border: 5px solid;
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: var(--app-font-color) !important;
}

.spiner-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: auto;
  height: 200px;
  font-weight: bold;
  font-size: 18px;
}

.spiner-center-inner {
  position: absolute;
  margin-top: 15% !important;
  width: 100%;
  height: 200px;
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto;
  text-align: center;
  margin-top: 15%;
}

.device-acquire-time {
  float: left;
  color: var(--app-sub-font-color);
}

.device-card .ant-card-body {
  padding: 15px;
}

.app-management-button:hover {
  color: white !important;
  background-color: #338fcc !important;
}

.app-management-button a {
  color: #338fcc !important;
}

.build-type-column {
  width: 100px;
}

.build-platform-column {
  width: 100px;
}

.build-version-column {
  width: 150px;
}

.build-app-info-column {
  width: 100% !important;
}

.build-title-coloumn {
  font-size: 15px;
  color: rgba(52, 110, 216, 0.65) !important;
}

.build-info-card {
  text-align: left !important;
}

.build-name-column {
  width: 20%;
}

.build-action-column {
  width: 20%;
}

.build-details-table {
  padding: 0 36px;
}

.app-management-title {
  color: var(--app-font-color) !important;
  height: 57px;
  text-align: center;
  font-size: 23px;
  font-family: sans-serif;
  padding-left: 10% !important;
}

.add-build-button {
  width: 119px;
  min-height: 30px;
}

.delete-app-build {
  background-color: rgb(203, 15, 15) !important;
  color: white !important;
  float: right;
  margin-right: 10px;
}

.add-app-build-form-title {
  text-align: center;
  font-size: 23px;
  font-family: sans-serif;
  color: rgb(111, 108, 108);
}

.input100-textarea {
  font-size: 15px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  background: transparent;
  padding: 0 5px;
  margin-top: 31px;
}

.latest-build-checkbox {
  margin: 22px 0 0 0;
}

.shared-with-checkbox {
  margin: 14px 0 0 0;
}

.save-build-button {
  font-size: 14px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  margin: 20px 0 0 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px !important;
}

.upload-app-panel {
  margin: 26px 0 26px 0;
}

.build-tags-column {
  min-width: 300px;
}

@media only screen and (max-width: 1200px) {
  .app-management-button {
    margin: 4px 0px 2px 0px;
  }
}

@media only screen and (max-width: 1000px) {
  .app-management-button {
    margin: 4px 0px 2px -3px;
  }
}

.app-build-user-date-info {
  margin: 13px 0 0 0;
  font-size: 11px;
}

.app-build-tags {
  margin: 13px 0 0 0;
}

.app-build-farm-list {
  margin: 9px 0 0 0;
}

.app-build-add-container {
  min-height: 78vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-build-user-date-info-build-push {
  margin: 1px 0 0 0;
  font-size: 11px;
}

.app-build-tags-build-push {
  margin: 0 0 0 0;
}

.wrap-add-app-build {
  width: 450px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px 55px 33px 55px;
}

.container-add-build-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.add-build-form {
  width: 100%;
}

.wrap-add-build-input100 {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}

.add-build-input100 {
  font-size: 15px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px;
  margin-top: 15px;
}

.build-type-radio {
  margin: 27px 0 21px 0px;
}

.app-build-date-picker {
  width: 100% !important;
}

.build-type-individual-radio {
  padding-left: 23.5px !important;
}

.build-type-radio-group {
  margin: 0px 0px 0px -13px !important;
}

.radio-header {
  color: grey;
}

.upload-app-button {
  width: 340px !important;
}

.app-list-tag-margin {
  margin: 4px 5px 0px 0px !important;
}

.app-build-error {
  border: 2px solid red !important;
}

.hilight-app-build-error-item {
  animation-name: example;
  animation-duration: 4s;
}

@keyframes example {
  0% {
    border: 2px solid red !important;
  }

  25% {
    border: 2px solid red !important;
  }

  50% {
    border: 2px solid red !important;
  }

  100% {
    border: 2px solid red !important;
  }
}

.build-details-for-install-table {
  min-width: 400px;
}

.add-build-limiter {
  width: 100%;
  margin: 3px auto;
}

.existing-build-details {
  width: 100% !important;
  background: rgba(245, 245, 245, 0.65) !important;
  padding: 7px !important;
}

.existing-build-card-body {
  margin: -25px !important;
}

.free-text-search-app-manage {
  padding: 0px 0px 6px 15px !important;
}

.search-text-input-app-management {
  width: 40% !important;
}

.free-text-search-app-install {
  padding: 0px 0px 6px 2px !important;
}

.search-text-input-app-install {
  width: 35% !important;
}

.search-text-input span input[type="text"] {
  background-color: transparent !important;
}

.prod-radio-margin {
  margin-right: 3.8px !important;
}

.platform-radio-header {
  color: grey;
  padding: 0 0 0 2px !important;
}

.canceled-button {
  color: red !important;
  float: right;
  margin-right: 10px;
}

.cancel-cross-Button {
  width: 15px !important;
  cursor: pointer;
}

.uploading-spinner {
  float: right;
  margin-right: 17px;
}

.upload-progress-bar {
  width: 142px;
  float: right;
}

/* Splliter */

@media only screen and (min-width: 700px) {
  .splitter-layout {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .splitter-layout .layout-pane {
    position: relative;
    flex: 0 0 auto;
    overflow: auto;
  }

  .splitter-layout .layout-pane.layout-pane-primary {
    flex: 1 1 auto;
  }

  .splitter-layout > .layout-splitter {
    flex: 0 0 auto;
    width: 4px;
    height: 100%;
    cursor: col-resize;
    background-color: #ccc;
  }

  .splitter-layout .layout-splitter:hover {
    background-color: #bbb;
  }

  .splitter-layout.layout-changing {
    cursor: col-resize;
  }

  .splitter-layout.layout-changing > .layout-splitter {
    background-color: #aaa;
  }

  .splitter-layout.splitter-layout-vertical {
    flex-direction: column;
  }

  .splitter-layout.splitter-layout-vertical.layout-changing {
    cursor: row-resize;
  }

  .splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%;
    height: 4px;
    cursor: row-resize;
  }
}

.splitter-layout {
  height: calc(100% - 50px);
}

.ERROR {
  color: red !important;
  font-family: monospace !important;
  font-size: 12px !important;
}

.VERBOSE {
  color: rgb(42, 206, 235) !important;
  font-family: monospace !important;
  font-size: 12px !important;
}

.DEBUG {
  color: rgb(7, 174, 60) !important;
  font-family: monospace !important;
  font-size: 12px !important;
}

.WARNING {
  color: rgb(235, 167, 21) !important;
  font-family: monospace !important;
  font-size: 12px !important;
}

.INFO {
  color: gainsboro !important;
  font-family: monospace !important;
  font-size: 12px !important;
}

.stop-logs-button {
  margin-left: 4px !important;
  min-width: 43px;
  border: unset !important;
  box-shadow: unset !important;
  background: #f9f9fb !important;
}

.clr-logs-button {
  margin-left: 3px !important;
  font-family: monospace;
  margin-top: 1px;
  border: unset !important;
  box-shadow: unset !important;
  background-color: none !important;
  background: #f9f9fb !important;
  color: #65676b !important;
}

.filter-logs-button {
  min-width: 43px;
  margin-left: 4px !important;
  border: unset !important;
  box-shadow: unset !important;
  background: #f9f9fb !important;
}

.start-logs-button {
  min-width: 43px;
  margin-left: 4px !important;
  border: unset !important;
  box-shadow: unset !important;
  background: #f9f9fb !important;
  border-color: #f9f9fb !important;
}

.devices-logs-box {
  width: 100%;
  height: 600px !important;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: var(--antd-wave-shadow-color);
  margin: 7px 0px 0px 0px !important;
  background-color: rgb(18, 51, 63) !important;
}

.deviceList,
.deviceView::-webkit-scrollbar {
  display: none !important;
}

.loading-logs-spinner-text {
  text-align: center;
  margin: 25% 0 0 0;
  font-size: 18px;
  color: white;
}

.log-search-bar {
  width: 50% !important;
}

.logs-action-pannel {
}

.app-selection-pannel-logs {
  width: 50%;
  margin: 1px 0 0 0 !important;
}

.download-logs-button {
  min-width: 43px;
  margin-left: 3px !important;
  border: unset !important;
  box-shadow: unset !important;
  background: #f9f9fb !important;
}

.reset-logs-button {
  color: #65676b !important;
  border: unset !important;
  box-shadow: unset !important;
  background: #f9f9fb !important;
}

.radio-for-log-filter {
  display: block;
}

.auto-scroll-checkbox {
  margin-left: 4px !important;
}

.recTestStepList::-webkit-scrollbar {
  display: none !important;
}

.deviceDashboardItem {
  margin: 5px;
}

.browser-icon-in-navigation {
  width: 21px;
}

.browser-icon-in-navigation-button {
  min-width: 53px;
  margin: 1px 1px 0 0 !important;
  border: unset !important;
  box-shadow: unset !important;
}

.empty-url-error {
  border: 1px solid red !important;
}

.quick-launch-buttons {
  min-height: 55px;
  min-width: 100px;
  padding: 0px !important;
  margin: 3px 3px 0 0;
  border: unset !important;
  box-shadow: unset !important;
}

.quick-launch-buttons div {
  font-size: 10px !important;
}

.appstore-icon-in-quick-launch {
  width: 21px;
}

.device-input-submit-text {
  margin: 6px 0 0 0;
}

.install-app-button-install-screen {
  float: right;
  margin: 0 18px 0px 0px;
}

.progress-for-app-installed {
  float: right !important;
  width: 170px !important;
  margin: 3px 3px 0px 0px !important;
}

.app-title-install-screen div div {
  margin-bottom: none !important;
}

.device-install-app-Item {
  margin-left: 4px;
  margin-top: 6px;
}

.upload-push-build-button {
  width: 100% !important;
}

.stop-session-icon {
  background-color: red !important;
  color: white;
}

.device-usage-report-table {
  padding: 0 36px;
}

.loading-device-usage-report-list {
  font-size: 18px !important;
  font-weight: bold !important;
  color: gray !important;
}

.page-header {
  height: 40px;
  font-size: 24px;
  text-align: center;
  margin-top: 6px !important;
}

.device-usage-report-button {
  margin: 4px 0px 0 0;
  color: #338fcc;
  cursor: pointer;
  line-height: 2.5em;
}

.device-usage-tabble-sr-no-column {
  width: 10% !important;
}

.reset-button-link {
  line-height: 2.2em;
  padding-left: 10px;
  color: #338ecc;
  cursor: pointer;
}

.rotate-to-vertical-recent-icon {
  transform: rotate(90deg) !important;
}

.date-range-picker-right {
}

.close-session-from-interaction-header {
  text-align: center;
  font-size: 18px;
  position: absolute;
  border: 0px;
  margin-bottom: 0px;
  left: 60px;
  bottom: 10px;
}

.search-text-input-device-usage input {
  border-radius: 4px !important;
}

/* .device-usage-report-rangeicker {
  float: right !important;
  margin-right: 33px !important;
} */

/* PROXY */

.proxy-container {
  padding: 15px;
}

.proxy-container .labels {
  margin: 5px;
}

.proxy-container .labels span {
  font-weight: bold;
}

.proxy-iframe {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 280px);
  overflow: auto;
  border: 0;
}

.ant-card-head {
  min-height: 34px !important;
}

.ant-card-head-title {
  color: var(--app-font-color) !important;
  padding: 3px 0 !important;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.flow-table table {
  width: 100%;
  table-layout: fixed;
}

.flow-table thead {
  background-color: #f2f2f2;
  line-height: 23px;
}

.flow-table table {
  width: 100%;
  table-layout: fixed;
}

.flow-table {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 230px);
}

.flow-table tr {
  height: 30px;
  cursor: pointer;
  width: 100%;
}

.flow-table tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell;
}

.col-path {
  max-width: 100px;
}

.flow-table td {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.flow-table .col-icon {
  width: 32px;
}

.flow-table .col-method {
  width: 60px;
}

.flow-table .col-status {
  width: 50px;
}

.flow-table td.col-size,
.flow-table td.col-time {
  text-align: left;
}

.flow-table .col-size {
  width: 70px;
}

.flow-table .col-time {
  width: 50px;
}

.flow-table .col-tls {
  width: 5px;
}

.col-tls-https {
  background-color: rgba(0, 185, 0, 0.5);
}

.darkred {
  color: darkred;
}

.green {
  color: green;
}

.darkgreen {
  color: darkgreen;
}

.lightblue {
  color: lightblue;
}

.lightred {
  color: lightred;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.resource-icon {
  width: 32px;
  height: 32px;
}

.resource-icon-css {
  background-image: url(./../images/resourceCSSIcon.png);
}

.resource-icon-document {
  background-image: url(./../images/resourceDocumentIcon.png);
}

.resource-icon-js {
  background-image: url(./../images/resourceJSIcon.png);
}

.resource-icon-plain {
  background-image: url(./../images/resourcePlainIcon.png);
}

.resource-icon-executable {
  background-image: url(./../images/resourceExecutableIcon.png);
}

.resource-icon-flash {
  background-image: url(./../images/resourceFlashIcon.png);
}

.resource-icon-image {
  background-image: url(./../images/resourceImageIcon.png);
}

.resource-icon-java {
  background-image: url(./../images/resourceJavaIcon.png);
}

.resource-icon-not-modified {
  background-image: url(./../images/resourceNotModifiedIcon.png);
}

.resource-icon-redirect {
  background-image: url(./../images/resourceRedirectIcon.png);
}

.app-build-upload-note {
  font-size: 12px !important;
  color: rgb(189, 189, 189);
}

.terms-condition span {
  color: #008ecc;
}

.privacy-terms-header {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 21px;
  text-decoration: underline;
}

.privacy-term-container {
  padding: 20px;
}

.privacy-term-container p {
  text-align: justify;
}

.app-shared-farms {
  margin: 4px 5px 0px 0px !important;
  color: #84c2fc !important;
  background-color: white !important;
}

.loading-app-list-install-screen {
  text-align: center !important;
  padding-top: 25px;
  padding-bottom: 25px;
}

.default-app-list-container {
  width: 100% !important;
  padding: 7px !important;
  height: 100% !important;
  min-height: 100px !important;
  background-color: rgb(230, 228, 228) !important;
}

.default-app-list-container .ant-card-body {
  padding: 0px !important;
}

.default-app-tags {
  padding: none !important;
}

.select-app-for-default {
  width: 100% !important;
}

.default-app-set-modal {
  height: 300px !important;
}

.add-app-button-for-default-app {
  float: right !important;
  margin: 10px 0 0 0 !important;
}

.loading-default-app-list {
  text-align: center !important;
  font-weight: bold;
  margin-top: 5%;
}

.list-items-release-warning {
  font-size: 12px !important;
  color: rgb(181, 181, 181) !important;
}

.header-release-device-warning {
  font-weight: bold !important;
}

.release-device-admin-warning-modal {
  height: 300px !important;
  width: 60% !important;
}

.checkbox-device-cleanUp {
  margin-bottom: 10px !important;
}

.exluded-app-title {
  font-size: 14px !important;
  color: rgb(0, 0, 0, 0.65);
  margin: 10px 0 0 0 !important;
  font-weight: 600;
}

.save-changes-of-device-config {
  float: right !important;
  margin: 10px 4px 0 0 !important;
}

.container-sso-config {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.container-sso-config .wrap {
  width: 700px;
  overflow: hidden;
  padding: 5px;
}

.container-sso-config .form {
  width: 100%;
}

.container-sso-config .input {
  font-size: 14px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 35px;
  background: transparent;
  padding: 0 5px;
  margin-top: 15px;
}

.container-sso-config textarea {
  height: 100px !important;
  background: transparent;
  color: #555555;
  border: 1px solid #65676b30;
  resize: none;
}

.view-sso-details-row .header {
  font-weight: 600;
  margin: 5px 0 0 5px;
  display: inline-block;
}

.view-sso-details-row .details {
  color: rgb(150, 150, 150) !important;
  margin-left: 5px;
}

.exluded-app-info-msg {
  font-size: 11px !important;
  margin: 2px 0 0 0 !important;
  color: rgba(103, 102, 102, 0.65);
  font-weight: 600;
}

.installed-app-list-title {
  font-size: 14px !important;
  font-weight: bold;
  margin: 14px 0 0 0 !important;
  color: rgb(0, 0, 0, 0.65);
  font-weight: 600;
}

.sso-configuration-fieldd-lable {
  margin: 12px 0 0 0 !important;
  font-weight: 600;
}

.container-save-config-sso-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 13px;
  padding-bottom: 80px !important;
}

.wrap-save-config-sso-button {
  width: 270px;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
}

.sso-config-save-button {
  margin-right: 4px !important;
}

.sso-config-cancel-button {
  margin-left: 4px !important;
  color: var(--app-font-color) !important;
  background-color: rgb(240, 240, 240) !important;
}

.sso-config-cancel-button:hover {
  color: var(--app-font-color) !important;
  background-color: rgb(240, 240, 240) !important;
}

.sso-config-required-field {
  color: red !important;
  width: 20px !important;
}

/*Cookie Consent Begin*/

#cookieConsent {
  background-color: var(--app-font-color);
  min-height: 26px;
  font-size: 14px;
  color: #eee;
  line-height: 26px;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

#cookieConsent a {
  color: #008ecc !important;
  text-decoration: none;
}

#closeCookieConsent {
  float: right;
  display: inline-block;
  cursor: pointer;
  padding: 20px;
  margin: 0;
  font-weight: bold;
}

#closeCookieConsent:hover {
  color: #fff;
}

#cookieConsent button {
  display: inline-block;
  border-radius: 5px;
  padding: 0 20px;
  cursor: pointer;
  margin: 0 10px 0 10px;
  float: right;
}

#cookieConsent a.cookieConsentOK:hover {
  background-color: #e0c91f;
}

/*Cookie Consent End*/

.signup-back-link {
  cursor: pointer;
  color: #1890ff !important;
  text-align: center;
  margin-top: 10px;
}

.device-security-shield-icon {
  color: rgba(70, 69, 69, 0.65) !important;
}

.ant-alert-with-description {
  padding: 15px 15px 15px 64px;
  color: var(--app-font-color);
  line-height: 1.5;
  border-radius: 4px;
  width: 100% !important;
  z-index: 1000 !important;
}

.device-usage-loader-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 80vh;
}

.no-device-usage-found {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 15%;
  font-size: 18px;
  font-weight: bold;
  font-size: 18px !important;
  font-weight: bold !important;
  color: gray !important;
}

.app-manager-build-loader-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 80vh;
}

.app-manager-build-list {
  font-size: 18px !important;
  font-weight: bold !important;
  color: gray !important;
}

.install-app-build-loader-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 50vh;
}

.install-app-build-list {
  font-size: 14px !important;
  font-weight: bold !important;
  color: gray !important;
}

.install-app-build-card {
  min-height: 400px !important;
}

.no-install-app-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 50vh;
  font-size: 14px !important;
  font-weight: bold !important;
  color: gray !important;
}

.scenario-screen-android-logo {
  margin: 0px 5px 6px;
  width: 24px;
}

.scenario-screen-apple-logo {
  width: 19px;
  margin: 0px 1px 8px 8px;
}

.screenshotBox {
  /* margin-top: 15px; */
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: auto;
}

.scenario-card {
  padding: 3px !important;
  font-size: 11px;
  min-width: 600px !important;
  cursor: pointer;
}

.preview-loader {
  position: absolute;
  margin-left: 38%;
  margin-top: 9%;
  width: auto;
  height: 200px;
  font-weight: bold;
  font-size: 18px;
}

@media only screen and (max-width: 1200px) {
  .scenario-card {
    width: 420px !important;
    min-width: 450px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .scenario-card {
    width: 360px !important;
    min-width: 400px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .scenario-card {
    width: 300px !important;
    min-width: 350px !important;
  }
}

@media only screen and (max-width: 700px) {
  .scenario-card {
    width: 250px !important;
  }
}

.back-button {
  width: 17px !important;
  margin: 0 6px;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .recording-screen-back-button {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 700px) {
  .recording-screen-back-button {
    margin-left: 44px;
  }
}

.scenario-list-title {
  text-align: center;
  font-size: 18px;
  border-bottom: 0.8px solid #d9d9d9;
}

.scenario-title {
  font-size: 14px;
  color: var(--app-font-color) !important;
}

.start-execute-button {
  background-color: #009933 !important;
}

.start-execute-button:hover {
  background-color: #009933 !important;
}

.add-recording-button {
  margin: 0px 5px 12px 0;
}

.scenario-list-loader {
  margin-left: 35% !important;
  margin-top: 29% !important;
  width: auto !important;
  height: 200px !important;
  font-weight: bold !important;
  font-size: 18px !important;
}

.execution-set-text {
  width: 100px;
  float: left;
  font-weight: bold;
}

.execution-title-block {
  border-bottom: 1px solid #e6e6e6;
}

.execution-set-text-block {
  display: flex;
  text-align: left !important;
  margin: -2px 0px -3px 10px;
}

.execution-loader {
  text-align: center;
  margin-top: 231px;
  font-size: 21px;
}

.scenario-screen-android-logo {
  margin: 0px 5px 6px;
  width: 24px;
}

.scenario-screen-apple-logo {
  width: 19px;
  margin: 0px 1px 8px 8px;
}

.execution-tab {
  margin: 0 7px;
}

.recorder-header-block {
  border-bottom: 1px solid #e8e8e85c !important;
}

.recorder-header-back {
  z-index: 1;
  width: 30px;
  float: left;
}

.recording-screen-header {
  text-align: center !important;
  font-size: 16px !important;
  margin-left: -5px !important;
  z-index: 0;
  width: calc(100% - 31px);
}

.custom-verification-row {
  padding: 10px;
}

.custom-verification-dropdown-control {
  max-width: 250px !important;
  width: 100% !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.custom-verification-input-control {
  max-width: 250px !important;
  width: 100% !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.modal-build-verification {
  max-width: 800px;
}

.modal-build-verification .ant-modal-body {
  max-height: 400px;
  overflow: auto;
}

.custom-verification-string-builder-header {
  color: var(--app-font-color) !important;
  font-weight: bold;
  margin-left: 23px !important;
}

.custom-verification-string-builder-text {
  background: transparent;
  padding: 10px;
  border: 1px solid var(--app-sub-font-color);
  min-height: 50px;
  margin-left: 23px !important;
  width: calc(100% - 35px) !important;
}

.span-reference-text-preview-item {
  font-style: italic;
}

.align-right {
  text-align: right;
  padding-top: 15px;
}

.text-align-right {
  text-align: right;
}

/* Scheduler Query-Builder */

.query-builder-row-handler {
  border: 1px solid rgba(130, 131, 134, 0.1) !important;
  padding: 5px;
  background-color: #ededed;
  width: 638px;
}

.query-builder-row-handler .ant-select {
  width: 200px;
}

/* Verification Control */

.verification-builder-main-handler {
  border: 1px solid rgba(130, 131, 134, 0.1) !important;
  padding: 5px;
  background-color: #ededed;
  margin-top: 5px !important;
}

.verification-row {
  border-bottom: 0.5px solid rgba(225, 225, 225, 0.5);
  padding: 10px;
  background: #f9f9fb;
}

.verification-row .data-value {
  margin-left: 5px;
}

.verification-builder-main-handler .ant-select {
  width: 120px;
}

.verification-items > .verification-operator-label:last-of-type {
  font-weight: bold;
  font-size: 18px;
}

.verification-row .ant-select,
.verification-row input {
  min-width: 225px !important;
  margin-left: 10px;
  width: auto !important;
}

.verification-row input {
  border-radius: unset;
  background: transparent !important;
  cursor: initial;
  color: #8c8c8c;
  border: unset !important;
  margin-left: 10px;
  margin-top: -1px;
  border-bottom: 1px solid rgba(225, 225, 225, 1) !important;
}

.verification-group-main-handler {
  background-color: #008ecc;
  border: 1px solid #008ecc;
  padding-left: 10px;
}

.verification-group-row {
  background-color: #ededed;
  border: 1px solid #d2d2d2;
  margin-left: 10px;
}

.add-verification-row-button {
  color: #467fd7;
  cursor: pointer;
}

.add-verification-group-button {
  color: var(--app-font-color) !important;
  margin-top: 6px;
  padding-left: 6px;
  padding-right: 6px;
  float: left;
}

.add-verification-group-icon,
.remove-verification-group-icon {
  text-align: center;
  float: left;
}

.add-verification-group-icon svg {
  width: 30px !important;
  height: 20px !important;
  cursor: pointer;
  color: #008ecc !important;
  margin-top: 6px;
}

.remove-verification-group-icon svg {
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
  padding: 6px;
  color: #ff4d4f;
}

.remove-querybuilder-group-icon {
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
  color: #ff0000 !important;
  padding: 6px;
  margin-left: 0px;
}

.verification-operator {
  float: left;
}

.verification-operator-label {
  position: absolute;
  z-index: 1;
  margin-left: 10px;
  /* left: 0; */
  margin-top: -11px;
  background: #59b2d8;
  text-transform: uppercase;
  font-size: 11px;
  padding-left: 2px;
  border-radius: 3px;
  color: black;
  padding-right: 2px;
  border: 1px solid #e8e8e8;
  font-weight: 300;
}


.label-step-name {
  width: 100% !important;
}

.verification-container {
  max-height: calc(28vh - 0px) !important;
  overflow: auto;
}

.custom-text-verification-selected-element .label {
  float: left;
  padding-left: 24px;
  font-weight: bold;
  width: auto !important;
  padding-right: 5px;
}

.custom-text-verification-selected-element .note {
  font-size: 12px;
  padding-left: 24px;
  margin-top: 2px;
  color: #c6c6c6;
}

.edit-scenario-loader-spin {
  text-align: center;
  margin-top: 25%;
  font-weight: bold;
  font-size: 18px;
}

.device-install-app-Item {
  margin-left: 4px;
  margin-top: 6px;
}

.upload-push-build-button {
  width: 100% !important;
}

.bread-crumbs-item {
  float: left;
  cursor: pointer;
  padding: 5px;
}

.bread-crumbs div:last-child {
  cursor: auto;
}

.bread-crumbs-item:not(:last-child):after {
  content: "\003E";
  margin: 0px 0px 0px 10px;
  text-decoration: none;
  font-weight: 600;
}

.step-group-back {
  z-index: 1;
  padding: 5px;
  max-height: 30px;
  padding-top: 7px;
  text-align: right;
  max-width: 30px;
}

input.shell-input-text-box {
  font-size: 14px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 35px;
  background: transparent;
  padding: 0 5px;
  border: 1px solid rgba(145, 145, 145, 0.65) !important;
  border-radius: 2px !important;
  float: left !important;
}

.shell-output-result-box {
  width: 100%;
  max-height: 400px !important;
  margin: 7px 0px 0px 0px !important;
  background-color: rgb(110, 110, 110) !important;
  border-radius: 6px;
  border: 2px dashed gray;
  padding: 1em;
  margin-bottom: 0.4em;
  white-space: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
}

.shell-result {
  font-size: 14px;
  font-family: "Courier New", Courier, monospace !important;
  color: #e8e8e8;
}

.action-tab-cards {
  min-height: 160px !important;
}

.clear-shell-result {
  float: left !important;
  border: none !important;
  color: #f04d5b !important;
  height: 35px !important;
  box-shadow: none !important;
  padding: 5px !important;
}

.execute-button {
  float: left !important;
  height: 35px !important;
  border: none !important;
  box-shadow: none !important;
  padding: 5px !important;
}

.invite-button {
  /* width: 100%; */
  border: none;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: 500;
  padding: 0px !important;
  margin: 0px !important;
}

.ant-tabs-tabpane-active {
  /* overflow-y: scroll; */
}

#imageAndHiligherContainer {
  position: relative;
}

.swipe-element-selected {
  border-color: #338fcc;
}

.swipe-element-selected .arrow-right {
  border-left: 10px solid #338fcc;
}

.swipe-element-selected .arrow-down {
  border-top: 10px solid #338fcc;
}

.swipe-element-selected .arrow-left {
  border-right: 10px solid #338fcc;
}

.swipe-element-selected .arrow-up {
  border-bottom: 10px solid #338fcc;
}

.swipe-element-percentage-icon {
  padding-top: 1.5%;
  padding-left: 2%;
}

.swipe-element-input-row {
  margin-bottom: 2%;
}

.swipe-element-direction-label {
  margin-left: 10px;
}

.test-plan-item-row {
}

.test-plan-list-table {
  padding: 0 36px;
}

.test-plan-table-action-button-schedule {
  padding-right: 5px;
  color: white !important;
  background: #338fcc !important;
}

.test-plan-table-action-button-edit {
  background-color: #1890ff !important;
  padding-right: 5px;
}

.test-plan-table-action-button-delete {
  background-color: red !important;
}

.test-plan-execute-count {
  margin-top: 0;
}

.test-plan-execute-count-label {
  margin-top: 15px;
  font-size: 14px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
}

.header-label {
  background-color: #80808070;
  color: white;
  text-align: center;
  height: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 2em;
}

.rec-test-step-list-tree .ant-tree-node-content-wrapper {
  width: 95%;
}

.rec-test-step-list-tree .ant-tree-treenode-switcher-close {
  margin: 10px;
  border: 1px solid #d9d9d9;
}

.rec-test-step-list-tree .ant-tree-treenode-switcher-open {
  margin: 10px;
  border: 1px solid #d9d9d9;
}

.rec-test-step-list-tree ul .ant-tree-treenode-switcher-close {
  margin: 10px;
  border: 1px solid #d9d9d9;
}

.rec-test-step-list-tree .ant-tree-treenode-switcher-close.ant-tree-treenode-selected {
  background-color: #338fcc54;
  color: white;
}

.rec-test-step-list-tree .ant-tree-treenode-switcher-close.ant-tree-treenode-selected {
  background-color: #338fcc54;
  color: white;
}

.rec-test-step-list-tree .ant-tree-treenode-switcher-open.ant-tree-treenode-selected.parent {
  background-color: #338fcc54;
  color: white;
  background: linear-gradient(to bottom, #338fcc54 10%, #ffffff 22%);
}

.rec-test-step-list-tree .ant-tree-treenode-switcher-open.ant-tree-treenode-selected {
  background-color: #338fcc54;
  color: white;
}

.rec-test-step-list-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: transparent;
}

.rec-test-step-list-tree li .ant-tree-node-content-wrapper:hover {
  background-color: transparent !important;
}

.rec-test-step-list-tree li ul li {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.passed {
  border: 1px solid #7cd175 !important;
}

.skipped {
  border: 1px solid #f7ba7b !important;
}

.failed {
  border: 1px solid #ff4d4f !important;
}

.inprogress {
  border: 1px solid #a13597 !important;
}

.CONTINUE_AFTER_FAILED {
  border: 1px solid #a13597 !important;
}

.passed-text {
  color: #7cd175 !important;
}

.failed-text {
  color: #ff4d4f !important;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  min-width: 35px;
}
.red-font-color{
  color:#e72e2e
}
.bg-danger {
  background-color: #e72e2e !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-primary {
  background-color: #3461ff !important;
}

.bg-info {
  background-color: #32bfff !important;
}

.bg-warning {
  background-color: #ffcb32;
  color: #000 !important;
}

.bg-abort {
  background-color: #e8bb34fa;
}

.bg-uploading {
  background-color: #fff15c85;
  color: #000;
}

.bg-success {
  background-color: #12bf24 !important;
}

.inporgress-text {
  color: #0080ff;
}

.skipped-text {
  color: #f7ba7b;
}

.failed-text {
  color: #ff4d4f;
}

.inprogress-text {
  color: #a13597;
}

.total-text {
  color: #676f7c;
}

.CONTINUE_AFTER_FAILED-text {
  color: #a13597;
}

.default-result-text {
  color: gray;
}

.import-rec-test-scenario-step-tree .ant-tree-title {
  white-space: pre-wrap;
  height: fit-content;
  padding-right: 20px;
}

.import-rec-test-scenario-step-tree .ant-tree-node-content-wrapper {
  height: auto !important;
  width: auto !important;
}
/* 
.scenario {
  border: 1px solid #d9d9d9;
  margin-bottom: 1vh;
  padding: 5px;
  width: 100%;
} */

.scenario {
  display: flex;
  align-items: center;
  padding: 5px !important;
  margin-bottom: 1vh;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.scenario .ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  outline: none;
}
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}

.hr-text:before {
  content: "";
  /* use the linear-gradient for the fading effect */
  /* use a solid background color for a solid bar */
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;

  padding: 0 0.5em;
  line-height: 1.5em;
  /* this is really the only tricky part, you need to specify the background color of the container element... */
  color: #818078;
  background-color: #fcfcfa;
}

.other-element-item {
  margin-top: 2%;
  border: 1px solid #d9d9d9;
  padding: 2%;
  font-size: 12px;
  color: #939393;
  cursor: pointer;
}

.other-element-item-selected {
  margin-top: 2%;
  border: 1px solid #d9d9d9;
  padding: 2%;
  font-size: 12px;
  color: #939393;
  background-color: #c0d9ef;
}

.other-element-modal-index {
  padding-right: 10px;
}

.other-elements-popover {
  width: 50%;
}

.other-element-popover-close-button {
  font-size: 20px;
  color: #ff4d4f;
  /* margin-left: 15px; */
  cursor: pointer;
  position: absolute;
  right: 1%;
}

.text-danger {
  color: #ff0000;
}

.import-rec-test-scenario-step-tree {
  max-height: 290px;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px auto;
  width: 100%;
}

.recorded-scenario-list-modal {
  max-height: 290px;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 10px;
}

.ant-notification-notice-message {
  color: #000000a6;
}

.modal-pagination-wrapper {
  padding: 10px 0 30px 0;
  position: relative;
  display: block;
}

.recorded_img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  max-width: 400px;
  max-height: 700px;
  margin: auto;
  padding: 15px 10px;
}

.titleName {
  font-size: 12px;
  display: block;
}

.titleNameBig {
  font-size: 14px;
  font-weight: 600;
}

.title {
  color: #65676b;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 0.5px solid rgb(241, 240, 240);
  padding: 5px;
}

.titleData {
  font-size: 12px;
  display: block;
  text-align: left;
  margin: 10px 15px 0px;
}

.projectTeamHeaders {
  text-align: left;
  padding: 15px 30px 15px 30px;
  font-size: 24px;
  font-weight: 600;
}

.pageHeader {
  text-align: left;
  padding: 10px 30px 0px 25px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 50px;
}

.orgName {
  text-align: left;
  padding: 10px 0px 0px 30px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.memberList {
  padding: 0px 16px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  color: #111548;
}

.memberList > span {
  font-weight: 400;
  color: #000;
}

.memberRemove {
  font-size: 15px;
  float: right;
}

.header-fixed .app-logo {
  height: 52px;
  width: auto;
  margin-top: -10px;
  cursor: pointer;
}

.app-management-button {
  padding: 4px 16px 0px 16px;
  color: #338fcc !important;
  cursor: pointer;
  width: 100%;
  line-height: 2.5em;
}

.app-management-button-active {
  padding: 4px 16px 0px 16px;
  color: #504f4e !important;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  line-height: 2.5em;
}

.free-text-search-app-manage > div {
  display: flex;
  align-items: center;
}

@media (max-width: 810px) {
  .free-text-search-app-manage > div {
    align-items: baseline;
  }
}

.app-build-header {
  height: 45px !important;
  margin-left: 10px !important;
}

/* .date-range-picker-right {
  float: right !important;
} */
.date-range-picker-left {
  float: left !important;
}

.device-usage-report-rangeicker {
  margin-left: 12px !important;
  margin-right: 33px !important;
}

.pageHeaderBig {
  text-align: left;
  padding: 20px 25px 0px 25px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.plusIcon {
  font-size: 42px;
  margin-top: 10px;
}

.scroll-top {
  position: fixed;
  bottom: 70px;
  right: 15px;
  height: 36px;
  z-index: 99;
  cursor: pointer;
}

.textUnderEmailBox {
  margin-bottom: 0;
}

.mper10 {
  margin: 10%;
}

.mPer-1 {
  margin: 1%;
}

.m-auto {
  margin: auto;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.m-40 {
  margin: 40px;
}

.m-50 {
  margin: 50px;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 1px;
}
.ml-2 {
  margin-left: 2px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-15 {
  margin-left: 15px;
}
.ml-18 {
  margin-left: 18px;
}
.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px !important;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-32 {
  margin-left: 32px;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-less-10 {
  margin-left: -10px;
}

.mr-less-10 {
  margin-right: -10px;
}

.ml-less-13 {
  margin-left: -13px;
}
.ml-less-5 {
  margin-left: -5px !important;
}
.ml-less-3 {
  margin-left: -3px !important;
}
.ml-less-25 {
  margin-left: -25px;
}
.ml-less-30 {
  margin-left: -30px;
}
.mr-0 {
  margin-right: 0px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-per-10 {
  margin-right: 10%;
}

.mr-per-2 {
  margin-right: 2%;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-27 {
  margin-right: 27px !important;
}

.mr-30 {
  margin-right: 30px;
}

.mr-38 {
  margin-right: 38px !important;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}
.mb-less-13 {
  margin-bottom: -13px;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-5 {
  margin-top: 5px;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-less-3 {
  margin-top: -3px;
}

.mt-less-6 {
  margin-top: -6px;
}

.mt-less-19 {
  margin-top: -19px;
}
.mt-less-10 {
  margin-top: -10px;
}
.mt-less-14 {
  margin-top: -14px !important;
}
.mt-less-30 {
  margin-top: -30px;
}

.mt-less-35 {
  margin-top: -35px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-per-50 {
  margin-top: 50%;
}

.mt-per-35 {
  margin-top: 35% !important;
}

.p-0 {
  padding: 0px !important;
}

.p-5 {
  padding: 5px;
}

.p-8 {
  padding: 8px;
}
.p-12 {
  padding: 12px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.p-50 {
  padding: 50px;
}

.pl-0 {
  padding: 0px !important;
}

.pl-5 {
  padding-left: 5px;
}

.pl-12 {
  padding-left: 12px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-50 {
  padding-left: 50px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px;
}

.text-decoration-underline {
  text-decoration: underline;
}

.align-self {
  align-self: center;
}

.display-flex {
  display: flex !important;
}

.display-contents {
  display: contents;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.word-break {
  word-break: break-word;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-self-flex-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-baseline{
  align-items: baseline;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.vertical-align-inherit {
  vertical-align: inherit;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.vertical-align {
  vertical-align: -webkit-baseline-middle;
}

.vertical-align-top {
  vertical-align: top;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.font-size-8 {
  font-size: 8px;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-28 {
  font-size: 28px;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.font-weight-bold {
  font-weight: bold;
}
.border-none {
  border: none !important;
}

.border-1-gray {
  border: 1px solid lightgrey;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-1-lightGray {
  border: 1px solid #e9e9e9 !important;
}
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.font-color-black {
  color: black;
}

.bg-lightBlue {
  background-color: #338fcc !important;
}

.bg-lightPurple {
  background-color: #4a4c69 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.word-break {
  word-break: break-word;
}

.max-width-50 {
  max-width: 50%;
}

.max-width-40 {
  max-width: 40%;
}

.memberRow {
  width: 100%;
}

.memberCol {
  padding: 10px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}

.memberAvatar {
  margin-left: 8px;
  margin-right: 8px;
}

.componentContainerPadding {
  padding: 1px 30px 0px 30px;
}

.popOver > .Content {
  margin: 0;
  padding: 0px !important;
}
.popOverContent {
  padding: 0px !important;
}

.ant-popover-inner {
  margin: 0;
  padding: 0;
}

.ant-popover-inner-content {
  margin: 0;
  /* padding: 0; */
}

.border-left-pass {
  border-left: 2px solid #12bf24 !important;
}

.border-left-abort {
  border-left: 2px solid #e8bb34fa !important;
}

.border-left-fail {
  border-left: 2px solid #e72e2e !important;
}

.border-left-skip {
  border-left: 2px solid #ffcb32 !important;
}

.border-left-inProgress {
  border-left: 2px solid #32bfff !important;
}
.border-left-continue-after-fail {
  border-left: 2px solid #a13597 !important;
}

.text-align-end {
  text-align: "end";
}

.panel-background-color {
  background-color: "#f7f7f7";
}

.panel-background-color-disable {
  background-color: rgb(213, 213, 213) !important;
}

@media only screen and (min-width: 900px) {
  .main-test-plan {
    padding: 0 70px 0 70px;
  }
}

.font-weight-900 {
  font-weight: 900px;
}

.main-test-plan {
  padding: 0 10px 0 10px;
  margin-top: 50px;
}

.ant-collapse {
  background-color: white;
}

.ant-collapse-header {
  border-color: rgb(177, 177, 177);
  border-radius: 0px;
}

.arrowSize {
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
  margin: 6px 3px 6px 3px;
}

.DropDownArrow {
  margin-left: -10x;
  font-size: 16px !important;
}

.h-31 {
  height: 31px;
}
.w-13 {
  width: 13px;
}

.w-18 {
  width: 18px;
}

.width-20 {
  width: 20px;
}

.width-600 {
  width: 600px !important;
}
.width-90 {
  width: 90px !important;
}

.w-100 {
  width: 100% !important;
}

.w-95 {
  width: 95% !important;
}
.w-90 {
  width: 90% !important;
}

.w-98 {
  width: 98% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-35 {
  width: 35% !important;
}

.w-30 {
  width: 30% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80%;
}

.w-30 {
  width: 30px;
}

.w-45 {
  width: 45px;
}

.w-50 {
  width: 50px;
}

.w-60 {
  width: 60px;
}

.width-30 {
  width: 30%;
}

.width-50 {
  width: 50%;
}

.width-60 {
  width: 60%;
}

.width-100 {
  width: 100px;
}

.w-110 {
  width: 110px !important;
}

.w-125 {
  width: 125px !important;
}

.w-150 {
  width: 150px !important;
}

.w-200 {
  width: 200px !important;
}

.w-160 {
  width: 160px !important;
}

.w-170 {
  width: 170px !important;
}

.w-190 {
  width: 190px;
}

.w-10-vh {
  width: 10vh;
}

.w-250 {
  width: 250px;
}

.h-17 {
  height: 17px;
}

.h-15 {
  height: 15px;
}

.h-90 {
  height: 90%;
}

.h-20 {
  height: 20px;
}

.h-25 {
  height: 25px !important;
}

.h-28 {
  height: 28px;
}

.h-30 {
  height: 30px !important;
}

.h-40 {
  height: 40px !important;
}

.h-50 {
  height: 50px;
}

.h-55 {
  height: 55px;
}

.h-60 {
  height: 60px;
}

.h-150 {
  height: 150px;
}

.h-200 {
  height: 200px;
}

.h-230 {
  height: 230px;
}

.h-250 {
  height: 250px;
}

.h-280 {
  height: 280px;
}

.h-380 {
  height: 380px;
}

.h-300 {
  height: 300px;
}

.h-400 {
  height: 400px;
}

.h-450 {
  height: 450px;
}
.h-100 {
  height: 100%;
}
.min-w-60 {
  min-width: 60px;
}
.max-h-140 {
  max-height: 140px;
}

.line-height-2 {
  line-height: 2.5;
}

.spinner-style {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
}

.spinner-center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}

.font-color-lightGreen {
  color: #07ae3c;
}

.font-color-red {
  color: rgb(231, 15, 15);
}

.font-color-orange {
  color: orange;
}

.font-color-white {
  color: white !important;
}

.back-color-lightGray {
  background: #ddd;
}

.back-body {
  background-color: #f7f8fa;
}

.DropdownScroll {
  max-height: 250px !important;
  overflow: auto !important;
}
.inputTextBox {
  width: 50%;
  margin: 10px;
  margin-bottom: 1px;
  box-shadow: unset !important;
  padding-left: 5px;
  outline: none !important;
  border-bottom: 1px solid #65676b30;
  border-radius: unset;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  height: 32px;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
}

.styledRow {
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  margin: 15px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}

.styledRow:hover {
  background-color: #e8e8e885;
}
.chart-height {
  height: 250px;
  margin-left: 10px;
}

.ant-modal-body {
  max-height: 450px;
  overflow: auto;
}

.droppable_style {
  width: 260px;
}

.height-50 {
  height: 50px;
}

.height {
  height: calc(100vh - 115px);
}

.bg-color-gray {
  background-color: #a5aeaf24;
}

.bg-color-darkgray {
  background-color: #646363;
}

.bg-color-lightgray {
  background-color: lightgray;
}

.bg-color-white {
  background-color: white;
}

.bg-color-whiteGray {
  background-color: #d3d3d33b;
}
.box-shadow {
  box-shadow: (0 0 0 / 8%) 0px 0.125rem 0.25rem;
}

@media only screen and (max-width: 1000px) {
  .droppable_style {
    width: 180px;
  }
}

@media only screen and (max-width: 1100px) {
  .droppable_style {
    width: 190px;
  }
}

@media only screen and (max-width: 900px) {
  .droppable_style {
    width: 150px;
  }
}

@media only screen and (max-width: 700px) {
  .droppable_style {
    width: 100px;
  }
}

@media only screen and (max-width: 500px) {
  .droppable_style {
    width: 70px;
  }
}

@media only screen and (max-width: 1012px) {
  .headerTabsMenu1 {
    display: none;
  }
}

@media only screen and (min-width: 1012px) {
  .headerTabsMenu2 {
    display: none;
  }
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  opacity: 0;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 480px;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.tag {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 5px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #338fcc;
}

.tag-title {
  margin-top: 3px;
}

.Input {
  flex: 1;
  border: none;
  font-size: 13px;
  padding: 4px;
  border: 1px solid #dedede;
  border-radius: 5px;
}

.Inputs:focus {
  outline: transparent;
}

.ant-time-picker-panel-combobox {
  height: 65px;
  overflow: auto;
}

.ant-time-picker-input {
  border-radius: 4px !important;
}

.selectDay {
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #111548;
}

.selectDay:hover {
  background-color: #dedede;
}

.schedule_job {
  margin: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 5px;
}

.schedule_job:hover {
  background-color: lightgray;
}

.testDataCard {
  position: fixed;
  z-index: 10;
  height: auto;
}

.project-options {
  height: 43px;
  line-height: 40px !important;
  width: 100%;
}

.menu-item {
  float: left;
  margin-left: 13px;
  cursor: pointer;
  font-size: 14px;
}

.sub-menu-item {
  cursor: pointer;
  font-size: 14px;
}

.menu-item-selected {
  border-bottom: 2px solid #338fcc;
  color: #338fcc;
  padding-bottom: 5px;
}

.text-align-center {
  text-align: center;
}

.sub-header {
  background-color: #f2f2f2;
  position: fixed;
  height: 32px;
  width: 100%;
  z-index: 999;
}

.h-60vh {
  height: 60vh;
}

.h-min-content {
  height: min-content;
}
.sub-header-options > label {
  margin: 3px 0;
  padding: 0px 15px;
  line-height: 22px;
  height: 30px;
}

.test-data-row-header {
  font-weight: 600;
  display: inline-block;
  width: 20%;
}

.test-data-row {
  display: inline-block;
  width: 20%;
}

.test-data-row-key-header {
  font-weight: 600;
  display: inline-block;
  width: 10%;
}

.test-data-row-key {
  display: inline-block;
  width: 10%;
}

.width-fit-content {
  width: fit-content;
}
.buildStatus {
  display: inline-block;
  font-size: 0.75em;
  font-weight: 700;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  min-width: 50px;
}

.font-lightBlue {
  color: #338fcc !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis !important;
}

.HeaderSubMenu {
  padding: 4px 16px 0px 16px;
  color: #338fcc !important;
  cursor: pointer;
  width: 100%;
  line-height: 2.5em;
}

.HeaderSubMenu-menu-item-selected {
  padding: 4px 16px 0px 16px;
  color: #338fcc !important;
  cursor: pointer;
  width: 100%;
  line-height: 2.5em;
  border-bottom: 2px solid #338fcc;
  color: #338fcc;
}
.position-fixed {
  position: fixed;
}
.position-absolute {
  position: absolute;
}

.white-space-nowrap {
  white-space: nowrap;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.4;
}

.tabs .ant-tabs-nav {
  width: 100%;
}

.tabs .ant-tabs-tab {
  width: calc(100% - 8px);
  text-align: center;
}

.tabsRetry .ant-tabs-nav {
  width: 100%;
}

.tabsRetry .ant-tabs-tab {
  width: calc(100% - 8px);
  text-align: center;
}

.white-space-pre-line {
  white-space: pre-line;
}

.skipStep {
  background-color: #b7b7b7 !important;
}

.text-transform-capital {
  text-transform: capitalize;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.steps-content {
  height: calc(100vh - 250px) !important;
}

.border-color-red {
  border-color: red !important;
}
.device-offline-img {
  width: 25px !important;
  height: inherit !important;
}

.search-error > .ant-input {
  border: 1px solid red !important;
  border-radius: 4px;
}

.Container {
  border: 1px solid lightgray;
  padding: 10px;
  height: 100%;
}

.modal_style .ant-modal-body {
  padding: 15px;
}

.investigation-button {
  height: auto;
  padding: 3px 10px;
}

.render-source {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  padding: 2px;
}

.render-source:hover {
  background-color: #e8e8e8;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.device-img {
  float: left;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #dee2e6 !important;
}

.border-bottom-1 {
  border-bottom: 1px solid #e8e8e8;
}
.border-bottom-5 {
  border-bottom: 5px solid black;
}
.schedule-job-active {
  color: #008ecc;
}

.bg-color-blue {
  background: #008ecc !important;
}

.border-blue {
  border: 1px solid #008ecc !important;
}

.border-purple {
  border: 2px solid #5160cd !important;
}

.border-red {
  border: 1px solid #ff4d4fab !important;
}

.bg-purple {
  background-color: #ebedfc !important;
}
.slick-prev:before,
.slick-next:before {
  color: black !important;
}

.margin-left-50 {
  margin-left: 50%;
}

.flex-grow-1 {
  flex-grow: 1;
}

.font-color-darkBlue {
  color: #111548;
}

.font-color-primaryGray {
  color: #6c6e8d;
}

.bg-color-darkOrange {
  background-color: #ce6d42 !important;
}

.bg-color-primaryGray {
  background-color: #cbcde3 !important;
}

.button-style {
  border: none !important;
  padding: 0px 12px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  height: 33px;
}

.bottom-0 {
  bottom: 0;
}

.dataSetTitle {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.85);
}

.footerTitleColor {
  color: #6e7282;
}

.red-icon {
  color: red;
}

.green-icon {
  color: green;
}

.rec-test-step-element > .ant-collapse-header {
  padding: 5px !important;
  margin: 0px !important ;
  width: 100%;
}
.addAction-col-wrapper {
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px 0px;
  gap: 10px;
  z-index: 999;
}
.ant-collapse {
  margin-bottom: 0 !important;
}
.addAction-col-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 10px;
}
.addAction-col-image img {
  width: 100%;
  object-fit: cover;
}
.subStepCollapse {
  overflow: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}
.pageScroll {
  height: calc(100vh - 138px);
  overflow-y: scroll;
}

.devicePools {
  height: calc(100vh - 160px);
  overflow-y: scroll;
}

.padding {
  padding: 8px 1px 0px 14px;
}

.innerHeader {
  background-color: #ffffff;
}

.textHeader {
  text-align: center;
  display: flex;
  align-items: center;
}

.mainHeaderText {
  font-size: 20px;
  font-weight: 600;
  color: #111548;
}

.ant-input-group.ant-input-group-compact .ant-input[disabled] {
  width: 100px;
  color: black;
  cursor: auto;
  margin-top: 5px;
  font-weight: 500;
}
.no-wrap {
  white-space: no-wrap;
}

.selectionElement {
  background-color: #ebeaea;
  padding: 15px;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.orangeText {
  color: orange;
  font-size: 12px;
}

.addVerification {
  text-align: center;
  padding: 25px;
  color: #006cd8;
}

.blueText {
  color: #40a9ff;
}

.desk-w-50 {
  width: 50% !important;
}

.select-element {
  min-width: 150px;
}

@media only screen and (max-width: 1000px) {
  .select-element {
    min-width: 100%;
  }
}
.word-wrap-break{
  word-wrap: break-word;
}
.pageScrollOfTestData {
  height: calc(100vh - 178px);
  overflow-y: scroll;
}

.font-color-lightBlue {
  color: #216fdb;
}

.filterListScroll {
  max-height: calc(100vh - 600px);
  overflow-y: scroll;
}

.stepsArea{
  display: flex;
  flex-direction: column;
}
.emptyAreaofDesigner{
  /* background-color: yellow; */
  flex-grow: 1;
}