@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.row {
  margin-bottom: 0.3rem;
}

.elementColumn {
  color: #3d3d49;
  word-break: break-all;
  @include update_fonts($font_weight__400, $font-size__16);
}

.generatedMethodCol {
  margin-left: -0.813rem;
}

.title {
  text-transform: uppercase;
  color: #636467;
  @include update_fonts($font_weight__400, $font-size__14);
}

.titleActive {
  margin-left: 0.13rem;
}

.xpathCol {
  color: #636467;
  word-break: break-all;
  margin-left: 0.8rem;
  @include update_fonts($font_weight__400, $font-size__16);
}

.recElement {
  padding: 0.9rem;
  gap: 0.6rem;
  border: 0.06rem solid lightgrey;
}
