@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

body {
  font-family: "Inter";
  background: #f6f6f6;
  font-size: 12px;
  color: #4a4c69 !important;
}
html,
body {
  height: auto;
}

/* button */
.ant-btn {
  font-weight: 500;
  font-size: 12px;
  font-family: "Inter";
  border: none;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
  text-shadow: none;
  letter-spacing: 0.3px;
}

.ant-btn.gray {
  background: #d3d4de;
  color: #111548;
  border: 1px solid #c3c5d2;
  font-weight: 500;
}

.ant-btn.gray:hover {
  background: #d3d4de;
}

.ant-btn.purple {
  background: #cbcde3;
  color: #111548;
  border: 1px solid #cbcde3;
  font-weight: 500;
}

.ant-btn.purple:hover {
  background: #a8a5a526;
  color: #b4b6cc;
}

.ant-btn.purple[disabled] {
  background: #70707026;
  color: rgba(0, 0, 0, 0.25);
}

.ant-btn.white {
  background: #ffffff;
  color: #6e7282;
  border: 1px solid #c3c5d2;
  font-weight: 500;
}

.ant-btn.white:hover {
  background: #f5f5f5;
  color: #4a4c69;
}

.ant-btn-text {
  color: #141850;
  background: transparent;
}

.ant-btn-lg {
  height: 40px;
  padding: 0 20px;
}

.orange {
  background: #ce6d42;
  color: #fff;
}

.orange:hover {
  background: #bf5629;
  color: #fff;
}
.orange[disabled] {
  background: #e6e6e6;
  color: #6e7282;
}

.purple {
  background: #cbcde3 !important;
  color: #111548 !important;
}

.purple:hover {
  background: #babede;
  color: #111548;
}
.font-blue {
  color: #3e43d5;
}

.ant-btn.light-outline {
  background: #f6f6f6;
  color: #6e7282;
  border: 1px solid #c3c5d2;
}

.ant-btn.light-outline:hover {
  background: #d3d4de;
  color: #111548;
}

.ant-btn.backToTop {
  position: absolute;
  bottom: 17px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
  cursor: pointer;
  background: transparent;
  width: 132px;
  height: 35px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4a4c69;
  padding: 9px 20px;
}
.ant-btn.backToTop:hover {
  background: #eaeaea;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.ant-input {
  height: 2.1875rem;
}

.ant-input {
  border: 1px solid #b0b1c1;
  border-radius: 5px;
  color: #545569;
  font-size: 12px;
}

.ant-input-lg {
  height: 40px;
}

.ant-input:focus,
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #b4b4b4;
  box-shadow: none;
}

/* dropdown */
.ant-dropdown-menu-submenu-title {
  padding-top: 10px;
  padding-bottom: 10px;
}
.submenu-title {
  font-weight: 400;
  font-size: 12px;
  color: #6e7282 !important;
  border-bottom: 1px solid #e2e3e6;
  padding: 10px 0 !important;
  pointer-events: none;
  width: 100%;
  margin: auto !important;
}

.ant-dropdown-menu {
  padding: 0px;
}

h1 {
  font-family: "Inter";
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #111548;
}

/* text-color varients */
.text-dark {
  color: #111548;
}
.text-light1 {
  color: #4a4c69;
}
.text-light2 {
  color: #6e7282;
}
/* color */
.font-color-blue {
  color: #4a9bf2;
}
/* weight */
.font-weight-medium {
  font-weight: 500;
}
.pe {
  cursor: pointer;
}
.m-auto {
  margin: auto;
}

.innerheader-fixed {
  color: #8c8c8c;
  box-sizing: border-box;
  -webkit-transition: top 0.2s;
  transition: top 0.2s;
  text-align: center;
  font-size: 1.3em !important;
  height: 56px;
  background-color: #26262b;
  border-bottom: 1px solid #c3c5d2;
  width: 100%;
  position: fixed;
  z-index: 10;
  padding: 0 20px;
  top: 0px;
  left: 0px;
}

.logo {
  width: 55px;
  padding-right: 15px;
  position: relative;
  border-right: 1px solid #c3c5d2;
}

.more-menu {
  width: calc(100vw);
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.06);
  margin: auto;
}
.more-menu .ant-dropdown-menu {
  background: #fff;
}
/* .more-menu > .ant-menu {
  padding: 20px 10px;
  margin-top: 10px;
} */

.more-menu h4 {
  margin-bottom: 0;
  line-height: 1;
}

.more-menu li {
  height: 100% !important;
}

.more-menu .ant-divider {
  margin: 10px 0;
}
.more-menu li:hover {
  background: transparent !important;
}
.recent-list {
  margin-bottom: 15px;
  padding: 10px 15px 15px 15px;
}

.recent-list:hover {
  background: #e9e9f1;
  border-radius: 5px;
}

.recent-list:last-child {
  margin-bottom: 0;
}

.recent-list span {
  font-weight: 400;
  font-size: 12px;
  color: #6e7282;
  margin-bottom: 6px;
  display: block;
  line-height: 1;
}

.recent-list p {
  font-size: 13px;
  color: #111548;
  line-height: 1;
  margin-bottom: 0;
}

.menu-list img,
.menu-list-active img {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.menu-list,
.menu-list-active {
  font-weight: 400;
  font-size: 13px;
  color: #111548;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 5px;
}

.menu-list:last-child,
.menu-list-active:last-child {
  margin-bottom: 0;
}

.menu-list:hover,
.menu-list-active {
  background: #e9e9f1;
  border-radius: 5px;
}

.organization-grid {
  border: none;
}

.organization-grid li {
  width: 155px;
  height: 125px !important;
  margin-right: 15px;
  text-align: center;
  display: inline-block;
  font-size: 13px !important;
  border-radius: 5px;
  overflow: visible !important;
}

.selectedOrg {
  color: #111548;
  background: #f2f2f3;
  border: 1px dashed #6e7282;
  margin-right: 15px;
  border-radius: 5px;
  display: inline-block;
  width: 155px;
  height: 125px !important;
}
.selectedOrg .check-icon {
  display: block;
  margin: auto;
  color: #fff;
  float: right;
  right: 6px;
  top: -6px;
  position: absolute;
}
.organization-grid::after,
.organization-grid::before {
  content: none;
}

.organization-grid li .short-name {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: block;
  margin: auto;
  color: #fff;
  font-size: 14px;
  margin-top: 30px;
}

/* .more-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
} */

/* header popover */
.user-tag-username {
  color: #111548;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0px;
  margin-top: 15px;
}

.user-tag-email {
  color: #6e7282;
  font-size: 13px;
  text-align: center;
  margin-bottom: 15px;
}

.ant-popover-title {
  font-weight: 400;
  font-size: 13px;
  color: #6e7282;
  line-height: 21px;
}

.label {
  font-weight: 400;
  font-size: 13px;
  color: #4a4c69;
}

.text {
  font-weight: 400;
  font-size: 13px;
  color: #3d3d49;
}

.pagination-prev {
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 6px;
  border-radius: 5px;
  margin-left: 10px;
  background: transparent;
}
.pagination-next {
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 6px;
  border-radius: 5px;
  background: transparent;
}
.pagination-prev:hover,
.pagination-next:hover {
  background: #d3d4de;
}
.pagination-prev:hover img,
.pagination-next:hover img {
  filter: brightness(0);
}

/* radio + checkbox */

/* .ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #6c6e8d !important;
  border-radius: 2px;
} */

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #d9d9d9 !important;
  border: 1px solid #d9d9d9 !important;
}
/* .ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-checked .ant-radio-inner, */
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active,
.ant-select-selection {
  border: 1px solid #6c6e8d !important;
  background: transparent !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #444aea;
}

/* cursor */
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-drag {
  cursor: grab;
}
.cursor-default {
  cursor: default !important;
}

/* table css */
.ant-table h3 {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.ant-table {
  font-weight: 400;
  font-size: 13px;
}
.ant-table-thead tr th {
  font-weight: 500;
  font-size: 12px;
  color: #4a4c69;
  padding: 12px 20px;
  background: transparent;
  border-bottom: 1px solid #dcdcf4;
}
.ant-table-thead tr {
  background: #f6f6f6;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}
.ant-table-cell {
  color: #6e7282;
  font-weight: 400;
  font-size: 13px;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: transparent;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected),
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected),
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected),
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > th {
  background: #f6f6f6;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
  background: #fbfbfb;
}
.ant-table-tbody > tr.activeRow,
.ant-table-tbody > tr.activeRow:hover,
.ant-table-tbody > tr.activeRow:hover {
  background: #ebf0f6 !important;
}
.ant-table-tbody > tr.activeRow:last-child td.ant-table-cell-row-hover {
  border-bottom: none;
}

.ant-table-thead .ant-table-cell {
  padding: 0px 16px !important;
  height: 22px !important;
  text-transform: uppercase;
}

.ant-drawer-body {
  background-color: #f6f6f6;
}

.ant-table table {
  border-collapse: collapse;
}

.ant-table-tbody > tr:last-child > td {
  border: none;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #dcdcf4;
}
.ant-table-bordered .ant-table-tbody > tr > td {
  border-bottom: 1px solid #dcdcf4;
}
.ant-table-bordered .ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}
tr td.ant-table-cell:last-child,
tr th.ant-table-cell:last-child {
  border-right: none !important;
}
.ant-table-cell .ant-input {
  background: transparent;
  padding: 0;
  font-size: 13px;
  font-weight: 400;
}
.ant-table-cell .ant-input::placeholder {
  color: #6e7282;
}
.ant-table-cell .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
  border: 1px solid #dcdcf4;
  border-radius: 5px;
}
.ant-table-cell .ant-select-selection-search-input::placeholder,
.ant-table-cell .ant-select-selection-placeholder {
  color: #111548;
}
.select-all {
  background: transparent;
  border-radius: 5px;
  border: 1px solid #d3d4de;
  padding: 7px 10px;
}
.select-all:hover {
  border-radius: 5px;
  border: 1px solid #d3d4de;
  padding: 7px 10px;
}
.active-select-all {
  border: 1px solid #d3d4de;
  border-radius: 5px;
  padding: 7px 10px;
}

.ant-checkbox-wrapper-checked ~ span {
  color: #111548;
}
.select-action-hover {
  border-radius: 5px;
  padding: 9px 10px;
  cursor: pointer;
}
.select-action-hover:hover {
  border: 1px solid #d3d4de;
}

/* ant tab custom*/

.ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  margin: 0;
  color: #6e7282;
  font-weight: 400;
  font-size: 14px;
}
.ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #444aea;
  font-weight: 500;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 20px;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #c3c5d2;
}
.custom-hr {
  border: none;
  border-top: 1px solid #c3c5d2;
}

/* ant input */
.ant-input:hover,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #111548;
}

/* multi tag */
.ant-select-multiple .ant-select-selection-item {
  background: #f6f6f6;
  border: 1px solid #b0b1c1;
  padding: 8px 12px;
  height: 35px;
  font-weight: 500;
  font-size: 13px;
  color: #111548;
  line-height: 1;
}

/* ant select */
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  min-height: 34px;
  padding: 2px 12px;
  border-radius: 5px;
  border: 1px solid #b0b1c1;
  /* margin-left: 2px; */
}
.ant-select {
  font-size: 13px;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #111548;
  box-shadow: none;
  border-right-width: 1px;
  outline: 0;
}

.ant-select-arrow span {
  display: none;
}
.ant-select-arrow span {
  display: none;
}
.ant-select-arrow::before {
  content: "";
  position: absolute;
  background: url("../images/arrow-down.svg");
  display: block;
  width: 8px;
  height: 6px;
  right: 0;
  transform: rotate(0deg);
}
.ant-select-open .ant-select-arrow::before {
  transform: rotate(180deg);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #111548;
  font-weight: 400;
  background-color: #ebebec;
}
.ant-select-item {
  color: #4a4c69;
  font-weight: 400;
  font-size: 13px;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #ebebec;
}
/* title */
.sub-title {
  font-size: 12px;
  color: #6e7282;
}

.textarea {
  border-radius: 5px !important;
  border: 1px solid #b0b1c1 !important;
}

.datePicker {
  border-radius: 5px !important;
  border: 1px solid #b0b1c1 !important;
}

.ant-notification-notice {
  padding: 14px 15px;
  background: #ffffff;
  box-shadow: 0px 6px 14px rgba(120, 120, 120, 0.18);
  border-radius: 5px;
  margin-bottom: 0px;
}

.mx-width-90 {
  max-width: 90px;
}

.borderForDevice {
  border-bottom: 1px solid #d9d9d9;
}

.ellipse {
  max-width: 112px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-right: 10px;
}

/* ant select */
.ant-collapse {
  border: none;
  border-bottom: 0px;
  margin-bottom: 8px;
  border-radius: 5px;
}

.collapse {
  border: 1px solid #c3c5d2;
  border-radius: 5px;
}

.ant-collapse .ant-collapse-item-active .ant-collapse-header {
  border-bottom: 0px;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: 0px;
}

.collapse .ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-item,
.ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-item.ant-collapse-item-active {
  border-bottom: 0px;
}

.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 5px 5px;
}
.display-block {
  display: block;
}

.ant-tabs-tab:hover {
  color: rgba(0, 0, 0, 0.85);
}

.text-align-left {
  text-align: left;
}

/* device cards */
.devicesCard {
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 616px !important;
  overflow-y: auto;
  background: #ffffff;
  margin: 10px 10px;
  border: 1px solid #c3c5d2;
  border-radius: 10px;
}

.gap-20 {
  gap: 20px;
}

.deviceCardBorder {
  border: 1px solid #c3c5d2;
  border-radius: 5px;
}

.deviceCardLabel {
  color: #545569;
}

/* Tooltip */
.ant-tooltip-inner {
  color: #111548;
  font-weight: 400;
  font-size: 12px;
  padding: 7px 10px;
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(21, 21, 21, 0.06);
}

/* upload */
.ant-upload {
  width: 100%;
}
.custom-card {
  background: #ffffff;
  border: 1px solid #c3c5d2;
  border-radius: 10px;
}
.custom-card .ant-card-body {
  padding: 20px;
}
.drag-drop-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.ant-collapse.drag-drop-panel {
  border-radius: 5px;
  font-size: 13px;
  color: #4a4c69;
}

.max-width-50 {
  max-width: 50px;
}

.text-color {
  color: #4a4c69;
}
.ant-upload {
  width: 100%;
}

/* ant style : utility */
.ant-input-lg {
  height: 40px;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.mb-15 {
  margin-bottom: 15px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.m-0 {
  margin: 0;
}
.width-250 {
  width: 250px;
}
.pb-5 {
  padding-bottom: 5px;
}

.main-footer {
  background: #4a4c69;
  color: #fff;
  padding: 10px 20px;
  display: none;
}
.main-footer p {
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 0;
}
.dd-title {
  font-weight: 400;
  font-size: 12px;
  color: #6e7282;
  padding: 8px 5px;
  margin: 0 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.text-right {
  text-align: right;
}

.close-seperator {
  position: relative;
  width: 55px;
  text-align: center;
  right: -20px;
  border-left: 1px solid #ddd;
  height: 55px;
  padding-top: 13px;
}

.closeIcon {
  position: relative;
  width: 55px;
  text-align: center;
  border-left: 1px solid black;
  height: 55px;
  padding-top: 16px;
}

.relative {
  position: relative;
}

/* width */
*::-webkit-scrollbar {
  width: 12px;
  width: 12px;
  display: block;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}
.ant-select-lg .ant-select-selection-item,
.ant-select-lg .ant-select-selection-placeholder {
  font-size: 13px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  border-right: 1px solid #c3c5d2;
}
.ant-modal-content {
  box-shadow: 0px 10px 12px rgba(86, 86, 86, 0.44);
  border-radius: 5px;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  color: #4a4c69;
}
.modal-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #111548;
}
.modal-title img {
  margin-right: 10px;
}
.modal-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a4c69;
  margin-top: 15px;
}
.w-140 {
  width: 140px;
}
.w-178 {
  width: 178px;
}
.deviceCardColor {
  background: #f6f6f6;
}

.btn-help img {
  margin-right: 5px;
}

.userprofile-popover {
  padding: 0.5rem 0rem 0.875rem 0rem;
}

.menuIcon{
  padding:5px 0px 0px 0px;
}

.dd-profile-header {
  background: #f6f6f6;
  height: 50px;
  text-align: center;
  margin-bottom: 30px;
}
.dd-profile-header img {
  margin-top: 20px;
}
.dd-profile-width {
  min-width: 200px;
}
.logout-button {
  margin: 0px auto;
  display: block;
  text-align: center;
}
.logout-button .ant-btn {
  padding: 5px 15px;
  font-weight: 400;
  font-size: 13px;
  color: #111548;
  height: 38px;
  text-align: left;
}

.projectlist-tag {
  background: #eeeeee;
  border-radius: 100px;
  padding: 5px 12px;
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
}
.fontGreen {
  color: #004e41;
}
.fontBlue {
  color: #002fd8;
}
.projectlist-tag.web {
  color: #212121;
}

.active-Project {
  border: 1px solid #6c6e8d;
  background: #f8f8f8;
  border-radius: 4px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  height: 39px;
  left: 0px;
  background: #4a4c69;
  color: #ffffff;
  width: 100%;
  position: fixed;
  z-index: 10;
  bottom: 0px;
}

.sub-text {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 14px;
  color: #4a4c69;
}

.my-8 {
  margin: 8px 0px;
}

.avatar-font {
  font-weight: 700;
  font-size: 11px;
  text-align: center;
  color: #111548;
  text-transform: uppercase;
}

.addMembersNote {
  color: #6e7282;
  font-size: 12px;
  margin-top: 5px;
}

.avatarCard {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  padding: 10px;
}

.divider-90 {
  width: calc(100% - 30px);
  margin: auto;
  display: block;
  min-width: 1px;
}

/* authentication */
.auth-card-wrap {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #eee;
}
.auth-card {
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 10px 12px rgba(201, 201, 201, 0.44);
  border-radius: 6px;
  max-width: 480px;
  width: 480px;
}
.auth-card input[type="text"] {
  border: 1px solid #dedede;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 12px;
  font-weight: 400;
  font-size: 13px;
  color: #4a4c69;
}

.auth-card input::placeholder {
  color: #a0a0a5;
  text-transform: capitalize;
}
.auth-card .label {
  font-weight: 500;
  font-size: 12px;
  color: #4a4c69;
  font-family: "Inter";
  margin-bottom: 5px;
  display: block;
}
.auth-logo {
  margin-bottom: 30px;
}
.auth-card h2 {
  font-weight: 700;
  font-size: 22px;
  color: #111548;
  margin-bottom: 5px;
}
.auth-card h3 {
  font-weight: 400;
  font-size: 13px;
  color: #4a4c69;
  margin-bottom: 20px;
}
.auth-card h4 {
  font-weight: 500;
  font-size: 16px;
  color: #111548;
  margin-bottom: 20px;
}
.auth-footer-lines {
  font-weight: 400;
  font-size: 13px;
  color: #4a4c69;
}
.auth-footer-lines a {
  font-weight: 500;
  font-size: 13px;
  color: #4a9bf2;
}
.btn-auth {
  margin: 20px 0 15px 0;
}

.terms-condition {
  display: flex;
  align-items: center;
}
.terms-condition span {
  font-weight: 400;
  font-size: 13px;
  margin-left: 5px;
  color: #4a4c69 !important;
}
.terms-condition a {
  font-weight: 400;
  font-size: 13px;
  margin-left: 3px;
  color: #4a4c69;
}
.terms-condition a:hover {
  color: #4a9bf2;
}
.img-step {
  margin-bottom: 30px;
}
.skip {
  font-weight: 500;
  font-size: 13px;
  color: #4a9bf2;
  cursor: pointer;
}
.back {
  font-weight: 400;
  font-size: 12px;
  color: #141850;
  cursor: pointer;
  text-align: center;
}
.auth-card .ant-select-selector {
  border: 1px solid #dedede;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 0 5px;
}
.auth-card .ant-select-multiple .ant-select-selection-item {
  padding: 6px 10px;
  height: 29px;
  font-weight: 500;
  font-size: 13px;
  color: #111548;
  line-height: 1;
}
.error-message {
  color: #e74e4e;
  padding: 0;
}
@media (max-width: 767px) {
  .auth-card {
    width: 90%;
  }
}

.selectedTag {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111548;
  padding: 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #ffffff;
}

.selectedJobCriteria {
  background-color: #f4f7fa !important;
  border: 1px solid #111548 !important;
  border-radius: 4px !important;
  cursor: pointer;
}

.jobContainer {
  background: #ffffff;
  min-height: 65vh !important;
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 20px;
  margin-top: 55px;
}

.headerTitle {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #111548;
}

.radioBox {
  padding: 5px 9px;
  border: 1px solid #dedede;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.activeRadioBox {
  padding: 5px 9px;
  border: 1px solid #444aea;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.border-textArea {
  border: 1px solid #dedede;
}

.schedule {
  height: 78%;
  background-color: #f6f6f6;
  padding: 20px;
  border-bottom: 1px solid #dedede;
}

.scheduleTestRunDrawer {
  padding: 0px !important;
}

.scheduleTextContainer {
  width: 100%;
  background-color: #c5c7f8;
  border-radius: 5px;
  padding: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #111548;
  height: 70px;
}

.curl-container {
  background: #fafafa;
  border: 1px solid #dedede;
  border-radius: 4px;
}

.result-tag {
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
}

.activeFilter {
  align-items: center;
  padding: 3px 12px;
  gap: 10px;
  background: #ebedfc;
  border: 1px solid #444aea;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  color: #444aea;
  font-family: "Inter";
}

.filter {
  cursor: pointer;
  align-items: center;
  padding: 3px 12px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 100px;
  font-weight: 600;
  font-size: 13px;
  color: #4a4c69;
  font-family: "Inter";
}

.headerText {
  color: #ffffff;
}
.resetButton {
  color: #6e7282;
}
.resetButton:hover {
  color: #6e7282;
  background-color: rgb(224, 215, 215);
}

.resetButton[disabled] {
  color: #6e7282;
}
.resetButton[disabled]:hover {
  color: #6e7282;
}

.headerDetail {
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
  margin-left: 15px;
}

.scenarioBlackHeader {
  top: 54px;
  background: #ffffff;
  padding: 0px 0px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fixInnerHeader {
  top: 54px;
  background: #ffffff;
  padding: 0px 0px 0px 20px;
}

.inputStyle {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #111548;
  background: transparent;
  border: 1px solid #80808085;
  border-radius: 1px;
  margin-left: 10px;
}

.inputStyle.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  color: #111548;
}

.stepView {
  height: calc(100vh - 110px);
  background: #ffffff;
  border: 1px solid #cecece;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.stepText {
  font-weight: 700;
  font-size: 15px;
  color: #26262a;
  display: flex;
  align-items: center;
}

/* .steps {
  display: flex;
  align-items: center;
  border: 1px solid #dce5f5;
  font-weight: 500;
  font-size: 12px;
  background: #dce5f5;
  border-radius: 5px;
  margin-left: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 2%;
} */

.textBlue {
  color: #444aea;
}

.textRed {
  color: #ff3a3a;
}
.import-scenario-step-tree .ant-tree-title {
  white-space: pre-wrap;
  height: fit-content;
  padding-right: 20px;
}

.import-scenario-step-tree .ant-tree-node-content-wrapper {
  height: 35px !important;
  width: 100% !important;
}

.import-scenario-step-tree {
  margin-bottom: 20px;
  padding: 10px auto;
  width: 100%;
}

.blue {
  background: #444aea;
  color: #fff;
}

.blue:hover {
  background: #444aea;
  color: #fff;
}

.blue[disabled] {
  background: #e6e6e6;
  color: #6e7282;
}

.red {
  background: rgb(243, 55, 55);
  color: #fff;
}

.red:hover {
  background: rgb(243, 55, 55);
  color: #fff;
}

.labelDevice {
  color: #636467;
  font-size: 12px;
  font-weight: 400;
}

.ant-modal-title {
  margin: 0;
  color: #26262a;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.ant-drawer-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #26262a;
}

.font-blueDark {
  color: #3d3d49;
  font-size: 13px;
}

.font-blueDark :hover {
  color: #3d3d49;
  font-size: 13px;
}

.lightPurple,
.lightPurple:hover {
  background: #dcdcf4;
  color: #3d3d49;
}

.lightGray,
.lightGray:hover {
  background: #f5f5f5;
  color: #444aea;
}

.grayBtn,
.grayBtn:hover {
  background: #efefef;
  color: #26262a;
}

.deviceData {
  color: #26262a;
  font-weight: 500;
}

.add-Action {
  background: #ffffff;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #e0e0e0;
  border-radius: 4px 4px 0px 0px;
  margin-left: 10px;
  max-height: 50vh;
  overflow: auto;
}

.treeData {
  overflow: auto;
  flex: 1;
}

input[type="radio"]:checked {
  background-color: #93e026;
}

@media only screen and (max-width: 991px) {
  .addAction-col-wrapper {
    height: initial;
  }
  .add-Action {
    margin-left: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ededed;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 99px;
}

.tagInput {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  align-items: center;
}

.ant-select-selector {
  border: none !important;
  border-radius: 0 !important;
}
.stepsInTreeView,
.conditionalStepsTreeView {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 0px;
  width: 100%;
  height: 35px;
  border-radius: 4px;
  cursor: default;
  margin-top: 0;
  margin-left: 0;
}

.selectSteps .ant-input {
  background: #f7f7ff;
  border: 1px solid #444aea;
  border-right-style: hidden;
}

.border-1 {
  border-radius: 0px;
  border-right-style: hidden;
}

.stepsName {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 60px;
  justify-content: center;
}

.stepsInTreeView .ant-input:hover {
  border: 1px solid #e0e0e0;
  border-right-style: hidden;
}

.addAction-col-wrapper > div {
  width: 100%;
}

.ant-tree .ant-tree-treenode {
  width: 100%;
}
.stepsInTreeView > div:first-child {
  z-index: 999;
  background: #fff;
  height: 35px;
  flex: 0 0 calc(100% - 30px);
  position: relative;
}
.stepsInTreeView > div:first-child:not(.labelDevice)::before {
  content: "";
  width: 0.5px;
  height: 34px;
  position: absolute;
  left: 56px;
  top: -2px;
  bottom: 0;
  background-color: #d9d9d9;
  z-index: 9;
}
input.deSelectSteps,
input.selectSteps,
input.border-inProgress,
input.border-pass,
input.border-fail,
input.border-skip,
input.border-continue-after-fail,
input.border-abort {
  border: 0 !important;
  height: 33px;
}

.ant-tree-show-line .ant-tree-indent-unit::before {
  top: -40px;
  right: 7px;
}

.ant-tree-treenode-leaf-last span.ant-tree-switcher.ant-tree-switcher_open:before {
  content: "";
  width: 1px;
  height: 22px;
  position: absolute;
  top: -5px;
  left: 16px;
  background: #d9d9d9;
}

.ant-tree-treenode-leaf-last span.ant-tree-switcher.ant-tree-switcher_open:after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 16px;
  left: 16px;
  background: #d9d9d9;
}

div.stepsInTreeView,
div.conditionalStepsTreeView:not(.no-left-line) {
  position: relative;
}
div.stepsInTreeView:not(.no-left-line):not(.labelDevice):before {
  content: "";
  width: 1px;
  height: 40px;
  position: absolute;
  top: -24px;
  left: -12px;
  background: #d9d9d9;
}

div.stepsInTreeView:not(.no-left-line):after {
  content: "";
  width: 12px;
  height: 1px;
  position: absolute;
  top: 16px;
  left: -12px;
  background: #d9d9d9;
}

div.stepsInTreeView:not(.no-left-line):not(.labelDevice):after {
  content: "";
  width: 12px;
  height: 1px;
  position: absolute;
  top: 16px;
  left: -12px;
  background: #d9d9d9;
}

.ant-tree-show-line .ant-tree-indent-unit:first-child {
  opacity: 0;
}

.stepsInTreeView.deSelectSteps.no-left-line {
  position: relative;
  z-index: 9;
}

.ant-tree-show-line .ant-tree-switcher {
  opacity: 0;
}

.stepsInTreeView.deSelectSteps > div:first-child {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}
.stepsInTreeView.selectSteps > div:first-child {
  border: 1px solid #444aea;
  border-radius: 4px;
  overflow: hidden;
  background-color: #f7f7ff;
}

.stepsInTreeView.deSelectSteps > div:last-child {
  transform: translateX(-37px);
  position: relative;
  z-index: 999;
  border-radius: 4px;
}

.stepsInTreeView.selectSteps > div:last-child {
  transform: translateX(-37px);
  position: relative;
  z-index: 999;
  border-radius: 4px;
}
.stepsInTreeView > div > div.ant-space.ant-space-horizontal {
  height: 33px;
  transform: translateX(-26px);
}

.stepsInTreeView > div > div:not(.stepsName) {
  width: 100%;
  height: 35px;
  margin: 0;
}

.stepsInTreeView .ant-space .ant-space-item:last-child {
  background-color: #f1f1f1;
  padding: 5px 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 39px !important;
  border-radius: 0 4px 4px 0;
  position: fixed;
}

.bg-gray {
  background-color: #f5f5f5 !important;
}
.border-gray {
  border: 1px solid #e0e0e0;
}

.actionCollapse {
  background-color: #f5f5f5 !important;
}

.scenario .ant-tree-treenode .ant-tree-treenode-switcher-open {
  height: auto;
}

div.conditionStepsInTreeView:not(.no-left-line):not(.labelDevice):before {
  content: "";
  width: 1px;
  height: 60px;
  position: absolute;
  top: -43px;
  left: -52px;
  background: #d9d9d9;
}
div.conditionStepsInTreeView:not(.leaf-line):before {
  content: "";
  width: 1px;
  height: 81px;
  position: absolute;
  top: -64px;
  left: -12px;
  background: #d9d9d9;
}

div.conditionStepsInTreeView:not(.no-left-line):not(.labelDevice):after {
  content: "";
  width: 14px;
  height: 1px;
  position: absolute;
  top: 16px;
  left: -51px;
  background: #d9d9d9;
}
.stepName {
  align-items: center;
  display: flex;
  margin-left: 7px !important;
  color: #3d3d49;
}

.ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  display: none;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: white;
}
.stepTitle {
  white-space: nowrap;
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* preview status */
.border-pass {
  border-right-style: hidden;
  border-color: #18b35d !important;
  border-width: 1px 1px 1px 3px;
}
.stepsInTreeView.border-pass > div:first-child {
  border-color: #18b35d !important;
  border-style: solid;
  border-width: 1px 1px 1px 3px;
  border-radius: 4px;
  overflow: hidden;
}
.stepsInTreeView.border-pass > div:last-child {
  transform: translateX(-37px);
  position: relative;
  z-index: 999;
  border-radius: 4px;
}

.border-abort {
  border-right-style: hidden;
  border-width: 1px 1px 1px 3px;
  border-color: #dbaa00 !important;
}
.stepsInTreeView.border-abort > div:first-child {
  border-width: 1px 1px 1px 3px;
  border-style: solid;
  border-color: #dbaa00 !important;
  border-radius: 4px;
  overflow: hidden;
}

.border-fail {
  border-right-style: hidden;
  border-width: 1px 1px 1px 3px;
  border-color: #db2a39 !important;
}
.stepsInTreeView.border-fail > div:first-child {
  border-width: 1px 1px 1px 3px;
  border-style: solid;
  border-color: #db2a39 !important;
  border-radius: 4px;
  overflow: hidden;
}
.stepsInTreeView.border-fail > div:last-child {
  transform: translateX(-37px);
  position: relative;
  z-index: 999;
  border-radius: 4px;
}
.border-skip {
  border-right-style: hidden;
  border-width: 1px 1px 1px 3px;
  border-color: #ffcb32 !important;
}
.stepsInTreeView.border-skip > div:first-child {
  border-width: 1px 1px 1px 3px;
  border-style: solid;
  border-color: #ffcb32 !important;
  border-radius: 4px;
  overflow: hidden;
}
.stepsInTreeView.border-skip > div:last-child {
  transform: translateX(-37px);
  position: relative;
  z-index: 999;
  border-radius: 4px;
}
.border-inProgress {
  border-right-style: hidden;
  border-width: 1px 1px 1px 3px;
  border-color: #32bfff !important;
}
.stepsInTreeView.border-inProgress > div:first-child {
  border-width: 1px 1px 1px 3px;
  border-style: solid;
  border-color: #32bfff !important;
  border-radius: 4px;
  overflow: hidden;
}
.stepsInTreeView.border-inProgress > div:last-child {
  transform: translateX(-37px);
  position: relative;
  z-index: 999;
  border-radius: 4px;
}
.border-continue-after-fail {
  border-right-style: hidden;
  border-width: 1px 1px 1px 3px;
  border-color: #a13597 !important;
}
.stepsInTreeView.border-continue-after-fail > div:first-child {
  border-width: 1px 1px 1px 3px;
  border-style: solid;
  border-color: #a13597 !important;
  border-radius: 4px;
  overflow: hidden;
}
.stepsInTreeView.border-continue-after-fail > div:last-child {
  transform: translateX(-37px);
  position: relative;
  z-index: 999;
  border-radius: 4px;
}
/* .stepsInTreeView.border-pass,.border-abort,.border-fail,.border-skip,.border-inProgress,.border-continue-after-fail > div:last-child {
  transform: translateX(-40px);
  position: relative;
  z-index: 999;
  border-radius: 4px;
} */

.blueFont {
  font-weight: 500;
  font-size: 13px;
  color: #444aea;
}

.testDataLabel {
  font-weight: 500;
  font-size: 12px;
  color: #3d3d49;
}

.swipe {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 30px !important;
  padding: 6px;
}

.selectElement {
  border: 1px solid #444aea;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
}

.elementDetails {
  display: flex;
  margin-top: 5px;
}

.recTestStepList {
  height: calc(100vh - 250px);
  overflow-y: auto;
}

.conditionInMainTestBlock {
  height: calc(100vh - 113px);
  overflow-y: auto;
}

.deviceList {
  height: calc(100vh - 151px);
  overflow-y: auto;
}

.deviceView {
  height: calc(100vh - 130px);
  /* overflow-y: auto; */
}

.lightGrayText {
  color: #4e4e4e;
}
.z-index-1 {
  z-index: 1;
}
.setOrderName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 57px;
  text-align: left;
  direction: rtl;
  text-align: center;
}

.ant-popover .ant-popconfirm .ant-popover-placement-bottom {
  top: 90px !important;
}

.headerLabel {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
  color: #111548;
}
.selectCheckBox {
  padding: 0px 18px;
  display: flex;
  align-items: center;
}

.scenarioHeaderCloseIcon {
  position: relative;
  width: 55px;
  text-align: center;
  height: 55px;
  padding-top: 16px;
}
.border-right {
  border-right: 1px solid #9e9e9e !important;
}
.border-left {
  border-left: 1px solid #9e9e9e;
}

.confirmationModal {
  margin: 0;
  top: 42%;
  left: 35%;
}

.backIcon {
  position: relative;
  width: 30px;
  text-align: center;
  height: 55px;
  padding-top: 16px;
}

@media only screen and (min-width: 1200px) {
  .bg-deviceList {
    height: calc(100vh - 167px);
  }
  .deviceHeight {
    height: calc(100vh - 56px);
  }
}
@media only screen and (max-width: 1200px) {
  .innerHeader {
    background-color: #ffffff;
    margin-top: 15px;
  }
  .fixInnerHeader {
    margin-top: 15px;
    background-color: #ffffff;
    padding: 0px 0px 0px 20px;
  }

  .scenarioBlackHeader {
    margin-top: 15px;
    background: #ffffff;
    padding: 0px 0px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

