@import "../././../../../styles/variables.scss";
@import "../././../../../styles/mixins.scss";

.device_Details_Container {
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
}

.label {
  flex-wrap: wrap;
  color: #3a3a3a;
  gap: 0.19rem;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__400, $font_size__15);
}

.deviceId_And_Usages {
  color: $textBlack;
  font-size: $font_size__15;
}

.divider {
  margin: 0 0.13rem;
}

.device_OsVer {
  margin-left: 0.13rem;
}

.device_Os_And_Locale {
  color: $darkGrayColor;
  @include update_fonts($font_weight__500, $font_size__16);
}

.device_BrandName {
  padding-left: 0.6rem;
  color: #4a4c69;
  @include update_fonts($font_weight__500, $font_size__16);
}
