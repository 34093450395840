@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.swipeAction {
  margin-top: 0.6rem;
}

//Updated design

.swipe_Element_Details {
  // gap: 4.38rem;
  flex-wrap: wrap;
  display: $display__flex;
}
.swipe_Label {
  color: $textGray;
  @include update_fonts($font_weight__700, $font_size__16);
}

.swipe_Direction_Icons {
  width: 2.5rem;
  height: 2.5rem;
  gap: 0.64rem;
  border-radius: 2.97rem;
  flex-direction: row;
  border: 0.06rem solid $white;
  background-color: $bgGray;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.swipe_RIGHT {
  margin-left: 4.03rem;
  margin-top: -2.5rem;
}

.swipe_DOWN {
  margin-left: 2rem;
  margin-top: -0.6rem !important;
}

.swipe_UP {
  margin-left: 2rem;
  margin-bottom: -0.5rem;
}

.swipe_Icons_Text {
  margin-bottom: 1rem;
  gap: 0.78rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.swipe_Direction_Text {
  padding: 0.4rem 1rem;
  border-radius: 0.44rem;
  background-color: rgba(55, 114, 255, 0.2);
  border: 0.06rem solid $white;
  color: $Dark__Blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__400, $font_size__14);
}

.swipe_Label_And_Icons {
  width: 300px;
  border-right: 0.09rem solid rgba(0, 0, 0, 0.09);
}

.swipe_Border_Bottom {
  border-bottom: 0.09rem solid rgba(0, 0, 0, 0.09);
}

.percentage_Input {
  border: none !important;
  border-radius: 0.375rem !important;
  width: 4.25rem;
  padding: 0.375rem 0.5rem 0.375rem 0.625rem;
  gap: 3.1875rem;
  border: 0.06rem solid $silver;
  display: $display__flex;
  align-items: $align_items__center;
  color: $Dark__Blue !important;
  font-size: $font_size__15 !important;
  @include update_fonts($font_weight__400);
}

.swipe_Length_Input_And_Text {
  padding: 0.25rem;
  border-radius: 0.25rem;
  border: 0.06rem solid #d6d6d6;
  gap: 0.62rem;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  background-color: $white;
  display: $display__flex;
  align-items: $align_items__center;
}

.element_Size_Text {
  color: rgba(0, 0, 0, 0.6);
  @include update_fonts($font_weight__400, $font_size__16);
}

.swipe_Length_Details {
  margin-left: 3.3rem;
}

.disabled_Input {
  background-color: #f5f5f5 !important;
  cursor: $cursor__not_allowed;
}