@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.selectElementRow {
  margin: 0.3rem;
  width: $width__100;
  display: $display__flex;
  align-items: $align_items__center;
}

.selectElementCol {
  padding-right: 0.9rem;
  margin-left: -0.3rem;
  display: $display__flex;
  @include update_fonts($font_weight__500, $font_size__16);
}

.selectElement {
  white-space: nowrap;
  font-size: $font_size__14;
  display: $display__flex;
  align-items: $align_items__center;
}

.selectElementOption {
  width: $width__50;
  margin: 0rem 2.375rem 0rem 1.125rem;
}

.checkboxDiv {
  display: $display__flex;
  align-items: $align_items__center;
}

.checkboxText {
  margin-left: 0.3rem;
  font-size: $font_size__12;
}

.useTestDataRow {
  margin: 0.3rem;
  width: $width__100;
}

.useTestDataCol {
  margin-left: -0.3rem;
  width: $width__100;
  display: $display__flex;
  @include update_fonts($font_weight__500, $font_size__12);
}

.styledSelect {
  margin-left: 1.5rem;
  width: 100%;
  border-radius: 0.3rem;
  border: 0.06rem solid #e9e9e9 !important;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
}

.useTestDataSelect{
  margin-left: 1.1rem !important;
  width: 50% !important;
}

.button {
  margin-left: 0.6rem;
}

.blue {
  color: #000fff;
  margin-left: 0.6rem;
}

.useTestDataInput {
  margin-left: 1.125rem;
  border: 0.06rem solid $disable;
  width: $width__50 !important;
}

.label {
  width: 9.375rem;
  padding: 0.438rem;
  background: $bgDarkGray;
}

.elementType {
  margin-top: 0.3rem;
}

.elementTypeInput {
  margin: 0.3rem 0rem 0rem 1.125rem;
  border: 0.06rem solid $disable;
  width: $width__50 !important;
}

@media only screen and (max-width: 62.5rem) {
  .selectElement {
    min-width: $width__100;
  }
}
