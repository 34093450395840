@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.menu_Icon {
  padding-top: 0.3rem;
}

.menu_list {
  width: 16.69rem;
  gap: 0.6rem;
  padding: 0.75rem 1.19rem;
  border-radius: 0.6rem;
  background-color: #dbe6ff;
  display: $display__flex;
  align-items: $align_items__center;
}

.menu_list_Active {
  background-color: $Dark__Blue !important;
}

.menu_Items {
  gap: 0.6rem;
  flex-direction: column;
  display: $display__flex;
}

.menu_Item_Label {
  margin-top: 0.13rem;
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__16);
}

.menu_Item_Active_Label {
  color: $white !important;
}

.recent-list {
  width: 16.69rem;
  margin-bottom: 0.6rem;
  padding: 0.6rem 0.94rem 0.94rem 0.94rem;
  padding: 0.75rem 1.19rem;
  border-radius: 0.6rem;
  background-color: #dbe6ff;
  flex-direction: column;
  color: $textBlack;
  display: $display__flex;
  @include update_fonts($font_weight__500, $font_size__14);
}

.recent_Label_Icon {
  gap: 3px;
  display: $display__flex;
  align-items: $align_items__center;
}

.menu_Footer {
  width: 100%;
  padding: 0.6rem 2.3rem 0.56rem 2.38rem;
  background-color: #ebebeb !important;
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__15);
}

@media (max-width: 61.94rem) {
  .xs-my-30 {
    margin:1.875rem 0rem;
  }
}

@media (max-width: 1246px) and (min-width: 991px) {
  .custom-col {
    flex: 0 0 41.66667%; 
    max-width: 41.66667%;
  }
  .custom-col-menu,
  .custom-col-recent {
    flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
}