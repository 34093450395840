@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.importModalDetails {
  margin-top: 0.6rem;
  width: 100%;
}

.collapse {
  border: 0.06rem solid #e0e0e0 !important;
  border-radius: 0.9rem !important;
  background-color: $white !important;
}

.collapseOfChildSteps {
  padding: 0rem !important;
}

.recTestStepName {
  margin-left: 0.6rem;
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__15);
}

.recTestActionName {
  color: $grayColor;
  @include update_fonts($font_weight__400, $font_size__15);
}

.recTestStepActionsDetails {
  padding: 0.69rem 0 0.69rem 0;
  margin-left: 1.88rem;
  gap: 0.44rem;
  border-bottom: 0.06rem solid #ededed;
  display: $display__flex;
  align-items: $align_items__center;
}

.actionIconOrName {
  gap: 0.44rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.stepName {
  display: $display__flex;
  align-items: $align_items__center;
}

.padding {
  padding: 0.75rem;
  border-left: 0.06rem solid $lightPurpleBorder;
  margin-left: -0.4rem;
}

.selectedStep {
  border-radius: 0 0.69rem 0.69rem 0;
  border: 0.06rem solid $lightPurpleBorder;
  background: $lightPurple;
}

.expandIcon {
  margin-top: 0.5rem;
  padding: 0.75rem;
  margin-left: 0.75rem;
}

.parentStepName {
  margin-left: 0.6rem;
}

.activeCollapse {
  border-radius: 0 0.69rem 0 0;
}