@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.actionTitle {
  color: $textGray;
  @include update_fonts($font_weight__400, $font_size__17);
}

.actionsSelection {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.contentInCenter {
  gap: 0.87rem;
  flex-wrap: wrap;
  align-items: flex-start;
  display: $display__flex;
}

.action_Details {
  margin-top: 1rem;
}

.selectTapOrEnterTextAction {
  width: 20rem !important;
}

.showActionDetails {
  gap: 0.88rem;
  flex-wrap: wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.selectActionsOrText {
  gap: 0.19rem;
  flex-wrap: $flex__wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.selectBoxBgColor {
  background-color: #f5f5f5;
}

.selectActions {
  @include common_Select_Box(17.5rem);
}

.operation_Text {
  text-transform: uppercase;
  color: #4a4d53;
  margin-right: 0.44rem;
  @include update_fonts($font_weight__700, $font-size__17);
}

.selectBoxAndLabel {
  gap: 0.38rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.verification_Text_Select_Box {
  gap: 0.19rem;
  margin-bottom: 1rem;
  flex-wrap: $flex__wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.label {
  text-transform: uppercase;
  color: #4a4d53;
  margin-right: 0.44rem;
  @include update_fonts($font_weight__700, $font-size__17);
}

.select_Box_And_Label {
  gap: 0.38rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.verify_Text_Label {
  color: $textGray;
  @include update_fonts($font_weight__400, $font_size__17);
}