@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.main-header {
  top: 3.38rem;
  padding: 0rem 0rem 0rem 1.3rem;
  background-color: #f2f4f7;
  height: 4.13rem;
  border-bottom: 0.06rem solid #ccc;
}

.main-Header-Text {
  color: #002858;
  @include update_fonts($font_weight__700, $font_size__20);
}

.draggable-row {
  display: block;
  overflow: auto;
  width: 100%;
  border-radius: 0.3rem;
  height: calc(100vh - 14.38rem);
  padding: 1.25rem;
  padding-top: 0px;
  margin-top: 0.6rem;
}

.drag-drop-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3.13rem;
  height: calc(100vh - 8.63rem);
  overflow-y: scroll;
  padding: 0 1.3rem;
}

.test-plan-row {
  margin-top: 0.75rem;
}

.empty_Row {
  width: 100%;
}

.input-box {
  border-radius: 0.3rem;
  width: 100%;
}

.border-red {
  border-color: red !important;
}

.test-data-row {
  @include update_fonts($font_weight__700, $font_size__13);
}

.test-data-col {
  border: black 0.5px;
  font-size: $font_size__13;
}

.test-data-panel {
  margin-right: 0.75rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.close_Icon {
  position: relative;
  width: 3.44rem;
  height: 40.8px;
  border-left: 0.06rem solid black;
  display: $display__flex;
  text-align: $text_align__center;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.scenario_Name {
  max-width: 25.17rem;
  margin-top: -0.06rem;
  margin-bottom: 0 !important;
  color: $textColor;
  @include update_fonts($font_weight__400, $font_size__16);
}

.data_Not_Found_Text {
  display: $display__flex;
  justify-content: $justify_content__center;
  color: $textBlack;
  @include update_fonts($font_weight__400, $font_size__15);
}

.all_Scenarios_Header {
  padding: 0.81rem;
  border-radius: 0.6rem 0.6rem 0 0;
  width: 100%;
  display: $display__flex;
  align-items: $align_items__center;
  background-color: $Dark__Blue;
}

.all_Scenario_Text {
  color: $white;
  @include update_fonts($font_weight__700, $font_size__17);
}

.test_Plan_Header {
  padding: 0.81rem;
  border-radius: 0.6rem 0.6rem 0 0;
  width: 100%;
  background-color: #f3f6ff;
  display: $display__flex;
  align-items: $align_items__center;
  border-bottom: 0.06rem solid #d6d6d6;
}

.test_Plan-Title {
  color: #002858;
  @include update_fonts($font_weight__700, $font_size__17);
}

.search_Test_Scenario {
  width: 100% !important;
}
.empty_Data_Text {
  margin-top: -1rem;
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__17);
}

.empty_Data_Container {
  flex-direction: column;
  height: calc(100vh - 14.38rem);
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.spinner_Center {
  min-height: 85vh;
}
@media only screen and (max-width: 1200px) {
  .main-header {
    margin-top: 0.9rem;
    padding: 0rem 0rem 0rem 1.3rem;
    background-color: $white;
  }
}
