@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.collapseIcon {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: $bgGray;
  display: $display__flex !important;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.conditionText {
  color: #545569;
  @include update_fonts($font_weight__400, $font-size__16);
}

.previewOrDeleteIcon {
  gap: 0.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.deleteIcon {
  margin-right: 0.4rem;
  cursor: $cursor__pointer;
}

.marginTop {
  margin-top: 0.5rem;
}
