@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.hideDrawer {
  display: $display__none;
}

.drawerTitle {
  height: 3.38rem;
  background: rgba(214, 214, 214, 0.19);
  color: $textBlack;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__700, $font_size__18);
}

.title {
  color: $textBlack;
  @include update_fonts($font_weight__600, $font_size__18);
}

.label {
  margin-left: 1.3rem;
}

.attributesDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include update_fonts($font_weight__500, $font_size__18);
}

.attributesDetails > div {
  color: $blue;
}

.attributesDetails > div > span {
  margin-left: 0.4rem;
  word-break: break-all;
  color: $darkGrayColor;
}

.deviceAttributes {
  padding: 1.5rem 0.2rem 0rem 1.5rem;
}
