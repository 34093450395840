@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.headerTitle {
    font-family: "Inter";
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #111548;
  }

.headerTitle {
  margin-left: 0.6rem;
  color: $textBlack;
  text-align: center;
  @include update_fonts($font_weight__600, $font_size__16);
}

.headerOptions {
    max-width: 43.75rem;
    overflow: auto;
    padding: 0.625rem 0;
    gap: 0.625rem;
    margin-right: 0.625rem;
    display: $display__flex;
    align-items: $align_items__center;
  }