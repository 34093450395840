@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.deviceLoadMessage {
  color: orange;
  font-size: 0.875rem;
  margin-top: 0.875rem;
  white-space: pre-line;
}

.labelDevice {
  font-size: 0.875rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.6rem;
  display: $display__flex;
  color: $lightGrayTextColor;
}

.selectElementValue {
  flex-wrap: $flex__wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.disableApplicationString {
  color: $disableText;
  cursor: $cursor__not_allowed !important;
}

.moreProperties {
  padding: 0.4rem 0.5rem;
  gap: 0.4375rem;
  border-radius: 0.43063rem;
  box-shadow: 0rem 0.06rem 0.375rem 0rem rgba(0, 0, 0, 0.09);
  color: $darkBlue;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  background: $white;
  @include update_fonts($font-weight__500, $font-size__14);
}

.moreLength {
  color: $blue;
}

.replaceText {
  color: $white;
}

.inspectorButton {
  width: 5.8125rem;
  height: 2rem;
  padding: 0.625rem 0.8125rem;
  gap: 0.4375rem;
  border-radius: 0.375rem;
  background: $darkBlue;
  color: $bgColorLightBlue;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__500, $font-size__14);
}

.disableText {
  color: $disableText !important;
}

.elementDivider {
  font-size: 0.625rem !important;
}

.ellipse {
  // width: 230px;
  width: 183px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  word-break: break-word;
  cursor: $cursor__pointer;
}

.identifierString {
  word-break: break-word;
  margin-left: 0.3rem;
  font-size: $font-size__14;
}

.loadDeviceText {
  @include update_fonts($font-weight__500, $font-size__16);
}

.divider {
  font-size: 30px;
  display: flex;
  align-items: center;
  border-top: 0.1rem solid #e8e8e8;
  margin-top: 22px;
}

.dividerText {
  padding: 0rem 1rem;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
  margin-top: -0.813rem;
  border-radius: 6.25rem;
  border: 0.06rem solid #dedede;
  background: #f3f3f3;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__500, $font-size__16);
}

.buttonText {
  color: $darkBlue;
  @include update_fonts($font-weight__600, $font-size__14);
}

.iconOrText {
  gap: 0.44rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.recTestStepSelectorDetail {
  padding-right: 0.9rem;
}

.disableButton {
  color: $white;
  background: $silver;
}

.load-device-icon-and-text {
  padding: 0.3rem;
  gap: 0.44rem;
  border-radius: 0.44rem;
  margin-top: 9px;
  background: rgba(214, 149, 23, 0.13);
  display: inline-flex;
  align-items: flex-start;
  justify-content: $justify_content__center;
}

.load-device-text {
  color: #d69517;
  @include update_fonts($font_weight__500, $font_size__16);
}

.load_Device_Icon {
  margin-top: 0.38rem;
}
//Properties_Details
.properties_Settings {
  border-radius: 0.9rem;
  background: #f8faff;
  margin-top: 1.1rem;
  border: 0.06rem solid $borderColor;
}

.properties_Title {
  padding: 0.8rem 0.8rem 0.6rem 0.8rem;
  border-bottom: 0.09rem solid $borderColor;
  color: $textBlack;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__500, $font_size__18);
}

.select_Element_details {
  padding: 0.8rem 0.8rem 0.69rem 0.8rem;
}

.rec_Element_Display {
  flex-direction: column;
  align-items: flex-start;
  display: $display__flex;
}
.element_Details_Label {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__16);
}

.button_Text {
  margin-left: 0.3rem;
  color: $Dark__Blue;
  @include update_fonts($font-weight__500, $font-size__14);
}

.icons_And_Text {
  gap: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.more_Properties_Button {
  margin-top: 0.6rem;
  gap: 0.5rem;
  flex-wrap: wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.more_And_Replace_Element_Btn {
  padding: 0.4rem 0.5rem;
  border-radius: 0.44rem;
  background-color: rgba(55, 114, 255, 0.2);
  border: 0.06rem solid $white;
  color: $Dark__Blue;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__500, $font-size__14);
}

.replace_Element,
.replace_Element:hover {
  border: 0.06rem solid $white;
  background-color: #c5ecdd;
}

.disabled_Replace_Element,
.disabled_Replace_Element:hover {
  background-color: #e6e6e6 !important;
  cursor: $cursor__not_allowed !important;
}

.replace_Element_Text {
  margin-left: -4px;
  color: #00ab55 !important;
  @include update_fonts($font-weight__500, $font-size__14);
}

.disabled_Replace_Element_Text {
  color: #6e7282 !important;
}

.inspector_Text {
  color: #ee0b0b;
  @include update_fonts($font-weight__500, $font-size__16);
}

.disable_Inspector_Text {
  color: #888;
  @include update_fonts($font-weight__500, $font-size__16);
}

.disable_Inspector_Button {
  background-color: $Gray !important;
  color: $disableText !important;
  cursor: $cursor__not_allowed !important;
}

.inspector_Button {
  width: 5.75rem;
  height: 2.38rem;
  flex-direction: row;
  gap: 0.64rem;
  margin-top: 0.6rem;
  padding: 0.26rem 0.6rem;
  border-radius: 0.44rem;
  border: 0.06rem solid $white;
  background-color: #d1dfff;
  color: $Dark__Blue;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__500, $font-size__14);
}

.inspector_Button_And_Text {
  gap: 0.4rem;
  margin-top: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.element_Label {
  gap: 0.3rem;
  display: $display__flex;
}

.identifier {
  color: $darkGrayColor;
  @include update_fonts($font-weight__500, $font-size__16);
}

.element_Value {
  margin-left: 1.375rem;
}

.tooltip_Label {
  @include update_fonts($font-weight__600, $font-size__14);
}

.applicationString {
  margin-left: 0.6rem;
  color: $blue;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__400, $font-size__14);
}
