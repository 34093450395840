@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.pagination {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.disabled-text {
  color: #9e9e9e;
  cursor: $cursor__not_allowed;
  @include update_fonts($font-weight__400, $font_size__14);
}

.text {
  @include update_fonts($font-weight__400, $font_size__14);
}