@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.selectVariable {
  gap: 0.3rem;
  flex: 1;
  display: $display__flex;
  align-items: $align_items__center;
}

.footer {
  gap: 0.3rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.postActionSettings {
  flex-wrap: wrap;
  gap: 1.25rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.time {
  margin-left: 0.13rem;
  font-size: $font_size__12;
}

.delayExecutionTitle {
  margin-left: 1.5rem;
  font-size: $font_size__12;
}

.invertResult {
  margin-top: 0.6rem;
}

.rec_Test_Step_Post_Actions {
  margin-top: 1rem;
}

.post_Action_Title {
  text-transform: uppercase;
  color: $textGray;
  @include update_fonts($font_weight__700, $font_size__17);
}

//New Design
.save_Element_Text {
  margin-top: 0.6rem;
  color: $textGray;
  @include update_fonts($font_weight__500, $font_size__16);
}
.element_Test_Data {
  flex-wrap: wrap;
  gap: 0.3rem;
  margin-top: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.styled_Select_Test_Data {
  border-radius: 0.3rem;
  padding: 0.06rem;
  border: 0.06rem solid #d6d6d6 !important;
  width: 40%;
  .ant-select-selector {
    border: 0rem solid $silver !important;
    width: 100% !important;
    border-radius: 0.5rem !important;
  }
}

.disable_Button {
  background-color: $Gray !important;
  color: $disableText !important;
  cursor: $cursor__not_allowed !important;
  border: 0.06rem solid $Gray !important;
}

.remove_Select_Variable_Btn {
  gap: 0.3rem;
  width: 94px;
  height: 40px;
  padding: 4.1px 10px;
  cursor: $cursor__pointer;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
  border-radius: 7px;
  border: solid 1px #fff;
  background-color: rgba(255, 18, 18, 0.1);
  color: #ee0b0b;
  @include update_fonts($font_weight__500, $font_size__14);
}

.selectTestData {
  height: 40px;
  padding: 4.1px 10px;
  gap: 0.3rem;
  border: 0.06rem solid $blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  border-radius: 7px;
  border: solid 1px #fff;
  background-color: #d7e3ff;
  color: $Dark__Blue;
  cursor: $cursor__pointer;
  @include update_fonts($font_weight__500, $font_size__14);
}

.label {
  margin: 0 0 0 6px;
  color: $grayColor;
  @include update_fonts($font_weight__500, $font_size__16);
}
.input_And_Label {
  width: 7.9375rem;
  display: $display__flex;
  align-items: $align_items__center;
  cursor: $cursor__pointer;
  height: 40px;
  flex-grow: 0;
  margin: 0 0 0 12px;
  padding: 6px;
  border-radius: 4px;
  border: solid 1px #d6d6d6;
  background: #fff;
}

.disable_Input_And_Label {
  background: #f5f5f5 !important;
  cursor: $cursor__not_allowed !important;
}

.input_Box {
  border: none !important;
  margin-left: 0.3rem;
  width: 5rem;
  padding: 0;
  color: $Dark__Blue !important;
}

.disabled_Input_Box {
  border: none !important;
  margin-left: 0.3rem;
  width: 5rem;
  padding: 0;
  color: rgba(0, 0, 0, 0.25) !important;
}

.input_Label {
  margin: 0 0.3rem 0 0.3rem;
  color: rgba(0, 0, 0, 0.6);
  @include update_fonts($font_weight__400, $font_size__16);
}

.actions {
  margin-top: 0.8rem;
  white-space: nowrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.select_Test_Data_Btn {
  height: 2.56rem;
}

.collapse_Icon {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.6rem;
  border-radius: 50%;
  cursor: $cursor__pointer;
  background-color: $bgGray;
  display: $display__flex !important;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}
