@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.border_Bottom {
  border-bottom: 0.06rem solid #e9e9e9;
}

.device_More_Details {
  padding: 0.625rem;
  gap: 1rem;
  flex-direction: column;
  display: $display__flex;
}

.shared_With_Details_Label {
  color: $Dark__Blue;
  @include update_fonts($font_weight__700, $font_size__18);
}

.device_Shared_Details {
  border-radius: 0.6rem;
  background-color: #e9efff;
  padding: 0.6rem;
}

.spinner_Height {
  min-height: 10.63rem;
}

.spinner_Center {
  min-height: 8.63rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.shared_With_Details {
  gap: 0.3rem;
  color: $textBlack;
  display: $display__flex;
  @include update_fonts($font_weight__400, $font_size__16);
}

.scrollBar {
  margin-left: 0.6rem;
  height: 7.31rem;
  overflow-y: scroll;
}

.sharedWithDetailsLabel {
  color: $blue;
  @include update_fonts($font_weight__700, $font_size__18);
}

.usage_Details {
  text-transform: capitalize;
  color: $textBlack;
  @include update_fonts($font_weight__400, $font_size__16);
}

.usage_Details > span {
  font-weight: $font_weight__500 !important;
}

.modal_content_wrapper {
  padding: 1.1rem 0.31rem;
}

.header_Buttons_Gap {
  gap: 0.3rem !important;
}
