@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.create-project-title {
  margin-top: 0.4rem;
  color: $Dark_Black;
  @include update_fonts($font-weight__500, $font_size__24);
}

.details-title {
  color: $Dark_Black;
  @include update_fonts($font-weight__600, $font_size__20);
}

.custom-input-wrapper {
  position: relative;
}

.custom-input-label {
  position: absolute;
  left: 0.9rem;
  padding: 0 0.5em;
  z-index: 1;
  color: #84818a;
  background-color: $white;
  @include update_fonts($font-weight__400, $font_size__14);
}

.modal-input {
  padding: 1.38rem 0px 1.3rem 1.57rem !important;
  border-radius: 0.8rem !important;
  border: 0.06rem solid #dcdbdd !important;
  background-color: $white !important;
  margin: 0.6rem 0 0.9rem;
  color: #353535;
  font-size: $font_size__14 !important;
  @include update_fonts($font-weight__400);
  .ant-picker-input > input::placeholder {
    color: red;
  }
}

.modal-textArea {
  padding: 1rem 0px 0px 1.44rem !important;
  border-radius: 0.8rem;
  border: 0.06rem solid #dcdbdd;
  background-color: $white;
  margin: 0.6rem 0 0.9rem;
  color: $Dark__Blue;
}

.modal-select-box {
  margin: 0.6rem 0 0.9rem;
  padding: 0.5rem 0px 0.3rem 0.4rem;
  border-radius: 0.8rem;
  border: 0.06rem solid #dcdbdd;
  width: 100%;
  color: #353535;
  background-color: $white;
  font-size: $font_size__14 !important;
  @include update_fonts($font-weight__400);
}

.scroll-content {
  max-height: 35.9rem;
  overflow: auto;
}

.scroll-content::-webkit-scrollbar {
  display: $display__none;
}

.field-required {
  color: red;
  font-size: 0.9rem;
  margin-left: 0.19rem;
}
