@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.test-Data-Set-Title {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.85);
  @include update_fonts($font-weight__600, $font_size__15);
}

.test-data-set-details {
  color: #4a4c69;
}

.test-data-set-List {
  align-items: baseline;
  display: $display__flex;
}

.test-data-set-actions {
  align-self: center;
  justify-content: flex-end;
  cursor: $cursor__pointer;
  display: $display__flex;
}

.test_Data_Details {
  width: 100%;
  margin-top: 1.25rem;
}

.page-Scroll {
  height: calc(100vh - 8.63rem);
  overflow-y: scroll;
}

.page-Scroll-Of-TestData {
  height: calc(100vh - 13.69rem);
  overflow-y: scroll;
}

.test-data-set-details-list {
  padding-right: 1.3rem;
  margin-bottom: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.float-right {
  float: right;
}

.new-profile-btn {
  color: $white;
  margin-left: 0.6rem;
  background: $blueTextColor;
}

.new-profile-btn:hover {
  color: $white;
  margin-left: 0.6rem;
  background: $blueTextColor;
}

.new-profile-btn[disabled] {
  background: #e6e6e6;
  color: #6e7282;
  margin-left: 0.6rem;
}

.select-box {
  width: 20%;
}

.default-switch-data {
  margin: 0.3rem;
  cursor: $cursor__pointer;
}

.test-data-name {
  margin-top: 0.6rem;
}

.margin-5 {
  margin: 0.3rem;
}

.data_Set_Title {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.85);
  @include update_fonts($font-weight__600, $font_size__14);
}

.label {
  color: #6d6d6d;
  @include update_fonts($font-weight__400, $font_size__14);
}

.label-name {
  @include update_fonts($font-weight__400, $font_size__14);
}
