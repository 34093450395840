@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.verification_Text_Select_Box {
  gap: 0.19rem;
  margin-bottom: 1rem;
  flex-wrap: $flex__wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.verifyTextOrSelectBox {
  flex-wrap: $flex__wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.verify_Text_Label {
  color: $textGray;
  @include update_fonts($font_weight__400, $font_size__17);
}

.select_Verify_ELement {
  background-color: $white;
  @include common_Select_Box(14.38rem);
}

.condition_Text {
  text-transform: uppercase;
  color: #4a4d53;
  margin-right: 0.44rem;
  @include update_fonts($font_weight__700, $font-size__17);
}

.select_Box_And_Label {
  gap: 0.38rem;
  display: $display__flex;
  align-items: $align_items__center;
}
