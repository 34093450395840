@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.recElementDetails {
  width: 100%;
  border-radius: 0.75rem;
  box-shadow: 0.25rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.05);
  background-color: $white;
  display: $display__flex;
}

.recElementsList {
  width: 25%;
  border: 0.06rem solid $lightPurpleBorder;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}

.recElementContent {
  width: 75%;
}

.select-use-data-or-string {
  margin-top: 0.5rem;
  gap: 1.88rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.checkbox {
  padding: 0.3rem 0;
  gap: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.checkbox-Text {
  white-space: nowrap;
  color: #344054;
  @include update_fonts($font_weight__500, $font_size__14);
}

.label {
  margin-top: 0.5rem;
  color: $textGray;
  @include update_fonts($font_weight__500, $font_size__16);
}

.text-And-Button {
  width: 100%;
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.application-String-key {
  font-size: $font_size__14 !important;
  font-weight: $font_weight__400 !important;
}

.input-box {
  border: 0.06rem solid #b0b1c1;
  &:hover {
    border: 0.06rem solid #b0b1c1 !important;
  }
}

.selectMode {
  border-radius: 0.3rem;
  border: 0.06rem solid #d6d6d6;
  width: 100%;
  background: $white;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
  }
}

.required_Field {
  color: $text_Red;
}
