@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.verification-rules-details {
  border-radius: 0.9rem;
  background: #f8faff;
  height: 100%;
  border: 0.06rem solid $borderColor;
}

.rules-Title-and-edit-button {
  padding: 0.6rem 0.9rem;
  flex-wrap: wrap;
  border-bottom: 0.09rem solid $borderColor;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.identification-Rules-Title {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__18);
}

.details-Of-Verification-Rules {
  padding: 0.6rem 0.9rem;
  height: 26.5rem;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: $display__none;
  }
}

.identification-Rules {
  border-radius: 0.4rem;
  background: $white;
  box-shadow: 0rem 0.06rem 0.3rem 0rem rgba(0, 0, 0, 0.15);
  padding: 0.6rem 1.19rem;
}
.container-Of-Operators {
  position: relative;
  padding: 0.5rem 0rem;
}

.border-blue {
  position: absolute;
  bottom: 1.6rem;
  left: 0;
  width: 100%;
  border-bottom: 0.09rem solid $Dark__Blue;
}

.and-Or-Operators {
  width: 2.63rem;
  height: 2.19rem;
  position: relative;
  border-radius: 0.4rem;
  box-shadow: 0rem 0.06rem 0.3rem 0rem rgba(0, 0, 0, 0.15);
  z-index: 1;
  text-transform: uppercase;
  border: 0.06rem solid $Dark__Blue;
  background: $white;
  color: $Dark__Blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__500, $font_size__14);
}

.add-Rules {
  padding: 2.6rem;
  gap: 0.3rem;
  flex-direction: column;
  display: $display__flex;
  align-items: $align_items__center;
  text-align: $text_align__center;
  justify-content: $justify_content__center;
}

.addOrEditRulesButton {
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  gap: 0.4375rem;
  cursor: $cursor__pointer;
  background: $blue;
  color: $bgColorLightBlue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__500, $font_size__14);
}

.editRulesButton {
  gap: 0.4375rem;
  height: 2rem;
  padding: 0rem 0.6875rem;
  border-radius: 0.3125rem;
  background: $blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  cursor: $cursor__pointer;
}

.verification-Type {
  color: $textBlack;
  @include update_fonts($font_weight__400, $font_size__16);
}

.rules-Title {
  color: $textBlack;
  @include update_fonts($font_weight__600, $font_size__19);
}

.add-Rules-Text {
  color: #5b5b5b;
  text-align: $text_align__center;
  @include update_fonts($font_weight__400, $font_size__16);
}

.verification-Key-Or-Value {
  color: $textBlack;
  @include update_fonts($font_weight__600, $font_size__16);
}

.disableText {
  color: #6e7282;
}

.verification-Details {
  gap: 0.3rem;
  flex-wrap: wrap;
  display: $display__flex;
}

.add_And_Edit_Text{
  span{
    font-size: $font-size__14 !important;
    font-weight: $font-weight__500 !important;
  }
}