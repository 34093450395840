@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.innerHeader {
  background-color: $white;
}

.headerOrButton {
  margin-right: 1.25rem;
}

.buildScenarioButton {
  background-color: #444aea;
  margin-left: 0.625rem;
}

.pageScroll {
  height: calc(100vh - 8.625rem);
  overflow-y: scroll;
}

.mainContainer {
  display: $display__flex;
  justify-content: $justify_content__space_between;
  align-items: $align_items__center;
}

.mainHeaderText {
  padding: 0.75rem;
  margin-left: 0.625rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #111548;
}

.mainPermissionFilter {
  display: $display__flex;
  justify-content: $justify_content__space_between;
  align-items: $align_items__center;
  margin: 0.9375rem;
}

.permissionFilter {
  display: $display__flex;
}

.commonSearch {
  display: $display__flex;
}

.selectAllBox {
  margin-left: 0.625rem;
  margin-bottom: 0.625rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.paginationSection {
  float: right;
  margin-right: 0.625rem;
}

.selectAllBoxDelete {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.isScenarioLoadModelOpen {
  margin-right: 0.625rem;
  float: right;
}

.isScenarioLoadModelClose {
  margin-right: 0.625rem;
}
