@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";


.drawerTitle {
  color: $textBlack;
  @include update_fonts($font_weight__700, $font_size__20);
}

.drawerTitleOrCloseIcon {
  padding: 1rem 1.5rem 0rem 1.5rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.searchTitle {
  color: #696a6c;
  @include update_fonts($font_weight__500, $font_size__18);
}

.border {
  width: 34.0625rem;
  height: 0.3rem;
  background: #e8e8e8;
  margin-top: 0.6rem;
}

.searchBoxOrBorder {
  padding: 1.5rem 1.5rem 0rem 1.5rem;
}

.selectorData {
  height: calc(100vh - 171px);
  overflow-y: auto;
  border-radius: 4px;
  padding: 1.5rem 1.5rem 0rem 0.7rem;
}

.borderShadow {
  box-shadow: 0 -0.375rem 0.4375rem 0 rgba(0, 0, 0, 0.14);
}