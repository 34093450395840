@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.job_Criteria_Wrap {
  padding: 14px;
  border-radius: 0.94rem;
  flex-direction: column;
  gap: 0.94rem;
  background-color: $white;
  display: $display__flex;
}

.job_Name_And_Input {
  gap: 0.6rem;
  margin: 0.6rem 0rem 0.6rem 0.6rem;
  white-space: nowrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.border_Color_Red {
  border-color: red !important;
}

.job_Input_Box {
  width: 100%;
}

.job_Name_Title {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__16);
}

.red_Text {
  color: #ff0000;
}

.criteria_Item {
  padding: 0.6rem;
  color: $black;
  height: auto;
  border-radius: 0.6rem;
  border: 0.06rem solid #d1d1d1;
  background-color: $white;
  cursor: $cursor__pointer;
}

.selected_Job_Criteria {
  border: 0.06rem solid #97b5ff !important;
  background-color: #f3f6ff !important;
}

.steps_Wrap {
  display: flex;
  align-items: flex-start;
}

.criteria_Details {
  flex-direction: column;
  color: $textBlack;
  display: $display__flex;
  @include update_fonts($font_weight__500, $font_size__14);

}

.validation_Icon {
  margin-top: -2px;
}

.job_Criteria_Col {
  display: $display__flex;
  flex-wrap: wrap;
}

.test_Data {
  margin-left: 0.3em;
}

.build_Url_Label {
  white-space: nowrap;
}

.build_Url_Value {
  max-width: 6.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.25rem;
}