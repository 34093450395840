@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.title {
  color: #232323;
  @include update_fonts($font_weight__700, $font_size__16);
}

.styledStepHeader {
  padding: 0 0.625rem;
  display: $display__flex;
}
.styledStepName {
  overflow: hidden;
  border-radius: 0.875rem !important;
  box-shadow: 0.25rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.05);
  width: calc(100% - 4.0625rem);
  min-height: 2.75rem;
  margin-left: auto;
}

.recTestStep {
  display: $display__flex;
  align-items: $align_items__center;
  width: $width__100;
}
.recTestStepTextInput {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1.25rem;
  width: $width__100;
  display: $display__flex;
  align-items: $align_items__center;
}

.borderRight {
  width: 1rem;
  height: 2.2rem;
  border-right: 0.0625rem solid rgba(219, 219, 219, 0.51);
}

.inputStyle {
  border: none !important;
  width: 95% !important;
  background: transparent !important;
  font-size: $font_size__16 !important;
  @include update_fonts($font_weight__400, $font-size__19);
}

.childStyledStepOrder {
  min-width: 3.88044rem;
  height: 3.5rem;
  @include recTestStepOrderName;
}

.childDisableStepOrder {
  min-width: 3.88044rem;
  height: 3.5rem;
  background: #eee !important;
  cursor: $cursor__not_allowed !important;
  @include recTestStepOrderName;
}

.disabledTextOrSwitch > span {
  color: $textGray;
  @include update_fonts($font_weight__500);
}

.childDisableStepOrder > span {
  color: $lightGrayTextColor !important;
}

.childCollapseIcon {
  margin-top: 0.875rem;
  cursor: $cursor__pointer;
}

.disabledTextOrSwitch {
  gap: 1rem;
  margin-right: 10px;
  display: $display__flex;
  align-items: $align_items__center;
}

.dragAndDrop {
  cursor: grab;
}

.switchOrPreviewResult {
  gap: 0;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__end;
}

.displayIcon {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.18rem;
  width: 2.2rem;
  margin-left: 0.63rem;
}

.switch {
  display: $display__flex;
  align-items: $align_items__center;
}

.styledTapByIndexValue {
  bottom: 0.3rem;
  font-size: 0.8rem;
  position: relative;
}

.stylesCaptureScreenshot {
  width: 0.7rem;
  color: #338fcc;
  display: $display__flex;
}

.actionIcon {
  margin-left: 0.6rem;
  gap: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.failureIcon {
  padding-left: 0.8rem;
  margin-right: -0.25rem;
}

.collapseIcon {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: $bgGray;
  display: $display__flex !important;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.deleteActionIcon {
  float: right;
  margin-right: 0.6rem;
  cursor: $cursor__pointer;
}

.performActions {
  display: $display__flex;
  align-items: $align_items__center;
}

.previewIcon {
  margin-left: 0.6rem;
}
