@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.deviceLoadMessage {
  color: orange;
  font-size: 0.875rem;
  margin-top: 0.875rem;
  white-space: pre-line;
}

.selectElementDetail {
  border-radius: 0.4375rem;
  background: rgba(214, 214, 214, 0.19);
  margin-top: 0.625rem;
  align-items: flex-start;
  gap: 0.375rem;
  padding: 1rem;
  flex-direction: column;
  display: $display__flex;
}

.labelDevice {
  font-size: 0.875rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.6rem;
  display: $display__flex;
  color: $lightGrayTextColor;
}

.recElementDisplay {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.6rem;
  display: $display__flex;
}

.selectElementValue {
  flex-wrap: $flex__wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.applicationString {
  margin-left: 0.6rem;
  color: $blue;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__400, $font-size__14);
}

.disableApplicationString {
  color: $disableText;
  cursor: $cursor__not_allowed !important;
}

.moreProperties {
  padding: 0.4rem 0.5rem;
  gap: 0.4375rem;
  border-radius: 0.43063rem;
  box-shadow: 0rem 0.06rem 0.375rem 0rem rgba(0, 0, 0, 0.09);
  color: $darkBlue;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  background: $white;
  @include update_fonts($font-weight__500, $font-size__14);
}

.replaceElement {
  background: $blue;
}

.moreLength {
  color: $blue;
}

.replaceText {
  color: $white;
}

.inspectorButton {
  width: 5.8125rem;
  height: 2rem;
  padding: 0.625rem 0.8125rem;
  gap: 0.4375rem;
  border-radius: 0.375rem;
  background: $darkBlue;
  color: $bgColorLightBlue;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__500, $font-size__14);
}

.disableButton {
  background-color: $Gray;
  color: $disableText;
  cursor: $cursor__not_allowed !important;
}

.disableText {
  color: $disableText !important;
}

.elementDivider {
  font-size: 0.625rem !important;
}

.identifier {
  color: $darkGrayColor;
  @include update_fonts($font-weight__500, $font-size__16);
}

.ellipse {
  width: 230px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  word-break: break-word;
  cursor: $cursor__pointer;
}

.identifierString {
  word-break: break-word;
  margin-left: 0.3rem;
  font-size: $font-size__14;
}

.inspectorButtonOrText {
  gap: 0.6rem;
  margin-top: 0.94rem;
  padding-right: 0.9rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.text {
  color: $orange;
  font-size: 0.875rem;
}

.morePropertiesButton {
  margin-top: 0.6rem;
  gap: 0.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.loadDeviceText {
  @include update_fonts($font-weight__500, $font-size__16);
}

.elementLabel {
  gap: 0.3rem;
  display: $display__flex;
}

.elementValue {
  margin-left: 1.375rem;
}

.divider {
  font-size: 30px;
  display: flex;
  align-items: center;
  border-top: 0.1rem solid #e8e8e8;
  margin-top: 22px;
}

.dividerText {
  padding: 0rem 1rem;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
  margin-top: -0.813rem;
  border-radius: 6.25rem;
  border: 0.06rem solid #dedede;
  background: #f3f3f3;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__500, $font-size__16);
}

.label {
  color: $grayColor;
  @include update_fonts($font-weight__400, $font-size__16);
}

.buttonText {
  color: $darkBlue;
  @include update_fonts($font-weight__600, $font-size__14);
}

.iconOrText {
  gap: 0.44rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.recTestStepSelectorDetail {
  padding-right: 0.9rem;
}

.disableInspectorText {
  color: #a4a5a9;
}

.disableButton {
  color: $white;
  background: $silver;
}

.tooltipLabel{
  @include update_fonts($font-weight__600, $font-size__14);
}