@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.collapseIcon {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  margin-left: 1rem;
  background-color: $bgGray;
  display: $display__flex !important;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.conditionText {
  color: #545569;
  @include update_fonts($font_weight__400, $font-size__16);
}

.previewOrDeleteIcon {
  gap: 0.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.deleteIcon {
  margin-right: 0.4rem;
  cursor: $cursor__pointer;
}

.marginTop {
  margin-top: 0.5rem;
}

.preview_Result {
  margin: 0 0.6rem;
}

//preCondition condition
.input_Title {
  color: $textGray;
  @include update_fonts($font_weight__500, $font-size__16);
}

.input_Box {
  width: 96% !important;
  border: 0.06rem solid #d6d6d6 !important;
  padding: 0.6rem 0.75rem;
  border-radius: 0.6rem !important;
  height: 2.5rem !important;
  margin-top: 0.25rem;
  font-size: $font-size__15 !important;
  @include update_fonts($font_weight__400);
}

//Loop condition

.loop_Condition_Label {
  color: $textGray;
  @include update_fonts($font_weight__500, $font-size__16);
}

.repeat_Count_Input_Box {
  width: 30% !important;
  border-radius: 0.38rem !important;
  border: 0.06rem solid #d6d6d6 !important;
  height: 2.5rem !important;
}

.loop_Condition_Details {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.save-button,
.save-button:hover {
  border-radius: 0.56rem !important;
  height: 2.5rem;
  box-shadow: 0.25rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.05) !important;
  background: $Dark__Blue;
}

.save-button[disabled],
.save-button[disabled]:hover {
  background: $Gray;
  color: $disableText;
}

.add_Element_Verification {
  padding: 0.6rem;
  gap: 0.6rem;
  border-radius: 0.44rem;
  height: 2.5rem;
  cursor: $cursor__pointer;
  border: 0.06rem solid $Dark__Blue;
  color: $Dark__Blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__500, $font-size__14);
}

.disabled_Add_Element_Verification {
  cursor: $cursor__not_allowed;
  color: $disableText !important;
  border: 0.06rem solid $disableText !important;
}

.input_Box_And_Delete_Icon {
  gap: 1rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.inputTitle {
  height: 2.1875rem;
  padding: 0.9rem 1.1rem 0.9rem 1.2rem;
  border-right: 0.06rem solid #d6d6d6;
  border-radius: 0.6rem 0rem 0rem 0.6rem;
  color: $textBlack;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__500, $font_size__16);
}

.inputTitleOrInputBox {
  width: 100%;
  border-radius: 0.6rem;
  border: 0.06rem solid #d6d6d6;
  background: $white;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.inputStyle {
  width: 90%;
  background: transparent !important;
  border: 0rem solid $Gray !important;
  color: $darkGrayColor;
  font-size: $font_size__16 !important;
  @include update_fonts($font_weight__500);
}

.inputStyle .ant-input-disabled {
  background: transparent !important;
}

.input_Box_And_Delete_Icon {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.spinner {
  margin-right: 0.6rem;
  display: $display__flex;
}

.title_And_Input_Box {
  width: 100%;
  display: $display__flex;
  align-items: $align_items__center;
}

.step-name {
  width: 100%;
  margin-top: -0.06rem;
  margin-bottom: 0 !important;
}

.truncated_Text {
  display: inline-block;
  max-width: calc(100% - 3rem);
  width: 100%;
}

.header_Title {
  flex: 1;
}

.AI_Icon {
  margin-top: 0.5rem;
}

.AI_Generate_Icon {
  margin-right: 0.6rem;
}