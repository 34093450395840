@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.recElementDetails {
  display: $display__flex;
}

.recElementsList {
  border-radius: 0.4375rem;
  border: 0.06rem solid $lightPurpleBorder;
}

.elementCount {
  padding-left: 0.6rem;
  padding-right: 0.4rem;
  gap: 0.4rem;
  height: 3rem;
  border-radius: 0.375rem 0rem 0rem 0.375rem;
  white-space: nowrap;
  border: 0.06rem solid $lightPurpleBorder;
  display: $display__flex;
  background: $lightPurple;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.addElement {
  flex-wrap: wrap;
  height: 3.1rem !important;
  border-radius: 0.375rem 0.375rem 0rem 0rem;
  padding: 0rem 0.75rem;
  justify-content: space-around;
  border-bottom: 0.06rem solid $lightPurpleBorder;
  display: $display__flex;
  align-items: $align_items__center;
}

.addElementText {
  color: $blue;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__600, $font_size__16);
}

.disableAddELementText {
  color: #d3d3d3;
}

.elementCountText {
  color: #7d7e82;
  @include update_fonts($font_weight__500, $font_size__16);
}

.screenshot {
  z-index: 99;
  position: relative;
}

.orLabel {
  flex-direction: column;
  border-radius: 0.20238rem;
  width: 1.72025rem;
  height: 1.0625rem;
  padding: 0.50594rem;
  gap: 0.50594rem;
  flex-shrink: 0;
  z-index: 1;
  margin: -0.4rem 0 -0.4rem 0.8rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  color: $blue;
  display: $display__flex;
  border: 0.81px solid $lightPurpleBorder;
  background: $white;
  color: $blue;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
  @include update_fonts($font_weight__600, 0.65775rem);
}

.allElementsList {
  padding: 10px 0 10px 10px;
}

.elementBgColor {
  padding-left: 0.6rem;
  padding-right: 0.4rem;
  background: $blue;
  position: relative;
  color: $white;
}

.elementBgColor::before {
  content: "";
  width: 1.8rem;
  height: 2rem;
  position: absolute;
  right: -0.6rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: $blue;
}

.elementBgColor:after {
  content: "";
  width: 2.875rem;
  height: calc(100% + 4rem);
  z-index: 1;
  position: absolute;
  right: -1.25rem;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='112' viewBox='0 0 29 112' fill='none'%3E%3Cpath d='M13 80H0V68.5H28.5V112H27V94C27 86.268 20.732 80 13 80Z' fill='%2301A3E3'/%3E%3Cpath d='M13 32H0V43.5H28.5V0H27V18C27 25.732 20.732 32 13 32Z' fill='%2301A3E3'/%3E%3C/svg%3E");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: contain;
  pointer-events: none;
}

.disableElementBgColor {
  padding-left: 0.6rem;
  padding-right: 0.4rem;
  position: relative;
  color: $white;
  background: #7d7e82;
}

.disableElementBgColor::before {
  content: "";
  width: 0.8rem;
  height: 2rem;
  position: absolute;
  right: -0.6rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: #7d7e82;
}

.disableElementBgColor:after {
  content: "";
  width: 2.875rem;
  height: calc(100% + 4rem);
  z-index: 1;
  position: absolute;
  right: -1.25rem;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='112' viewBox='0 0 29 112' fill='none'%3E%3Cpath d='M13 80H0V68.5H28.5V112H27V94C27 86.268 20.732 80 13 80Z' fill='%237D7E82'/%3E%3Cpath d='M13 32H0V43.5H28.5V0H27V18C27 25.732 20.732 32 13 32Z' fill='%237D7E82'/%3E%3C/svg%3E");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: contain;
  pointer-events: none;
}

.disableElement {
  border: 0.163rem solid #7d7e82 !important;
}

.divider {
  font-size: 1.875rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.selectedElementText {
  color: $white !important;
}

.element {
  border-radius: 0.375rem;
  margin: 0 0 0 0.4rem;
  border: 0.163rem solid $blue;
  fill: $white;
}

.newElementText {
  white-space: nowrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.settingsIcon {
  display: $display__flex;
  align-items: $align_items__center;
}

.disableElementIcon {
  gap: 0.4rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.disableElementCount {
  background-color: #c7c9cf;
}

.disableElementText {
  color: $white;
}

.imageOrName {
  width: 85%;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}
