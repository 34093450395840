@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.main-header {
  top: 3.38rem;
  width: 100%;
  padding: 0rem 0rem 0rem 20px;
  background: $white;
}

.main-Header-Title {
  color: #111548;
  margin-left: 1.13rem;
  margin-bottom: 0;
  @include update_fonts($font-weight__600, $font-size__22);
}

.stepsData {
  height: calc(100vh - 11.94rem);
  overflow-y: scroll;
}
