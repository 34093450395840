@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.main_Header {
  top: 3.38rem;
  padding: 0rem 1.3rem 15px 1.3rem;
  background-color: #f2f4f7;
}

.page_Scroll {
  height: calc(100vh - 8.625rem);
  overflow-y: scroll;
}

.sub_Header {
  margin: 0.6rem;
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__end;
}

.main-Header-Text {
  color: #002858;
  @include update_fonts($font_weight__700, $font_size__20);
}

.key_Name_Add_Icon {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.delete_Icon {
  margin-bottom: 0.6rem;
}

.action_Icons {
  gap: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.device_String_Details {
  margin-bottom: 1.875rem;
  margin-left: 0.6rem;
}
