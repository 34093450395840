@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.tabs{
    margin-top: 0.6rem;
}

.pageScroll{
    height: calc(100vh - 10.5rem);
    overflow-y: scroll;
}