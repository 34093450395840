@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.postActionSettings {
  @include postActionSettings;
}

.disableButton {
  background-color: $Gray;
  color: $disableText;
  cursor: $cursor__not_allowed;
}

.launchAppTitle {
  margin-bottom: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.launchAppLabel {
  gap: 0.87rem;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font-weight__500, $font-size__16);
}

.launchAppText {
  margin-bottom: 0.3rem;
}

.actionTitle {
  text-transform: uppercase;
  color: #505862;
  @include update_fonts($font_weight__700, $font-size__15);
}
