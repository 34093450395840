@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.actionIconsInHeader {
  flex: 1;
  @include actions_list_header;
}

.deleteInHeader {
  width: 4.5rem;
  justify-content: $justify_content__center;
  @include actions_list_header;
}

.header {
  margin: 0.6rem 1.06rem 1.1rem 3.75rem;
  border-radius: 3rem;
  border: 0.06rem solid #d6e0ee;
  background: #ebebee;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.borderRight {
  height: 2.625rem;
  border-right: 0.0625rem solid rgba(0, 0, 0, 0.14);
}

.padding_stepOperation {
  padding: 0rem 0.9375rem 0rem 0.9375rem;
}

.selectedRecTestStepOrderId {
  margin-right: 1.37rem;
  color: $Dark__Blue;
  @include update_fonts($font_weight__600, $font-size__18);
}

.selectedRecTestSteps {
  margin-left: 1rem;
  color: $darkBlue;
  font-size: $font_size__14;
  font-weight: $font_weight__600;
}

.selectedRecTestStepMainDiv {
  display: $display__flex;
  align-items: $align_items__center;
}

.selectRecTestStepCheckBoxDiv {
  margin-left: 0.8rem;
  min-width: 3.125rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.actionIconAndDeleteIcon {
  width: calc(100% - 4.0625rem);
}

.selectedStepIsNotEmpty {
  gap: 0.625rem;
  display: $display__flex;
}

.centerMenu {
  white-space: nowrap;
  overflow: auto;
  height: 100%;
  padding: 0.3125rem 0;
  display: $display__flex;
  align-items: $align_items__center;
}
