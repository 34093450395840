@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.quickActions {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.selectQuickActions {
  gap: 0.87rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.selectBoxForActions {
  margin-left: 0.3rem;
  border-radius: 0.3rem;
  margin-top: 0.3rem;
  border: 0.06rem solid #e9e9e9 !important;
  width: 100%;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
}

.label {
  margin-top: 0.3rem;
  text-transform: uppercase;
  color: #505862;
  @include update_fonts($font_weight__700, $font-size__15);
}
