@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.members_By_Team_Details {
  gap: 10px;
  flex-direction: column;
  display: $display__flex;
}

.member_Row {
  width: 100%;
}

.member_Col {
  padding: 0.6rem 0.94rem;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
  background: $white;
  display: $display__flex;
  align-items: $align_items__center;
}

.member_Name {
  padding: 0px 1rem;
  width: 100%;
  border-radius: 0.13rem;
  color: #000000b8;
  @include update_fonts($font-weight__600, $font_size__16);

}