@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.main-container {
  margin-top: 4.5rem;
  padding-bottom: 1rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.main--header-text {
  margin-left: 0.625rem;
  padding: 0.75rem;
  color: #002858;
  @include update_fonts($font-weight__600, $font_size__20);
}

.header--or-button {
  margin-right: 1.25rem;
}

.search_And_Add_Device {
  gap: 0.94rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.brand_Logo_And_Name {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.device_Details {
  margin: 1.3rem;
}

.device_logo {
  float: left;
}

.icon {
  margin-left: 1.13rem;
  cursor: $cursor__pointer;
}

.label {
  color: #6d6d6d;
  @include update_fonts($font_weight__400, $font_size__14);

  span {
    color: #242424;
    @include update_fonts($font_weight__500, $font_size__14);
  }
}

.project_And_User_Count {
  color: $Dark__Blue !important;
  margin-left: 0.13rem;
  cursor: $cursor__pointer;
}

.action_Icon {
  display: $display__flex;
  justify-content: $justify_content__end;
  align-items: $align_items__center;
}

.border-bottom {
  border-bottom: 0.06rem solid #ccc;
}

.project_And_User_List {
  padding: 0.3rem;
  max-height: 9.375rem;
  overflow-y: auto;
}

.project_Name {
  margin-left: 0.6rem;
  @include update_fonts($font_weight__400, $font_size__14);
}

.share_Button {
  margin-top: 0.3rem;
  cursor: $cursor__pointer;
}