@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.iconMoreButton {
  margin-left: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.action-title {
  color: #6e7282;
  padding: 0.5rem 0.3rem;
  margin: 0 0.6rem;
  border-bottom: 0.06rem solid rgba(0, 0, 0, 0.06);
  @include update_fonts($font_weight__400, $font_size__13);
}

.menu-options {
  color: $navyBlue;
  font-size: $font_size__13;
}
