@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  display: $display__flex;
  flex-wrap: $flex__wrap;
}

.sign_In_Text {
  text-align: left;
  margin-top: 0.6rem;
  color: $Dark_Black;
  @include update_fonts($font_weight__500, $font-size__28);
}

.welcome_Text {
  text-align: left;
  color: $Dark_Black;
  @include update_fonts($font_weight__500, 1.31rem);
}

.custom-input-wrapper {
  position: relative;
  margin-top: 0.5rem;
}

.custom-input-label {
  position: absolute;
  left: 0.9rem;
  padding: 0 0.5em;
  z-index: 1;
  color: #84818a;
  background-color: $white;
  @include update_fonts($font-weight__400, $font_size__14);
}

.modal-input {
  padding: 1.38rem 1.38rem 1.3rem 1.57rem !important;
  border-radius: 0.75rem !important;
  border: 0.06rem solid #dcdbdd !important;
  margin: 0.6rem 0;
  color: $textBlack !important;
  background-color: $white !important;
  font-size: $font_size__15 !important;
  @include update_fonts($font-weight__400, $font_size__15);
}

.error {
  border: 0.06rem solid red !important;
}

.btn_login_Or_Token,
.btn_login_Or_Token:hover {
  height: 44px !important;
  border-radius: 0.75rem !important;
  margin: 0.6rem 0 0.9rem;
  color: $white;
  background-color: $Dark__Blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  cursor: $cursor__pointer;
}

.btn_login_Or_Token > span {
  font-size: $font_size__16 !important;
  @include update_fonts($font-weight__500, $font_size__16);
}

.btn_login_Or_Token:disabled,
.btn_login_Or_Token:disabled:hover {
  background-color: rgba(55, 114, 255, 0.6);
  border-radius: 0.75rem !important;
  color: $white;
  cursor: $cursor__not_allowed;
}

.create_Account_Text {
  text-align: left;
  gap: 0.3rem;
  color: $Dark_Black;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font-weight__500, $font_size__14);
}

.create_Account_Text a {
  color: $Dark__Blue;
}

.resend_Token_Text {
  cursor: $cursor__pointer;
  color: $Dark__Blue;
  display: $display__flex;
  justify-content: $justify_content__end;
  @include update_fonts($font-weight__500, $font_size__14);
}

.back_Button {
  height: 2.75rem !important;
  border-radius: 0.75rem !important;
  margin: 0.6rem 0 0.9rem;
  border: 0.06rem solid $Dark__Blue;
  cursor: $cursor__pointer;
  color: $Dark__Blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__500, $font_size__16);
}
