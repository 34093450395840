@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.build_Details {
  margin: 1.25rem 0rem;
  height: calc(100vh - 8.63rem);
  overflow-y: scroll;
}

.custom_Input_Wrapper {
  position: relative;
}

.custom_Input_Label {
  position: absolute;
  left: 0.9rem;
  padding: 0 0.5em;
  z-index: 1;
  color: #84818a;
  background-color: $white;
  @include update_fonts($font-weight__400, $font_size__16);
}

.disable_Input_Label {
  background-image: linear-gradient(to top, #f5f5f5 52%, #f2f4f7 75%) !important;
}

.modal_Date_Picker_Box {
  border-radius: 0.8rem !important;
  border: 0.06rem solid #dcdbdd !important;
  margin: 0.6rem 0 0.9rem !important;
  padding: 0.75rem 0.625rem 0.6125rem 0.9625rem !important;
  width: 100%;
  color: #353535;
  background-color: $white;
}

.modal_Input {
  padding: 1.38rem 0rem 1.3rem 1.25rem !important;
  border-radius: 0.8rem !important;
  border: 0.06rem solid #dcdbdd !important;
  margin: 0.6rem 0 0.9rem;
  color: #000 !important;
  background-color: $white !important;
  font-size: $font_size__15 !important;
  @include update_fonts($font-weight__400, $font_size__15);
}

.modal_Input[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
}

.modal_TextArea {
  width: 100%;
  color: #353535;
  margin: 0.6rem 0 0.9rem;
  border-radius: 0.8rem !important;
  padding: 1rem 0rem 0rem 1.13rem !important;
  border: 0.06rem solid #dcdbdd !important;
  background-color: $white;
  font-size: $font_size__15 !important;
  @include update_fonts($font-weight__400, $font_size__15);
}

.field_Required {
  color: red;
  font-size: 0.9rem;
  margin-left: 0.19rem;
}