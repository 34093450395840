@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.parentTagName {
  color: $textBlack;
  z-index: 111111;
  @include update_fonts($font_weight__600, 1.06rem);
}

.childTagName {
  color: $textGray;
  @include update_fonts($font_weight__500, font_size__15);
}

.stepsInTreeView {
  padding: 0;
  width: 100%;
  height: 2.1875rem;
  border-radius: 0.25rem;
  cursor: default;
  margin-top: 0;
  margin-left: 0;
  display: $display__flex;
  align-items: $align_items__center;
}

div.stepsInTreeView:not(.no-left-line):not(.labelDevice):before {
  content: "";
  width: 0.0625rem;
  height: 2.5rem;
  position: absolute;
  top: -1.5rem;
  left: -0.75rem;
  background: #d9d9d9;
}

div.stepsInTreeView:not(.no-left-line):after {
  content: "";
  width: 0.75rem;
  height: 0.0625rem;
  position: absolute;
  top: 1rem;
  left: -0.75rem;
  background: #d9d9d9;
}

.selectElement {
  border-radius: 0.25rem;
  position: relative;
  z-index: 1;
  background: $blue;
}

.selectElement > div > span {
  margin-left: 0.6rem;
  color: $white;
}

.selectElementText {
  color: $white;
}
.selectElement:before {
  background: #002858 !important;
}
div.selectElement:after {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="26" viewBox="0 0 18 26" fill="none"><path d="M17.495 20.995C17.7683 20.7216 17.7683 20.2784 17.495 20.005L13.0402 15.5503C12.7668 15.2769 12.3236 15.2769 12.0503 15.5503C11.7769 15.8236 11.7769 16.2668 12.0503 16.5402L16.0101 20.5L12.0503 24.4598C11.7769 24.7332 11.7769 25.1764 12.0503 25.4497C12.3236 25.7231 12.7668 25.7231 13.0402 25.4497L17.495 20.995ZM0.8 0V16.5H2.2V0H0.8ZM5.5 21.2H17V19.8H5.5V21.2ZM0.8 16.5C0.8 19.0957 2.90426 21.2 5.5 21.2V19.8C3.67746 19.8 2.2 18.3225 2.2 16.5H0.8Z" fill="%23002858"/></svg>') !important;
}

.element {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  padding: 2px;
  height: 35px;
  width: 100%;
  z-index: 111111;
  display: $display__flex;
  align-items: $align_items__center;
}

.tagNameOrIcon {
  gap: 0.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.bgWhite {
  background-color: $white;
}

.parentElementArrowIcon {
  margin-left: -1.62rem;
}
