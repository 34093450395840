@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.hoverActionIcons {
  gap: 1.5rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.borderRight {
  height: 2.2rem;
  border-right: 0.0625rem solid rgba(0, 0, 0, 0.09);
}

.buttonForAIStep {
  width:2.2rem;
  height:2.2rem;
  border-radius: 5px;
  padding: 0.3rem 0.5rem 0.1rem 0.3rem;
  background-color: $Dark__Blue;
}

.disableButtonForAIStep {
  cursor: $cursor__not_allowed;
}