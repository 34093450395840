@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.recTestStepPostActions {
  margin-top: 1rem;
}

.elementTestData {
  flex-wrap: wrap;
  gap: 0.3rem;
  margin-top: 0.75rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.text {
  color: $darkGrayColor;
  @include update_fonts($font_weight__500, $font_size__15);
}

.selectVariable {
  gap: 0.3rem;
  flex: 1;
  display: $display__flex;
  align-items: $align_items__center;
}

.styledSelect {
  border-radius: 0.3rem;
  border: 0.06rem solid $grayBorder !important;
  width: 50%;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
}

.footer {
  gap: 0.3rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.postActionSettings {
  flex-wrap: wrap;
  gap: 1.25rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.actions {
  gap: 0.37rem;
  margin-top: 0.8rem;
  white-space: nowrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.title {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  color: $grayColor;
  @include update_fonts($font_weight__500, $font_size__15);
}

.time {
  margin-left: 0.13rem;
  font-size: $font_size__12;
}

.styledInput {
  border: none !important;
  margin-left: 0.3rem;
  width: 5rem;
  padding: 0;
}

.delayExecutionTitle {
  margin-left: 1.5rem;
  font-size: $font_size__12;
}

.postActionTitle {
  color: $gray;
  text-transform: uppercase;
  @include update_fonts($font_weight__700, $font_size__15);
}

.removeSelectVariable {
  width: 5.6875rem;
  height: 2.5rem;
  padding: 0.625rem 0.8125rem;
  flex-direction: column;
  gap: 0.625rem;
  border-radius: 0.375rem;
  cursor: $cursor__pointer;
  border: 0.06rem solid $darkGrayColor;
  color: $darkBlue;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
  @include update_fonts($font_weight__500, $font_size__16);
}

.disableButton {
  background-color: $Gray;
  color: $disableText;
  cursor: $cursor__not_allowed;
  border: 0.06rem solid $Gray;
  @include update_fonts($font_weight__500, $font_size__16);
}

.selectTestData {
  flex-direction: column;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0rem 0.8125rem;
  gap: 0.625rem;
  border-radius: 0.375rem;
  border: 0.06rem solid $blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.disableSelectTestData {
  border: 0.06rem solid $Gray !important;
}

.invertResult {
  margin-top: 0.6rem;
}

.inputOrLabel {
  width: 7.9375rem;
  border-radius: 0.375rem;
  border: 0.06rem solid #cdced0;
  border: 0.06rem solid var(--G5, #cdced0);
  display: $display__flex;
  align-items: $align_items__center;
}

.disableInputOrLabel {
  background: #f5f5f5;
  cursor: $cursor__not_allowed;
}

@media (min-width: 1650px) {
  .repeatStep {
    justify-content: $justify_content__space_between;
  }
}
