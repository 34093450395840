@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.cancelBtn,
.cancelBtn:hover {
  padding: 1.19rem 3rem 1.3rem !important;
  background-color: #fbfcfc !important;
  border-radius: 0.75rem !important;
  border: 0.06rem solid #9a9a9a !important;
  display: $display__flex;
  align-items: $align_items__center;
  span {
    color: $textBlack;
    @include update_fonts($font_weight__600, $font_size__18);
  }
}

.saveBtn,
.saveBtn:hover {
  width: 18.63rem;
  height: 2.69rem;
  border-radius: 0.8rem !important;
  color: $white;
  background-color: $Dark__Blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  &[disabled],
  &:disabled {
    background-color: rgba(55, 114, 255, 0.6) !important;
    color: $white !important;
  }
  span {
    color: $white;
    @include update_fonts($font_weight__500, $font_size__18);
  }
}
