@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.actionIcons {
  gap: 0.6rem;
  display: $display__flex;
}

.styledRow {
  margin: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__end;
  align-items: $align_items__center;
}

.pageScroll {
  height: calc(100vh - 8.625rem);
  overflow-y: scroll;
}

.stylesCol {
  display: $display__flex;
  justify-content: $justify_content__end;
}

.addIcon {
  margin-right: 0.6rem;
}

.table {
  width: 100%;
  margin-top: 0.3rem;
  padding: 0rem 0.6rem 0.6rem 0.6rem;
}

.pagination {
  float: right;
  margin-top: 0.6rem;
}

.applicationStringKey {
  width: 29.75rem;
}

.clearAllStrings {
  border-radius: 0.3rem;
  padding: 0.5rem 0.69rem;
  margin-bottom: 0.9rem;
  color: $white;
  background: $blueTextColor;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__400, $font-size__14);
}

.selectBox {
  margin-bottom: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.checkBox {
  margin-right: 0.6rem;
}

.disableclearAllString {
  cursor: $cursor__not_allowed;
  color: $disableTextColor;
  background-color: $Gray;
}

.selectBoxOrClear {
  display: $display__flex;
  justify-content: $justify_content__end;
}

.justify_end {
  justify-content: $justify_content__space_between;
}

.checkBoxOrKey {
  display: $display__flex;
}
