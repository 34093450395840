@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.inputOrText {
  padding: 1.5rem 1.5rem 0px 1.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.elementText {
  @include update_fonts($font_weight__400, $font-size__16);
}

.inputRetryCount {
  width: 5rem;
}

.time {
  padding-left: 5px;
  color: #636467;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__400, $font-size__16);
}

.saveButton {
  background-color: #01a3e3;
  color: #fff;
}

.saveButton:hover {
  background: #01a3e3;
  color: #fff;
}

.saveButton[disabled] {
  background: #e6e6e6;
  color: $disableText;
}

.footer {
  gap: 0.3rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.settingsModalDivider {
  border-top-color: #d4d2d2 !important;
}

.styledSwipeLabel {
  background: $bgDarkGray;
  padding: 0.5rem;
  @include update_fonts($font_weight__500, $font-size__14);
}

.styledInputOuter {
  background: $bgDarkGray;
  padding: 0.438rem;
  margin-left: 0.6rem;
  @include update_fonts($font_weight__500, $font-size__14);
}

.swipeCountInputOrText {
  margin-left: 0.6rem;
  display: $display__flex;
}

.percentageInput {
  width: 5rem;
  margin-right: 0.6rem;
  border-radius: 0.3rem;
}

.swipeDirectionOrCount {
  display: $display__flex;
  padding: 0.3rem 1.5rem 1.3rem 1.5rem;
}

.scrollToLocate {
  padding-top: 0.06rem;
}

.reverseScroll {
  padding-left: 1.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.checkbox_Text {
  margin-left: 0.6rem;
  @include update_fonts($font_weight__500, $font-size__16);
}

.retryCountOrText {
  margin-left: 0.13rem;
  display: $display__flex;
}

.swipeDirection {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.scroll {
  margin-top: 0.6rem;
}

.swipeUp{
  margin-left:2rem;
  margin-bottom:-0.813rem;
}

.swipeCountOrInput{
  display:$display__flex;
  align-items: $align_items__center;
}

.swipeRight{
  margin-left: 2.188rem;
}

.swipeDown{
  margin-left:2rem;
  margin-top: -0.875rem !important;
}

.setDefault{
  margin-left:0.3rem;
}

.hideDrawer {
  display: $display__none;
}