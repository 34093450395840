@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.organization_List {
  gap: 0.6rem;
  flex-direction: column;
  display: $display__flex;
}

.organization_Name_Details {
  gap: 0.56rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.organization_Short_Name {
  width: 3.13rem;
  height: 3.13rem;
  flex-direction: column;
  gap: 0.6rem;
  padding: 0.3rem 0.4rem;
  border-radius: 0.6rem;
  border: 0.13rem solid #cbcbcb;
  text-transform: uppercase;
  background-color: #f2f2f2;
  color: $textBlack;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__500, $font_size__16);
}

.active_Organization_Short_Name {
  border: 0.13rem solid $Dark__Blue !important;
  background-color: #b5cbff !important;
}

.organization_Name {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__16);
}

.active_Organization_Name {
  color: $Dark__Blue;
}
