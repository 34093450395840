@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.selectVariable {
  gap: 0.3rem;
  flex: 1;
  display: $display__flex;
  align-items: $align_items__center;
}
.styledSelect {
  border-radius: 0.3rem;
  border: 0.06rem solid $grayBorder !important;
  width: 35%;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
}

.removeButton {
  display: $display__flex;
  align-items: $align_items__center;
  gap: 5px;
}

.showTestDataRow {
  display: $display__flex;
  align-items: $align_items__center;
}

.showTestDataCol {
  display: $display__flex;
}

.setAppLocaleVariableCol {
  gap: 0.625rem;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  display: $display__flex;
  @include update_fonts($font_weight__500, $font_size__13);
}

.setAppLocaleVariableRow {
  display: $display__flex;
}

.checkBox {
  grid-gap: 0.37rem;
  gap: 0.37rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.checkBoxText {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  color: #535456;
  @include update_fonts($font_weight__500, $font_size__13);
}

.localeOrSelect {
  margin-top: 4px;
}

.checkBoxOrText {
  display: flex;
  align-items: center;
}

.removeSelectVariable {
  width: 5.6875rem;
  height: 2.5rem;
  padding: 0.625rem 0.8125rem;
  flex-direction: column;
  gap: 0.625rem;
  border-radius: 0.375rem;
  cursor: $cursor__pointer;
  border: 0.06rem solid $darkGrayColor;
  color: $darkBlue;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
  @include update_fonts($font_weight__500, $font_size__16);
}

.disableButton {
  background-color: $Gray;
  color: $disableText;
  cursor: $cursor__not_allowed;
  border: 0.06rem solid $Gray;
  @include update_fonts($font_weight__500, $font_size__16);
}

.selectTestData {
  flex-direction: column;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0rem 0.8125rem;
  gap: 0.625rem;
  border-radius: 0.375rem;
  margin-left: 1.3rem;
  border: 0.06rem solid $blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.disableSelectTestData {
  border: 0.06rem solid $Gray !important;
}