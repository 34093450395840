@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.recElementDetails {
  width: 100%;
  border-radius: 0.75rem;
  box-shadow: 0.25rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.05);
  background-color: $white;
  display: $display__flex;
}

.recElementsList {
  width: 25%;
  border: 0.06rem solid $lightPurpleBorder;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}

.recElementContent {
  width: 75%;
}

.elementCount {
  padding-left: 0.6rem;
  padding-right: 0.4rem;
  gap: 0.4rem;
  height: 3rem;
  border-radius: 0.375rem 0rem 0rem 0.375rem;
  white-space: nowrap;
  border: 0.06rem solid $lightPurpleBorder;
  display: $display__flex;
  background: $lightPurple;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.addElement {
  height: 3.1rem !important;
  padding-right: 0.3rem;
  border-bottom: 0.06rem solid $lightPurpleBorder;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.addElementText {
  color: $Dark__Blue;
  display: $display__none;
  align-items: $align_items__center;
  @include update_fonts($font_weight__400, $font_size__16);
}

.disableAddELementText {
  color: #d3d3d3;
}

.elementCountText {
  color: #7d7e82;
  @include update_fonts($font_weight__500, $font_size__16);
}

.screenshot {
  z-index: 99;
  position: relative;
}

.operator_Divider {
  font-size: 1.875rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.operator_Label {
  flex-direction: column;
  border-radius: 0.20238rem;
  width: 1.72025rem;
  height: 1.0625rem;
  padding: 0.50594rem;
  gap: 0.50594rem;
  flex-shrink: 0;
  z-index: 1;
  margin: -0.4rem 0 -0.4rem 0.8rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  color: $blue;
  display: $display__flex;
  border: 0.81px solid $lightPurpleBorder;
  background: $white;
  color: $Dark__Blue;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
  @include update_fonts($font_weight__600, 0.65775rem);
}

.allElementsList {
  padding: 10px 0 10px 10px;
}

.elementBgColor {
  padding-left: 0.6rem;
  padding-right: 0.4rem;
  background: $Dark__Blue;
  position: relative;
  color: $white;
}

.elementBgColor::before {
  content: "";
  width: 1.25rem;
  height: 2rem;
  position: absolute;
  right: -3px;
  top: 50%;
  transform: translateY(-50%);
  background-color: $Dark__Blue;
}

.elementBgColor:after {
  content: "";
  width: 2.875rem;
  height: calc(100% + 3.81rem);
  z-index: 1;
  position: absolute;
  right: -0.81rem;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='112' viewBox='0 0 29 112' fill='none'%3E%3Cpath d='M13 80H0V68.5H28.5V112H27V94C27 86.268 20.732 80 13 80Z' fill='%233772FF'/%3E%3Cpath d='M13 32H0V43.5H28.5V0H27V18C27 25.732 20.732 32 13 32Z' fill='%233772FF'/%3E%3C/svg%3E");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: contain;
  pointer-events: none;
}

.disableElementBgColor {
  padding-left: 0.6rem;
  padding-right: 0.4rem;
  position: relative;
  color: $white;
  background: #7d7e82;
}

.disableElementBgColor::before {
  content: "";
  width: 1.25rem;
  height: 2rem;
  position: absolute;
  right: -2px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #7d7e82;
}

.disableElementBgColor:after {
  content: "";
  width: 2.875rem;
  height: calc(100% + 3.81rem);
  z-index: 1;
  position: absolute;
  right: -0.81rem;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='112' viewBox='0 0 29 112' fill='none'%3E%3Cpath d='M13 80H0V68.5H28.5V112H27V94C27 86.268 20.732 80 13 80Z' fill='%237D7E82'/%3E%3Cpath d='M13 32H0V43.5H28.5V0H27V18C27 25.732 20.732 32 13 32Z' fill='%237D7E82'/%3E%3C/svg%3E");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: contain;
  pointer-events: none;
}

.disableElement {
  border: 0.163rem solid #7d7e82 !important;
}

.selectedElementText {
  color: $white !important;
}

.element {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  border: 0.06rem solid #dde5f6;
  border-left: none;
  fill: $white;
}

.selected_Element {
  border: 0.13rem solid $Dark__Blue;
}

.newElementText {
  margin-right: 0.3rem;
  white-space: nowrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.settingsIcon {
  margin-left: -0.25rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.disableElementIcon {
  gap: 0.4rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.disableElementCount {
  background-color: #c7c9cf;
}

.disableElementText {
  color: $white;
}

.imageOrName {
  width: 85%;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.condition-And-Settings-Icon {
  gap: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.select_Operators {
  width: 2.94rem;
  height: 2.38rem;
  gap: 0.64rem;
  margin: 0 0.3rem 0 0.8rem;
  padding: 0.26rem 0.6rem;
  border-radius: 0.44rem;
  background-color: rgba(55, 114, 255, 0.2);
  flex-direction: row;
  border: 0.06rem solid $white;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(55, 114, 255, 0.2) !important;
  }
}

.disabled_Icon {
  z-index: 1;
}

.element_Text_And_Icon {
  display: $display__flex;
  align-items: $align_items__center;
}

.load_Device_Icon {
  margin-right: 0.3rem;
}

.hideAddElementText {
  display: $display__none !important;
}

@media only screen and (min-width: 106.25rem) {
  .addElementText {
    display: $display__flex;
  }
  .tooltip_Visible {
    display: $display__none;
  }
}

@media screen and (max-width: 48rem) {
  .elementBgColor::before {
    width: 0.06rem;
    right: -0.13rem;
  }

  .elementBgColor:after {
    width: 2rem;
    right: -10px;
    height: calc(100% + 2rem);
  }
}

@media screen and (max-width: 30rem) {
  .elementBgColor:after {
    width: 1.5rem;
    right: -0.3rem;
    height: calc(100% + 1.5rem);
  }
}
