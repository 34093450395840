@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.checkBox {
  margin-right: 0.6rem;
}

.borderBottom {
  border-bottom: 0.06rem solid #d9d9d9;
}
.popoverContents {
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
}

.data {
  margin-left: 0.13rem;
}

.popoverDetails {
  margin-left: 0.6rem;
  color: #4a9bf2;
  cursor: $cursor__pointer;
}

.permissionTooltip {
  margin-right: 0.3rem;
}

.stepName {
  display: $display__flex;
  font-weight: $font_weight__700;
  font-size: $font_size__14;
}

.recTestStepRights {
  align-items: baseline;
  display: $display__flex;
}

.checkbox {
  margin: 0.6rem;
}

.mainContainer {
  display: $display__flex;
}

.testStepDetails {
  margin-right: 0.6rem;
}

.mainTestStepDetails {
  display: $display__flex;
}

.ActionIcon {
  padding: 1.25rem;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.editIcon {
  cursor: $cursor__pointer;
}

.buildTestDataIcon {
  padding-left: 0.6rem;
  cursor: $cursor__pointer;
  font-size: 1.13rem;
}

.applicationStrings {
  padding-left: 0.6rem;
  font-size: 1.75rem;
  cursor: $cursor__pointer;
}

.spinnerCenter {
  min-height: 90vh;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.innerHeader {
  background-color: $white;
}

.pageScroll {
  height: calc(100vh - 138px);
  overflow-y: scroll;
}

.importModel {
  margin: 0.94rem;
  display: $display__flex;
  text-align: $text_align__center;
  justify-content: $justify_content__space_between;
}

.importRecTestStepModal {
  margin-right: 0.6rem;
  margin-bottom: 0.6rem;
}

.testStepBlockForImport {
  margin-top: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.mainTableComponents {
  margin-bottom: 0.6rem;
  margin-top: 0.6rem;
  margin: 0.94rem;
}

.selectAllBox {
  margin-top: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
  margin-bottom: 0.6rem;
}

.testStepBlocksHeader {
  display: $display__flex;
  justify-content: $justify_content__space_between;
  align-items: $text_align__center;
}

.testBlocksText {
  padding: 0.75rem;
  margin-left: 0.625rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #111548;
}

.testBlockList {
  margin-right: 1.69rem;
}

.BuildTestBlockButton {
  margin-left: 0.6rem;
  background-color: $blueTextColor;
}

.label {
  color: #636467;
  font-weight: $font_weight__400;
  font-size: $font_size__12;
}

.admins {
  margin-left: 0.13rem;
  display: $display__flex;
}

//New
.sub-header {
  padding: 0.9rem;
  margin-left: 0.38rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.select--all-box {
  margin-left: 0.4rem;
  margin-bottom: 0.625rem;
  padding-top: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.checkBox-in-header {
  margin-left: 0.9rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.table-header {
  margin-left: 3.25rem;
}

.header-checkBox {
  margin-left: -0.6rem;
}
