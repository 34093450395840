@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.modalContent {
  gap: 0.4rem;
  margin-top: 16px;
  display: $display__flex;
  align-items: $align_items__center;
}

.cancelBtn {
  width: 5.44rem;
  height: 2.19rem;
  color: #7d7e82;
  border-radius: 0.43rem !important;
  border: 0.086rem solid #7d7e82 !important;
  background: $white;
  @include update_fonts($font_weight__500, $font_size__20);
}

.cancelBtn:hover {
  border-radius: 0.43rem;
  color: #7d7e82;
  border: 0.086rem solid #7d7e82;
  background: $white;
}

.tooltipNote {
  margin-top: 0.19rem;
}

.tooltipLabel {
  @include update_fonts($font_weight__700, $font_size__14);
}

.saveBtn {
  width: 5.44rem;
  height: 2.25rem;
  padding: 0px 0.945rem;
  border-radius: 0.43rem;
  color: $white;
  background: $blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.saveBtn:hover {
  color: $white;
  background: $blue;
}

.saveBtn[disabled],
.saveBtn[disabled]:hover {
  background: #e6e6e6;
  color: $disableText;
}

.modalDetails {
  gap: 1.1rem;
  flex-direction: column;
  display: $display__flex;
}

.modalText {
  @include update_fonts($font_weight__400, $font_size__16);
}

.modalLabel {
  @include update_fonts($font_weight__500, $font_size__16);
}

.modalFooter {
  display: $display__flex;
  justify-content: $justify_content__end;
}

.otherDetails {
  color: gray;
  margin-top: 0.6rem;
  @include update_fonts($font_weight__400, $font_size__14);
}

.defaultButton {
  width: 4.25rem;
  height: 2.19rem;
  color: #7d7e82;
  border-radius: 0.43rem !important;
  border: 0.086rem solid #7d7e82 !important;
  background: $white;
  @include update_fonts($font_weight__500, $font_size__20);
}

.defaultButton:hover {
  border-radius: 0.43rem;
  color: #7d7e82;
  border: 0.086rem solid #7d7e82;
  background: $white;
}
