@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.edit-Test-Data-Header {
  top: 3.37rem;
  background: $white;
  justify-content: $justify_content__space_between;
}

.edit-Test-Data-Col {
  gap: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.back-button {
  margin-right: 0.93rem;
  cursor: $cursor__pointer;
}

.test-data-set-profile-name {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__400, $font_size__16);
}

.test-data-set-title {
  width: 10.68rem;
  @include update_fonts($font_weight__600, $font_size__16);
}

.test-data-set-name {
  margin-left: -4.44rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.saveResetButton {
  margin-right: 0.9rem;
}

.device-test-data-set-list {
  margin-top: 1.25rem;
  display: $display__flex;
  align-items: $align_items__center;
}