@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.table-details {
  margin-left: 2.5rem !important;
}

.permission_Icons_And_Name {
  margin-left: 2.81rem;
}

.icon-and-details {
  margin-left: -0.19rem;
  display: $display__flex;
}

.permission-tooltip {
  margin-right: 0.3rem;
}

.step-name {
  max-width: 14.2rem;
  margin-bottom: 0 !important;
  color: #000000b8;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__600, $font_size__15);
}

.label {
  color: #6d6d6d;
  @include update_fonts($font-weight__400, $font_size__14);
}

.label-name {
  @include update_fonts($font-weight__400, $font_size__14);
}

.test_Plan_Name_Label {
  gap: 0.06rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.test_Plan_Name {
  max-width: 10.75rem;
  margin-bottom: 0 !important;
  color: #6e7282;
  @include update_fonts($font-weight__400, $font_size__14);
}

.build_To_Be_Used_Text {
  color: #4a9bf2;
  cursor: $cursor__pointer;
}

.run_Result_Details {
  margin-top: 0.3rem;
}
