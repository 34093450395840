@import "../../../../styles/variables.scss";

.hoverActionIcons {
  gap: 1.5rem;
  justify-content: center;
  display: $display__flex;
  align-items: $align_items__center;
}

.borderRight {
  height: 2.2rem;
  border-right: 0.0625rem solid rgba(0, 0, 0, 0.09);
}
