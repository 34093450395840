@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.header {
  padding: 0.6rem 0.69rem 0.6rem 1.1rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
  align-items: $align_items__center;
}

.header_Title {
  flex: 1;
  padding: 0.625rem 0.625rem 0.625rem 0;
  display: $display__flex;
}

.action_Button_Hide {
  width: 100%;
}

.text_Input {
  border-radius: 0.4rem;
  border: 0.06rem solid $Gray;
  padding: 0.9375rem;
  width: 100%;
  height: 2.75rem;
  background: $white;
  display: $display__flex;
  align-items: $align_items__center;
  color: $lightGray !important;
  @include update_fonts($font_weight__400);
}

.step_Text {
  white-space: nowrap;
  @include update_fonts($font_weight__600);
}

.input_Style {
  border: none !important;
  font-size: $font_size__16 !important;
  background: $Brilliance !important;
}

.select_Box_Delete_Icon {
  display: $display__flex;
  align-items: $align_items__center;
}

.selectRecTestStepCheckBox {
  gap: 0.625rem;
  min-width: 4.25rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.deleteInHeader {
  margin-left: -1rem;
  margin-right: 0.6rem;
}

.hidden_Tooltip {
  display: $display__none;
}

@media only screen and (max-width: $max_width__1199) {
  .header {
    margin-top: 0.9375rem;
  }
}
