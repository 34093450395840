@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.selectedString {
  margin-bottom: 0.6rem;
  padding: 0.6rem 0;
  border-bottom: 0.06rem solid #c5c8ca4d;
}

.ellipse {
  margin-right: 0.6rem;
  width: 16.25rem;
}

.showLength {
  margin-top: 0.6rem;
  color: #4a9bf2;
  font-weight: $font_weight__500;
  cursor: $cursor__pointer;
}

.showPopover {
  max-width: 31.25rem;
}

.deviceTitle {
  color: #26262a;
  @include update_fonts($font-weight__600, $font_size__16);
}

.tableComponent {
  margin-top: 1.25rem;
}

.pagination {
  margin-top: 0.6rem;
  float: right;
}

.showValues {
  flex-direction: column;
  align-items: flex-start;
  display: $display__flex;
}

.hideDrawer {
  display: $display__none;
}

.searchBoxOrFilter {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.attachText {
  color: $blueTextColor;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__500, $font_size__13);
}

.disableAttachText {
  color: $disableTextColor;
  cursor: $cursor__not_allowed;
}
