@import "../../../styles/variables.scss";

.submenu_Title {
  font-weight: 400;
  font-size: 0.8rem;
  color: $disableText !important;
  border-bottom: 0.06rem solid #e2e3e6;
  padding: 0.6rem 0 !important;
  pointer-events: none;
  width: 100%;
  margin: auto !important;
}

.projectTitle {
  margin-left: 0.9rem;
}
.active_Project {
  border: 0.06rem solid #6c6e8d;
  background: #f8f8f8;
  border-radius: 0.3rem;
}

.projectBreadcrumbs {
  padding: 0.5rem 0.8rem;
  margin: 0 0.9rem;
  width: 10rem;
  text-align: left;
  color: $white;
  font-size: $font_size__13;
}

.selectedProject {
  cursor: $cursor__pointer;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.project_select_title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 1rem;
  width: calc(100% - 0.3rem);
}

.project_List_Name {
  font-weight: 400;
  font-size: 0.9rem;
  color: #111548;
}

.projectCheckIcon {
  text-align: right;
}

.projectDivider {
  margin: 0;
  margin: auto;
  display: block;
  min-width: 0.06rem;
  width: calc(100% - 1.875rem);
}

@media (max-width: 576px) {
  .ProjectBreadcrumbs {
    max-width: 6rem;
    margin: 0 0.6rem;
  }
}
