@font-face {
  font-family: "Causten Extra Light Oblique";
  src: url("../Fonts/Causten-ExtraLightOblique.eot");
  src: url("../Fonts/Causten-ExtraLightOblique.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-ExtraLightOblique.woff2") format("woff2"),
    url("../Fonts/Causten-ExtraLightOblique.woff") format("woff"),
    url("../Fonts/Causten-ExtraLightOblique.ttf") format("truetype"),
    url("../Fonts/Causten-ExtraLightOblique.svg#Causten-ExtraLightOblique") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten Light Oblique";
  src: url("../Fonts/Causten-LightOblique.eot");
  src: url("../Fonts/Causten-LightOblique.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-LightOblique.woff2") format("woff2"), url("../Fonts/Causten-LightOblique.woff") format("woff"),
    url("../Fonts/Causten-LightOblique.ttf") format("truetype"),
    url("../Fonts/Causten-LightOblique.svg#Causten-LightOblique") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten";
  src: url("../Fonts/Causten-Medium.eot");
  src: url("../Fonts/Causten-Medium.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-Medium.woff2") format("woff2"), url("../Fonts/Causten-Medium.woff") format("woff"),
    url("../Fonts/Causten-Medium.ttf") format("truetype"),
    url("../Fonts/Causten-Medium.svg#Causten-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten Black Oblique";
  src: url("../Fonts/Causten-BlackOblique.eot");
  src: url("../Fonts/Causten-BlackOblique.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-BlackOblique.woff2") format("woff2"), url("../Fonts/Causten-BlackOblique.woff") format("woff"),
    url("../Fonts/Causten-BlackOblique.ttf") format("truetype"),
    url("../Fonts/Causten-BlackOblique.svg#Causten-BlackOblique") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten Oblique";
  src: url("../Fonts/Causten-RegularOblique.eot");
  src: url("../Fonts/Causten-RegularOblique.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-RegularOblique.woff2") format("woff2"),
    url("../Fonts/Causten-RegularOblique.woff") format("woff"),
    url("../Fonts/Causten-RegularOblique.ttf") format("truetype"),
    url("../Fonts/Causten-RegularOblique.svg#Causten-RegularOblique") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten Extra";
  src: url("../Fonts/Causten-ExtraBold.eot");
  src: url("../Fonts/Causten-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-ExtraBold.woff2") format("woff2"), url("../Fonts/Causten-ExtraBold.woff") format("woff"),
    url("../Fonts/Causten-ExtraBold.ttf") format("truetype"),
    url("../Fonts/Causten-ExtraBold.svg#Causten-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten";
  src: url("../Fonts/Causten-Black.eot");
  src: url("../Fonts/Causten-Black.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-Black.woff2") format("woff2"), url("../Fonts/Causten-Black.woff") format("woff"),
    url("../Fonts/Causten-Black.ttf") format("truetype"), url("../Fonts/Causten-Black.svg#Causten-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten";
  src: url("../Fonts/Causten-Bold.eot");
  src: url("../Fonts/Causten-Bold.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-Bold.woff2") format("woff2"), url("../Fonts/Causten-Bold.woff") format("woff"),
    url("../Fonts/Causten-Bold.ttf") format("truetype"), url("../Fonts/Causten-Bold.svg#Causten-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten Bold Oblique";
  src: url("../Fonts/Causten-BoldOblique.eot");
  src: url("../Fonts/Causten-BoldOblique.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-BoldOblique.woff2") format("woff2"), url("../Fonts/Causten-BoldOblique.woff") format("woff"),
    url("../Fonts/Causten-BoldOblique.ttf") format("truetype"),
    url("../Fonts/Causten-BoldOblique.svg#Causten-BoldOblique") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten Medium Oblique";
  src: url("../Fonts/Causten-MediumOblique.eot");
  src: url("../Fonts/Causten-MediumOblique.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-MediumOblique.woff2") format("woff2"),
    url("../Fonts/Causten-MediumOblique.woff") format("woff"),
    url("../Fonts/Causten-MediumOblique.ttf") format("truetype"),
    url("../Fonts/Causten-MediumOblique.svg#Causten-MediumOblique") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten";
  src: url("../Fonts/Causten-Regular.eot");
  src: url("../Fonts/Causten-Regular.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-Regular.woff2") format("woff2"), url("../Fonts/Causten-Regular.woff") format("woff"),
    url("../Fonts/Causten-Regular.ttf") format("truetype"),
    url("../Fonts/Causten-Regular.svg#Causten-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten Extra";
  src: url("../Fonts/Causten-ExtraLight.eot");
  src: url("../Fonts/Causten-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-ExtraLight.woff2") format("woff2"), url("../Fonts/Causten-ExtraLight.woff") format("woff"),
    url("../Fonts/Causten-ExtraLight.ttf") format("truetype"),
    url("../Fonts/Causten-ExtraLight.svg#Causten-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten Extra Bold Oblique";
  src: url("../Fonts/Causten-ExtraBoldOblique.eot");
  src: url("../Fonts/Causten-ExtraBoldOblique.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-ExtraBoldOblique.woff2") format("woff2"),
    url("../Fonts/Causten-ExtraBoldOblique.woff") format("woff"),
    url("../Fonts/Causten-ExtraBoldOblique.ttf") format("truetype"),
    url("../Fonts/Causten-ExtraBoldOblique.svg#Causten-ExtraBoldOblique") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten";
  src: url("../Fonts/Causten-Light.eot");
  src: url("../Fonts/Causten-Light.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-Light.woff2") format("woff2"), url("../Fonts/Causten-Light.woff") format("woff"),
    url("../Fonts/Causten-Light.ttf") format("truetype"), url("../Fonts/Causten-Light.svg#Causten-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten Semi";
  src: url("../Fonts/Causten-SemiBold.eot");
  src: url("../Fonts/Causten-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-SemiBold.woff2") format("woff2"), url("../Fonts/Causten-SemiBold.woff") format("woff"),
    url("../Fonts/Causten-SemiBold.ttf") format("truetype"),
    url("../Fonts/Causten-SemiBold.svg#Causten-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten";
  src: url("../Fonts/Causten-Thin.eot");
  src: url("../Fonts/Causten-Thin.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-Thin.woff2") format("woff2"), url("../Fonts/Causten-Thin.woff") format("woff"),
    url("../Fonts/Causten-Thin.ttf") format("truetype"), url("../Fonts/Causten-Thin.svg#Causten-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten Thin Oblique";
  src: url("../Fonts/Causten-ThinOblique.eot");
  src: url("../Fonts/Causten-ThinOblique.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-ThinOblique.woff2") format("woff2"), url("../Fonts/Causten-ThinOblique.woff") format("woff"),
    url("../Fonts/Causten-ThinOblique.ttf") format("truetype"),
    url("../Fonts/Causten-ThinOblique.svg#Causten-ThinOblique") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Causten Semi Bold Oblique";
  src: url("../Fonts/Causten-SemiBoldOblique.eot");
  src: url("../Fonts/Causten-SemiBoldOblique.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Causten-SemiBoldOblique.woff2") format("woff2"),
    url("../Fonts/Causten-SemiBoldOblique.woff") format("woff"),
    url("../Fonts/Causten-SemiBoldOblique.ttf") format("truetype"),
    url("../Fonts/Causten-SemiBoldOblique.svg#Causten-SemiBoldOblique") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
