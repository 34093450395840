@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.moreActions {
  @include onHoverActionIcon;
}

.moreActions > span {
  @include onHoverActionsText;
  @include update_fonts($font_weight__500, 1.0625rem);
}

.menuItems {
  gap: 1.0625rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.menuItemsTitle {
  color: $textGray;
}

.moreActionsDropdown {
  overflow: hidden;
  border-radius: 1.375rem;
  box-shadow: 0rem 0.0625rem 0.375rem 0rem rgba(0, 0, 0, 0.3);
  background: $white;
}

.disableAction {
  gap: 3.63rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.borderBottom {
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.07);
}

.disableSwitch .ant-switch-checked {
  background: red !important;
}
