@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.header {
  color: #8c8c8c;
  box-sizing: border-box;
  -webkit-transition: top 0.2s;
  transition: top 0.2s;
  font-size: 1.3em !important;
  height: 3.5rem;
  background: linear-gradient(171deg, #050b20 0%, #394694 100%);
  border-bottom: 0.06rem solid #c3c5d2;
  width: 100%;
  position: fixed;
  z-index: 10;
  padding: 0 1.25rem;
  top: 0rem;
  left: 0rem;
  text-align: $text_align__center;
}

.header_Title {
  color: $white;
  @include update_fonts($font_weight__700, $font-size__24);
}

.back_Icon {
  position: relative;
  width: 3.44rem;
  right: -1.25rem;
  height: 3.44rem;
  padding-top: 0.81rem;
  border-left: 0.06rem solid #ddd;
  text-align: $text_align__center;
}

.border_Right {
  height: 2.375rem;
  margin: 0 1rem;
  border-right: 0.06rem solid #c3c5d2;
}

.sub_Header_Text {
  margin-left: 1.25rem;
  color: #002858;
  @include update_fonts($font_weight__600, $font-size__20);
}

.header_Buttons {
  margin-left: 1.25rem;
}

.divider {
  margin: 0.9rem 0;
}
