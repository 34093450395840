@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.blueFontColor {
  color: #111548;
  cursor: $cursor__pointer;
}

.deviceListPopover {
  margin-left: 0.3rem;
  color: #4a9bf2;
  cursor: $cursor__pointer;
  font-weight: $font_weight__500;
}

.marginRight {
  margin-right: 0.3rem;
}

.buildTOBeUsedText {
  color: #4a9bf2;
  cursor: $cursor__pointer;
}

.noDeviceText {
  color: #4a9bf2;
  font-weight: $font_weight__500;
}

.headerOfTestCoverage {
  font-weight: bold;
  color: #3d3d49;
  margin-top: 0.01875rem;
  font-size: $font_size__14;
  font-family: $font_family__Causten;
}

.actionIcon {
  padding: 0.078125rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.icons {
  padding-right: 0.6rem;
  cursor: $cursor__pointer;
}

.spinner_Center {
  min-height: 90vh;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.innerHeader {
  background-color: $white;
  display: $display__flex;
  justify-content: $justify_content__space_between;
  align-items: $align_items__center;
}

.mainHeaderText {
  padding: 0.8rem;
  margin-left: 0.6rem;
  color: #111548;
  font-weight: $font_weight__600;
  font-size: $font_size__20;
}

.selectAllBox {
  margin-top: 0.0375rem;
  margin-bottom: 0.0375rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.tableComponent {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  margin: 0.9375rem;
}

.pagination {
  float: right;
}

.label {
  color: #636467;
  font-weight: $font_weight__400;
  font-size: $font_size__12;
}

.deleteIcon {
  padding-right: 0.6rem;
  cursor: $cursor__pointer;
}

.abortIcon {
  padding-left: 0.6rem;
  font-size: 1.8rem;
  vertical-align: middle;
  cursor: $cursor__pointer;
}

.checkBox {
  margin-right: 0.6rem;
}

.inProgressResult {
  margin-left: 1.88rem;
}

.runResultDetails {
  margin-top: 0.3rem;
  margin-left: 0.3rem;
}

.searchBox {
  margin: 0.9rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.datePickerBox {
  display: $display__flex;
  align-items: $align_items__center;
}

.selectBoxInReport {
  margin-bottom: 0.6rem;
  float: right;
}

.selectBoxInFooter {
  margin-top: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.pullRight {
  float: right;
}

.paginationInExecutionReport {
  margin-top: -0.88rem;
}

//new
.select--all-box {
  margin-left: 0.4rem;
  margin-bottom: 0.625rem;
  padding-top: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.header-checkBox {
  margin-left: -0.6rem;
}

.checkBox-in-header {
  margin-left: 0.9rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.test_Reports__Buttons {
  margin-left: 0.6rem;
  cursor: $cursor__pointer;
}
