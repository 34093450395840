@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.table-details {
  margin-left: 2.5rem !important;
}

.permission_Icons_And_Name {
  margin-left: 0.56rem;
}

.icon-and-details {
  margin-left: -0.19rem;
  display: $display__flex;
}

.permission-tooltip {
  margin-right: 0.3rem;
}

.step-name {
  max-width: 17.17rem;
  margin-top: -0.06rem;
  margin-bottom: 0 !important;
  color: #000000b8;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__600, $font_size__15);
}

.label {
  color: #6d6d6d;
  @include update_fonts($font-weight__400, $font_size__14);
}

.label-name {
  @include update_fonts($font-weight__400, $font_size__14);
}

.step-And-Test-Plan-Details {
  @include update_fonts($font-weight__500, $font_size__14);
}

.job_Name {
  margin-left: 0.13rem;
  max-width: 13.3rem;
  margin-bottom: 0 !important;
  color: #6e7282;
}

.jobsInPopover {
  color: #4a4c69;
  font-weight: $font_weight__500;
  font-size: $font_size__14;
}

.jobIdOrName {
  color: #4a4c69;
  font-weight: $font_weight__500;
  font-size: $font_size__13;
}

.jobId {
  color: #6e7282;
}

.popover-details {
  color: $Dark__Blue;
  cursor: $cursor__pointer;
}

.border-bottom {
  border-bottom: 0.06rem solid $darkGray;
}

.popover-contents {
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
}

.marginRight {
  margin-right: 0.6rem;
}

.radioButtons {
  height: 1.06rem;
  width: 1.13rem;
  vertical-align: middle;
  cursor: $cursor__pointer;
}

.action-icon {
  display: $display__flex;
  justify-content: $justify_content__end;
}

.test--Plan-Action__Buttons {
  margin-left: 0.6rem;
  cursor: $cursor__pointer;
}

.black_Color {
  color: $textBlack;
}

.popover_Name {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.test_Plan_Button,
.test_Plan_Button:hover {
  background-color: rgba(55, 114, 255, 0.2) !important;
  height: 2.4rem !important;
  border-radius: 0.6rem !important;
  padding: 0.25rem 0.81rem !important;
  span {
    color: $Dark__Blue !important;
    @include update_fonts($font-weight__500, $font_size__16);
  }
  &[disabled],
  &:disabled {
    background-color: #e8e8e8 !important;
    span {
      color: $disableText !important;
    }
  }
}

.last-Job-Execution-Text {
  color: #4a9bf2;
  cursor: $cursor__pointer;
}

@media (max-width: 86.5rem), (max-width: 61.11rem) {
  .test_Plan_Info_Details {
    max-width: 12.5rem;
  }
}

@media (max-width: 74.94rem) {
  .test_Plan_Info_Details {
    max-width: 100%;
  }
}

@media screen and (max-width: 85.38rem) {
  .job_Name {
    max-width: 12rem;
  }
}

@media screen and (max-width: 90rem) {
  .job_Name {
    max-width: 11rem;
  }
}

@media screen and (max-width: 80rem) {
  .job_Name {
    max-width: 9rem;
  }
}
