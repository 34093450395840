@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.headerTitle {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #111548;
}

.headerTitle {
  margin-left: 0.6rem;
  color: $textBlack;
  text-align: center;
  @include update_fonts($font_weight__600, $font_size__16);
}

.headerOptions {
  max-width: 43.75rem;
  overflow: auto;
  padding: 0.625rem 0;
  gap: 0.625rem;
  margin-right: 0.625rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.jsonViewer {
  border: 0.06rem solid lightgray;
  border-radius: 1rem;
  min-height: 30rem;
  overflow-y: scroll;
  margin-top: 1rem;
  padding: 1rem;
}

.generateActionButtons {
  margin-top: 1rem;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.instructionEditor {
  min-height: 25rem !important;
}

.AI_Generate_Header_Details {
  display: $display__flex;
  text-align: $text_align__center;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
  @include update_fonts($font_weight__500, $font-size__16);
}

.close_Icon {
  width: 3.44rem;
  height: 2.55rem;
  display: $display__flex;
  text-align: $text_align__center;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.AI_Generate_Header {
  margin: 0rem !important;
  border: 0.06rem solid #dfdfdf;
  overflow: hidden;
  border-radius: 0.875rem !important;
  flex: 1 1;
  margin-top: 0.3rem;
  border-radius: 0.88rem !important;
  border-style: solid;
  border-width: 0.19rem;
  min-width: 31.25rem;
  box-shadow: 0 0.25rem 0.38rem rgba(0, 0, 0, 0.1);
  position: relative;
  border-image-source: radial-gradient(circle at 0% 5%, #1ab0f3, #e2c264 71%, #b82dd0 100%);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), radial-gradient(circle at 0% 6%, #19b0f3, #e2c262 46%, #b82ccf 100%), radial-gradient(circle at 0% 5%, #1ab0f3, #e2c264 71%, #b82dd0 100%);
  background-origin: border-box;
  background-clip: content-box, content-box, border-box;
  background-color: $white;
  align-items: $align_items__center !important;
}

.AI_Generate_Container {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.ai_objective_select {
  width: 100%;
  max-width: 12rem;
  margin: 0.5rem 0;
  border-radius: 0.3rem;
  border: 0.06rem solid #d6d6d6;
  background: $white;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
  }
}

.hidden_Tooltip {
  display: $display__none;
}

.stop_Generate_Step {
  gap: 0.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.start_Or_Stop_Btn,
.start_Or_Stop_Btn:hover {
  background-color: #444aea !important;
  height: 2.31rem !important;
  span {
    color: $white !important;
  }
  &[disabled],
  &:disabled {
    background-color: #e8e8e8 !important;
    span {
      color: $disableText !important;
    }
  }
}

.inspector_Button,
.inspector_Button:hover {
  background-color: #d1dfff !important;
  height: 2.31rem !important;
  span {
    color: $Dark__Blue !important;
  }
  &[disabled],
  &:disabled {
    background-color: #e8e8e8 !important;
    span {
      color: $disableText !important;
    }
  }
}

.label {
  @include update_fonts($font_weight__400, $font_size__16);
}


.padding {
  padding: 0.8rem;
}

@media (max-width: 1200px) {
  .ai_objective_select {
    max-width: 50%;
  }
}


.auto_Generate_Test_Sequence {
  margin-top: 0.13rem;
  color: #4a4d53;
  text-align: $text_align__center;
  @include update_fonts($font_weight__600, $font_size__16);
}

.AI_Objective_Input {
  height: 2.31rem !important;
  border-radius: 0.63rem;
  border: 0.06rem solid #d7d7d7 !important;
  font-size: $font_size__16 !important;
  @include update_fonts($font_weight__400, $font_size__16);
}

.input_Or_CheckBox {
  gap: 0.94rem;
  margin-top: 0.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.generate_Button {
  background-color: $Dark__Blue !important;
  height: 2.31rem !important;
  border-radius: 0.63rem !important;
  padding: 0.25rem 0.81rem;
  span {
    color: $white !important;
  }
  &[disabled],
  &:disabled {
    background-color: #e8e8e8 !important;
    span {
      color: $disableText !important;
    }
  }
}

.inspector_Button,
.inspector_Button:hover {
  background-color: #d1dfff !important;
  height: 2.31rem !important;
  span {
    color: $Dark__Blue !important;
  }
  &[disabled],
  &:disabled {
    background-color: #e8e8e8 !important;
    span {
      color: $disableText !important;
    }
  }
}

.radio_Or_Checkbox__Text {
  color: #344054;
  @include update_fonts($font-weight__500, $font_size__16);
}

.stop_Generate_Step {
  gap: 0.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}
