@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.main_Header {
  top: 3.38rem;
  padding: 0rem 0rem 0rem 1.3rem;
  background-color: #f2f4f7;
  height: 4.13rem;
  border-bottom: 0.06rem solid #ccc;
}

.main-Header-Text {
  color: #002858;
  @include update_fonts($font_weight__700, $font_size__20);
}
.close_Icon {
  position: relative;
  width: 3.44rem;
  height: 2.55rem;
  border-left: 0.06rem solid black;
  display: $display__flex;
  text-align: $text_align__center;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.page_Scroll {
  height: calc(100vh - 8.63rem);
  overflow-y: scroll;
  display: $display__flex;
}