@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.drawer {
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.drawerTitle {
  color: #111548;
  font-weight: $font_weight__600;
}

.elementProperties {
  border-bottom: 0.06rem solid lightgray;
  padding: 0.3rem;
  color: #00000073;
}

.elementPropertiesKey {
  font-weight: $font_weight__600 !important;
}

.propertiesValue {
  color: #3d3d49;
  @include update_fonts($font_weight__500, $font_size__16);
}

.elementDetails {
  margin-top: 0.3rem;
  display: $display__flex;
}

.styledDiv {
  color: #636467;
  word-break: break-all;
  margin-left: 0.13rem;
  @include update_fonts($font_weight__400, $font_size__16);
}

.elementLocators {
  padding: 0.9rem;
  gap: 0.6rem;
  border: 0.06rem solid lightgrey;
}
