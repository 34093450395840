@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.popover_Title {
  text-transform: capitalize;
  color: $textGray;
  @include update_fonts($font_weight__700, $font_size__18);
}

.title_And_Close_Icon {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.element_Title {
  color: #333;
  padding: 0.75rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  color: $white;
  @include update_fonts($font_weight__700, $font_size__18);
}

.element_List {
  gap: 0.75rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.element_Details_Column {
  flex: 1;
  border: 0.06rem solid #ccc;
  border-radius: 0.5rem;
}

.clickable-item {
  margin: 0.6rem 0;
  padding: 0.6rem;
  box-shadow: 0.25rem 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.05);
  border: 0.06rem solid $borderColor;
  border-radius: 0.88rem;
  cursor: $cursor__pointer;
  background-color: $white;
}

.clickable-item-selected {
  margin-top: 0.6rem;
  padding: 0.6rem;
  border-radius: 0.88rem;
  box-shadow: 0.25rem 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.05);
  margin-top: 0.6rem;
  background-color: #d0e7ff;
  cursor: $cursor__pointer;
  border: 0.06rem solid $Dark__Blue;
}

.no-data {
  color: $textBlack;
  @include update_fonts($font_weight__600, $font_size__16);
}

.element_Details {
  margin-top: 0.6rem 0;
  padding: 0 0.75rem;
  height: calc(100vh - 43.5rem);
  overflow-y: scroll;
}

.select_Element_Text {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__15);
}

.index_And_Text {
  text-transform: uppercase;
  gap: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.text {
  margin-top: -0.3em;
}

.element_Not_Selected_Text {
  color: $textColor !important;
}

.non_Clickable_Title {
  background-color: #424242;
}

.clickable_Title {
  background-color: $Dark__Blue;
}

.empty_Data {
  flex-direction: column;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}