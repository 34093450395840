@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.modalTitleDiv {
  gap: 0.6rem;
  flex-wrap: $flex__wrap;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.modalTitle {
  text-transform: uppercase;
  color: $gray;
  @include update_fonts($font_weight__700, 1.06rem);
}

.modalTitleDivHeader {
  gap: 0.6rem;
  flex-wrap: $flex__wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.importTitleDiv {
  width: 29rem;
  height: 2.5rem;
  border-radius: 0.5625rem;
  background: rgb(240 240 241);
  margin-left: 1rem;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
}

.title {
  width: 9.56rem;
}

.text {
  height: 2.38rem;
  color: $grayColor;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__600, $font-size__14);
}

.selectedStepType {
  border-radius: 0.56rem;
  box-shadow: 0 0 0.4375rem 0 rgba(0, 0, 0, 0.23);
  color: $white;
  background: $darkBlue;
}

.searchBox {
  width: 23.75rem;
  border-radius: 0.56rem;
  height: 2.5rem;
  border: 0.06rem solid $silver;
}

.importBtn {
  height: 2.25rem;
  padding: 0px 0.945rem;
  border-radius: 0.43rem;
  color: $white;
  background: $blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.importBtn:hover {
  color: $white;
  background: $blue;
}

.importBtn[disabled],
.importBtn[disabled]:hover {
  background: #e6e6e6;
  color: $disableText;
}

.cancelBtn {
  width: 5.44rem;
  height: 2.19rem;
  color: #7d7e82;
  border-radius: 0.43rem !important;
  border: 0.086rem solid #7d7e82 !important;
  background: $white;
  @include update_fonts($font_weight__500, $font_size__20);
}

.cancelBtn:hover {
  border-radius: 0.43rem;
  color: #7d7e82;
  border: 0.086rem solid #7d7e82;
  background: $white;
}

.modalCloseIcon {
  margin-top: 1.75rem;
  margin-left: 0.6rem;
}

.width {
  width: 100% !important;
}

.modalDetails {
  margin-top: -0.88rem;
  width: 100% !important;
}

.modalFooter {
  margin-right: 1.125rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.footerButtons {
  justify-content: $justify_content__space_between;
}

.importHeader {
  width: 19.13rem !important;
  margin-left: 0.6rem;
}

.spinning_center {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  min-height: 43vh;
}

.searchBoxDiv {
  margin-right: 1.25rem;
}

.emptyRecords {
  height: 40vh;
}
