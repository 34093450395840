@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.uploadBuildInModal {
  border: 0.06rem solid rgb(176, 177, 193) !important;
  width: 100%;
  height: 2.25rem;
}

.detailsInPushBuildModal {
  padding-bottom: 0.3rem;
  padding-top: 0.6rem;
  font-size: $font_size__14;
}
