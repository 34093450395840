@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.subHeaderIcons {
  @include design_Actions_icon;
}

.textButton {
  font-family: "Inter";
  font-style: normal;
  cursor: $cursor__pointer;
  font-size: $font_size__12;
  font-weight: $font_weight__500;
}

.disableTextButtonText {
  cursor: $cursor__not_allowed;
  color: $disableTextColor;
}

.textButtonText {
  color: $blueTextColor;
}

.actionButtons {
  border-radius: 0.3125rem;
  height: 2rem;
  padding: 0rem 0.6875rem;
  gap: 0.4375rem;
  border: 0.06rem solid $blue;
  color: $blue;
  background: $white;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.disableButton {
  background-color: $Gray;
  color: $disableText;
  cursor: $cursor__not_allowed;
  border: 0.06rem solid $Gray;
}

.titleText {
  line-height: 0;
  margin: auto 0;
  @include update_fonts($font_weight__500, $font_size__15);
}

@media only screen and (max-width: $max_width__1562) {
  .subHeaderIcons > span {
    display: $display__none;
  }
  .titleText {
    display: $display__none;
  }
}

@media (min-width: $min_width__1562) {
  .tooltipVisible {
    display: none;
  }
}
