.toggle {
  background: #ffffff;
  border-radius: 3px;
  padding: 0px 7px;
  cursor: pointer;
}

.activeLabel {
  font-weight: 500;
  font-size: 13px;
  color: #4a4c69;
}

.inActiveLabel {
  font-weight: 500;
  font-size: 13px;
  color: #6e7282;
}
