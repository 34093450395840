@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.empty_Records {
  flex-direction: column;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.description {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__18);
}
