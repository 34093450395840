@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.custom_Input_Wrapper {
  position: relative;
}

.custom_Input_Label {
  top: -0.6rem;
  position: absolute;
  left: 0.9rem;
  padding: 0 0.5em;
  z-index: 1;
  color: #84818a;
  background-image: linear-gradient(to top, #f0f4ff 52%, #f2f4f7 75%);
  @include update_fonts($font-weight__400, $font_size__16);
}

.input_Label_Modal {
  background-image: linear-gradient(to top, #f0f4ff 52%, $white 75%) !important;
}

.upload_Label {
  color: #84818a;
  @include update_fonts($font-weight__400, $font_size__16);
}

.upload_Box {
  margin: 0.6rem 0 0.9rem;
  border-radius: 0.6rem;
  padding: 1rem;
  gap: 0.6rem;
  position: relative;
  flex-direction: column;
  border: 0.06rem dashed $Dark__Blue;
  background-color: #eff4ff;
  cursor: $cursor__pointer;
  display: $display__flex;
  text-align: $text_align__center;
}

.upload_Button {
  background-color: #4d79ff !important;
  border: none !important;
  border-radius: 0.3rem !important;
  padding: 0.6rem 1.25rem !important;
  width: 10.63rem !important;
  height: 2.31rem;
  color: $white !important;
  cursor: $cursor__pointer !important;
  font-size: $font_size__14 !important;
  display: $display__flex;
  align-items: $align_items__center;
}

.upload_Button_Detail {
  display: $display__flex;
  text-align: $text_align__center;
  justify-content: $justify_content__center;
}

.drag_Over_Text {
  height: 7.63rem;
  justify-content: $justify_content__center;
}

.drag_And_Drop_Text {
  color: $textBlack;
  text-align: $text_align__center;
  @include update_fonts($font-weight__400, $font_size__16);
}

.OR_Label {
  color: #8a8a8a;
  text-align: $text_align__center;
  @include update_fonts($font-weight__400, $font_size__13);
}

.field_Required {
  color: red;
  font-size: 0.9rem;
  margin-left: 0.19rem;
}
