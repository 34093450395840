@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.deleteModalNote {
  margin-top: 20px;
  font-size: $font_size__12;
  color: #ff0000;
}

.modalFooter {
  display: $display__flex;
  justify-content: $justify_content__end;
}

.grayBtn,
.grayBtn:hover {
  background: #efefef;
  color: #26262a;
}

.testDataDetails {
  align-items: baseline;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.scopeDetails {
  display: $display__flex;
  align-items: $align_items__center;
}

.label {
  margin-right: 0.3rem;
  @include update_fonts($font_weight__500, $font-size__12);
}
