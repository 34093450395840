@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.Search_Box_And_Filter {
  padding: 1.3rem 1.5rem 0rem 1.5rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.buildSearchDevice {
  padding-left: 0.6rem;
  margin-top: 0.6rem;
}

.searchDeviceForDevicePools {
  margin-right: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__end;
}

.searchBox {
  margin-top: 0.5rem;
  padding: 0rem 2rem;
}

.borderRight {
  width: 0.0625rem;
  height: 3.0625rem;
  background: #ececec;
}

.filterText {
  color: $textGray;
  @include update_fonts($font_weight__700, $font_size__16);
}

.filterIcon {
  padding: 0 0.75rem;
  gap: 0.44rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.pageScroll {
  height: calc(100vh - 8.63rem);
  overflow-y: scroll;
}

.deviceListCardsDetails {
  display: $display__flex;
  flex-wrap: $flex__wrap;
}

.deviceList {
  gap: 1.44rem;
  margin-top: 1.44rem;
  justify-content: flex-start;
  display: $display__flex;
}

.deviceCardOrDevice {
  width: 13.8125rem;
  height: 16.5rem;
}

.deviceCard {
  margin-left: 1.25rem;
}

.deviceCardItems {
  width: 13.8125rem;
  margin: 0.6rem;
  font-size: 0.81rem;
  color: #4a4c69;
  border-radius: 1.125rem;
  background: #f5f5f5;
  box-shadow: 0 0 0.9rem 0 rgba(0, 0, 0, 0.25);
}

.use_Text_And_Icon {
  gap: 0.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.border {
  width: 0.0625rem;
  height: 1.3125rem;
  background: rgba(0, 0, 0, 0.12);
}

.usedDevice {
  background-color: $blue;
}

.useTextInCenter {
  justify-content: $justify_content__center;
}

.ellipse {
  width: 6.13rem;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  word-break: break-word;
  cursor: $cursor__pointer;
}

.textInCenter {
  margin-top: 0.19rem;
}

.deviceListInCenter {
  margin-left: 0.6rem;
  justify-content: flex-start;
  display: $display__flex;
}

@media only screen and (min-width: $min_width__1200) {
  .bg_deviceList {
    height: calc(100vh - 8.44rem);
  }
}

//New added
.header_Btn_And_SearchBox {
  gap: 1rem;
  width: 100%;
  padding: 1.56rem 1.88rem 0.6rem 1.88rem;
  flex-direction: column;
  display: $display__flex;
}

.device_List_Details {
  height: 100% !important;
  position: relative;
  flex-direction: column;
  border-right: 0.06rem solid #ececec;
  background-color: $white;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.bg_deviceList {
  padding: 0px 1.19rem 0.6rem 0.25rem;
}

.device-list {
  padding: 0 1.88rem;
  height: calc(100vh - 186px);
}

.device_Filter {
  padding-right: 0.19rem;
  gap: 0.38rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.device_Search_And_Filter {
  gap: 0.3rem;
  height: 3rem;
  border-radius: 0.6rem;
  border: 0.06rem solid #d6d6d6;
  background: $white;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.device_Search_Box {
  width: 100%;
  padding-left: 0.88rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.search_Device_Input {
  border: none !important;
  box-shadow: none !important;
  padding-left: 0.3rem;
  background-color: transparent !important;
  padding: 0 !important;
  .ant-input {
    background-color: transparent !important;
    @include update_fonts($font_weight__400, $font_size__16);
  }
  .ant-input-lg {
    height: 3rem;
  }
}

.add_Remove_Device_Btn {
  display: $display__flex;
  justify-content: $justify_content__center;
}

.device_List {
  margin-top: 4rem;
}

.use_Device_Text {
  color: $Dark__Blue;
  cursor: $cursor__pointer;
  @include update_fonts($font_weight__400, $font_size__14);
}

.empty-Records {
  flex-direction: column;
  height: 100%;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.device_Loader {
  height: 70vh;
  flex-direction: column;
  display: $display__flex;
  align-items: $align_items__center;
  text-align: $text_align__center;
  justify-content: $justify_content__center;
}

.advanced_Search_Btn {
  width: 9.25rem !important;
}

.search_And_Device_Icon {
  border-radius: 0.6rem;
  padding: 0.56rem;
  border: 0.13rem solid #ebebec;
  cursor: $cursor__pointer;
}

@media screen and (max-width: 90.75rem) and (min-width: 74.94rem) {
  .deviceList {
    justify-content: $justify_content__center;
  }
}

@media screen and (max-width: 106.25rem) {
  .bg_deviceList {
    padding: 0rem !important;
  }
  .deviceList {
    gap: 0rem !important;
    justify-content: $justify_content__center;
  }
}

@media screen and (max-width: 71.75rem) {
  .bg_deviceList {
    padding: 0rem !important;
  }
  .deviceList {
    gap: 0rem !important;
    justify-content: flex-start;
  }
}
