@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.main-container {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.main--header-text {
  margin-left: 0.625rem;
  padding: 0.75rem;
  color: #002858;
  @include update_fonts($font-weight__600, $font_size__20);
}

.header--or-button {
  margin-right: 1.25rem;
}

.margin_Top {
  margin-top: 0.4rem;
}
