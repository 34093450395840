@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.selected_device_name {
  color: $textGray;
  line-height: normal;
  letter-spacing: 0.01rem;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__700);
}

.device_screen_platform {
  color: $textBlack;
  letter-spacing: 0.01rem;
  @include update_fonts($font_weight__400);
}

.deviceConnectionName {
  color: $textBlack;
  padding-right: 0.3rem;
  @include update_fonts($font_weight__400);
}

.recording_device_recording_section {
  padding: 0.5rem 0rem 0.5rem 0.6rem;
  display: $display__flex;
  justify-content: center;
  align-items: center;
}

.deviceNameCenter {
  padding-right: 2.3rem;
}

.deviceName {
  margin: auto;
  text-align: center;
  border-radius: 0.75rem;
  border: 0.06rem solid #ececec;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.deviceViewButton {
  display: $display__flex;
  justify-content: $justify_content__end;
}
.deviceSection {
  background-color: $white;
}

.borderRight {
  width: 0.0625rem;
  height: 100%;
  background: #ececec;
}

.deviceTitle {
  padding-right: 0.6rem;
  white-space: nowrap;
  display: $display__flex;
}

.deviceNameOrIcon {
  padding: 0.6rem;
  justify-content: center;
  cursor: $cursor__pointer;
  flex-wrap: $flex__wrap;
  display: $display__flex;
}

.deviceIconOrConnectionName {
  display: flex;
  align-items: center;
}

.clockIcon {
  padding: 0.6rem;
}

.device {
  gap: 1.456rem;
  flex: 1;
  overflow: auto;
  display: $display__flex;
}

.closeSession {
  margin-left: 0.8rem;
  margin-bottom: 0.3rem;
  z-index: 2;
  position: relative;
}

.tooltipNote {
  margin-top: 0.19rem;
}

.tooltipLabel {
  @include update_fonts($font_weight__700, $font_size__14);
}

@media only screen and (min-width: $min_width__1200) {
  .deviceSection {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 3.5rem);
    background-color: $white;
  }
}

//New
.device_List {
  height: 100%;
  background: $white;
  border: 0.06rem solid #ececec;
}

.showDeviceViewButton {
  position: absolute;
  top: 50%;
  left: 96%;
  z-index: 1;
}

.device_Loader {
  height: 100%;
  flex-direction: column;
  display: $display__flex;
  align-items: $align_items__center;
  text-align: $text_align__center;
  justify-content: $justify_content__center;
}
