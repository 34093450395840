@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.table-details {
  margin-left: 2.5rem !important;
}

.permission_Icons_And_Name {
  margin-left: 0.56rem;
}

.icon-and-details {
  margin-left: -0.19rem;
  display: $display__flex;
}

.permission-tooltip {
  margin-right: 0.3rem;
}

.step-name {
  max-width: 18.2rem;
  margin-top: -0.06rem;
  margin-bottom: 0 !important;
  color: #000000b8;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__600, $font_size__15);
}

.label {
  color: #6d6d6d;
  @include update_fonts($font-weight__400, $font_size__14);
}

.label-name {
  @include update_fonts($font-weight__400, $font_size__14);
}

.build_Linked_Label {
  flex-wrap: wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.build_Link {
  margin-right: 0.3em;
  margin-left: 0.3em;
}

.link {
  @include update_fonts($font-weight__700, $font_size__14);
}

.action-icon {
  display: $display__flex;
  justify-content: $justify_content__end;
}

.app_Build_action__buttons {
  margin-left: 0.6rem;
  cursor: $cursor__pointer;
}

.app_Build__Buttons {
  margin-left: 0.6rem;
  cursor: $cursor__pointer;
}

.in_Progress_Loader{
  display: $display__flex;
  justify-content: $justify_content__center;
}