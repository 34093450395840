@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.selector {
  padding-right:0.9rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.selectionText {
  white-space: nowrap;
  color: $darkGrayColor;
  display: $display__flex;
  @include update_fonts($font_weight__700, $font_size__16);
}

.selectMode {
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid $grayBorder !important;
  width: 100%;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
}

@media screen and (min-width: 86.5rem) and (max-width: 119.93rem), (min-width: 61.11rem) and (max-width: 75rem) {
  .selector {
    display: block;
  }
}
