@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.footer {
  gap: 0.6rem;
  height: 2.44rem;
  left: 0;
  width: 100%;
  bottom: 0;
  padding: 0.75rem 1.25rem;
  text-align: left;
  flex-direction: row;
  position: absolute;
  color: $white;
  color: $textBlack;
  cursor: $cursor__pointer;
  display: $display__flex;
  text-align: $text_align__center;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__500, $font_size__15);

}
