@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.sub-header {
  padding: 0.9rem;
  margin-left: 0.38rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.common-search {
  padding: 1rem 1rem 0rem 1rem;
  display: $display__flex;
}

.select--all-box {
  margin-left: 0.4rem;
  margin-bottom: 0.625rem;
  padding-top: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.checkbox {
  margin-right: 0.6rem;
}

.border-bottom {
  border-bottom: 0.06rem solid $darkGray;
}

.popover-contents {
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
}

.data {
  margin-left: 0.13rem;
}

.popover-details {
  color: $Dark__Blue;
  cursor: $cursor__pointer;
}

.permission-tooltip {
  margin-right: 0.3rem;
}

.step-name {
  max-width: 18.20rem;
  margin-top: -0.06rem;
  margin-bottom: 0 !important;
  color: #000000b8;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__600, $font_size__15);
}

.rec--test-step__rights {
  align-items: baseline;
  display: $display__flex;
}

.checkbox {
  margin: 0.6rem;
}

.action-icon {
  display: $display__flex;
  justify-content: $justify_content__end;
}

.spinner-center {
  min-height: 90vh;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.label {
  color: #6d6d6d;
  @include update_fonts($font-weight__400, $font_size__14);
}

.test--scenarios-action__buttons {
  margin-left: 0.6rem;
  cursor: $cursor__pointer;
}

.label-name {
  @include update_fonts($font-weight__400, $font_size__14);
}

.step-And-Test-Plan-Details {
  @include update_fonts($font-weight__500, $font_size__14);
}

.header-checkBox {
  margin-left: -0.6rem;
}

.table-details {
  margin-left: 2.5rem !important;
}

.icon-and-details {
  margin-left: -0.19rem;
  display: $display__flex;
}

.checkBox-in-header {
  margin-left: 0.9rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.table-header {
  margin-left: 3.25rem;
}

.permission_Icons_And_Name {
  margin-left: 0.56rem;
}

.test_Plan_Name {
  max-width: 9.38rem;
  margin-bottom: 0 !important;
  margin-right: 0.6rem;
  @include update_fonts($font-weight__500, $font_size__14);
}

.popover_Name {
  display: $display__flex;
  align-items: $align_items__center;
}

.ai_Objective_With_Loader {
  padding: 0.5rem;
  margin: 0 0.5rem;
  background-color: $white;
}

.ai_objective {
  gap: 0.6rem;
  flex-wrap: wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.ai_objective_input_label {
  width: 100%;
  max-width: 14.38rem;
  line-height: 2rem;
  font-weight: 600;
}

.ai_objective_input {
  flex-grow: 1;
  max-width: 32rem;
  margin: 0.5rem 0;
}

.ai_objective_select {
  width: 100%;
  max-width: 12rem;
  border: 1px solid #dedede;
  margin: 0.5rem 0;
}

.ai_objective_button {
  width: 100%; 
  max-width: 5rem;
  margin: 0.5rem 0;
}


.start_Or_Stop_Btn,
.start_Or_Stop_Btn:hover {
  margin-left: 0.6rem;
  background-color: #444aea !important;
  height: 2.31rem !important;
  span {
    color: $white !important;
    @include update_fonts($font-weight__500, $font_size__16);
  }
  &[disabled],
  &:disabled {
    background-color: #e8e8e8 !important;
    span {
      color: $disableText !important;
    }
  }
}
// Responsive adjustments
@media (max-width: 1200px) {
  .ai_objective_input {
    max-width: 50%;
  }

  .ai_objective_select {
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  .ai_objective {
    padding: 0.25rem;
    gap: 0.3rem;
  }

  .ai_objective_input_label {
    line-height: 1.5rem;
    font-size: 1rem; 
  }

  .ai_objective_input,
  .ai_objective_select,
  .ai_objective_button {
    font-size: 0.875rem;
  }
}

@media (max-width: 86.5rem), (max-width: 61.11rem) {
  .test_Scenario_Info_Details {
    max-width: 12.5rem;
  }
}

@media (max-width: 74.94rem) {
  .test_Scenario_Info_Details {
    max-width: 100%;
  }
}
