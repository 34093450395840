@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.deleteModalNote {
  color: #ff0000;
  margin-top: 1.25rem;
  font-size: $font_size__12;
}

//Update UI:
.add_Test_Data_label {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.drawer_Title {
  color: #4a4d53;
  @include update_fonts($font_weight__700, $font_size__18);
}

.custom-input-wrapper {
  position: relative;
}

.custom-input-label {
  position: absolute;
  left: 0.9rem;
  padding: 0 0.5em;
  z-index: 1;
  color: #84818a;
  background-color: $white;
  @include update_fonts($font-weight__400, $font_size__16);
}

.modal-select-box {
  margin: 0.6rem 0 0.9rem;
  padding: 0.5rem 0px 0.3rem 0.4rem;
  border-radius: 0.8rem;
  border: 0.06rem solid #dcdbdd;
  width: 100%;
  color: #353535;
  background-color: $white;
}

.modal-input {
  padding: 1.38rem 0px 1.3rem 1.57rem !important;
  border-radius: 0.8rem !important;
  border: 0.06rem solid #dcdbdd !important;
  margin: 0.6rem 0 0.9rem;
  color: #000 !important;
  background-color: $white !important;
  font-size: $font_size__15 !important;
  @include update_fonts($font-weight__400, $font_size__15);
}

.modalFooter {
  display: $display__flex;
  justify-content: $justify_content__center;
}

.cancelBtn,
.cancelBtn:hover {
  padding: 0.25rem 1.88rem;
  border-radius: 0.6rem !important;
  border: 0.06rem solid #9a9a9a !important;
  height: 2.5rem;
  width: 7.5rem;
  color: $textBlack;
}

.cancelBtn > span {
  @include update_fonts($font-weight__500, $font_size__15);
}

.update_Or_Add_Btn,
.update_Or_Add_Btn:hover {
  padding: 0.25rem 1.88rem;
  border-radius: 0.6rem !important;
  height: 2.5rem;
  width: 7.5rem;
  color: $white;
  background-color: $Dark__Blue;
}

.update_Or_Add_Btn > span {
  @include update_fonts($font-weight__500, $font_size__15);
}

.update_Or_Add_Btn:disabled,
.update_Or_Add_Btn:disabled:hover {
  color: #6e7282 !important;
  cursor: $cursor__not_allowed;
}

.edit_Test_Data_Details {
  padding: 0.75rem;
  border-radius: 0.94rem;
  border: 0.06rem solid #cadaff;
  background-color: #fdfdff;
}
.edit_Test_Data_Key {
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.edit_Input_Box {
  width: 50%;
}

.test_Data_Text {
  gap: 0.3rem;
  color: $textBlack;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font-weight__400, $font_size__16);
}
.test_Data_Label {
  font-weight: $font-weight__700 !important;
}

.edit_Note {
  gap: 0.19rem;
  flex-direction: column;
}

.edit_Test_Data {
  margin-top: 1.25rem;
}

.no_Data_Text {
  padding: 3.13rem;
  color: $textBlack;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__600, $font_size__16);
}

.test_Data_Scroll {
  max-height: 16.06rem;
  overflow: auto;
  &::-webkit-scrollbar {
    display: $display__none;
  }
}
