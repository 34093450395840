@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.performActionsButton {
  flex-direction: row;
  margin-top: 0rem;
  gap: 1.25rem;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__end;
  padding: 0rem 0.9rem 0rem 0.9rem;
  @include update_fonts($font_weight__600, $font_size__14);
}