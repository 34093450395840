@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.button {
    margin-left: 0.6rem;;
    background: #ce6d42;
    color: $white;
  }
  
  .button:hover {
    margin-left: 0.0375rem;
    background: #ce6d42;
    color: $white;
  }
  
  .button[disabled] {
    background: #e6e6e6;
    color: #6e7282;
    margin-left: 0.0375rem;
  }

  .headerTitle {
    font-family: "Inter";
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #111548;
  }

.headerTitle {
  margin-left: 0.6rem;
  color: $textBlack;
  text-align: center;
  @include update_fonts($font_weight__600, $font_size__16);
}

.radio_Button_Text {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__16);
}

.radio_Input_Box {
  vertical-align: middle;
  margin-right: 0.3rem;
  height: 1.06rem;
  width: 1.5rem;
  cursor: $cursor__pointer;
}
