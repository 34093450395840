@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.localDeviceServer-header {
  margin-top: 4.5rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.localDeviceServer-title {
  margin-left: 1.5rem;
  color: $darkBlue;
  @include update_fonts($font-weight__700, $font_size__20);
}

.dropdown-container {
  margin-top: 5rem;
  flex-direction: column;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.dropdown {
  padding: 0.5rem;
  font-size: 1rem;
  width: 25.75rem !important;
}

.button-container {
  gap: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.cancel_Btn {
  padding: 1.25rem !important;
  background-color: #fbfcfc !important;
  border-radius: 0.75rem !important;
  border: 0.06rem solid #9a9a9a !important;
  display: $display__flex;
  align-items: $align_items__center;
  span {
    color: $textBlack;
    @include update_fonts($font_weight__500, $font_size__18);
  }
}

.continue_Btn,
.continue_Btn:hover {
  border-radius: 0.75rem !important;
  color: $white;
  padding: 1.25rem !important;
  border-radius: 0.75rem !important;
  text-transform: capitalize;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
  background-color: $Dark__Blue;
  span {
    font-size: $font_size__16 !important;
    @include update_fonts($font-weight__500, $font_size__16);
  }
}

.continue_Btn:disabled,
.continue_Btn:disabled:hover {
  background-color: rgba(55, 114, 255, 0.6);
  border-radius: 0.75rem !important;
  color: $white;
  cursor: $cursor__not_allowed;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.header {
  margin-bottom: 2rem;
}

.logo {
  gap: 1rem;
  margin-bottom: 2rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.logoText {
  font-size: 2rem;
  font-weight: bold;
}

.check_Icon {
  background: #22c55e;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  color: $white;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.login_Text {
  color: $black;
  @include update_fonts($font-weight__600, $font_size__22);
}

.content {
  gap: 2rem;
  flex-wrap: wrap;
  display: $display__flex;
}

.section {
  flex: 1;
  padding: 1rem;
  min-width: 18.75rem;
  border-radius: 0.5rem;
  border: 1px solid #4976ff;
}

.section_Title {
  margin-bottom: 1rem;
  @include update_fonts($font-weight__600, $font_size__22);
}

.list {
  color: #3c3c3c;
  line-height: 1.4;
  font-size: $font_size__16 !important;
  @include update_fonts($font-weight__600, $font_size__16);
}

.bullet_List {
  padding-left: 1.5rem;
  line-height: 1.8;
}

.bullet_List li {
  color: #3c3c3c;
  line-height: 1.4;
  font-size: $font_size__16 !important;
  @include update_fonts($font-weight__600, $font_size__16);
}

.link,
.link:hover {
  color: #4976ff;
  margin-left: 0.19rem;
  cursor: $cursor__pointer;
}
.link:hover {
  text-decoration: underline !important;
}

.border_Right {
  height: 2.38rem;
  border-right: 0.19rem solid #dbdbdb;
  margin: 0 2px;
}

@media (max-width: 48rem) {
  .content {
    flex-direction: column;
  }

  .section {
    width: 100%;
  }

  .logo {
    flex-direction: column;
    text-align: $text_align__center;
  }
}
