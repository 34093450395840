@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.tap_By_Index {
  width: 8.38rem;
  padding: 0.2rem 0.2rem 0.2rem 0.7rem;
  border-radius: 0.3rem;
  border: 0.06rem solid #d6d6d6;
  display: $display__flex;
  align-items: $align_items__center;
}

.select_Index {
  border-radius: 0.3rem;
  width: 6rem;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
}
