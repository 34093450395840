//common classes

.width-full {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.margin-10 {
  margin: 0.6rem;
}

.ml-5 {
  margin-left: 0.3rem;
}

.mr-5 {
  margin-right: 0.3rem;
}

.gap-10 {
  gap: 0.6rem;
}

.gap-14 {
  gap: 0.9rem;
}

.bg-color-gray {
  background-color: #a5aeaf24;
}

.mt-5 {
  margin-top: 0.3rem;
}

.mt-10 {
  margin-top: 0.6rem;
}

.mt-15 {
  margin-top: 0.94rem;
}

.mt-20 {
  margin-top: 1.3rem;
}

.mb-20 {
  margin-bottom: 1.3rem;
}

.mr-26 {
  margin-right: 1.63rem;
}

.padding__20 {
  padding: 1.25rem;
}
.h-100vh {
  height: 100vh !important;
}

.max-width-163 {
  max-width: 10.2rem;
}

.project-platform-tag {
  width: 2.63rem;
  height: 1.6rem;
  gap: 0.6rem;
  padding: 0.13rem 0.6rem;
  border-radius: 3.13rem;
  color: $white;
  display: $display__flex;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__400, $font_size__14);
}

.android {
  width: 4.25rem !important;
  background-color: $light_green;
}

.ios {
  background-color: $Dark__Blue;
}

.web {
  background-color: #2e3778;
}

.common_Search_Box {
  .ant-input {
    height: 1.6rem;
    color: $textGray;
    @include update_fonts($font-weight__400, $font_size__15);
  }
  border-radius: 0.6rem !important;
  border: 0.06rem solid #dedede;
  box-shadow: none !important;
  height: 2.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.common_Search_Box_Width {
  width: 21.75rem !important;
}

// .table__wrapper {
//   padding: 0rem;
//   overflow: hidden;
//   border-top-left-radius: 0.6rem;
//   border-top-right-radius: 0.6rem;

//   .ant-table table {
//     table-layout: auto !important;
//   }

//   .ant-table-pagination.ant-pagination {
//     margin-right: 0.6rem;
//   }
//   .ant-table-thead tr {
//     background-color: $white;
//   }
//   .ant-table-cell {
//     padding: 1rem;
//     height: 2.81rem;
//     vertical-align: top;
//     border: none;
//   }

//   .ant-table-thead th {
//     padding: 1rem !important;
//     border: none;
//     vertical-align: middle;
//     background-color: #cadaff;
//     color: $textBlack;
//     @include update_fonts($font-weight__700, $font_size__14);
//   }
//   .ant-table-body {
//     scrollbar-width: none;
//     tr {
//       &:nth-child(odd),
//       &:nth-child(odd):hover {
//         background-color: #f3f6ff;
//       }
//       td {
//         border: none;
//       }
//     }
//     .ant-table-tbody > tr.activeRow,
//     .ant-table-tbody > tr.activeRow:hover,
//     .ant-table-tbody > tr.activeRow:hover {
//       background: #d7e4ff !important;
//       border-bottom: 0.06rem solid #bdd1ff !important;
//     }
//     .ant-table-tbody > tr.activeRow:last-child td.ant-table-cell-row-hover {
//       border-bottom: none;
//     }
//     .ant-table-container table > thead > tr:first-child th:last-child {
//       display: inline-table;
//     }
//   }
// }

.table__wrapper {
  padding: 0rem;
  overflow: hidden;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;

  .ant-table table {
    table-layout: auto !important;
  }

  .ant-table-pagination.ant-pagination {
    margin-right: 0.6rem;
  }
  .ant-table-thead tr {
    background-color: #ccdaff !important;
  }
  .ant-table-cell {
    padding: 1rem;
    height: 2.81rem;
    vertical-align: top;
    border: none;
  }
  .ant-table-thead th {
    padding: 1rem !important;
    border: none;
    vertical-align: middle;
    background-color: #cadaff;
    color: $textBlack;
    @include update_fonts($font-weight__700, $font_size__14);
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    display: inline-table;
    background-color: transparent;
  }
  .ant-table-body {
    scrollbar-width: none;
    tr {
      &:nth-child(odd),
      &:nth-child(odd):hover {
        background-color: #f3f6ff;
      }
      td {
        border: none;
      }
    }
    .ant-table-tbody > tr.activeRow,
    .ant-table-tbody > tr.activeRow:hover,
    .ant-table-tbody > tr.activeRow:hover {
      background: #d7e4ff !important;
      border-bottom: 0.06rem solid #bdd1ff !important;
    }
    .ant-table-tbody > tr.activeRow:last-child td.ant-table-cell-row-hover {
      border-bottom: none;
    }
  }
  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none !important;
  }
}

/* Styling only the WCAG Criterion column header */
.ant-table-thead .wcag-criterion-column {
  text-transform: none !important;
}

.hide_column_name {
  border-radius: 0 !important;
  .ant-table-thead tr {
    display: $display__none;
  }
}

.common-modal-wrapper {
  .ant-modal-content {
    padding: 1rem 1.3rem 1.88rem 1.3rem;
    border-radius: 1.88rem;
    background-color: #fbfcfc;
  }

  .ant-modal-close {
    padding: 1rem !important;
  }

  .ant-modal-body {
    max-height: 46.88rem;
    overflow: hidden;
    padding: 0 1.5rem !important;
  }
}

.identification-Rules-Drawer {
  .ant-drawer-body {
    background: white;
    box-shadow: 0.25rem 0.25rem 1.25rem 0px rgba(0, 0, 0, 0.05);
  }
  .ant-drawer-body {
    padding: 0px !important;
    background-color: $white;
  }
}

.select-Operators {
  border-radius: 0.6rem;
  border: 0.06rem solid #d6d6d6;
  background: $white;
  width: 100%;
  background: $white;
  height: 2.75rem;
  .ant-select-selector {
    height: 2.75rem !important;
    border: none !important;
    width: 100% !important;
    background-color: transparent !important;
    padding: 4px 13px !important;
  }
}

.select-Verify-Value {
  border-radius: 4px;
  border: 0.06rem solid #d6d6d6;
  background: $white;
  width: 11.56rem;
  background: $white;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    background-color: transparent !important;
  }
}

.ant-select-dropdown {
  border-radius: 0.6rem !important;
  box-shadow: 0 0.06rem 6px 0 rgba(0, 0, 0, 0.3);
}

.select_Operators_For_Verifications {
  border-radius: 0.6rem;
  width: 100%;
  border-radius: 7px;
  border: solid 1px #fff;
  background-color: rgba(55, 114, 255, 0.2);
  height: 2.38rem;
  color: $Dark__Blue;
  display: $display__flex;
  align-items: $align_items__center;
  .ant-select-selector {
    height: 2.38rem !important;
    border: none !important;
    width: 100% !important;
    background-color: transparent !important;
    padding: 4px 13px !important;
  }
  .ant-select-arrow::before {
    filter: invert(40%) sepia(100%) saturate(10000%) hue-rotate(220deg);
  }
  .ant-select-arrow::after {
    filter: invert(40%) sepia(100%) saturate(10000%) hue-rotate(220deg);
  }
}

.disabled_Operators_Select_Box {
  color: rgba(27, 27, 27, 0.25) !important;
  background: #f5f5f5 !important;
  cursor: $cursor__not_allowed !important;
  .ant-select-arrow::before {
    filter: none;
  }
  .ant-select-arrow::after {
    filter: none;
  }
}
//Modal
.common_Modal_v2 {
  .ant-modal-header {
    padding: 0rem;
    border-top-left-radius: 1.1rem;
    border-top-right-radius: 1.1rem;
    box-shadow: 0.3rem 0.3rem 1.3rem 0rem rgba(0, 0, 0, 0.05);
    background: $white;
  }
  .ant-modal-body {
    padding: 0.9rem;
    border-radius: 1.1rem;
    background: $white;
  }

  .ant-modal-content {
    border-radius: 1.1rem;
    box-shadow: 0.3rem 0.3rem 1.3rem 0rem rgba(0, 0, 0, 0.05);
  }
}

.modal_Header {
  gap: 0.6rem;
  padding: 1rem 0.81rem 0.4rem 2.13rem;
  color: $textGray;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
  @include update_fonts($font_weight__700, $font_size__18);
}

.modal-Content-Btn {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
  text-align: $text_align__center;
  justify-content: $justify_content__end;
}
.delete-modal-content-note {
  color: #ee0b0b;
  padding: 0 0 0.94rem 1.13rem;
  display: $display__flex;
  text-align: $text_align__center;
  @include update_fonts($font_weight__600, $font_size__14);
}

.modal_Buttons {
  height: 2.81rem;
  padding: 0.3rem 0.81rem;
  gap: 0.6rem;
  border-radius: 0.6rem;
  cursor: $cursor__pointer;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
  @include update_fonts($font_weight__600, $font_size__18);
}

.modal-Button-Width {
  width: 7.75rem !important;
}
.delete_Modal_Cancel_Btn {
  background: rgba(0, 195, 97, 0.1);
  color: $light_green;
}

.modal_Cancel_Btn,
.modal_Cancel_Btn:hover {
  border: 0.06rem solid #c0bfc0;
  color: #3f3e3f;
}

.more-Actions-Dropdown {
  .ant-dropdown-menu {
    border-radius: 0.6rem !important;
    box-shadow: 0 0.06rem 6px 0 rgba(0, 0, 0, 0.3);
  }
}

.clone_Modal_Content {
  border-radius: 0.69rem;
  background-color: #cbd9ff;
  padding: 0.4rem;
  margin-top: 0.9rem;
  gap: 0.6rem;
  padding: 0.8rem;
  color: $textBlack;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__400, $font_size__16);
}

.modal-Ok-Btn,
.modal-Ok-Btn:hover {
  background-color: #007a5a;
  color: $white;
}

.clone_Modal_Notes {
  display: $display__flex;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__500, $font_size__16);
}

.clone_Modal_Container {
  display: $display__flex;
  justify-content: $justify_content__center;
}

.delete_Modal_Delete_Btn {
  background-color: #e01e5a;
  color: $white;
}

.modal_Content {
  padding: 0.8rem;
  color: $textBlack;
  display: $display__flex;
  align-items: $align_items__center;
  margin-left: 6px;
  @include update_fonts($font_weight__500, $font_size__16);
}

//Table-Details
.table_Details__wrapper {
  margin: 0.9rem 1.3rem 0.9rem 1.3rem;
  border-radius: 0.6rem;
  background: $white;
}

//Pagination
.pagination_Section {
  width: 100%;
  padding: 1rem;
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  background-color: $white;
  display: $display__flex;
  justify-content: $justify_content__center;
}

// Green button
.common_Green_Button,
.common_Green_Button:hover {
  @include common_button($dark_green, $white, 2.75rem);
}

// Light green button
.common_Light_Green_Button,
.common_Light_Green_Button:hover {
  @include common_button(#c5ecdd, #00ab55, 2.75rem);
}

//blue button
.common_Blue_Button,
.common_Blue_Button:hover {
  @include common_button(#4d79ff,$white, 2.75rem);
}

//purple button
.common_Purple_Btn,
.common_Purple_Btn:hover {
  padding: 0.26rem 0.6rem;
  border-radius: 0.44rem;
  background-color: rgba(55, 114, 255, 0.2);
  color: $Dark__Blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__500, $font-size__14);
}

.common_Purple_Btn[disabled],
.common_Purple_Btn[disabled]:hover {
  color: $disableText;
  background-color: $Gray;
}

.table_Details_scroll {
  height: calc(100vh - 11.625rem);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.table_Details_scroll::-webkit-scrollbar {
  width: 0.13rem;
}

.table_Details_scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.table_Details_scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.device-loader-V2-wrapper {
  height: 100%;
  .spinner {
    display: inline-block;
    position: relative;
    width: 3rem;
    height: 1.38rem;
  }
  .spinner div {
    display: inline-block;
    position: absolute;
    left: 0.22rem;
    width: 0.5rem;
    height: 2.5rem;
    border-radius: 0.59rem;
    animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite, colorChange 2s infinite;
    background: $Dark__Blue;
  }

  .spinner div:nth-child(1) {
    left: 0rem;
    animation-delay: -0.4s;
  }

  .spinner div:nth-child(2) {
    left: 0.75rem;
    animation-delay: -0.3s;
  }

  .spinner div:nth-child(3) {
    left: 1.5rem;
    animation-delay: -0.2s;
  }

  .spinner div:nth-child(4) {
    left: 2.25rem;
    animation-delay: -0.1s;
  }

  .spinner div:nth-child(5) {
    left: 3rem;
    animation-delay: 0s;
  }

  .spinner div:nth-child(6) {
    left: 3.75rem;
    animation-delay: 0.1s;
  }

  @keyframes colorChange {
    0% {
      background: $Dark__Blue;
    }
    25% {
      background: #d5e0fd;
    }
    50% {
      background: #d5e0fd;
    }
    75% {
      background: #d5e0fd;
    }
    100% {
      background: $Dark__Blue;
    }
  }
}

.generateAIStepsSpinnerHight div {
  height: 1.5rem !important;
}

.add_Application_String_Scroll {
  max-height: calc(100vh - 14.81rem);
  overflow-y: scroll;
}

.pagination .ant-pagination-item {
  border: none;
  border-radius: 5px;
  background: rgba(165, 174, 175, 0.1411764706);
  color: black;

  &:hover {
    a {
      color: black !important;
    }
  }
}

.pagination .ant-pagination-item-active {
  background: #1890ff;
  a {
    color: $white !important;
  }
  &:hover {
    a {
      color: $white !important;
    }
  }
}

.hideDrawer {
  display: $display__none !important;
}

.smartphones {
  position: relative;
  width: 20rem;
  height: 40.63rem;
  margin: 0 auto;
  border: 0.38rem black solid;
  border-top-width: 3.75rem;
  border-bottom-width: 3.75rem;
  border-radius: 2.25rem;
}

/* The horizontal line on the top of the device */

.smartphones:before {
  content: "";
  display: block;
  width: 3.75rem;
  height: 0.3rem;
  position: absolute;
  top: -1.88rem;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 0.6rem;
}

.smartphones .content {
  width: 22.5rem;
  height: 40rem;
  background: white;
}

.iPhone {
  position: relative;
  height: 32.81rem;
  width: 17.81rem;
  padding: 3.13rem 1.25rem;
  border-radius: 12%/6%;
  box-shadow: #f1ceb9 0 0 17px;
  margin: 0 auto;
  border: 0.13 solid #f1ceb9;
  background-color: $white;
}

.iPhone:before {
  content: "";
  display: block;
  width: 3.75rem;
  height: 0.3rem;
  position: absolute;
  top: 2.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f1ceb9;
  border-radius: 0.6rem;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .smartphones {
    width: 21.75rem;
    height: 45.38rem;
  }
  .iPhone {
    width: 23.75rem;
    height: 44.38rem;
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .smartphones {
    width: 15.89rem;
    height: 34.76rem;
  }
  .iPhone {
    width: 19.58rem;
    height: 36.86rem;
  }
}

@media (min-width: 1366px) and (max-width: 1439px) {
  .smartphones {
    width: 16.6rem;
    height: 36.04rem;
  }
  .iPhone {
    width: 18.6rem;
    height: 35.41rem;
  }
}

@media (min-width: 1440px) and (max-width: 1599px) {
  .smartphones {
    width: 17.73rem;
    height: 38.07rem;
  }
  .iPhone {
    width: 19.73rem;
    height: 37.07rem;
  }
}

@media (min-width: 1600px) and (max-width: 1919px) {
  .smartphones {
    width: 20.22rem;
    height: 42.56rem;
  }
  .iPhone {
    width: 22.58rem;
    height: 42.2rem;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .smartphones {
    width: 25.21rem;
    height: 51.54rem;
  }
  .iPhone {
    width: 27.23rem;
    height: 50.54rem;
  }
}

@media (min-width: 2560px) and (max-width: 2879px) {
  .smartphones {
    width: 35.19rem;
    height: 69.5rem;
  }
  .iPhone {
    width: 37.19rem;
    height: 68.44rem;
  }
}

@media (min-width: 2880px) {
  .smartphones {
    width: 40.18rem;
    height: 78.48rem;
  }
  .iPhone {
    width: 41.88rem;
    height: 78.13rem;
  }
}

.android-device-loader-spinner-V2-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  position: relative;
  width: 3rem;
  height: 3rem;
}

.dot {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  animation: rotate 2s infinite linear, scale 1.2s infinite ease-in-out;
}

.dot1 {
  background-color: #f4b400;
  animation-delay: 0s;
}

.dot2 {
  background-color: #db4437;
  animation-delay: 0.5s;
}

.dot3 {
  background-color: #0f9d58;
  animation-delay: 1s;
}

.dot4 {
  background-color: #4285f4;
  animation-delay: 1.5s;
}

@keyframes rotate {
  0%,
  100% {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  25% {
    top: 50%;
    left: 100%;
    transform: translate(-100%, -50%);
  }
  50% {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  75% {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
}

@keyframes scale {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
}

.loading-text {
  color: $black;
  font-size: 1rem;
  margin-top: 1.56rem;
  text-align: $text_align__center;
}

.ios-device-loader-spinner-V2-wrapper {
  color: $white;
  text-align: $text_align__center;
}

.apple-logo {
  height: 3.44rem;
  margin-bottom: 1.25rem;
  color: $black;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.progress-bar {
  height: 0.25rem;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  background-color: $white;
}

.progress {
  width: 0;
  height: 100%;
  background-color: #333;
  animation: loading 5s linear infinite;
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.test_Data_Modal {
  .ant-modal-header {
    border-top-left-radius: 1.13rem;
    border-top-right-radius: 1.13rem;
  }
  .ant-modal-content {
    border-radius: 1.13rem;
    box-shadow: 0.25rem 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.05);
  }
}

.rounded_Border_Modal {
  .ant-modal-header {
    border-top-left-radius: 1.13rem;
    border-top-right-radius: 1.13rem;
  }
  .ant-modal-content {
    border-radius: 1.13rem !important;
  }
}

.edit_Test_Data_Set_Modal {
  .ant-modal-body {
    max-height: 46.88rem;
    overflow: hidden;
  }
}

.test_Data_Collapse {
  overflow: hidden;
  border-radius: 0.875rem !important;
  flex: 1;
  margin: 0.6rem 0;
  border: 0.06rem solid #dfdfdf;

  .ant-collapse {
    margin: 0.6rem !important;
    background-color: $white;
  }

  .ant-collapse-header {
    margin-left: 0.75rem;
    width: 100%;
    height: 50px;
    padding: 0.8rem 1rem 0.8rem 0rem !important;
    display: $display__flex;
    align-items: $align_items__center !important;
  }
  .ant-collapse-header-text {
    margin-left: 0.75rem;
    color: $textBlack !important;
    @include update_fonts($font_weight__400, $font_size__16);
  }
}

.test_Plan_Collapse {
  border-radius: 0.88rem !important;
  box-shadow: 0.25rem 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.05);
}

.inspectorElement {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.688rem;
  z-index: 900;
  position: fixed;
  background-color: $blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.select_Element_Modal {
  .ant-modal-header {
    border-top-left-radius: 1.13rem;
    border-top-right-radius: 1.13rem;
  }
  .ant-modal-content {
    border-radius: 1.13rem !important;
  }
}

.auth_card_wrap {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  padding: 0.94rem;
  background: #fbfcfc;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.auth_card {
  padding: 2.5rem;
  max-width: 30rem;
  width: 30rem;
}

.custom_Message {
  position: absolute;
  top: 0.94rem;
  left: 66%;
  right: 3%;
  float: right;
  text-align: left;
  color: $textBlack;
  display: $display__flex;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__500, $font_size__14);
  .ant-message-notice-content {
    flex-direction: row;
    padding: 0.6rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.13rem 3.38rem 0 rgba(0, 0, 0, 0.36);
    background-color: #f3f6ff;
    width: fit-content;
  }
}

.rec-Test-Step-List {
  height: calc(100vh - 9.63rem);
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: $display__none;
  }
}

.light_Gray_Text {
  color: #8d8787;
}

.multiple_select_steps {
  border: 0.1rem solid #2473f9 !important;
  background: #f6f9ff !important;
}

.image_Load_Text {
  color: rgb(43, 52, 113);
  display: $display__flex;
  text-align: $text_align__center;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__500, $font_size__20);
}

.hidden_Device_Image {
  display: $display__none;
}

.visible_Device_Image {
  display: block;
}

.invite_Members_Modal {
  .ant-modal-content {
    border-radius: 1.13rem !important;
    box-shadow: 0.25rem 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.05) !important;
    padding: 1.13rem 0rem !important;
    background-color: $white;
  }

  .ant-modal-body {
    padding: 1.13rem 1.5rem 0rem 1.5rem !important;
  }

  .ant-modal-header {
    padding: 0px 1.5rem 0.88rem !important;
  }
}

.image_Load_Text {
  color: rgb(43, 52, 113);
  display: $display__flex;
  text-align: $text_align__center;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__500, $font_size__20);
}

.hidden_Device_Image {
  display: $display__none;
}

.visible_Device_Image {
  display: block;
}

.menu_details {
  margin: auto;
  width: calc(100vw);
  margin-top: 0.94rem;
  box-shadow: 0rem 2.19rem 3.13rem rgba(0, 0, 0, 0.06);

  .ant-dropdown-menu {
    background-color: #f3f6ff !important;
  }

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  h4 {
    margin-bottom: 0;
    line-height: 1;
    margin-bottom: 0.94rem;
    color: #002858;
    @include update_fonts($font_weight__700, $font_size__18);
  }

  li {
    height: 100% !important;
  }

  .ant-divider {
    margin: 0.6rem 0;
  }

  li:hover {
    background: transparent !important;
  }
}

.device_List_Toggle.ant-switch-checked,
.device_List_Toggle .ant-switch-checked {
  background-color: $Dark__Blue !important;
}

.upload_Builds .ant-upload {
  gap: 0.6rem;
  flex-direction: column;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.upload_Builds .ant-upload-list {
  display: $display__flex;
  justify-content: $justify_content__center;
}

.upload_Files .ant-upload {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.select_Selection_Overflow .ant-select-selection-overflow {
  margin-top: 0.6rem;
}

.label_Background {
  background-image: linear-gradient(to top, $white 52%, #f2f4f7 75%);
}

.AI_Step_Generating_Modal {
  .ant-modal-content {
    width: 46.88rem;
    flex-grow: 0;
    border-radius: 0.88rem;
    border-style: solid;
    border-width: 0.19rem;
    min-width: 31.25rem;
    box-shadow: 0 0.25rem 0.38rem rgba(0, 0, 0, 0.1);
    position: relative;
    border-image-source: radial-gradient(circle at 0% 5%, #1ab0f3, #e2c264 71%, #b82dd0 100%);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
      radial-gradient(circle at 0% 6%, #19b0f3, #e2c262 46%, #b82ccf 100%),
      radial-gradient(circle at 0% 5%, #1ab0f3, #e2c264 71%, #b82dd0 100%);
    background-origin: border-box;
    background-clip: content-box, content-box, border-box;
  }
  .ant-modal-header {
    padding: 0rem;
    border-top-left-radius: 1.1rem;
    border-top-right-radius: 1.1rem;
    box-shadow: 0.3rem 0.3rem 1.3rem 0rem rgba(0, 0, 0, 0.05);
    border-bottom: none !important;
  }
  .ant-modal-body {
    padding: 24px;
  }

  .ant-modal-content {
    border-radius: 1.1rem;
    box-shadow: 0.3rem 0.3rem 1.3rem 0rem rgba(0, 0, 0, 0.05);
  }

  .ant-modal-footer {
    border-top: none !important;
  }
}

.accessibility_Report_Modal {
  .ant-modal-body {
    max-height: 70vh;
    overflow-y: auto;
  }
}

//testBlock_In_TestScenario tooltip
.testBlock_In_TestScenario_Tooltip {
  .ant-tooltip-inner {
    color: $white;
    border-radius: 0.44rem;
  }
}

@media (max-width: 48rem) {
  .accessibility_Report_Modal {
    .ant-modal-body {
      max-height: 50vh;
    }
  }
}

@media only screen and (max-width: 58.81rem) {
  .banner_Visibility {
    display: $display__none;
  }

  .auth_card_wrap {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .custom_Message {
    float: none;
    position: inherit;
  }
}
