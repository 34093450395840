@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.custom_Input_Wrapper {
  position: relative;
}

.custom_Input_Label {
  position: absolute;
  left: 0.9rem;
  padding: 0 0.5em;
  z-index: 1;
  color: #84818a;
  background-color: $white;
  @include update_fonts($font-weight__400, $font_size__16);
}

.modal-select-box {
  margin: 0.6rem 0 0.9rem;
  padding: 0.5rem 0px 0.3rem 0.56rem;
  border-radius: 0.8rem;
  border: 0.06rem solid #dcdbdd;
  width: 100%;
  color: #353535;
  background-color: $white;
  font-size: $font_size__15 !important;
  @include update_fonts($font-weight__400);
}

.field_Required {
  color: red;
  font-size: 0.9rem;
  margin-left: 0.19rem;
}
