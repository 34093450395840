@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.permission_Icons_And_Name {
  margin-left: 0.56rem;
}

.icon-and-details {
  margin-left: -0.19rem;
  display: $display__flex;
}

.permission-tooltip {
  margin-right: 0.3rem;
}

.label {
  color: #6d6d6d;
  @include update_fonts($font-weight__400, $font_size__14);
}

.label-name {
  @include update_fonts($font-weight__400, $font_size__14);
}

.step-name {
  max-width: 18.81rem;
  margin-top: -0.06rem;
  margin-bottom: 0 !important;
  color: #000000b8;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__600, $font_size__15);
}

.table-details {
  margin-left: 2.5rem !important;
}

.step-And-Test-Plan-Details {
  @include update_fonts($font-weight__500, $font_size__14);
}

.used-In-Scenario-Details {
  max-width: 9.38rem;
  margin-bottom: 0 !important;
  margin-right: 0.6rem;
  @include update_fonts($font-weight__500, $font_size__14);
}

.popover-contents {
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
}

.border-bottom {
  border-bottom: 0.06rem solid $darkGray;
}

.popover-details {
  color: $Dark__Blue;
  cursor: $cursor__pointer;
}

.test--Blocks-Action__Buttons {
  margin-left: 0.6rem;
  cursor: $cursor__pointer;
}

.action-icon {
  display: $display__flex;
  justify-content: $justify_content__end;
}

.popover_Name{
  display: $display__flex;
  align-items: $align_items__center;
}

@media (max-width: 86.5rem), (max-width: 61.11rem) {
  .test_Block_Info_Details {
    max-width: 12.5rem;
  }
}

@media (max-width: 74.94rem) {
  .test_Block_Info_Details {
    max-width: 100%;
  }
}
