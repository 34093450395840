@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.nameOrVersion {
  width: 100%;
  gap: 1.38rem;
  display: $display__flex;
}

.width {
  width: 50%;
}
.buildDateOrTime {
  width: 50%;
  margin-right: 0.6rem;
}
.checkBox {
  margin-right: 0.3rem;
}

.scrollbar {
  padding: 0.75rem;
  max-height: 23.13rem;
  overflow-y: scroll;
}
