@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.launch_App_Details {
  gap: 0.6rem;
  flex-direction: column;
  display: $display__flex;
}

.title_And_Launch_App {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.operation_Text {
  text-transform: uppercase;
  color: #4a4d53;
  @include update_fonts($font_weight__700, $font-size__17);
}

.action_Launch_App {
  color: $textBlack;
  @include update_fonts($font-weight__500, $font_size__16);
}

.Label {
  color: $textGray;
  @include update_fonts($font-weight__500, $font_size__16);
}

.select_Box {
  border-radius: 0.3rem;
  padding: 0.25rem;
  border: 0.06rem solid #d6d6d6 !important;
  width: 50%;
  .ant-select-selector {
    border: 0rem solid $silver !important;
    width: 100% !important;
    border-radius: 0.5rem !important;
  }
}

.checkBox_Note {
  color: #344054;
  gap: 0.13rem;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font-weight__500, $font_size__16);
}

.checkBox_And_Note {
  gap: 0.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.select_Box_And_Remove_Icon {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.disabled_Select_Box {
  background-color: #f5f5f5;
}
