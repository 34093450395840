@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.checkBox {
  margin-right: 0.6rem;
}

.borderBottom {
  border-bottom: 0.06rem solid #d9d9d9;
}

.popoverContents {
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
}

.data {
  margin-left: 0.13rem;
}

.popoverDetails {
  margin-left: 0.6rem;
  color: #4a9bf2;
  cursor: $cursor__pointer;
}

.permissionTooltip {
  margin-right: 0.3rem;
}

.stepName {
  display: $display__flex;
  font-weight: $font_weight__700;
  font-size: $font_size__14;
}

.recTestStepRights {
  align-items: baseline;
  display: $display__flex;
}

.checkbox {
  margin: 0.6rem;
}

.ActionIcon {
  padding: 1.25rem;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.editIcon {
  cursor: $cursor__pointer;
}

.icons {
  padding-left: 0.6rem;
  cursor: $cursor__pointer;
  font-size: 1.13rem;
}

.spinnerCenter {
  min-height: 90vh;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.label {
  color: #636467;
  font-weight: $font_weight__400;
  font-size: $font_size__12;
}

.table {
  margin-bottom: 1.3rem;
  margin-top: 0.6rem;
  margin: 0.9rem;
}
