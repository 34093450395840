@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.pageScroll {
  height: calc(100vh - 33.63rem);
  overflow-y: scroll;
}

.build_Details {
  padding: 0.75rem;
}

.search_Box {
  width: 100%;
}

.modal_Title {
  color: $gray;
  @include update_fonts($font_weight__700, $font_size__18);
}
