@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.deviceImg {
  margin-left: 1rem;
}

.deviceLogo {
  width: 2.6875rem;
  height: 2.6875rem;
  border-radius: 0 1.1rem;
  border: 0.09rem solid #ededed;
  background: $white;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.deviceLogoOrImg {
  display: $display__flex;
  align-items: $align_items__center;
}

.justify_end {
  justify-content: $justify_content__end;
}

.deviceBrandDetails {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.06rem;
  display: $display__flex;
}

.deviceName {
  color: $darkGrayColor;
  text-align: $text_align__center;
  @include update_fonts($font_weight__800, $font_size__18);
}

.deviceDisplayName {
  color: $darkGrayColor;
  text-align: $text_align__center;
  @include update_fonts($font_weight__800, $font_size__18);
}

.border {
  height: 0.0625rem;
  margin: 0.6rem;
  width: 14.7rem;
  background: #d8d8d8;
  text-align: $text_align__center;
}

.deviceDetails {
  gap: 1.38rem;
  display: $display__flex;
}

.deviceDetailsInCenter {
  justify-content: $justify_content__center;
}

.deviceIdOrLabel {
  color: $textGray;
  @include update_fonts($font_weight__500, $font_size__14);
}

.deviceId_And_Usages {
  color: $textBlack;
  font-size: $font_size__15;
}

.deviceIdInModal {
  width: 7rem;
}

.useButton {
  height: 2.75rem;
  border-radius: 0 0 1.125rem 1.125rem;
  margin-top: 0.5rem;
  background-color: $white;
}

.positionOfCard {
  position: relative;
}

.useButton {
  pointer-events: none;
}

.checkBoxOrIcon {
  gap: 0.4rem;
}

.deviceLogonInModal {
  width: 2.69rem;
  height: 2.69rem;
  border-radius: 1.125rem 0rem;
  border: 0.09rem solid #ededed;
}

.deviceOsORLocale {
  color: $darkGrayColor;
  @include update_fonts($font_weight__500, $font_size__16);
}

//New
.device_Card_Details {
  margin: 0.6rem;
  width: 204px;
  height: 216px;
  flex-grow: 0;
  padding: 12px 1px 1px;
  border-radius: 0.6rem;
  box-shadow: 0 4px 0.6rem 0 rgba(39, 39, 39, 0.15);
  border: solid 1px #d6d6d6;
  background-color: $white;
}

.opacity_On_Card:not(.in_Use_Button) {
  opacity: 0.7;
}

.info_Icon {
  margin-left: 11px;
  justify-content: flex-start;
  margin-left: 11px;
  display: $display__flex;
}

.device_Image {
  margin-top: -1rem;
  display: $display__flex;
  justify-content: $justify_content__center;
}
.device_Details {
  text-align: $text_align__center;
}

.device_Display_Name {
  color: $textBlack;
  @include update_fonts($font_weight__600, $font_size__15);
}

.display_Name_In_Card {
  margin-top: 0.5rem;
}

.device_Card_Footer {
  bottom: 0.06rem;
  width: 12.56rem;
  height: 2.25rem;
  border-top: 0.06rem solid #dce6ff;
  background-color: #f2f6ff;
  position: absolute;
  border-radius: 0rem 0rem 0.6rem 0.6rem;
  display: $display__none;
  justify-content: $justify_content__center;
}

.device_Card_Details_In_Modal {
  padding: 1.63rem 0rem 0.5rem 0rem;
  gap: 1rem;
  display: $display__flex;
}

.label {
  flex-wrap: wrap;
  color: #3a3a3a;
  gap: 0.19rem;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__400, $font_size__15);
}

.in_Use_Button_Div {
  position: absolute;
  top: 38%;
  bottom: 37%;
  left: 32%;
  z-index: 1;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.in_Use_Button {
  width: 4.88rem;
  height: 2rem;
  border-radius: 0.3rem;
  background-color: #ffca45;
  text-transform: uppercase;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.in_Use_Text {
  margin-top: 0.13rem;
  color: $textBlack;
  @include update_fonts($font_weight__400, $font_size__15);
}

.offline_Button {
  background-color: #6e6868 !important;
}

.offline_Text {
  color: $white !important;
}
.device_Card_Details:hover {
  .device_Card_Footer {
    display: $display__flex;
  }
}
.show_Device_Card_Footer {
  display: $display__flex !important;
}

.device_Image_And_Details {
  margin-left: 0.88rem;
  gap: 1rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.device_OsVer {
  margin-left: 0.13rem;
}

.usages_Label {
  margin-top: 0.13rem;
}

.express_Device_Id {
  width: 17.56rem;
  @include ellipse_Text();
}

.saucelabs_Device_Id {
  width: 9rem;
  @include ellipse_Text();
}

.device_Details_Of_Lambda_Test {
  margin-top: 0.94rem;
}