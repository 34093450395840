@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.filter_Button {
  padding: 0.5rem 0.25rem 0.5rem 0.75rem;
  background-color: rgba(55, 114, 255, 0.2);
  height: 2.31rem;
  padding: 0.26rem 0.6rem;
  border-radius: 0.44rem;
  margin: 0.06rem;
  gap: 0.5rem;
  border: 0.06rem solid $white;
  color: $Dark__Blue;
  display: $display__flex;
  align-items: $align_items__center;
  cursor: $cursor__pointer;
  @include update_fonts($font_weight__400, $font_size__14);
}

.filter_List_Scroll {
  width: 12.5rem;
  max-height: calc(100vh - 37.5rem);
  overflow-y: scroll;
}

.filter_Menu {
  width: 12.5rem;
}

.filter_Submenu_title {
  color: #6e7282 !important;
  border-bottom: 0.06rem solid #e2e3e6;
  padding: 10px 0 !important;
  pointer-events: none;
  width: 100%;
  margin: auto !important;
}

.filter_Text {
  margin-left: 0.94rem;
  @include update_fonts($font_weight__400, $font_size__14);
}
