@import '../../../../styles/mixins.scss';
@import '../../../../styles/variables.scss';

.notExecutedImages {
    width: 14.06rem;
    height: 21.88rem;
    border-top: 0.06rem solid lightgrey;
    display: $display__flex;
    justify-content: $justify_content__center;
    align-items: $align_items__center;
    @include update_fonts($font_weight__500, $font-size__16);
}