@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.mainContinuer {
  gap: 0.75rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.elementSettings {
  padding-left: 0.9rem;
}

.button {
  margin-left: 0.3rem;
}

.details {
  padding-right: 0.9rem;
}

.defaultButton {
  white-space: nowrap;
  width: 6.25rem;
  height: 2.125rem;
  padding: 0.625rem 0.8125rem;
  flex-direction: column;
  border-radius: 0.375rem;
  cursor: $cursor__pointer;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
  border: 0.06rem solid $darkGrayColor;
  color: $darkGrayColor;
  @include update_fonts($font_weight__600, $font_size__14);
}

.defaultButton:hover {
  color: $darkGrayColor;
}

.disableButton {
  background: $Gray;
  color: $disableText;
  border: 0.06rem solid #e6e6e6;
  cursor: $cursor__not_allowed;
}

.text {
  color: $grayColor;
  font-size: $font_size__14;
}

.inputOrText {
  margin-left: 0.13rem;
  display: $display__flex;
}

.input {
  width: 3.75rem;
}

.timeText {
  padding-left: 0.3rem;
  color: #636467;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__400, $font_size__12);
}

.divider {
  font-size: 1.875rem;
  border-top: 0.1rem solid #e8e8e8;
  margin-top: 1.375rem;
  margin-bottom: 1.53rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.dividerText {
  padding: 0rem 1rem;
  flex-direction: column;
  grid-gap: 0.625rem;
  gap: 0.625rem;
  margin-top: -0.813rem;
  border-radius: 6.25rem;
  border: 0.06rem solid #dedede;
  background: #f3f3f3;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__500, $font-size__16);
}

.checkBox {
  margin-top: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.checkBoxText {
  margin-left: 0.6rem;
  color: $grayColor;
  @include update_fonts($font-weight__500, 0.938rem);
}

.inputOrDefaultButton {
  gap: 0.4rem;
  display: $display__flex;
  align-items: $align_items__center;
}

@media screen and (min-width: 86.5rem) and (max-width: 119.93rem), (min-width: 61.11rem) and (max-width: 75rem) {
  .mainContinuer {
    display: block;
  }
}
