@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.custom-element-details {
  margin-top: 0.5rem;
}

.select-Element-label {
  color: $textGray;
  @include update_fonts($font_weight__400, $font_size__16);
}

.selectMode {
  border-radius: 0.3rem;
  border: 0.06rem solid #d6d6d6;
  width: 100%;
  background: $white;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
  }
}

.select-use-data-or-string {
  margin-top: 0.5rem;
  gap: 1.88rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.checkbox {
  gap: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.checkbox-Text {
  white-space: nowrap;
  color: #344054;
  @include update_fonts($font_weight__500, $font_size__14);
}

.label {
  margin-top: 0.5rem;
  color: $textGray;
  @include update_fonts($font_weight__500, $font_size__16);
}

.text-And-Button {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.application-String-key {
  font-size: $font_size__14 !important;
  font-weight: $font_weight__400 !important;
}

.input-box {
  border: 0.06rem solid #b0b1c1;
  &:hover {
    border: 0.06rem solid #b0b1c1 !important;
  }
}
