@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.testDataInfoHeader {
  gap: 2rem;
  display: $display__flex;
  @include update_fonts($font_weight__500, $font-size__18);
}

.stepsData {
  height: calc(100vh - 11.63rem);
  overflow-y: scroll;
}

.elementImage {
  cursor: $cursor__pointer;
}

.stepName {
  width: 21.875rem;
}

.modalTitleLabel {
  font-weight: normal;
}

.spinning_center {
  min-height: 20vh;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.main-header {
  top: 3.38rem;
  width: 100%;
  padding: 0rem 0rem 0rem 20px;
  background: $white;
}

.main-Header-Title {
  color: #111548;
  margin-left: 1.13rem;
  margin-bottom: 0;
  @include update_fonts($font-weight__600, $font-size__22);
}

@media only screen and (max-width: 75rem) {
  .main-header {
    margin-top: 0.9rem;
    width: 100%;
    padding: 0rem 0rem 0rem 1.3rem;
    background-color: $white;
  }
}
