@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.actionSelection {
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.postActionSettings {
  @include postActionSettings;
}

.disableButton {
  background-color: $Gray;
  color: $disableText;
  cursor: $cursor__not_allowed !important;
}

.selectorOrSettings {
  color: $gray;
  text-transform: uppercase;
  margin-bottom: 0.6rem;
  margin-top:0.6rem;
  @include update_fonts($font_weight__700, $font_size__15);
}
