@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.divider {
  margin-bottom: 0.6rem;
}

.steps-Area {
  height: 100%;
  flex-direction: column;
  display: $display__flex;
  overflow: scroll;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    display: $display__none;
  }
}

.empty-Area-of-Designer {
  flex-grow: 1;
}

.rec-Test-Step-List {
  height: calc(100vh - 9.63rem);
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: $display__none;
  }
}

.show_AI_Step {
  height: calc(100vh - 13.5rem) !important;
}

@media (max-width: 1366px) {
  .show_AI_Step {
    height: calc(100vh - 16.78rem) !important;
  }
}

@media (max-width: 1669px) {
  .show_AI_Step {
    height: calc(100vh - 16.5rem) !important;
  }
}
