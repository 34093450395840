@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.actionTitle {
  margin-top:0.3rem;
  text-transform: uppercase;
  color: $gray;
  margin-right: 0.3rem;
  @include update_fonts($font_weight__700, $font-size__15);
}

.actionsSelection {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.contentInCenter {
  gap: 0.87rem;
  flex-wrap: wrap;
  align-items: flex-start;
  display: $display__flex;
}

.actionDetails {
  margin-top: 0.6rem;
}

.selectTapOrEnterTextAction {
  width: 20rem !important;
}

.showActionDetails {
  gap: 1.88rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.selectActionsOrText {
  gap: 0.87rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.selectBoxBgColor {
  background-color: #f5f5f5;
}
