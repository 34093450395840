@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.projects-header {
  margin-top: 4.5rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.header-title {
  margin-left: 1.5rem;
  color: $darkBlue;
  @include update_fonts($font-weight__700, $font_size__20);
}

.pagination-section {
  padding: 1rem;
  background-color: $white;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.project-Name {
  color: #000000 !important;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__600, $font_size__14);
}

.project-Name:hover {
  text-decoration: underline;
}

.projects-icon-and-name {
  gap: 0.3rem;
  display: $display__flex;
}

.labels {
  color: #6d6d6d;
  @include update_fonts($font-weight__400, $font_size__14);
}

.labels-details {
  color: #242424 !important;
  font-weight: $font-weight__500 !important;
}

.team-name {
  color: $textBlack;
  @include update_fonts($font-weight__400, $font_size__14);
}

.projects-details {
  padding-top: 0.3rem;
}

.border-bottom {
  border-bottom: 0.06rem solid #ccc;
}

.header-buttons {
  margin: 1.3rem 1rem;
}

.projects-card {
  margin: 1.3rem;
}

.generate_Key_Text {
  max-width: 17.17rem;
  margin-top: -0.06rem;
  margin-bottom: 0 !important;
  color: $textBlack;
  white-space: nowrap;
  @include update_fonts($font-weight__400, $font_size__14);
}

.generate_Keys_And_Copy_Icon{
  display: $display__flex;
  align-items: $align_items__center;
}