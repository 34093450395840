@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.deviceCardOrDevice {
  width: 13.8125rem;
  height: 16.5rem;
}

.checkBoxOrIcon {
  gap: 0.6rem;
  justify-content: $justify_content__end;
}

.checkBox{
  margin-right:0.6rem;
}

.dragAndDropDeviceIcon{
  border: 2px dashed #1890ff;
  padding: 75px;
  width: 400px;
  border-radius: 5px;
}

.dragAndDropDeviceIconText{
  color: #1890ff;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $align_items__center;
  @include update_fonts($font_weight__600, $font_size__16);
}

.devicesList{
  display: $display__flex;
  align-items: $align_items__center;
  width: calc(100vw - 200px);
  overflow-x: auto;
}