@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.drawer-title-Text {
  margin-top: 0.19rem;
  color: $textGray;
  @include update_fonts($font_weight__500, $font_size__17);
}

.table-header-details {
  background: #f3f6ff;
  padding: 0.6rem 1rem;
  border-bottom: 0.06rem solid #d6d6d6;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.table-header-text {
  margin-left: 0.6rem;
  margin-top: 0.3rem;
  color: #002858;
  @include update_fonts($font_weight__600, $font_size__17);
}

.permission-table-header-btn,
.permission-table-header-btn:hover {
  background: #cbcde3 !important;
  color: $navyBlue !important;
}

.permission-table-header-btn[disabled] {
  background-color: #e6e6e6 !important;
  color: $disableText !important;
  cursor: $cursor__not_allowed;
}

.table-content {
  margin-top: 1.3rem;
}

.members-list-details {
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.apply-for-details {
  margin-top: 2.19rem;
}

.delete-icon__drawer {
  padding-right: 0.6rem;
  margin-top: -0.19rem;
  cursor: $cursor__pointer;
}

.drawer_Header_Text {
  gap: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.label_Background {
  background-image: linear-gradient(to top, white 52%, #f3f6ff 75%) !important;
}

.add_Button,
.add_Button:hover {
  @include common_button($dark_green, $white, 2.5rem);
}

.drawer_Title_Label {
  color: $textBlack;
  @include update_fonts($font_weight__600, $font_size__17);
}
