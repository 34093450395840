@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.profile_Name_Label {
  margin-left: 0.63rem;
  @include update_fonts($font_weight__600, $font_size__16);
}

.test_Data_Set_Name {
  margin-left: 0.63rem;
  font-size: $font_size__16;
  @include update_fonts($font_weight__400, $font_size__16);
}

.TestDataSetHeaderRow {
  height: 3.43rem;
  padding: 0 0 0 1.25rem;
  margin-top: 0.93rem;
  background-color: $white;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.TestDataSetHeaderCol {
  gap: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.backIcon {
  cursor: $cursor__pointer;
}

.container {
  display: $display__flex;
}

.column-detail {
  color: $navyBlue;
  padding: 0px !important;
}

.font-weight {
  font-weight: bold;
}
