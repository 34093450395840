@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.headerTitle {
    font-family: "Inter";
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #111548;
  }

.headerTitle {
  margin-left: 0.6rem;
  color: $textBlack;
  text-align: center;
  @include update_fonts($font_weight__600, $font_size__16);
}

.headerOptions {
    max-width: 43.75rem;
    overflow: auto;
    padding: 0.625rem 0;
    gap: 0.625rem;
    margin-right: 0.625rem;
    display: $display__flex;
    align-items: $align_items__center;
  }
  
  .jsonViewer{
    border: 1px solid lightgray;
    border-radius: 1rem;
    min-height: 30rem;
    overflow-y: scroll;
    margin-top: 1rem;
    padding: 1rem;
  }

  .generateActionButtons{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .instructionEditor{
    min-height: 25rem !important;
  }