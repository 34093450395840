@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.job_Container {
  width: 100%;
  border-radius: 0.94rem;
  padding: 1.25rem;
  margin-top: 3.75rem;
  background-color: $white;
}

.min_Height {
  min-height: 65vh !important;
}

//Build
.job_Details_wrap {
  padding: 1.13rem 1.38rem;
  border-radius: 0.94rem;
  border: 0.06rem solid #cadaff;
  background-color: #fdfdff;
}

.execution_Farm_Wrapper {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.build_And_Farm_Title {
  color: $textBlack;
  @include update_fonts($font_weight__600, $font_size__18);
}

.radio_Button {
  padding: 0.5rem 0.88rem;
  border-radius: 0.6rem;
  border: 0.06rem solid #d1d1d1;
  margin-right: 0.6rem;
  background-color: $white;
  display: $display__flex;
  align-items: $align_items__center;
}

.active_Radio_Button {
  border: 0.06rem solid #97b5ff !important;
  background-color: #f3f6ff !important;
}

.radio_Button_Text {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__16);
}

.radio_Input_Box {
  vertical-align: middle;
  margin-right: 0.3rem;
  height: 1.06rem;
  width: 1.5rem;
  cursor: $cursor__pointer;
}

.modal-input {
  width: 50%;
  padding: 1.38rem 0 1.3rem 1.57rem !important;
  border-radius: 0.8rem !important;
  border: 0.06rem solid #dcdbdd !important;
  margin: 0.6rem 0 0.9rem;
  color: #000 !important;
  background-color: $white !important;
  font-size: $font_size__15 !important;
  @include update_fonts($font-weight__400, $font_size__15);
}

.custom-input-wrapper {
  position: relative;
}

.build_Input_Box {
  margin-top: 1rem;
}

.custom-input-label {
  position: absolute;
  left: 0.9rem;
  padding: 0 0.5em;
  z-index: 1;
  color: #84818a;
  background-color: $white;
  @include update_fonts($font-weight__400, $font_size__16);
}

//execution mode
.execution_Settings_Text {
  color: $textBlack;
  @include update_fonts($font-weight__600, $font_size__18);
}

.label {
  margin-top: 0.94rem;
  color: $textBlack;
  @include update_fonts($font-weight__500, $font_size__16);
}

.radio_Or_Checkbox__Text {
  margin-right: 0.3em;
  color: $textBlack;
  @include update_fonts($font-weight__500, $font_size__15);
}

.select_Test_Rail_Suites {
  border-radius: 12px !important;
  border: 0.06rem solid #dcdbdd !important;
  background-color: $white !important;
  @include common_Select_Box(100%);
}

.disabled_Select_Box {
  background-color: #f5f5f5 !important;
}

//deviceTab

.modal-select-box {
  padding: 0.5rem 0px 0.3rem 0.4rem;
  border-radius: 0.8rem;
  border: 0.06rem solid #dcdbdd;
  width: 21.75rem !important;
  background-color: $white;
}

.aws_Settings_Title {
  color: $textBlack;
  @include update_fonts($font-weight__500, $font_size__16);
}

.lambdaTest_And_SauceLabs_DeviceFarm {
  border-radius: 0.94rem;
  border: 0.06rem solid #cadaff;
  background-color: #fdfdff;
  margin-top: 0.6rem;
  min-height: 50vh;
}

.deviceFarm_Header {
  padding: 0.88rem 1rem;
  background-color: #cadaff;
  border-top-right-radius: 0.94rem;
  border-top-left-radius: 0.94rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.deviceFarm_Label {
  margin-left: 12px;
  color: $textBlack;
  @include update_fonts($font_weight__600, $font_size__18);
}

.device_Farm_Details {
  padding: 0.88rem 0.6rem;
}

.spinner_Center {
  min-height: 50vh;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.empty_Data {
  min-height: 45vh;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}
.external_Farm_Table_Data {
  flex-wrap: wrap;
  display: $display__flex;
}

.radio_Box_Div {
  margin-top: 0.6rem;
  display: $display__flex;
}

.accessibility_And_Local_Report {
  margin-left: 1.63rem;
}

.checkBox_Text {
  gap: 0.6rem;
  margin-bottom: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.checkBox_Text label {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__15);
}