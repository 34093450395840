@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.container {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__400, $font_size__16);
}

.inputTitle {
  width: 10.68rem;
  @include update_fonts($font_weight__600, $font_size__16);
}

.inputBox {
  margin-left: -4.44rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.inputValue {
  cursor: $cursor__pointer;
}

.editTestDataRow {
  top: 3.37rem;
  background: $white;
  justify-content: $justify_content__space_between;
}

.editTestDataCol {
  gap: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.backBtn {
  margin-right: 0.93rem;
  cursor: $cursor__pointer;
}

.saveResetButton {
  margin-right: 0.9rem;
}

.pageScroll {
  overflow-y: scroll;
  height: calc(100vh - 8.63rem);
}

.pageScrollOfTestData {
  overflow-y: scroll;
  height: calc(100vh - 11.13rem);
}
