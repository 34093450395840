@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.actionSelection {
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.postActionSettings {
  @include postActionSettings;
}

.disableButton {
  background-color: $Gray;
  color: $disableText;
  cursor: $cursor__not_allowed !important;
}

.selectorOrSettings {
  text-transform: uppercase;
  margin-bottom: 0.6rem;
  margin-top: 0.6rem;
  color: $textGray;
  @include update_fonts($font_weight__700, $font_size__17);
}

.checkbox-Text {
  color: $textGray;
  @include update_fonts($font_weight__500, $font_size__16);
}

.AI_Step_Container {
  border-radius: 0.88rem;
  border-style: solid;
  border-width: 0.06rem;
  border-image-source: radial-gradient(circle at 0% 5%, #1ab0f3, #e2c264 46%, #b82dd0 100%);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
    radial-gradient(circle at 0% 6%, #19b0f3, #e2c262 53%, #b82ccf 116%),
    radial-gradient(circle at 0% 5%, #1ab0f3, #e2c264 46%, #b82dd0 100%);
  background-origin: border-box;
  background-clip: content-box, content-box, border-box;
}

.AI_Step_Row {
  padding: 0.8rem;
}

.AI_Steps_Header {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.AI_Objective_Input {
  height: 2.31rem !important;
  border-radius: 0.63rem;
  border: 0.06rem solid #d7d7d7 !important;
  font-size: $font_size__16 !important;
  @include update_fonts($font_weight__400, $font_size__16);
}

.AI_Generated_Step_Icons {
  gap: 0.6rem;
  display: $display__flex;
}

.AI_Steps_Divider {
  margin: 6px 0;
  border-color: #e3dae0;
}

.AI_Header_Padding {
  padding: 0.8rem 0.8rem 0;
}

.AI_Step_Details {
  padding: 0 0.8rem 0.8rem;
}

.label {
  margin-top: 0.5rem;
  color: $textGray;
  @include update_fonts($font_weight__500, $font_size__16);
}

.AI_Step_Objective_And_Image {
  width: 100%;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.manual_Element_Selection_Container {
  border-radius: 0.88rem;
  border: 0.063rem solid #c7c7c7;
  margin-top: 0.75rem;
  padding: 0.8rem;
}

.action_Details {
  gap: 0.88rem;
  margin: 1rem 0;
  flex-wrap: wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.AI_Element_Image {
  cursor: $cursor__pointer;
}

.AI_Margin_Top {
  margin-top: 0.75rem;
}

.json_Data {
  height: calc(100vh - 24.63rem);
  overflow-y: scroll;
}

.sub_Header {
  text-transform: uppercase;
  color: #4a4d53;
  margin-right: 0.44rem;
  @include update_fonts($font_weight__700, $font-size__17);
}

.swipe_Element {
  margin: 1rem 0;
}
