@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.divider{
  margin-top:1.3rem;
}

.settingsModalDivider {
  border-top-color: #d4d2d2 !important;
}

.relative_Point {
  flex-wrap: wrap;
  gap: 0.6rem;
  padding-left: 1.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.relative_Text {
  @include update_fonts($font_weight__500, $font-size__16);
}

.text {
  margin-left: 0.3rem;
}

.relative_Point_Text {
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__500, $font-size__16);
}

.percentage {
  margin-left: 1.1rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.input{
  margin-left:0.6rem;
  min-width: 3.75rem;
}

.inputOrText{
  margin-top:0.6rem;
  flex: 1;
}