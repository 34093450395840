@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.selected-Box-And-Delete-Icon {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.select-all {
  border-radius: 0.3rem;
  padding: 0.44rem 0.6rem;
  background-color: #cadaff;
}

.selected-length {
  gap: 0.3rem;
  margin-top: 0.5rem;
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__14);
}

.delete-Icon-And-Text {
  border-radius: 0.3rem;
  padding: 0.4rem;
  padding-right: 0.6rem;
  border: 0.06rem solid #d3d4de;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
}

.delete-Text {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__13);
}
