@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.tableTitle {
  text-align: $text_align__center;
}

.tableSubTitle {
  text-transform: capitalize;
}

.tableData {
  color: $navyBlue;
  padding: 0 !important;
}

.font_weight {
  font-weight: bold;
}

.collapse {
  margin-top: 0.6rem;
}

.details {
  margin: 0.6rem;
}

.actions {
  padding: 0 !important;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.input {
  border: none !important;
}

.checkBox {
  margin-right: 0.8rem;
  margin-top: 0.5rem;
}

.tooltipOrCheckBox {
  padding: 0 !important;
  display: $display__flex;
}
