@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.test_Data_Set_Input {
  width: 14.5rem;
  padding: 0.6rem 0.75rem;
  border-radius: 0.3rem !important;
  border: 0.06rem solid #d6d6d6 !important;
  height: 2.5rem !important;
  font-size: $font-size__15 !important;
  @include update_fonts($font_weight__400);
}

.input_Or_Select_Label {
  color: $textGray;
  @include update_fonts($font_weight__500, $font-size__16);
}

.enter_Text_Details {
  display: $display__flex;
  align-items: $align_items__center;
}

.appendText {
  margin-left: 0.9rem;
}

.checkBox_And_Text {
  gap: 0.8rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.select_Test_Data {
  width: 86%;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
}

.select_Box_And_Select_Icon {
  gap: 0.6rem;
  width: 14.5rem;
  padding: 0.19rem 0.8rem 0.19rem 0rem;
  border: 0.06rem solid #d6d6d6 !important;
  border-radius: 0.3rem !important;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}
