@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.spinner_Center {
  min-height: 90vh;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.header_Title {
  color: $textBlack;
  @include update_fonts($font_weight__600, $font_size__18);;
}

.test_Plan_Details {
  padding: 0.9rem;
  margin-left: 0.38rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.select--all-box {
  margin-left: 0.4rem;
  margin-bottom: 0.625rem;
  padding-top: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.header-checkBox {
  margin-left: -0.6rem;
}

.checkBox-in-header {
  margin-left: 0.9rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.table-header {
  margin-left: 3.25rem;
}

.searchBox_In_Jobs {
  margin-bottom: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.test_Plan_Details_wrap {
  padding: 1.13rem 1.38rem;
  border-radius: 0.94rem;
  border: 0.06rem solid #cadaff;
  background-color: #fdfdff;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}
