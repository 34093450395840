@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.applicationText {
  margin-bottom: 0.6rem;
  margin-left: 0.6rem;
}

.addOrCancelBUtton {
  margin-right: 0.9rem;
}

.content {
  margin-bottom: 1.875rem;
  margin-left: 0.6rem;
}

.applicationNameOrAddIcon {
  margin-bottom: 1.3rem;
  margin-top: 0.3rem;
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.label {
  padding: 0rem;
  color: #6e7282;
  @include update_fonts($font_weight__400, $font_size__14);
}

.applicationKeyInput {
  padding: 0.8rem;
  height: 3rem;
  border: 0.06rem solid #c3c5d2;
  border-radius: 0.3rem;
  color: #111548;
  font-size: $font_size__16 !important;
  @include update_fonts($font_weight__500);
}

.selectOrValue {
  gap: 0.6rem;
  align-items: flex-end;
  display: $display__flex;
}

.applicationValue {
  margin-bottom: 1.3rem;
  margin-top: 0.3rem;
  padding: 0.8rem;
  height: 3rem;
  border: 0.06rem solid #c3c5d2;
  border-radius: 0.3rem;
  color: #111548;
}

.addButton {
  background: #444aea;
  color: $white;
}

.addButton:hover {
  background: #444aea;
  color: $white;
}

.addButton[disabled] {
  background: #e6e6e6;
  color: $disableText;
}

.applicationDelete {
  margin-bottom: 1.5rem;
}

.text {
  color: #3d3d49;
  @include update_fonts($font_weight__400, $font_size__14);
}

.inputValue {
  width: $width__100;
}

.localeOrSelect {
  width:40%;
  flex-direction: column;
  display: $display__flex;
}
