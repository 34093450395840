@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.custom-input-wrapper {
  position: relative;
}

.custom-input-label {
  position: absolute;
  left: 0.9rem;
  padding: 0 0.5em;
  z-index: 1;
  color: #84818a;
  background-color: $white;
  @include update_fonts($font-weight__400, $font_size__14);
}

.modal-select-box {
  margin: 0.6rem 0 0.9rem;
  padding: 0.5rem 0px 0.3rem 0.4rem;
  border-radius: 0.8rem;
  border: 0.06rem solid #dcdbdd;
  width: 100%;
  background-color: $white;
}
