@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.element-Settings {
  border-radius: 0.9rem;
  border: 0.06rem solid $borderColor;
  background: #f8faff;
  margin-top: 1.1rem;
}

.settings-title {
  padding: 0.8rem 0.8rem 0.6rem 0.8rem;
  border-bottom: 0.09rem solid $borderColor;
  color: $textBlack;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__500, $font_size__18);
}

.label {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__16);
}

.input-box {
  width: 100%;
  margin-left: 0.13rem;
  margin-right: auto;
  display: $display__flex;
}

.input-and-default-button {
  padding: 0.13rem;
  gap: 0.4rem;
  border-radius: 0.6rem;
  border: 0.06rem solid #d6d6d6;
  background: $white;
  display: $display__flex;
  align-items: $align_items__center;
}

.timeText {
  padding-left: 0.3rem;
  color: #636467;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__400, $font_size__12);
}

.checkBox {
  margin-top: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.check-Box-Text {
  color: #344054;
  @include update_fonts($font_weight__400, $font_size__14);
}

.checkbox-details {
  margin-top: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.checkbox {
  margin-right: 0.6rem !important;
}

.settings-details {
  padding: 0.8rem 0.8rem 0.69rem 0.8rem;
}

.input-settings {
  width: 100%;
  border: none !important;
}

.default-Button {
  height: 2.25rem !important;
}
