@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.inValid_Verification_Container {
  height: 100vh;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.inValid_Verification_Wrapper {
  width: 461px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 30px 45px 30px 45px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.inValidation_Text {
  margin-bottom: 1.25rem;
  color: $black;
  text-align: $text_align__center;
  @include update_fonts($font_weight__400, $font_size__18);
}

.btn_Sign_Up,
.btn_Sign_Up:hover {
  height: 2.75rem !important;
  border-radius: 0.75rem !important;
  color: $white;
  background-color: $Dark__Blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  cursor: $cursor__pointer;
  font-size: $font_size__14 !important;
  @include update_fonts($font-weight__500, $font_size__14);
}

.btn_Sign_Up > span {
  font-size: $font_size__16 !important;
  @include update_fonts($font-weight__500, $font_size__14);
}
