@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.enterText {
  display: $display__flex;
  align-items: $align_items__center;
}

.selectTestDataText {
  width: 9.38rem;
  padding: 0.4rem;
  background: $bgDarkGray;
}

.selectTestData {
  border-radius: 0.3rem;
  width: 13.5rem;
  font-size: $font-size__15 !important;
  font-weight: $font_weight__400 !important;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
}

.insertTestDataText {
  width: 7.4375rem;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  padding: 0.5rem;
  display: $display__flex;
  background: $borderColor;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.enterTestDataSet {
  border: none !important;
  width: 14.5rem;
  font-size: $font-size__15 !important;
  font-weight: $font_weight__400 !important;
}

.appendText {
  margin-top: 0.3rem;
}

.selectTestDataButton {
  margin-left: 0.6rem;
  margin-top: 0.3rem;
}

.blueButton {
  background: #444aea;
  color: #fff;
}

.blueButton:hover {
  background: #444aea;
  color: #fff;
}

.blueButton[disabled] {
  background: #e6e6e6;
  color: #6e7282;
}

.selectTestDataOrText {
  display: $display__flex;
  align-items: $align_items__center;
}

.inputTestDataOrText {
  width:22rem;
  border-radius: 0.5rem;
  border: 0.06rem solid $silver;
  color: $textGray;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__600, $font-size__16);
}

.checkBoxText {
  color: $grayColor;
  @include update_fonts($font_weight__500, $font-size__15);
}

.checkBox {
  margin-left: 0.6rem;
}

.checkBoxOrText {
  gap: 0.62rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.testDataOptions {
  margin-top: 0.188rem;
}

.selectTestDataButton {
  border-radius: 0.25313rem;
  margin-right: 0.6rem;
  margin-top: 0.06rem;
  border: 0.675px solid $blue;
  background: $blue;
  background-color: $blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.disableSelectTestData {
  border: 0.06rem solid $Gray !important;
  background-color: $Gray !important;
}
