@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.curlContainer {
  background: #fafafa;
  border: 0.06rem solid #dedede;
  border-radius: 0.3rem;
}

.spinner {
  margin-left: -0.195rem;
}

.modalContent {
  font-size: 1.125rem;
  padding: 0.0375rem;
  display: $display__flex;
}

.modalText {
  align-self: flex-start;
}

.marginRight {
  margin-right: 0.3rem;
}

.deviceListPopover {
  margin-left: 0.008125rem;
  color: #4a9bf2;
  cursor: $cursor__pointer;
  font-weight: $font_weight__500;
}

.tooltipContent {
  font-weight: bold;
  font-size: 0.875rem;
  display: $display__flex;
}

.tooltip {
  margin-right: 0.3rem;
}

.blueFontColor {
  color: #111548;
  cursor: $cursor__pointer;
}

.fontSize {
  font-size: 0.75rem;
}

.noDeviceText {
  color: #4a9bf2;
  font-weight: $font_weight__500;
}

.buildTOBeUsedText {
  color: #4a9bf2;
  cursor: $cursor__pointer;
}

.adminsDetails {
  display: $display__flex;
  align-items: $align_items__center;
}

.adminsText {
  margin-left: 0.008125rem;
  display: $display__flex;
}

.headerOfTestCoverage {
  font-weight: bold;
  font-size: 0.8125rem;
  color: #3d3d49;
  margin-top: 0.01875rem;
}

.details {
  color: #4a9bf2;
  cursor: $cursor__pointer;
}

.actionIcon {
  padding: 0.078125rem;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.icons {
  padding-left: 0.6rem;
  cursor: $cursor__pointer;
}

.spinnerCenter {
  min-height: 5.625rem;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.innerHeader {
  background-color: $white;
  display: $display__flex;
  justify-content: $justify_content__space_between;
  align-items: $align_items__center;
}

.button {
  margin-left: 0.6rem;
  background: #444aea;
  color: $white;
}

.button:hover {
  margin-left: 0.0375rem;
  background: #444aea;
  color: $white;
}

.button[disabled] {
  background: #e6e6e6;
  color: #6e7282;
  margin-left: 0.0375rem;
}

.mainHeaderText {
  color: #111548;
  margin-left: 0.0375rem;
  padding: 0.75rem;
  font-weight: $font_weight__600;
  font-size: $font_size__20;
}

.buttonContainer {
  margin-right: 0.9375rem;
}

.pageScroll {
  height: calc(6.25rem - 8.625rem);
  overflow-y: scroll;
}

.permissionFilters {
  margin: 0.9375rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
  align-items: $align_items__center;
}

.selectAllBox {
  margin-top: 0.0375rem;
  margin-bottom: 0.0375rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.tableComponent {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  margin: 0.9375rem;
}

.pagination {
  float: right;
}

.label {
  color: #636467;
  font-weight: $font_weight__400;
  font-size: $font_size__12;
}

.buttonForActionStep {
  gap: 0.625rem;
  @include designer_header_button($white, $textGray, $Gray);
}