@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  display: $display__flex;
  flex-wrap: $flex__wrap;
}

.welcome_Text {
  color: $Dark_Black;
  @include update_fonts($font_weight__500, 1.31rem);
}

.custom-input-wrapper {
  position: relative;
  margin-top: 0.5rem;
}

.custom-input-label {
  position: absolute;
  left: 0.9rem;
  padding: 0 0.5em;
  z-index: 1;
  color: #84818a;
  background-color: $white;
  @include update_fonts($font-weight__400, $font_size__14);
}

.modal-input {
  padding: 1.38rem 1.38rem 1.3rem 1.57rem !important;
  border-radius: 0.75rem !important;
  border: 0.06rem solid #dcdbdd !important;
  margin: 0.6rem 0;
  color: $textBlack !important;
  background-color: $white !important;
  font-size: $font_size__15 !important;
  @include update_fonts($font-weight__400, $font_size__15);
}

.btn_Next,
.btn_Next:hover {
  height: 2.75rem !important;
  border-radius: 0.75rem !important;
  margin: 0.6rem 0 0.9rem;
  color: $white;
  background-color: $Dark__Blue !important;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  cursor: $cursor__pointer;
}

.btn_Next > span {
  font-size: $font_size__16 !important;
  @include update_fonts($font-weight__500, $font_size__16);
}

.create_Organization_Heading {
  color: $Dark_Black;
  @include update_fonts($font-weight__500, $font_size__32);
}

.steps_Text {
  float: right;
  color: $Dark_Black;
  @include update_fonts($font_weight__500, $font_size__14);
}

.modal-select-box {
  margin: 0.6rem 0 0.9rem;
  padding: 0.5rem 0rem 0.3rem 0.4rem;
  border-radius: 0.8rem;
  border: 0.06rem solid #dcdbdd;
  width: 100%;
  color: #353535;
  background-color: $white;
}

.skip_Button {
  height: 2.75rem !important;
  border-radius: 0.75rem !important;
  margin: 0.6rem 0 0.9rem;
  border: 0.06rem solid $Dark__Blue;
  cursor: $cursor__pointer;
  color: $Dark__Blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font-weight__500, $font_size__16);
}
