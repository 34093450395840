@mixin designer_header_button($bgColor: $blue, $color: $white, $border: "") {
  background-color: $bgColor;
  border-radius: 0.625rem;
  padding: 0 0.625rem;
  height: 2.5rem;
  display: $display__flex;
  color: $color;
  align-items: $align_items__center;
  font-weight: $font_weight__500;

  @if $border != "" {
    border: 0.0625rem solid $border;
  }
}

@mixin designer_list_header {
  height: 4rem;
  border-radius: 0.875rem;
  border: 0.0625rem solid #d6e0ee;
  font-weight: 500;
  font-size: $font_size__12;
  background: $white;
  display: $display__flex;
  align-items: $align_items__center;
}

@mixin design_Actions_icon {
  gap: 0.0625rem;
  flex-direction: column;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

@mixin update_fonts($font_weight, $font_size: $font_size__16) {
  font-family: $font_family__Causten;
  font-weight: $font_weight;
  font-size: $font_size;
}

@mixin recTestStepOrderName {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  border-radius: 0.5rem;
  border: 0.0875rem solid #dfdfdf;
  box-shadow: 0.25rem 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin onHoverActionIcon {
  display: $display__flex;
  align-items: $align_items__center;
}

@mixin onHoverActionsText {
  white-space: nowrap;
  color: $textGray;
}

@mixin scenarioOrBlockHeader {
  padding: 0 0.625rem;
  box-shadow: 0.25rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.1);
  background: $Brilliance;
  display: $display__flex;
  justify-content: $justify_content__space_between;
  align-items: $align_items__center;
}

@mixin postActionSettings {
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  color: $white;
  background-color: $blue;
  display: $display__flex;
  justify-content: $justify_content__center;
  cursor: $cursor__pointer;
  @include update_fonts($font_weight__500, $font-size__14);
}

//update UI
@mixin designer_header_options_button($bgColor: $blue, $color: $white, $border: "") {
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.3rem 0.81rem;
  flex-direction: column;
  gap: 0.6rem;
  border-radius: 0.6rem;
  border: 0.06rem solid #e6e6e6;
  background: $bgColor;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

@mixin actions_list_header {
  height: 4rem;
  border-radius: 0.875rem;
  font-weight: 500;
  font-size: $font_size__12;
  display: $display__flex;
  align-items: $align_items__center;
}

@mixin rec_Test_Action_OrderName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  margin-left: 0.6rem;
  min-width: 3.88rem;
  height: 2.75rem;
  border-radius: 0.8rem;
  box-shadow: 0.3rem 0.3rem 1.3rem 0rem rgba(0, 0, 0, 0.05);
  display: $display__flex;
  text-align: $text_align__center;
  cursor: $cursor__pointer;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

@mixin rec_Test_Step_Order_Name {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  box-shadow: 0.25rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.05);
  border-right: 0.0875rem solid $borderColor;
  background: $white;
  display: $display__flex;
  cursor: $cursor__pointer;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

@mixin common_button($bg-color, $text-color,$height) {
  background-color: $bg-color !important;
  gap: 0.6rem;
  height: $height !important;
  border-radius: 0.6rem !important;
  padding: 0.25rem 0.81rem !important;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  span {
    color: $text-color !important;
    @include update_fonts($font-weight__600, $font_size__16);
  }
  &[disabled],
  &:disabled {
    background-color: #e6e6e6 !important;
    span {
      color: #6e7282 !important;
    }
  }
}

@mixin common_Select_Box($width) {
  border-radius: 0.3rem;
  padding: 0.25rem;
  border: 0.06rem solid #d6d6d6 !important;
  width: $width;
  .ant-select-selector {
    border: 0rem solid $silver !important;
    width: 100% !important;
    border-radius: 0.5rem !important;
  }
}

@mixin ellipse_Text() {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  word-break: break-word;
  cursor: $cursor__pointer;
}
