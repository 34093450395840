@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.banner_Container {
  background-color: #f4f7f7;
  flex-direction: column;
  padding-left: 1.38rem;
  height: 100%;
  gap: 2.5rem;
  display: $display__flex;
  text-align: $text_align__center;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.features {
  margin: 0.6rem 0;
  flex-direction: row;
  gap: 1.38rem;
  padding: 0.6rem 1.25rem;
  border-radius: 0.6rem;
  background-color: #d9e4ff;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.features span {
  text-align: left;
  color: #002858;
  @include update_fonts($font_weight__600, $font-size__17);
}

.header {
  color: #1c2053;
  text-align: $text_align__center;
  @include update_fonts($font_weight__700, $font_size__34);
}

.banner_Sub_Header {
  color: #1c2053;
  text-align: $text_align__center;
  @include update_fonts($font_weight__500, $font_size__28);
}

.android_Ios_Text {
  color: $Dark__Blue !important;
}

.AI_Icon_And_Header {
  gap: 0.88rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.banner_Header_With_AI_Icon {
  gap: 0.6rem;
  flex-direction: column;
  display: $display__flex;
  justify-content: $justify_content__center;
  text-align: $text_align__center;
  align-items: $align_items__center;
}
.svg_container {
  width: 100%;
  max-width: 62.63rem;
  aspect-ratio: 1002 / 555;
  display: $display__flex;
  justify-content: $justify_content__center;

  @media (max-width: 768px) {
    width: 50%;
  }

  svg {
    margin: 1.25rem;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}