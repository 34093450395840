@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.menuItems {
  text-align: center;
  display: $display__flex !important;
  align-items: $align_items__center;
  @include update_fonts($font_weight__500, $font-size__16);
}

.mainHeader {
  position: fixed;
  z-index: 10;
  top: 0rem;
  padding: 0 1.25rem;
  height: 3.4375rem;
  width: $width__100;
  display: $display__flex;
  background: $main_Header_BackgroundColor;
  justify-content: $justify_content__space_between;
  align-items: $align_items__center;
  font-family: $font_family__Causten !important;
}

.mainMenu {
  line-height: 3.2 !important;
  background: $transparent;
  color: $white;
  border: $border__none;
  align-items: $align_items__center;
}

.logo {
  width: 3.4375rem;
  padding-right: 0.9375rem;
  position: relative;
}

.logo__section {
  align-items: $align_items__center;
  display: $display__flex;
}

.container {
  margin-top: 3.4375rem;
  display: $display__flex;
  flex-wrap: $flex__wrap;
}

.menuOptions {
  display: inline-flex;
  align-items: flex-start;
  gap: 1.5625rem;
  padding: 0.3rem;
}

.show_Device_View_Button {
  top: 50%;
  z-index: 1;
  position: absolute;
}

//media query for mainMenu
@media (min-width: $min_width__768) {
  .mainMenu {
    min-width: $min_width__300;
    max-width: $max_width__400;
  }
  .md {
    display: $display__none;
  }
}

@media (min-width: $min_width__1200) {
  .mainMenu {
    height: 3.3125rem;
    min-width: $min_width__750;
    margin-top: -0.0625rem;
  }
  .md {
    display: $display_inline__block;
  }
}

@media (max-width: $max_width__767) {
  .mainMenu {
    max-width: $max_width__300;
  }
  .mainMenu.ant-menu-horizontal > .ant-menu-submenu-selected {
    padding: 0rem 0.625rem;
    width: 2.1875rem;
    height: 2.1875rem;
    line-height: 2.25rem;
    border-radius: $border_radius__5;
    border: $border__none;
    font-weight: $font_weight__500;
    font-size: $font_size__14;
  }
  .md {
    display: $display__none;
  }
  .xs-my-30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .moreMenu .ant-dropdown-menu {
    top: 3.5rem;
  }
}
