@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

//Verification-Drawer
.identification-Rules-Drawer {
  .ant-drawer-wrapper-body {
    border-radius: 0rem 1.13rem 1.13rem 0rem;
    background: #c99494;
    box-shadow: 0.25rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.05);
  }
}

.drawer-title {
  color: $textGray;
  @include update_fonts($font-weight__700, $font_size__18);
}

.hideRulesDrawer {
  display: $display__none;
}

.infoMessage {
  padding: 0.94rem 0.94rem 0px 0.94rem;
  font-size: 0.81rem;
  margin-bottom: 0.6rem;
  font-weight: $font_weight__400;
  color: $textBlack;
}

.drawer-Footer {
  gap: 0.94rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.save-Icon-And-Label {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.save-button {
  border-radius: 0.56rem;
  box-shadow: 0.25rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.05);
  border: 0.1rem solid $Dark__Blue;
  color: $white !important;
  background: $Dark__Blue !important;
}

.save-button:hover {
  color: $white !important;
  background: $Dark__Blue !important;
}

.save-button[disabled] {
  color: $disableText !important;
  background: $Gray !important;
}

.save-button[disabled]:hover {
  background: $Gray !important;
  color: $disableText !important;
}

.reset-Button {
  border-radius: 6.087px;
  border: 0.898px solid $text_Red !important;
  background: rgba(238, 11, 11, 0.05);
  color: $text_Red;
}
.reset-Button:hover {
  border-radius: 6.087px;
  background: rgba(238, 11, 11, 0.05);
  border: 0.898px solid $text_Red;
  color: $text_Red;
}

.reset-Button[disabled] {
  border: none !important;
  background: $Gray;
  color: $disableText;
}
.reset-Button[disabled]:hover {
  background: $Gray;
  color: #6e7282;
  border: none !important;
}

//VerificationBuilders
.operators-and-add-rules {
  padding: 0.6rem;
  gap: 0.88rem;
  padding-left: 1.38rem;
  border: 0.06rem solid #e4e4e4;
  border-bottom: 0 !important;
  border-top: 0 !important;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.verifications_Border {
  border-top: 0.06rem solid #e4e4e4 !important;
  border-bottom: 0.06rem solid #e4e4e4 !important;
}

//add-rules-Dropdown
.add-Rules-Dropdown {
  cursor:$cursor__pointer;
  @include common_button(#c5ecdd, #00ab55,2.75rem);
}

.add-Rules-Dropdown > span {
  color: $white;
  @include update_fonts($font-weight__600, $font_size__16);
}

.verify-Element-Label-And-Select {
  padding: 1rem 1.25rem;
  border: 0.06rem solid #e4e4e4;
  flex-direction: column;
  gap: 0.94rem;
  border-bottom: 0.06rem solid #e4e4e4;
  display: $display__flex;
}

.verify-Element-Label {
  color: $textBlack;
  @include update_fonts($font-weight__600, $font_size__15);
}

.selectBox-and-Remove-Icon {
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.input-box {
  border-radius: 0.25rem;
  border: 0.06rem solid #d6d6d6;
  width: 11.56rem;
  background: $white;
  &:hover {
    border: 0.06rem solid #b0b1c1 !important;
  }
}

.input-Box-And-Select {
  gap: 0.6rem;
  flex-wrap: wrap;
  display: $display__flex;
}

.drawer-Main_Header {
  padding-left: 1.38rem;
  padding-top: 0.69rem;
  color: $textGray;
  @include update_fonts($font-weight__400, $font_size__16);
}

.group-Verification-Rules {
  border-top: 0.06rem solid #e4e4e4;
  border-bottom: 0.06rem solid #e4e4e4;
  border-left: 0.06rem solid #e4e4e4;
  background: #f3f6ff !important;
  padding-left: 0.6rem;
}

.verification_operator_label {
  text-transform: uppercase;
  border-radius: 0.38rem;
  box-shadow: 0 0.06rem 0.25rem 0 rgba(0, 0, 0, 0.15);
  width: 2.63rem;
  margin: 0.6rem 0rem 0.6rem 0.75rem;
  padding: 0.56rem 0.5rem 0.56rem 0.56rem;
  height: 2.19rem;
  border: 0.06rem solid $Dark__Blue;
  color: $Dark__Blue;
  background-color: $white;
  @include update_fonts($font-weight__500, $font_size__13);
}

.verification-group-main-handler {
  padding-left: 0.6rem;
}

.addVerification {
  padding: 1.56rem;
  color: #006cd8;
  text-align: $text_align__center;
}
