@import "./variables.scss";
@import "./mixins.scss";

body {
  font-family: "Inter";
}
html,
body {
  height: auto;
}

// main menu
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  color: $white;
  border-bottom: 0.25rem solid $Dark__Blue;
}
.ant-menu-horizontal,
.ant-menu-item::after,
.ant-menu-submenu::after {
  color: $white;
  border-bottom: none !important;
}
.ant-menu-item-disabled {
  color: $darkGray !important;
  cursor: $cursor__not_allowed;
}

// radio button
// .ant-radio-wrapper:hover .ant-radio,
// .ant-radio:hover .ant-radio-inner,
// .ant-radio-input:focus + .ant-radio-inner,
.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid #d0d5dd !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #002858;
  border-color: #002858 !important;
}
.ant-checkbox-inner::after {
  top: 47%;
  width: 0.357rem;
  height: 0.696rem;
}

.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 0.825rem 0.825rem;
}

// common classes
.display__flex {
  display: $display__flex;
}

.align_Items__Center {
  align-items: $align_items__center;
}

.width-full {
  width: 100% !important;
}

.display__none {
  display: $display__none;
}
.padding_right__10 {
  padding-right: 0.625rem;
}

.position__relative {
  position: relative;
}

.cursor__not_allowed {
  cursor: $cursor__not_allowed !important;
}

.cursor__pointer {
  cursor: $cursor__pointer;
}

.cursor__grab {
  cursor: grab;
}

.bg__light_Blue {
  background: $bgColorLightBlue;
}

.disable_Text {
  color: $disable;
  cursor: $cursor__not_allowed;
}

.border__blue {
  border: 0.0875rem solid $blue;
}

.color__blue {
  color: $blue;
}

.color__white {
  color: $white;
}

.color__darkGrayColor {
  color: $darkGrayColor;
}

.unsavedStepBorder {
  border: 0.0625rem solid #ff4d4fab !important;
}

.disable_bg_color {
  background: #ececec !important;
}

.bg__white {
  background: $white;
}

.bg__gray {
  background: $bgGray;
}

.hover_bg__blue {
  background-color: $hoverBgColor;
}

.hoverBorderBlue {
  border: 0.0875rem solid $borderBlue !important;
}

.disable__text {
  color: $disable !important;
  cursor: $cursor__not_allowed;
}

.order_path_blue {
  background: #d4dfff !important;
  border: none !important;
}

.order-path-border-bottom {
  border-bottom: 0.06rem solid #008ecc !important;
}
.disable_Switch.ant-switch-checked,
.disable_Switch .ant-switch-checked {
  background-color: $grayColor !important;
}

.recTestStepOrderNameBorder {
  border: 0.0875rem solid $borderColor;
}
.ant-dropdown-menu-submenu-popup:after {
  content: "";
  display: block;
  position: absolute;
  z-index: 9;
  top: 1.875rem;
  right: 0rem;
  filter: drop-shadow(0rem 0.0625rem 1.625rem rgba(0, 0, 0, 0.07));
  background-color: #fff;
  box-shadow: 0rem 0.0625rem 0.375rem 0rem rgba(0, 0, 0, 0.3);
  clip-path: polygon(0 3%, 0% 100%, 100% 50%);
  width: 0.9375rem;
  height: 1.25rem;
}

.recTestStepCollapse {
  overflow: hidden;
  border-radius: 0.875rem !important;
  flex: 1;

  .ant-collapse {
    margin: 0rem !important;
    background-color: $white;
  }
  .ant-collapse-header {
    height: 4.3rem;
    padding: 0.75rem 0.25 0.625rem 0.75rem !important;
    align-items: $align_items__center !important;
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 0.825rem 0.825rem !important;
  }

  .ant-collapse-header:hover .showRecTestStepActionsIconOnHover {
    display: block !important;
  }

  .ant-collapse-header:hover .showActionsIconOnHover {
    display: block !important;
  }

  &:not(.disable_bg_color) > div > div.ant-collapse-header:hover {
    background: $hoverBgColor !important;

    .collapseIconOnHover {
      background-color: $white;
    }

    .onHoverStepOrderNameBorder {
      border: 0.0875rem solid $blue;
    }
  }
}

.rec_Test_Step_Collapse {
  overflow: hidden;
  border-radius: 0.875rem !important;
  flex: 1;

  .ant-collapse {
    margin: 0rem !important;
    background-color: $white;
  }

  .ant-collapse-header {
    align-items: center !important;
    height: 4.3rem;
    padding: 0.8rem 0rem 0.8rem 0rem !important;
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 0.825rem 0.825rem !important;
  }

  .ant-collapse-header:hover .showRecTestStepActionsIconOnHover {
    display: block !important;
  }

  .ant-collapse-header:hover .showActionsIconOnHover {
    display: block !important;
  }

  &:not(.disable_bg_color) > div > div.ant-collapse-header:hover {
    background: #f3f6ff !important;

    .collapseIconOnHover {
      background-color: $white;
    }

    .onHoverActionOrderNameBorder {
      border: 0.06rem solid $Dark__Blue;
    }
  }
}

.recTestStepSteps {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0.8rem !important;
  }
}

.recTestStepActions {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 1rem !important;
  }
}

.recTestStepCollapse:not(.border-purple) {
  border: 0.0875rem solid $borderColor;
}

.rec_Test_Step_Collapse:not(.border_Blue) {
  border: 0.06rem solid #dfdfdf;
}

.visibleActionsMenu {
  background: $hoverBgColor !important;
}

.actionsMenuOnHover {
  background: $hoverBgColor !important;
}

.showActionsIconsOnHover {
  display: block !important;
}

.showRecTestStepActionsIconOnHover {
  display: none;
}

.showActionsIconOnHover {
  display: none;
}

.iconsOnHoverOfActions {
  display: block !important;
}

.recTestStepName {
  @include update_fonts($font_weight__700, $font-size__16);
}

.ant-dropdown-menu-item {
  font-weight: 400;
  font-size: $font_size__13;
  color: $navyBlue;
  padding: 0.69rem;
  line-height: 1.1rem;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  top: 50%;
  transform: translateY(-50%);
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background: $lightBlue !important;
  color: var(--app-font-color) !important;
}

.ant-dropdown-menu-submenu-popup ul {
  border-radius: 1.375rem;
  box-shadow: 0rem 0.0625rem 0.375rem 0rem rgba(0, 0, 0, 0.3);
  margin-right: 0.9rem !important;
  border: none;
}

.ant-dropdown-menu-submenu-popup ul > ul {
  margin-right: 0 !important;
}

.ant-dropdown-menu-submenu-popup {
  margin-left: 1.1875rem !important;
}

.ant-dropdown-menu-submenu-popup ul > li:hover {
  overflow: hidden !important;
  background-color: $lightBlue !important;
}

.ant-dropdown-menu-submenu-popup ul > ul > li:hover {
  overflow: hidden !important;
  background-color: $lightBlue !important;
}

.collapseIconBgColor {
  background-color: $white !important;
}

.border_darkBlue {
  border: 0.088rem solid #002858 !important;
}

.selected-step-or-action {
  border-bottom: none;
  border-right: 0.1rem solid $Dark__Blue !important;
  background: $Dark__Blue;
}

.border_onDragOver {
  border: 0.088rem double !important;
}

.projects-menu {
  overflow: auto;
  margin-left: -0.75rem;
  margin-top: 0.5rem;
  min-width: $max_width__300;
  max-height: $max_width__400;
}
.projects-menu li {
  padding: 0.313rem;
  background: $white;
}
.projects-menu li:last-child {
  border-bottom: none;
}
.projects-menu li .ant-row {
  padding: 0.625rem;
  border-radius: 0.25rem;
}
.projects-menu li .ant-row.selected {
  border: 0.06rem solid #6c6e8d;
  background: #f8f8f8;
}

.deviceTab .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  margin-left: 1.1rem;
}

.settingsDrawer .ant-drawer-body {
  padding: 0rem !important;
}

.ant-drawer-mask {
  background: rgba(0, 0, 0, 0) !important;
}

.elementDetailsDrawer .ant-drawer-body {
  background: $white !important;
  margin-top: 0.4rem;
}

.selectApplicationLocale {
  margin-top: 0.3rem;
  margin-bottom: 1.3rem;
  border-radius: 0.313rem;
  border: 0.06rem solid rgb(176, 177, 193) !important;
  width: 100%;
  .ant-select-selector {
    width: 100% !important;
    border-radius: 0.313rem !important;
  }
}

.setAppLocalValue {
  border-radius: 0.313rem;
  border: 0.06rem solid rgb(176, 177, 193) !important;
  width: 100%;
  .ant-select-selector {
    width: 100% !important;
    border-radius: 0.313rem !important;
  }
}

//new UI
.border_Blue {
  border: 0.1rem solid $Dark__Blue !important;
}

.hover_Border_Dark_Blue {
  border: 0.06rem solid $Dark__Blue !important;
}

.selectDeviceLocale {
  margin-top: 0.3rem;
  margin-bottom: 1.3rem;
  border-radius: 0.313rem;
  border: 0.06rem solid rgb(176, 177, 193) !important;
  width: 100%;
  .ant-select-selector {
    width: 100% !important;
    border-radius: 0.313rem !important;
  }
}

.selectActions {
  border-radius: 0.3rem;
  padding: 0.2rem;
  border: 0.06rem solid #d6d6d6 !important;
  width: 17.5rem;
  .ant-select-selector {
    border: 0rem solid $silver !important;
    width: 100% !important;
    border-radius: 0.5rem !important;
  }
}

.searchDeviceInput {
  border: none !important;
  box-shadow: none !important;
  padding-left: 0.3rem;
  background-color: transparent !important;
  padding: 0 !important;
  .ant-input {
    background-color: transparent !important;
    @include update_fonts($font_weight__400, $font_size__16);
  }
  .ant-input-lg {
    height: 3rem;
  }
}

.disable_Element.ant-switch-checked,
.disable_Element .ant-switch-checked {
  background-color: #34c759 !important;
}

.onHoverDeviceCard {
  display: $display__none;
}

.deviceDetailsCard {
  width: 13.8125rem;
  margin: 0.6rem;
  font-size: 0.81rem;
  color: #4a4c69;
  border-radius: 1.125rem;
  background: #f5f5f5;
  box-shadow: 0 0 0.9rem 0 rgba(0, 0, 0, 0.25);
}

.deviceCardItems {
  height: 13.25rem;
  width: 15.81rem;
  margin: 0.6rem;
  font-size: 0.81rem;
  color: #4a4c69;
  border-radius: 1.125rem;
  background: #f5f5f5;
  box-shadow: 0 0 0.9rem 0 rgba(0, 0, 0, 0.25);
  position: relative;
}

.useButton {
  border-radius: 0rem 0rem 1.125rem 1.125rem;
  width: 15.81rem;
  height: 2.75rem;
  bottom: 0;
  position: absolute;
  background-color: $white;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.deviceCardItems:hover:not(:has(.deviceCardFooter:hover)),
.visibleModal {
  background-color: $darkBlue;
  .useButton {
    background-color: $blue;
  }
  .onHoverDeviceCard {
    display: block;
    padding-top: 0.13rem;
  }
  & .showDeviceDetails {
    display: $display__none;
  }

  .deviceIdDetails {
    display: $display__none;
  }
  .useDeviceText,
  .scheduledBy {
    color: $white;
  }
  .usedText {
    color: $white;
  }
  .deviceIconContainer,
  .lockIcon {
    color: $white;
  }
  .releaseDeviceIconColor {
    color: $white;
  }
}

.usedText {
  color: $blue;
  cursor: $cursor__pointer;
  @include update_fonts($font_weight__700, $font_size__15);
}

.deviceIconContainer {
  color: $blue;
}

.lockIcon {
  color: $red;
}

.releaseDeviceIconColor {
  color: $pink;
}

.useDeviceText {
  color: $blue;
  cursor: $cursor__pointer;
  @include update_fonts($font_weight__600, $font_size__16);
}

.scheduledBy {
  margin-top: 0.3rem;
  color: $textGray;
  @include update_fonts($font_weight__400, $font_size__14);
}

.deviceDetailsModal {
  .ant-modal-content {
    width: 17.81rem;
    position: relative;
    border-radius: 1.125rem;
    box-shadow: 0 0 1.768rem 0 rgba(0, 0, 0, 0.25);
    background: $white;
  }
  .ant-modal-body {
    overflow: visible;
    padding: 0;
  }
  .ant-modal-footer {
    display: $display__none;
  }
  .ant-modal-close-x {
    margin-top: 0.9rem;
    margin-right: -0.81rem;
  }
}

.advancedSelectorDrawer {
  .ant-drawer-body {
    padding: 0rem !important;
    overflow: hidden;
    background: $white;
  }
}

.deviceElement {
  .ant-drawer-content-wrapper {
    transition: all 0s !important;
  }
}

.searchElementBox {
  .ant-input {
    height: 1.88rem;
    font-size: $font_size__14;
  }
  border-radius: 0.4rem;
  border: 0.06rem solid $silver;
}

html body .import-scenario-step-tree .ant-tree-node-content-wrapper {
  padding: 0;
}
html body .ant-tree-list-holder-inner > .ant-tree-treenode:not(:first-child) .ant-tree-title > div > div:after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: transparent;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="26" viewBox="0 0 18 26" fill="none"><path d="M17.495 20.995C17.7683 20.7216 17.7683 20.2784 17.495 20.005L13.0402 15.5503C12.7668 15.2769 12.3236 15.2769 12.0503 15.5503C11.7769 15.8236 11.7769 16.2668 12.0503 16.5402L16.0101 20.5L12.0503 24.4598C11.7769 24.7332 11.7769 25.1764 12.0503 25.4497C12.3236 25.7231 12.7668 25.7231 13.0402 25.4497L17.495 20.995ZM0.8 0V16.5H2.2V0H0.8ZM5.5 21.2H17V19.8H5.5V21.2ZM0.8 16.5C0.8 19.0957 2.90426 21.2 5.5 21.2V19.8C3.67746 19.8 2.2 18.3225 2.2 16.5H0.8Z" fill="%23D1D1D1"/></svg>');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  position: absolute;
  top: 0rem;
  left: -1.63rem;
  opacity: 1;
}
.treeData {
  overflow-y: hidden;
}
.ant-tree-switcher-leaf-line {
  opacity: 0;
}
.ant-tree-title > div > div:after {
  opacity: 0;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  top: -3rem;
  right: 1rem;
  border-right: 0.13rem solid #d9d9d9;
}
.ant-tree-title > div > div::before {
  left: -1.1rem !important;
  width: 0.13rem !important;
}
.ant-tree-show-line .ant-tree-switcher:before,
.ant-tree-show-line .ant-tree-switcher:after {
  opacity: 0;
}
.ant-tree-list-holder-inner > .ant-tree-treenode:not(:first-child) {
  padding-left: 1.88rem;
}

.ant-tree .ant-tree-treenode {
  padding: 0 !important;
}

.importStepsOrActionsModal {
  .ant-modal {
    border-radius: 1.56rem;
    box-shadow: 0 0.3rem 2rem 0 rgba(0, 0, 0, 0.25);
    background: $white;
  }

  .ant-modal-content {
    border-radius: 1.56rem;
    box-shadow: 0 0.3rem 2rem 0 rgba(0, 0, 0, 0.25);
    background: $white;
  }
  .ant-modal-body {
    background-color: #f3f3f3;
    min-height: 28.13rem;
  }
  .ant-modal-header {
    background-color: transparent;
  }
}

.create_Build_Modal {
  .ant-modal-body {
    max-height: 32.13rem !important;
    background-color: $white !important;
  }
}

.device_Push_Build_Modal {
  .ant-modal-body {
    background-color: $white !important;
  }
}

.searchScenarioOrTestBlockForImport {
  .ant-input {
    height: 1.8rem !important;
  }
}
.intersectElementPopover {
  .ant-popover-inner {
    border-radius: 1.5rem !important;
    border: 0.13rem solid $blue !important;
    background: $white;
    box-shadow: 0rem 0.3rem 2.06rem 0rem rgba(0, 0, 0, 0.3) !important;
  }

  .ant-popover-inner-content {
    padding: 1.88rem;
  }
}

.elementOptionsCollapse {
  border-radius: 0.8rem;
  border: 0.06rem solid #dde5f6;

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 1.3rem 1.3rem !important;
    max-height: 11rem !important;
    overflow-y: auto !important ;
  }
  .ant-collapse .ant-collapse-item-active.ant-collapse-header {
    border-bottom: 0.06rem solid #dde5f6 !important;
  }
}

.collapseOfChildSteps {
  .ant-collapse-header {
    padding: 0rem !important;
  }
}

.assignTestDataModal,
.displayExecutionProfileModal {
  .ant-modal-body {
    min-height: 15.63rem;
  }
}

.counterDownTimerModal {
  .ant-modal {
    border-radius: 1.56rem;
    box-shadow: 0 0.3rem 2rem 0 rgba(0, 0, 0, 0.25);
    background: $white;
  }

  .ant-modal-content {
    width: 24.38rem;
  }

  .ant-modal-footer {
    border-top: none;
    padding-bottom: 0.6rem;
    padding-right: 0;
  }

  .ant-modal-body {
    padding: 0 1.38rem 1.38rem 1.38rem;
    text-align: center;
  }
}

.input-text-modal {
  width: 90%;
  margin: 0 auto;
  position: absolute !important;
  z-index: 9999;
}

.devicePushBuildModal {
  .ant-modal-body {
    overflow: hidden;
  }
}

.recordingSettingsModal {
  .ant-modal-body {
    padding: 0.9rem 2.19rem;
  }
}

.table_Details {
  margin-left: 1.88rem;
}

.showDeviceDetails {
  .ant-popover-inner-content {
    height: calc(40vh - 3.13rem);
    overflow-y: scroll;
  }
}

.hoverDragAndDrop {
  height: 0.325rem;
}
.hoverDragAndDropAction {
  height: 0.13rem;
}

.hoverEffectClass {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  height: 4.3rem;
  margin: 0px 0.625rem;
  padding: 0 0.625rem !important;
  border-radius: 0.6rem !important;
  background: #f3faff !important;
  border: 0.088rem double #1890ff !important;
}

.dragOverClass {
  margin: 0.13rem 0;
}

.dragAndDrop {
  display: $display__none;
}

.dragAndDropText {
  color: #1890ff;
  display: inline;
  margin: 0.13rem;
  @include update_fonts($font_weight__600, $font_size__16);
}

.cloneModalContent {
  border-radius: 0.69rem;
  background-color: #1e191930;
  padding: 0.4rem;
  color: black;
  margin-top: 0.9rem;
  font-size: $font_size__13;
}

.modal-content {
  color: #4a4c69;
  margin-top: 0.9rem;
  @include update_fonts($font_weight__400, $font_size__14);
}

.confirmModalTitle {
  gap: 0.44rem;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__600, $font_size__14);
}

.confirmModalIcon {
  font-size: $font_size__24;
  color: #4a4c69;
}

.modalTitleColor {
  color: $navyBlue;
}
.ant-modal-confirm .ant-modal-body {
  padding: 1.5rem 1.63rem;
}

.close-Icon {
  position: relative;
  width: 3.44rem;
  height: 3.44rem;
  padding-top: 1rem;
  border-left: 0.06rem solid black;
  text-align: $text_align__center;
}

.spinner-center {
  min-height: 90vh;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.spinner-center-testPlanResult {
  min-height: 20vh;
  display: $display__flex;
  justify-content: $justify_content__center;
  align-items: $align_items__center;
}

.page-Scroll {
  height: calc(100vh - 8.63rem);
  overflow-y: scroll;
}

.page-Scroll-Of-Test-Data {
  height: calc(100vh - 16.13rem);
  overflow-y: scroll;
}

.ant-collapse.drag-drop-panel {
  border-radius: 0.3rem;
  font-size: $font_size__13;
  color: #4a4c69;
}

.test-data-collapse {
  border: 0.06rem solid #c3c5d2;
  border-radius: 0.3rem;
  margin-bottom: 0.6rem;
}

.test-data-collapse .ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-item,
.ant-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-item.ant-collapse-item-active {
  border-bottom: 0rem;
}

//CheckBox
.selection-checkBox {
  .ant-checkbox .ant-checkbox-inner {
    border-radius: 0.4rem;
    border: 0.06rem solid $Dark__Blue !important;
    background: #f9f5ff !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border: 0.06rem solid $Dark__Blue !important;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #d9d9d9 !important;
    border: 0.06rem solid rgb(165, 172, 182) !important;
  }
  .ant-checkbox-inner::after {
    border-color: $Dark__Blue !important;
  }
  .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.25) !important;
  }
}

.AI_Selection_Checkbox {
  display: $display__flex;
  align-items: $align_items__center;
  .ant-checkbox + span {
    margin-top: 0.31rem;
  }
}

.checkBox-In-Table-Header {
  margin-top: -0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #0a2859 !important;
}

//Repeat condition collapse
.repeat_Condition_Collapse {
  overflow: hidden;
  border-radius: 0.75rem !important;
  box-shadow: 0.25rem 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.05);
  background-color: #f3f6ff !important;
  .ant-collapse-header {
    height: 5.13rem !important;
    padding: 0.8rem 1rem 0.8rem 0.8rem !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    background-color: #f3f6ff !important;
  }
}

.device_More_Details_Modal {
  .ant-modal-content {
    max-width: 32.81rem;
    position: relative;
    border-radius: 1.125rem;
    box-shadow: 0 0 1.768rem 0 rgba(0, 0, 0, 0.25);
    background: $white;
  }
  .ant-modal-body {
    overflow: visible;
    padding: 0;
  }
  .ant-modal-footer {
    display: $display__none;
  }
  .ant-modal-close-x {
    margin-top: 0.9rem;
    margin-right: -0.81rem;
  }
}

.saucelabs_Device_More_Details {
  width: 25rem !important;
  .ant-modal-content {
    max-width: 22.81rem !important;
  }
}

/* Remove autofill background color in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0.06rem solid #dcdbdd !important;
  -webkit-text-fill-color: #000 !important;
  -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.stop_Ai_Generate_spinner {
  line-height: 1 !important;
  .ant-spin-dot-item {
    background-color: #d3d3d3;
  }
}

@media only screen and (max-width: $max_width__1600) and (min-width: $min_width__1200) {
  .hide_in_mobile .addAction,
  .hide_in_mobile .subHeader,
  .hide_in_mobile .addSubStep,
  .hide_in_mobile .disableSwitch,
  .hide_Button_Label {
    display: none;
  }
}
@media only screen and (max-width: $max_width__1199) {
  .hide_in_mobile .subHeader {
    display: none;
  }
}
@media only screen and (max-width: $max_width__600) {
  .hide_in_mobile .addAction,
  .hide_in_mobile .subHeader,
  .hide_in_mobile .addSubStep,
  .hide_in_mobile .disableSwitch,
  .hide_Button_Label {
    display: none;
  }
}

@media (min-width: $min_width__1562) {
  .button_Tooltip_Visible {
    display: $display__none;
  }
}

.ai_Generate_spinner {
  line-height: 1 !important;
  margin-left: 1.5rem;
  .ant-spin-dot-item {
    background-color: #e19411;
  }
}

.table_SelectBox .ant-select-selector {
  background: $white !important;
}

//test data
.select-Test-Data .ant-select-selection-item {
  pointer-events: none !important;
}
