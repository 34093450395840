@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.textRestart {
  color: $lightGray;
  @include update_fonts($font_weight__400);
}

.devices_actions {
  position: relative;
  max-width: 90%;
  z-index: 1;
  border: 0.06rem solid rgba(0, 0, 0, 0.12);
  padding: 0.8rem;
  border-top-right-radius: 1.8rem;
  border-bottom-right-radius: 1.8rem;
  cursor: $cursor__pointer;
  background: $white;
}

.devices_actions:before {
  content: "";
  width: 2.5rem;
  height: 3.125rem;
  display: block;
  position: absolute;
  z-index: 9;
  left: 0;
  top: -3.1rem;
  border-left: 0.063rem solid rgba(0, 0, 0, 0.12);
  border-bottom: 0.063rem solid rgba(0, 0, 0, 0.12);
  border-radius: 0 0 0 1.813rem;
}

.devices_actions:after {
  content: "";
  width: 2.5rem;
  height: 3.125rem;
  display: block;
  position: absolute;
  z-index: 9;
  left: 0;
  bottom: -3.063rem;
  border-left: 0.063rem solid rgba(0, 0, 0, 0.12);
  border-top: 0.063rem solid rgba(0, 0, 0, 0.12);
  background: white;
  border-radius: 1.813rem 0 0 0;
}

.devices_actions > div {
  position: relative;
}

.devices_actions > div:first-child::before {
  content: "";
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  background-color: #fff;
  top: -1.25rem;
  left: -1.25rem;
}

.devices_actions > div:last-child::before {
  content: "";
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  background-color: #fff;
  bottom: -1.25rem;
  left: -1.25rem;
}

.devices_actions .platform_actions_item {
  cursor: move;
  text-align: center;
  border-right: none;
  border-bottom: none;
  font-weight: bold;
  padding: 0.3rem;
  margin: 0.031rem;
  min-height: 0.9rem;
  padding: 0.3rem;
  margin: 0rem;
  background: $white;
}

.devices_actions .actions_item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1.1rem;
}

.devices_actions .actions_item.last-item {
  width: calc(40% - 0.19rem);
  max-width: calc(40% - 0.19rem);
}

.borderRight {
  border-top-left-radius: 50%;
  border: 0.06rem solid rgba(0, 0, 0, 0.12);
}

.padding_top {
  padding-top: 0.5rem;
}
