@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.title {
  color: #232323;
  @include update_fonts($font_weight__700, $font_size__16);
}

.inputStyle {
  border: none !important;
  width: 95% !important;
  background: transparent !important;
  font-size: $font_size__16 !important;
  @include update_fonts($font_weight__400, $font-size__19);
}

.styledStepOrder {
  min-width: 3.25rem;
  height: 3.25rem;
  @include recTestStepOrderName;
}

.childStyledStepOrder {
  min-width: 3.88044rem;
  height: 3.5rem;
  @include recTestStepOrderName;
}

.disabledTextOrSwitch > span {
  color: $textGray;
  @include update_fonts($font_weight__500);
}

.disableStepOrderName {
  min-width: 3.25rem;
  height: 3.25rem;
  background: #eee !important;
  cursor: $cursor__not_allowed !important;
  @include recTestStepOrderName;
}

.childDisableStepOrder {
  min-width: 3.25rem;
  height: 3.25rem;
  background: #eee !important;
  cursor: $cursor__not_allowed !important;
  @include recTestStepOrderName;
}

.childDisableStepOrder > span,
.disableStepOrderName > span {
  color: $lightGrayTextColor !important;
}

.recTestStepTextInput {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1.25rem;
  width: $width__100;
  display: $display__flex;
  align-items: $align_items__center;
}

.borderRight {
  height: 1.7861875rem;
  padding: 0rem 0.3125rem 0rem 0.625rem;
  border-right: 0.0625rem solid rgba(219, 219, 219, 0.51);
}

.styledStepName {
  overflow: hidden;
  border-radius: 0.875rem !important;
  box-shadow: 0.25rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.05);
  width: 100%;
  min-height: 2.75rem;
  margin-left: auto;
}

.styledStepHeader {
  padding: 0 0.625rem;
  display: $display__flex;
}

.recTestStep {
  display: $display__flex;
  align-items: $align_items__center;
  width: $width__100;
}

.resultIcon {
  gap: 0.3125rem;
  margin-left: 0.625rem;
  margin-top: 0.1875rem;
  margin-right: 0.625rem;;
  display: $display__flex;
  align-items: $align_items__center;
}

.buildTestDataSet {
  display: $display__flex;
  justify-content: $justify_content__center;
}

.dragAndDrop {
  cursor: grab;
}

.buildTestDataSetDropdown {
  color: #338fcc;
  padding: 0.0625rem;
  cursor: $cursor__pointer;
}

.recTestSTepPadding {
  padding: 0.9375rem 0rem 0.9375rem 0rem;
}

.childCollapseIcon {
  margin-top: 0.875rem;
  cursor: $cursor__pointer;
}

.borderRight {
  height: 2.2rem;
  border-right: 0.0625rem solid rgba(0, 0, 0, 0.09);
}

.disabledTextOrSwitch {
  gap: 1rem;
  margin-right: 10px;
  display: $display__flex;
  align-items: $align_items__center;
}

.collapseIcon {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: $bgGray;
  display: $display__flex !important;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.conditionalIcon {
  margin-left: 0.4rem;
}