@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.saveButton {
  background-color: $blue;
  color: $white;
}

.saveButton:hover {
  background: $blue;
  color: $white;
}

.saveButton[disabled] {
  background: $Gray;
  color: $disableText;
}

.footer {
  gap: 0.3rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.verification {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.verifyTextOrSelectBox {
  flex-wrap: $flex__wrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.hideRulesDrawer {
  display: $display__none;
}

.verifyText {
  text-transform: uppercase;
  color: $gray;
  margin-right: 0.3rem;
  @include update_fonts($font_weight__700, $font-size__15);
}

.blueText {
  color: #40a9ff;
  font-size: $font_size__13;
}

.addVerification {
  padding: 1.56rem;
  color: #006cd8;
  text-align: $text_align__center;
}

.verification_operator_label {
  width: 1.9rem;
  margin-left: 0.5rem;
  margin-top: -0.688rem;
  font-size: 0.688rem;
  padding-left: 0.13rem;
  border-radius: 0.19rem;
  padding-right: 0.13rem;
  font-weight: 300;
  z-index: 1;
  position: relative;
  background: #59b2d8;
  text-transform: uppercase;
  color: $textBlack;
  border: 0.06rem solid #e8e8e8;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.verification_items > .verification_operator_label:last-of-type {
  font-weight: bold;
  font-size: 1.13rem;
}

.infoMessage {
  font-size: 0.81rem;
  margin-bottom: 0.6rem;
  font-weight: $font_weight__400;
  color: $textBlack;
}
