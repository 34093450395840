@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.subHeaderIcons {
  @include design_Actions_icon;
}

.subHeaderIcons > span {
  font-size: $font_size__13;
}

.stopButtonText {
  color: $pink;
}

.label {
  cursor: $cursor__pointer;
  @include update_fonts($font_weight__500, $font_size__13);
}

.dropDownArrow {
  gap: 0.375rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.subHeader {
  gap: 1.25rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.actionIconInHeader {
  padding: 0rem 0.9375rem 0rem 0.9375rem;
}

.menuItems {
  gap: 1.0625rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.menuItemsTitle {
  color: $textGray;
}

.actionsDropdown {
  overflow: hidden;
  border-radius: 1.375rem;
  box-shadow: 0rem 0.0625rem 0.375rem 0rem rgba(0, 0, 0, 0.3);
  background: $white;
}

.disable_Text {
  color: #a5a5a5;
  cursor: $cursor__not_allowed !important;
}

@media only screen and (max-width: $max_width__1562) {
  .subHeaderIcons > span {
    display: $display__none;
  }
}
@media (min-width: $min_width__1562) {
  .hideTooltip {
    display: none;
  }
}
