@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.rulesTitle {
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.addRules {
  padding: 0.6rem;
  width: 20.375rem;
  flex-direction: column;
  display: $display__flex;
  align-items: $align_items__center;
  text-align: $text_align__center;
  justify-content: $justify_content__center;
}

.addOrEditRulesButton {
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  gap: 0.4375rem;
  cursor: $cursor__pointer;
  background: $blue;
  color: $bgColorLightBlue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__500, $font_size__14);
}

.editRulesButton {
  gap: 0.4375rem;
  height: 2rem;
  padding: 0rem 0.6875rem;
  border-radius: 0.3125rem;
  background: $blue;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  cursor: $cursor__pointer;
}

.identificationRules {
  text-align: left;
  font-size: 0.81rem;
  border: 0.06rem solid rgb(1, 163, 227);
  margin: 0.6rem 0rem 0.9rem 0rem;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-radius: 0.375rem;
  flex-direction: column;
  gap: 0.56rem;
}

.identificationRulesGroup {
  text-align: left;
  font-size: 0.81rem;
  border: 0.06rem solid rgb(1, 163, 227);
  margin: 0.6rem 0rem 0.9rem 0rem;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-radius: 0.375rem;
  flex-direction: column;
  gap: 0.56rem;
}

.passed{
  margin-left: 5px;
  border: 0.06rem solid #12bf24;
}

.failed{
  margin-left: 5px;
  border: 0.06rem solid #e72e2e;
}

.skipped{
  margin-left: 5px;
  border: 0.06rem solid #ffcb32;
}

.operators {
  margin-top: -0.69rem;
  border: 0.06rem solid #d1effc;
  background: #a2daf1;
  text-transform: uppercase;
  font-size: 0.69rem;
  padding-left: 0.13rem;
  border-radius: 0.19rem;
  color: black;
  padding-right: 0.13rem;
  border: 1px solid #e8e8e8;
  font-weight: 400;
  font-size: 0.6rem;
  width: 2.19rem;
  display: $display__flex;
  justify-content: $justify_content__center;
}

.orBetweenText{
  color: #a2daf1;
  margin: 0.13rem;
}

.moreRules {
  float: left;
}

.verificationRules {
  overflow-y: auto;
}

.disableButton {
  background-color: $Gray;
  color: $disableText;
  cursor: $cursor__not_allowed !important;
}

.rulesText {
  color: $darkGrayColor;
  @include update_fonts($font_weight__700, 1.125rem);
}

.addRulesText {
  color: $grayColor;
  @include update_fonts($font_weight__400, 1.0625rem);
}

.verificationType {
  color: #696a6c;
  @include update_fonts($font_weight__400, $font_size__15);
}

.verificationKeyOrValue {
  margin-left: 0.3rem;
  color: $darkGrayColor;
  @include update_fonts($font_weight__600, $font_size__15);
}

.identificationRulesTitle {
  color: $darkGrayColor;
  @include update_fonts($font_weight__700, $font_size__18);
}

.editRulesTitle {
  color: $white;
  @include update_fonts($font_weight__500, $font_size__15);
}

.disableText {
  color: #6e7282;
}
