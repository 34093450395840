@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.header--button-wrapper,
.header--button-wrapper:hover {
  gap: 00.9rem;
  display: $display__flex;

  &::-webkit-scrollbar {
    display: $display__none;
  }

  button {
    border: 0.06rem solid $Gray !important;
    height: 2.38rem;
    padding: 0.25rem 0.81rem;
    border-radius: 0.6rem;
    color: $Dark__Blue;

    &.active {
      border: 0.06rem solid transparent !important;
      border-radius: 0.6rem;
      color: $white !important;
      background-color: $Dark__Blue;
    }
  }
  button > span {
    text-align: $text_align__center;
    @include update_fonts($font-weight__500, $font_size__16);
  }
}
