@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.replaceElement {
  margin-top: 0.6rem;
  padding: 0.3rem;
  gap: 0.4375rem;
  border-radius: 0.375rem;
  float: left;
  background: $blue;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  color: $bgColorLightBlue;
  @include update_fonts($font-weight__500, $font-size__14);
}

.disableButton {
  background-color: $Gray;
  color: $disableText;
  cursor: $cursor__not_allowed !important;
}

.selectImage {
  display: $display__flex;
}

.bounds {
  margin-left: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.stepImage {
  margin-top: 0.6rem;
}

.stepImageOnHover{
  max-height: 31.25rem;
}