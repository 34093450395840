@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.tab_Name_And_Icon {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.popover_Text {
  color: $textBlack !important;
  @include update_fonts($font_weight__600, $font_size__14);
}

.info_Icon {
  margin-top: 0.25rem;
}
