@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.main_Header {
  top: 3.38rem;
  padding: 0rem 0rem 0rem 1.3rem;
  background-color: #f2f4f7;
  height: 4.13rem;
  border-bottom: 0.06rem solid #ccc;
}

.main-Header-Text {
  color: #002858;
  @include update_fonts($font_weight__700, $font_size__20);
}

.close_Icon {
  position: relative;
  width: 3.44rem;
  height: 2.55rem;
  border-left: 0.06rem solid black;
  display: $display__flex;
  text-align: $text_align__center;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.header_Title {
  color: #202020;
  @include update_fonts($font_weight__600, $font_size__20);
}

.build_Details {
  margin: 1.25rem 0rem;
  height: calc(100vh - 10.06rem);
  overflow-y: scroll;
}

.checkbox_Label {
  margin-top: 0.88rem;
  gap: 0.56rem;
  color: #202020;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font-weight__500, $font_size__14);
}

.modal_Scrollbar {
  padding: 0.75rem;
  max-height: 23.13rem;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0.19rem;
  }
}

.create_Build_Modal {
  padding: 0.75rem;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0.19rem;
  }
}

.upload_Build_Options {
  margin: 0.6rem 0 0.9rem !important;
}

.device_Farm_Label {
  color: #84818a;
  @include update_fonts($font-weight__400, $font_size__16);
}
