@import "../../../../../styles/variables.scss";

.subHeader {
  gap: 1.25rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.actionIconInHeader {
  padding: 0rem 0.9375rem 0rem 0.9375rem;
}

.menuItems {
  gap: 1.0625rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.menuItemsTitle {
  color: $textGray;
}

.actionsDropdown {
  overflow: hidden;
  border-radius: 1.375rem;
  box-shadow: 0rem 0.0625rem 0.375rem 0rem rgba(0, 0, 0, 0.3);
  background: $white;
}
