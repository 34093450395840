@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.label {
  margin-top: 0.3rem;
  text-transform: uppercase;
  color: #505862;
  @include update_fonts($font_weight__700, $font-size__15);
}

.operation_Text {
  white-space: nowrap;
  text-transform: uppercase;
  color: #4a4d53;
  margin-right: 0.44rem;
  @include update_fonts($font_weight__700, $font-size__17);
}

.select_Actions_And_Label {
  gap: 0.19rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.selectBoxBgColor {
  background-color: #f5f5f5;
}

.selectActions {
  @include common_Select_Box(17.5rem);
}

//actions
.actions {
  margin-top: 0.8rem;
  white-space: nowrap;
  display: $display__flex;
  align-items: $align_items__center;
}

.actions_Label {
  color: $grayColor;
  @include update_fonts($font_weight__500, $font_size__16);
}

.disabled_Input_Box {
  border: none !important;
  margin-left: 0.3rem;
  width: 5rem;
  padding: 0;
  color: rgba(0, 0, 0, 0.25) !important;
}

.input_Box {
  border: none !important;
  margin-left: 0.3rem;
  width: 5rem;
  padding: 0;
  color: $Dark__Blue !important;
}

.input_Label {
  margin: 0 0.3rem 0 0.3rem;
  color: rgba(0, 0, 0, 0.6);
  @include update_fonts($font_weight__400, $font_size__16);
}

.input_And_Label {
  width: 7.9375rem;
  display: $display__flex;
  align-items: $align_items__center;
  cursor: $cursor__pointer;
  height: 40px;
  flex-grow: 0;
  margin: 0 0 0 12px;
  padding: 6px;
  border-radius: 4px;
  border: solid 1px #d6d6d6;
  background: #fff;
}

.disable_Input_And_Label {
  background: #f5f5f5 !important;
  cursor: $cursor__not_allowed !important;
}

//capture screenshot
.capture_Screenshot_Details {
  width: 100%;
  margin-left: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

//insert value
.insert_Value_Output_Data_Details {
  gap: 1.25rem;
  margin-top: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
  @include update_fonts($font_weight__500, $font_size__16);
}

.select_Test_Data_And_Icon {
  border-radius: 0.25rem;
  gap: 0.94rem;
  width: 100%;
  padding: 0.25rem;
  border: 0.06rem solid #d6d6d6 !important;
  display: $display__flex;
  align-items: $align_items__center;
}

.select_Test_Data {
  border: none !important;
  border-radius: 0.3rem;
  width: 100%;
  .ant-select-selector {
    border: 0rem solid $silver !important;
    width: 100% !important;
    border-radius: 0.5rem !important;
  }
}

.label_And_Input {
  width: 25%;
}

.label_And_Select_Box {
  width: 40%;
}

.assign_Value_Input_Box {
  padding: 0.6rem !important;
  height: 43px !important;
  border-radius: 0.25rem !important;
  border: 0.06rem solid #d6d6d6 !important;
}

.remove_Select_Variable_Btn {
  gap: 0.3rem;
  height: 2.93rem;
  padding: 0.26rem 0.6rem;
  border-radius: 0.44rem;
  background-color: rgba(255, 18, 18, 0.1);
  color: #ee0b0b;
  border: 0.06rem solid $white;
  cursor: $cursor__pointer;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
  @include update_fonts($font_weight__500, $font_size__14);
}

.disable_Button {
  background-color: $Gray !important;
  color: $disableText !important;
  cursor: $cursor__not_allowed !important;
  border: 0.06rem solid $Gray !important;
}

.select_Box_And_Remove_Btn {
  gap: 0.94rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.set_App_Locale_Variable_Details {
  gap: 0.6rem;
  margin-top: 0.6rem;
  flex-direction: column;
  display: $display__flex;
}

.select_Locale {
  @include common_Select_Box(20%);
}

.checkBox_Text {
  color: #344054;
  @include update_fonts($font_weight__500, $font_size__16);
}

.use_Test_Data_CheckBox {
  gap: 0.38rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.selectBoxAndLabel {
  gap: 0.38rem;
  display: $display__flex;
  align-items: $align_items__center;
}
