@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.icons_And_Result_Tag {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__end;
}

.report_Details {
  color: #6d6d6d;
  @include update_fonts($font_weight__500, $font-size__14);
}

.step-name {
  margin-top: -0.13rem;
  margin-left: 0.06rem;
  white-space: normal;
  max-width: 7.17rem;
  margin-bottom: 0 !important;
  color: #6d6d6d;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__500, $font_size__14);
}

.desc_And_Reason {
  max-width: 8.17rem;
  margin-bottom: 0 !important;
  color: #6d6d6d;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__500, $font_size__14);
}

.key_Value {
  display: $display__flex;
  align-items: $align_items__center;
}

.label_Key {
  white-space: nowrap;
  font-weight: $font_weight__600 !important;
}

.hint_Value {
  max-width: 11.17rem !important;
}

.spinner_Center {
  min-height: 12.5rem;
  display: $display__flex !important;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

.count_Details {
  cursor: $cursor__pointer;
  gap: 4.94rem;
  margin-left: 1.5rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.count_Label {
  color: $black;
  cursor: $cursor__pointer;
  @include update_fonts($font-weight__500, $font_size__26);
}

.locale_Report_Data {
  gap: 0.6rem;
  display: $display__flex;
}

.count_SubText {
  color: $Dark__Blue;
  @include update_fonts($font-weight__500, $font_size__15);
}

.ai_Report_Modal_Title {
  text-transform: uppercase;
  color: $textBlack;
  @include update_fonts($font_weight__600, $font_size__18);
}

.ai_Report_Modal_SubTitle {
  margin-top: 0.3rem;
  gap: 0.19rem;
  display: $display__flex;
}

.ai_Report_Modal_Label {
  color: #4a4d53;
  @include update_fonts($font_weight__600, $font_size__18);
}

.ai_Report_Modal_Text {
  color: $textGray;
  @include update_fonts($font_weight__500, $font_size__18);
}

.count_Level_Details {
  gap: 2rem;
  padding-left: 1rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.count_Level_Item {
  flex-direction: column;
  display: $display__flex;
  text-align: $text_align__center;
}

.count_Label {
  color: #333;
  cursor: $cursor__pointer;
  @include update_fonts($font_weight__700, $font_size__17);
}

.count_Text {
  color: #007bff;
  margin-top: 0.2rem;
  @include update_fonts($font_weight__700, $font_size__15);
}

.count_SubText {
  color: #555;
  margin-top: 0.2rem;
  @include update_fonts($font_weight__500, $font_size__15);
}

.json_Data {
  height: calc(100vh - 24.63rem);
  overflow-y: scroll;
}

.AI_Image {
  margin-right: 0.6rem;
}
