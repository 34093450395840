@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.tab_Name_And_Icon {
  gap: 0.6rem;
  display: $display__flex !important;
  align-items: $align_items__center !important;
}

.info_Icon {
  margin-top: 2px;
}

.header_Button_Group {
  padding: 0.9rem;
  margin-left: 0.38rem;
}

.divider {
  margin-bottom: 0.9rem;
  margin-top: 0;
}

.popover_Text {
  color: $textBlack !important;
  @include update_fonts($font_weight__600, $font_size__14);
}

.add_keys_Btn,
.add_keys_Btn:hover {
  @include common_button($dark_green, $white, 2.5rem);
}

.project_Test_Data_Header {
  gap: 0.6rem;
  margin-right: 1.3rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}

.page_Scroll {
  height: calc(100vh - 145px);
  overflow-y: scroll;
}

.system_Key_Page_Scroll {
  height: calc(100vh - 200px);
  overflow-y: scroll;
}

.action_Icons {
  display: $display__flex;
  justify-content: $justify_content__center;
}

.test_Data_action__buttons {
  margin-left: 0.6rem;
  cursor: $cursor__pointer;
}

.label {
  color: #6d6d6d;
  @include update_fonts($font-weight__400, $font_size__14);
}

.label-name {
  @include update_fonts($font-weight__400, $font_size__14);
}

.data_Set_Title {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.85);
  @include update_fonts($font-weight__600, $font_size__14);
}

//add-edit form
.main_Header {
  top: 3.38rem;
  padding: 0rem 1.3rem 15px 1.3rem;
  background-color: #f2f4f7;
}

.main-Header-Text {
  color: #002858;
  @include update_fonts($font_weight__700, $font_size__20);
}

//system-key
.action_Icon {
  display: $display__flex;
  justify-content: $justify_content__end;
}

.system_Key_Action__Buttons {
  margin-left: 0.6rem;
  cursor: $cursor__pointer;
}

.search_Box {
  margin-right: 1.3rem;
  display: $display__flex;
  justify-content: $justify_content__end;
}
