@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.app_Build_Details_Header {
  padding: 0.9rem;
  margin-left: 0.38rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__space_between;
}

.device_Push_Build_Modal_Search{
  padding: 0.6rem !important;
  padding-top: 0;
}

.select--all-box {
  margin-left: 0.4rem;
  margin-bottom: 0.625rem;
  padding-top: 0.6rem;
  display: $display__flex;
  justify-content: $justify_content__space_between;
}

.checkBox-in-header {
  margin-left: 0.81rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.table-header {
  margin-left: 3.25rem;
}

.header-checkBox {
  margin-left: -0.6rem;
}

.execution_Job_Text {
  margin-bottom: 0.5rem;
  gap: 1.38rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.checkBox_Text {
  gap: 0.6rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.checkBox_Text label {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__15);
}

.justify_End {
  justify-content: flex-end !important;
}

.available_Build_Text {
  color: $textBlack;
  @include update_fonts($font_weight__500, $font_size__17);
}
