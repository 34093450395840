@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.select_Test_Data_Label {
  text-transform: capitalize;
  color: $textGray;
  @include update_fonts($font_weight__700, $font_size__17);
}

.select_Test_Data_Details {
  margin-top: 0.5rem;
  width: 100%;
  gap: 0.3rem;
  display: $display__flex;
  align-items: $align_items__center;
}

.styled_Select_Test_Data {
  border-radius: 0.3rem;
  padding: 0.06rem;
  border: 0.06rem solid #d6d6d6 !important;
  width: 40%;
  .ant-select-selector {
    border: 0rem solid $silver !important;
    width: 100% !important;
    border-radius: 0.5rem !important;
  }
}

.selected_Test_Data_Value {
  width: 14.63rem;
  border-radius: 0.3rem !important;
  border: 0.06rem solid #d6d6d6 !important;
  height: 2.38rem !important;
  font-size: $font_size__15 !important;
  @include update_fonts($font_weight__400);
}
