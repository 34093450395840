@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.subHeaderIcons {
  @include design_Actions_icon;
}

.subHeaderIcons > span {
  font-size: $font_size__13;
}

.stopButtonText {
  color: $pink;
}

.label {
  cursor: $cursor__pointer;
  @include update_fonts($font_weight__500, $font_size__13);
}

.dropDownArrow {
  gap: 0.375rem;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $justify_content__center;
}

@media only screen and (max-width: $max_width__1562) {
  .subHeaderIcons > span {
    display: $display__none;
  }
}
@media (min-width: $min_width__1562) {
  .hideTooltip {
    display: none;
  }
}
