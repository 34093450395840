@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.deviceCardOrDevice {
  width: 13.8125rem;
  height: 16.5rem;
}

.checkBoxOrIcon {
  gap: 0.6rem;
  justify-content: $justify_content__end;
}

.checkBox{
  margin-right:0.6rem;
}

.dragAndDropDeviceIcon{
  border: 0.13rem dashed #1890ff;
  padding: 4.69rem;
  width: 25rem;
  border-radius: 0.31rem;
}

.dragAndDropDeviceIconText{
  color: #1890ff;
  display: $display__flex;
  align-items: $align_items__center;
  justify-content: $align_items__center;
  @include update_fonts($font_weight__600, $font_size__16);
}

.devicesList{
  width: calc(100vw - 12.5rem);
  overflow-x: auto;
  display: $display__flex;
  align-items: $align_items__center;
}
